import React from "react";
import {
  Input
} from 'reactstrap';

const LaboralBulkLoadEmployeeListHidden = ({ formName, filter, customFieldsHeaders }) => {

  const drawEmployeesHidden = () => {
    if (filter && filter?.length > 0) {
      return(
        <>
        { _.map(filter, function(item){
            return(
              <div key={ _.uniqueId(item.id) }>
                <Input type="hidden"
                  id={ `${item.row}-controldoc_id` }
                  name={ `${formName}[items_attributes][][controldoc_id]` }
                  defaultValue={ item.controldoc_id }
                />
                <Input type="hidden"
                  id={ `${item.row}-security_layer` }
                  name={ `${formName}[items_attributes][][security_layer]` }
                  defaultValue={ item.security_layer }
                />
                <Input type="hidden"
                  id={ `${item.row}-email` }
                  name={ `${formName}[items_attributes][][email]` }
                  defaultValue={ item.value }
                />
                <Input type="hidden"
                  id={ `${item.row}-name` }
                  name={ `${formName}[items_attributes][][name]` }
                  defaultValue={ item.name }
                />
                <Input type="hidden"
                  id={ `${item.row}-identifier` }
                  name={ `${formName}[items_attributes][][identifier]` }
                  defaultValue={ item.identifier }
                />
                { drawCustomFields(item) }
              </div>
              )
          })
        }
        </>
      )
    }
  }

  const drawCustomFields = (item) =>{
    return _.map(customFieldsHeaders, function(field){
      return(
        <Input type="hidden"
          key={ _.uniqueId(item.field) }
          id={ `${item.row}-${field}` }
          name={ `${formName}[items_attributes][][custom_fields][${field}]` }
          defaultValue={ item[field] }
        />
      )
    })
  }

  return(
    <div className="row">
      { drawEmployeesHidden() }
    </div>
  )
}
export default LaboralBulkLoadEmployeeListHidden;
