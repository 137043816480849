import React, {
  useState,
  useMemo,
  useEffect
} from 'react';

import {
  Row,
  Col,

  Nav,
  NavItem,
  NavLink,

  TabContent,
  TabPane,

  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,

  Button
} from 'reactstrap';

import UserProfilePersonalInformationAttributes from './personal_information_attributes';
import UserProfileSignatureInformationAttributes from './signature_information_attributes';

const UserProfileFormAttributes = props => {

  const {
    user,
    data,
    callbacks
  } = props;

  const [currentTab, setCurrentTab] = useState(
    ((user.completed_personal_information && user.completed_signature_information) || !user.completed_personal_information) ? 'personalInformationTab' : 'signatureInformationTab'
  )

  const personalInformationCompleted = useMemo(() => {
    return user.completed_personal_information;
  }, [user.completed_personal_information])

  const personalInformationCompletedIcon = useMemo(() => {
    if(personalInformationCompleted){
      return <i className="fas fa-check-circle text-success fa-lg ml-2"></i>
    }
  }, [user.completed_personal_information])

  const signatureInformationCompleted = useMemo(() => {
    return user.completed_signature_information;
  }, [user.signature_information_completed])

  const signatureInformationCompletedIcon = useMemo(() => {
    if(signatureInformationCompleted){
      return <i className="fas fa-check-circle text-success fa-lg ml-2"></i>
    }
  }, [user.completed_signature_information])

  return(
    <Row>
      <Col>
        <Nav justified pills className='mb-3'>
          <NavItem>
            <NavLink
              className={ currentTab == 'personalInformationTab' ? 'active' : null }
              onClick={ event => setCurrentTab('personalInformationTab') }
            >
              <small className='d-block'>Paso 1</small>
              <i className="fas fa-user mr-2"/>
              <span>Información Personal</span>
              { personalInformationCompletedIcon }
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={ currentTab == 'signatureInformationTab' ? 'active' : null }
              onClick={ event => setCurrentTab('signatureInformationTab') }
              disabled={ !personalInformationCompleted }
            >
              <small className='d-block'>Paso 2</small>
              <i className="fas fa-key mr-2"/>
              <span>Verificación en dos pasos</span>
              { signatureInformationCompletedIcon }
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={ currentTab }>
          <TabPane tabId="personalInformationTab">
            <Row>
              <Col sm="12">
                <Card className='p-md-4'>
                  <CardHeader className='border-0'>
                    <div className="text-center">
                      <p className="h5 text-primary font-weight-bold mb-0">
                        <small className='d-block'>Paso 1</small>
                        <i className="fas fa-user mr-2"></i>
                        Información Personal
                      </p>
                      <small>
                        Los campos marcados con asteriscos (
                        <strong className='text-danger'>*</strong>
                        ) son obligatorios
                      </small>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <UserProfilePersonalInformationAttributes
                      user={ user }
                      data={ data }
                      callbacks={ callbacks }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="signatureInformationTab">
            <Row>
              <Col>
                <Card className='p-md-4'>
                  <CardHeader className='border-0'>
                    <div className="text-center">
                      <p className="h5 text-primary font-weight-bold mb-0">
                        <small className='d-block'>Paso 2</small>
                        <i className="fas fa-key mr-2"></i>
                        Verificación en dos pasos
                        <strong className='text-danger'>*</strong>
                      </p>
                      <small className='text-danger'>
                        Esta acción es obligatoria
                      </small>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <UserProfileSignatureInformationAttributes
                      user={ user }
                      data={ data }
                      callbacks={ callbacks }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  )
}

export default UserProfileFormAttributes;
