import axios from 'axios';

export const updatePersonalInformation = (formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.update_personal_information_profiles_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const enableCustomPin = (formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.enable_custom_pin_profiles_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const disableCustomPin = (formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.disable_custom_pin_profiles_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const enableDynamicPin = (formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.enable_dynamic_pin_profiles_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const disableDynamicPin = (formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.disable_dynamic_pin_profiles_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const sendOtpCodeToken = (callback) => {
  var promise = axios({
    method: 'get',
    url: Routes.send_otp_code_token_profiles_path({'format': 'json'}),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
