import React, { useState, useEffect } from "react";
import I18n from "i18n-js";

const BootstrapTableFilterHeader = props => {

  let { column, colIndex, args} =  props;
  let { sortElement, filterElement } = args;

  let filter = args?.filter;

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    let bootstrapTableProps = props?.bootstrapTable.current.props;

    if(_.has(bootstrapTableProps, 'showSearchFilters') && bootstrapTableProps.showSearchFilters != isActive){
      setIsActive(bootstrapTableProps.showSearchFilters);
    }
  }, [props])

  useEffect(() => {
    // Al cerrar, se limpian los filtros
    if(!isActive){
      let currentFilters = props?.bootstrapTable.current.filterContext.currFilters;
      currentFilters[column.dataField] = {}

      props?.bootstrapTable.current.handleRemoteFilterChange(
        currentFilters
      )
    }
  }, [isActive])

  const drawInputSearch = () => {
    if(isActive){
      return(
        <>
          { filterElement }
        </>
      )
    }
  }

  return(
    <div>
      <div className="d-flex justify-content-between" style={{ minWidth: '250px' }}>
        <p className="mb-0">{ column.text }</p>
        { sortElement }
      </div>
      { drawInputSearch() }
    </div>
  )
}

export default BootstrapTableFilterHeader;
