import React, { useContext } from 'react'

import WorkflowCustomNode from './custom_node.jsx';
import WorkflowCustomNodeNoEditable from './custom_node_no_editable';

import WorkflowDiagramContext from '../../diagram_context';

const WorkflowOutputNode = (props) => {

  const {
    readOnly
  } = useContext(WorkflowDiagramContext);

  const CustomNodeComponent = readOnly ? WorkflowCustomNodeNoEditable : WorkflowCustomNode

  return (
    <CustomNodeComponent
      config={ { type: 'output' } }
      node={ props }
    >
      <div
        style={{
          width: 50,
          height: 50
        }}
      >
        <div
          className='w-100 h-100 rounded-circle border border-danger bg-danger d-flex align-items-center justify-content-center'
        >
          <i className="fas fa-flag-checkered fa-2x"></i>
        </div>
      </div>
    </CustomNodeComponent>
  );
}

export default WorkflowOutputNode;

