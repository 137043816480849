import React, { useState } from 'react';

import I18n from 'i18n-js';

import DocumentAttributes from '../../document/attributes';
import { DocumentSignerConfigProvider } from '../../document/signer_config_context';

import { index as indexLaboralHiring } from '../hiring/axios';
import {
  create as createDocument,
  update as updateDocument
} from './axios';

const LaboralHiringDocumentForm = props => {

  const [_document, setDocument] = useState(props?.document || {})

  return(
    <div className="row">
      <div className="col-12">
        <DocumentAttributes
          configuration={{
            entity: {
              key: 'hirings',
              label: I18n.t('activerecord.attributes.laboral/hiring_document.entity_id'),
              placeholder: `-- Selecciona una ${ I18n.t('activerecord.attributes.laboral/hiring_document.entity_id') } --`
            },
            formName: 'laboral_hiring_document',
            context: 'laboral',
            defaultRequestParams: {}
          }}
          actions={{
            indexEntities: indexLaboralHiring,
            createDocument: createDocument,
            updateDocument: updateDocument,
            showDocumentPath: Routes.laboral_hiring_document_path,
            indexDocumentsPath: Routes.laboral_hiring_documents_path(),
            showCheckListTaskPath: Routes.laboral_check_list_hiring_task_path
          }}
          _document={ _document }
          data={ props?.data }
          currentUser={ props?.currentUser || {} }
          currentCustomer={ props?.currentCustomer || {} }
          callbackSetDocument={ setDocument }
        />
      </div>
    </div>
  )
}

export default LaboralHiringDocumentForm;
