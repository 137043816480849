import React, { useEffect, useState, useCallback } from "react";
import I18n from 'i18n-js';
import AbstractBulkLoadEntityInputFile from './input_file'

// Table
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { options } from '../../../../helper/pagination';
import { defaultTableHeaderStyle } from "../../../../helper/bootstrap-table/helper";
import BootstrapTableCustomFilter from "../../../../helper/bootstrap-table/table_custom_filter"
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";

import useFilterRequestParams from "../../../../hooks/useFilterRequestParams";

import {
  Input,
  Button,
  FormGroup
} from 'reactstrap';

import { create } from "./axios";
import { index as indexAbstractEntities } from "../../../entity/axios";

const AbstractBulkLoadEntityForm = (props) => {
  const currentEntityType = props?.currentEntityType
  const headers = _.concat(props.defaultHeaders, _.map(props.customFields, 'code'), 'controldoc_id');
  const customFields = props?.customFields;

  const [entities, setEntities] = useState([]);
  const [file, setFile] = useState({});
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
  const [updateEntities, setUpdateEntities] = useState(false);
  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const { customer, securityLayers } = props;

  useEffect(() => {
    if(updateEntities){
      setEntities(entities)
      setUpdateEntities(false)
    }
  }, [updateEntities])

  useEffect(() => {
    if(entities.length > 0){
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [entities])

  const handleValues = (e, index, row) => {
    let key = e.target.id.split("-")[1];
    let _entities = [ ... entities ]

    _entities[index][key] = e.target.value;

    setEntities(_entities);
    setUpdateEntities(true)
  }

  useEffect(() => {
    _.each(currentEntityType.related_entity_types, function(entityType){
      let _requestFilterParams = { ... { ... requestFilterParams }, current_entity_type_id: entityType?.id }
      requestFilterParams['filterrific']['show_all'] = true;

      indexAbstractEntities(_requestFilterParams, response => {
        entityType.entities = response?.data?.entities || []
      })
    })
  }, [])

  const handleDestroyRow = (e, rowNumber) => {

    swalWithBootstrap.fire({
      title: 'Eliminar fila',
      html: '¿Estás seguro de eliminar la fila?',
    }).then(result => {
      if(result.isConfirmed){
        _.remove(entities, item => { return item.row == rowNumber });

        setUpdateEntities(true);
        swal.fire(
          "Eliminado!",
          "La fila ha sido eliminada correctamente",
          "success"
        )
      }
    })
  }

  const columnRelatedEntities = () => {
    return _.map(currentEntityType.related_entity_types, function(entityType){
      return {
        dataField: entityType.singular_name,
        text: entityType.singular_name,
        headerStyle: defaultTableHeaderStyle,
        formatter: function(value, row, index){
          return(
            <Input
              key={ _.uniqueId('select-related-entity-type-') }
              type='select'
              id={ `${ row.row }-${entityType.singular_name }` }
              placeholder={ I18n.t('actions.inputs.select.placeholder') }
              defaultValue={ value }
              onChange={ e => handleValues(e, index, row) }
            >
              <option value=""></option>
              { _.map(entityType.entities, function(entity){
                return(
                  <option
                    value={ entity.identifier }
                    key={ _.uniqueId('related-entity-option') }
                  >
                    { entity.name }
                  </option>
                )
              })}
            </Input>
          )
        }
      }
    })
  }

  const columnActionsInRow = () => {
    return [
      {
        dataField: "row",
        text: I18n.t("actions.actions"),
        headerStyle: {...defaultTableHeaderStyle, width: '100px'},
        editable: false,
        formatter: (cellContent, row) => {
          return(
            <div className="d-flex align-items-center justify-content-center">
              <Button
                color="danger"
                outline
                className="border-0"
                onClick={ e => handleDestroyRow(e, row.row)}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            </div>
          )
        }
      }
    ]
  }

  const customFieldSelectOptions = useCallback((listOptions) => {
    return listOptions.map((option) => {
      return (
        <option
          key={ _.uniqueId("_custom_fields") }
          value={ option }
        >
          { option }
        </option>
      );
    });
  },[])

  const customFieldSelectInput = (value, row, index, customField) => {
    if(customField.dataType == "list"){
      return (
        <Input
          type="select"
          key={ _.uniqueId("_custom_fields") }
          value={ value }
          onChange={ e => handleValues(e, index, row) }
          id={ `${ row.row }-${ customField.code }` }
        >
          <option key={ _.uniqueId("_custom_fields") } value=""></option>
          { customFieldSelectOptions(customField.list_options) }
        </Input>
      );
    }else{
      return(
        <Input
          key={ _.uniqueId(`header-custom-field-${ customField.id }-`) }
          type="text"
          id={ `${ row.row }-${ customField.code }` }
          onBlur={ e => handleValues(e, index, row) }
          defaultValue={ value }
        />
      )
    }
  }

  const columnsCustomFields = () => {
    return _.map(customFields, function(customField){
      return {
        dataField: customField.code,
        text: customField.label,
        headerStyle: defaultTableHeaderStyle,
        formatter: function(value, row, index){
          return customFieldSelectInput(value, row, index, customField)
        },
        filter: textFilter({
          placeholder: `Ingrese ${ _.lowerCase(customField.label) }`
        })
      }
    })
  }

  const columnSecurityLayer = () => {
    if(customer.security_layer){
      return [{
        dataField: 'security_layer',
        text: I18n.t('activerecord.attributes.abstract/entity.security_layer'),
        headerStyle: defaultTableHeaderStyle,
        formatter: function(value, row, index){
          return(
            <Input
              key={ _.uniqueId(`security-layer-input-`) }
              type="select"
              id={ `${ row.row }-security_layer` }
              onChange={ e => handleValues(e, index, row) }
              defaultValue={ value }
            >
              { drawSecurityLayerOptions() }
            </Input>
          )
        },
        filter: textFilter({
          placeholder: `Ingrese ${ _.lowerCase(I18n.t('activerecord.attributes.abstract/entity.security_layer')) }`
        })
      }]
    }else{
      return []
    }
  }

  const drawSecurityLayerOptions = () => {
    return _.map(securityLayers, security_layer => {
      return(
        <option
          value={ security_layer.name }
          key={ _.uniqueId('security_layer-option-') }
        >
          { security_layer.name }
        </option>
      )
    })
  }

  const columnIdentifier = () => {
    return [{
      dataField: "identifier",
      text: I18n.t('activerecord.attributes.abstract/entity.identifier'),
      headerStyle: defaultTableHeaderStyle,
      formatter: function( value, row, index) {
        return(
          <Input
            key={ _.uniqueId('identifier-input-') }
            type='text'
            id={ `${ row.row }-identifier` }
            onBlur={ e => handleValues(e, index, row) }
            defaultValue={ value }
          />
        )
      },
      filter: textFilter({
        placeholder: `Ingrese ${ I18n.t('activerecord.attributes.abstract/entity.identifier') }`
      }),
    }]
  }

  const columns = _.concat(columnSecurityLayer(), columnIdentifier(), columnRelatedEntities(), columnsCustomFields(), columnActionsInRow() )

  const drawEntityTable = () => {
    if(entities.length > 0){
      return(
        <div className="row">
          <div className="col-12">
            <h2 className='card-title mb-2'>
              { I18n.t('abstract.bulk.load.entities.form.table.title') } ({ `${ entities?.length }`}):
            </h2>

            <BootstrapTable
              id="table-primary"
              keyField='row'
              data={ entities }
              columns={ columns }
              wrapperClasses="table-responsive height-600"
              pagination={ paginationFactory(options) }
              hover
              noDataIndication="No existen registros"
              filter={filterFactory()}
            />
          </div>
        </div>
      )
    }
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: `Realizar carga de ${ currentEntityType.plural_name }`,
      html: `¿Estás seguro de realizar la carga masiva de ${ currentEntityType.plural_name }?`,
    }).then( result => {
      if(result.isConfirmed){
        handleSubmit()
      }
    })
  }

  const handleSubmit = () => {
    let formData = getFormData();
    create(currentEntityType.hashid, formData, response => {
      if(response.status == 201){
        window.location = Routes.abstract_bulk_load_entity_path(currentEntityType.hashid, response.data.hashid);
      } else {
        console.error("Problema al guardar la carga masiva abstracta")
      }
    })
  }

  const getFormData = () => {
    let formData = new FormData();
    formData.append("abstract_bulk_load_entity[file]", file || "");

    _.each(entities, function(entity){
      formData.append("abstract_bulk_load_entity[items_attributes][][controldoc_id]", entity?.controldoc_id || "");
      formData.append("abstract_bulk_load_entity[items_attributes][][identifier]", entity?.identifier || "");
      formData.append("abstract_bulk_load_entity[items_attributes][][security_layer]", entity?.security_layer || "");

      _.each(currentEntityType.related_entity_types, function(relatedEntityType){
        formData.append("abstract_bulk_load_entity[items_attributes][][config_entities][]", entity[relatedEntityType.singular_name])
      })

      _.each(customFields, function(customField){
        formData.append(`abstract_bulk_load_entity[items_attributes][][custom_fields][${ customField.code }]`, entity[customField.code]);
      })
    })

    return formData
  }

  return(
    <div className="row">
      <div className="col-12">
        <AbstractBulkLoadEntityInputFile
          currentEntityType={ currentEntityType }
          headers={ headers }
          customFields={ customFields }
          setEntities={ setEntities }
          setFile={ setFile }
        />

        { drawEntityTable() }
      </div>

      <div className="col-12">
        <FormGroup className="text-end">
          <a
            href={ Routes.abstract_bulk_load_entities_path(currentEntityType.hashid) }
            className="btn btn-default"
          >
            { I18n.t("actions.back") }
          </a>

          <Button
            color="success"
            className="ml-2"
            onClick={ handleConfirmSubmit }
            disabled={ disabledSubmitButton }
          >
            { I18n.t("actions.save") }
          </Button>
        </FormGroup>
      </div>
    </div>
  )
}

export default AbstractBulkLoadEntityForm;
