import React, {
  useState,
  useMemo
} from 'react';

import {
  Row,
  Col,

  Button,

  Collapse,

  Card,
  CardHeader,
  CardBody,

  Form,
  FormGroup,
  Input,
  Label,

  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import I18n from 'i18n-js';

import { QRCodeSVG } from 'qrcode.react';

import {
  inputId,
  inputName,
} from '../../helper/form';

import pinDynamicImage from 'images/icons/pin-dynamic.png';
import googleAuthLogo from 'images/logos/google_auth_logo.png';
import gmailLogo from 'images/logos/gmail_logo';
import appStoreBadge from 'images/logos/badge_app_store.png';
import googlePlayBadge from 'images/logos/badge_google_play';

import OtpInputForm from '../../common/otp_input_form';

import FieldError from '../../helper/FieldError';

import {
  sendOtpCodeToken as sendOtpCodeTokenService,
  enableDynamicPin as enableDynamicPinService,
  disableDynamicPin as disableDynamicPinService
} from './axios';

const UserProfileSignatureDynamicPinAttributes = props => {
  const {
    configuration: {
      formName = 'profile'
    } = {},
    callbacks = {}
  } = props;

  const [user, setUser] = useState(props?.user || {} )
  const [openModalGAuthenticatorModal, setOpenModalGAuthenticatorModal] = useState(false)

  const onChangeUser = (event, key) => {
    let value = event.target.value;
    const type = event.target.type;
    if(type == 'checkbox'){
      value = event.target.checked ? value : null
    }
    setUser(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeOtpToken = (value, attributeName) => {
    setUser(prevState => {
      return { ... prevState, [attributeName]: value }
    })
  }

  const onSendOtpCodeTokenService = event => {
    event.preventDefault();

    sendOtpCodeTokenService(response => {
      if(response.status == 200){
        swalToastBootstrap.fire({
          icon: 'success',
          title: 'Hemos enviado el PIN al correo electrónico'
        });
      } else {
        swalToastBootstrap.fire({
          icon: 'error',
          title: 'Tenemos problemas al generar el PIN'
        });
      }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[otp_code_token]`, user.otp_code_token || '');

    return formData;
  }

  const dynamicPinService = useMemo(() => {
    if(user.signature_method == 'disabled'){
      return enableDynamicPinService
    } else if(user.signature_method == 'dynamic_pin'){
      return disableDynamicPinService
    }
  }, [])

  const onSubmit = event => {
    event.preventDefault();

    const formData = getFormData();

    dynamicPinService(formData, response => {
      if(response.status == 200){
        if(_.isFunction(callbacks?.callbackAfterProfileCompleted) && response.data.completed_profile){
          callbacks.callbackAfterProfileCompleted()
        } else {
          location.reload();
        }
      } else {
        setUser(response.data)
      }
    })
  }

  const titleHeader = useMemo(() => {
    if(user.signature_method == 'disabled'){
      return 'Habilitar por PIN dinámico'
    } else if(user.signature_method == 'dynamic_pin'){
      return 'Deshabilitar por PIN dinámico'
    }
  }, [])

  const submitButton = useMemo(() => {
    if(user.signature_method == 'disabled'){
      return (
        <Button
          color='success'
          type='submit'
        >
          Habilitar verificación en dos pasos
        </Button>
      )
    } else if(user.signature_method == 'dynamic_pin'){
      return (
        <Button
          color='danger'
          type='submit'
        >
          Deshabilitar verificación en dos pasos
        </Button>
      )
    }
  }, [])

  const GAuthenticatorInstructionButton = useMemo(() => {
    if(user.signature_method == 'disabled'){
      return(
        <Button
          color='secondary'
          onClick={ event => setOpenModalGAuthenticatorModal(true) }
        >
          Obtener PIN
        </Button>
      )
    }
  }, [])

  return(
    <Row>
      <Col>
        <Card className='border-0 mb-3 hover-shadow-card options-card'>
          <CardHeader
            className='border-bottom-0 option-btn p-0 border-0 bg-light'
            type="button"
            htmlFor={ inputId(formName, 'signature_method_dynamic_pin') }
          >
            <FormGroup className='pl-0' check>
              <Label className='mb-0 py-3 pl-5 pr-3 d-block text-left w-100'>
                <Input
                  type='checkbox'
                  id={ inputId(formName, 'signature_method_dynamic_pin') }
                  name={ inputName(formName, 'signature_method_dynamic_pin') }
                  checked={ user.signature_method == 'dynamic_pin' }
                  value={ 'dynamic_pin' }
                  onChange={ event => onChangeUser(event, 'signature_method') }
                  disabled={ user.signature_method == 'custom_pin' }
                />
                <div className="d-flex align-items-center justify-content-start pl-2">
                  <img src={ pinDynamicImage } alt="Pin Personalizado" style={{width: 40}} className='mr-1'/>
                  <div className='pl-1'>
                    <p className="h3 font-weight-bold mb-0">
                      { titleHeader }
                    </p>
                    <p className="mb-0 small">
                      Puedes solicitar que se genere un
                      <strong>&nbsp;PIN dinámico&nbsp;</strong>
                      usando una App o tu email.
                    </p>
                  </div>
                </div>
              </Label>
            </FormGroup>
          </CardHeader>

          <Collapse isOpen={ user.signature_method == 'dynamic_pin'}>
            <Form onSubmit={ onSubmit }>
              <CardBody>
                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 1: </strong>
                      <span>Elige la forma de obtener el PIN de confirmación</span>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md='6'>
                    <Card className='bg-light border-0 material-shadow h-100'>
                      <CardBody>
                        <div className="float-left mr-2">
                          <img src={ googleAuthLogo } style={{ width: 55 }} className='mr-1' alt='Google Authenticator'/>
                        </div>

                        <div className="pl-5">
                          <p className="h5 font-weight-bold mb-0">Usando Google Authenticator</p>
                        </div>

                        <p>
                          Puedes utilizar el PIN generado por la <strong>aplicación instalada en tu teléfono.</strong>
                        </p>

                        <div className="text-center">
                          { GAuthenticatorInstructionButton }
                        </div>
                          <GoogleAuthenticatorInstructionModal
                            user={ user }
                            openModal={ openModalGAuthenticatorModal }
                            setOpenModal={ setOpenModalGAuthenticatorModal }
                          />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='6'>
                    <Card className='bg-light border-0 material-shadow h-100'>
                      <CardBody>
                        <div className="float-left mr-2">
                          <img src={ gmailLogo } style={{ width: 55 }} className='mr-1' alt='Correo electrónico'/>
                        </div>

                        <div className="pl-5">
                          <p className="h5 font-weight-bold mb-0">Usando tu email</p>
                        </div>

                        <p>
                          Puedes solicitar que enviemos el PIN en un email a tu cuenta de <strong>correo electrónico.</strong>
                        </p>

                        <div className="text-center">
                          <Button
                            color='primary'
                            onClick={ event => onSendOtpCodeTokenService(event) }
                          >
                            Enviar PIN al correo
                          </Button>
                        </div>
                          <GoogleAuthenticatorInstructionModal
                            user={ user }
                            openModal={ openModalGAuthenticatorModal }
                            setOpenModal={ setOpenModalGAuthenticatorModal }
                          />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className='my-3'>
                  <Col>
                    <p
                      className='h6 text-left mb-3 font-wight-lighter'
                    >
                      <strong className='d-block'>Paso 2: </strong>
                      <span>Ingresa el PIN generado por la App o enviado a tu correo</span>
                    </p>

                    <div className="text-center my-3">
                      <FieldError errors={ _.get(user, 'errors.otp_code_token') }>
                        <OtpInputForm
                          configuration={{
                            formName: formName,
                            attributeName: 'otp_code_token'
                          }}
                          callbacks={{
                            onChangeOtpToken: onChangeOtpToken
                          }}
                          pinCode={ user.otp_code_token || '' }
                        />
                      </FieldError>
                    </div>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='text-center'>
                    { submitButton }
                  </Col>
                </Row>
              </CardBody>
            </Form>
          </Collapse>
        </Card>
      </Col>
    </Row>
  )
}

export default UserProfileSignatureDynamicPinAttributes;


const GoogleAuthenticatorInstructionModal = props => {
  const {
    user,
    openModal,
    setOpenModal
  } = props

  return(
    <Modal
      isOpen={ openModal }
      toggle={ event => setOpenModal(false) }
      unmountOnClose={ true }
      className='text-dark'
      size='lg'
    >
      <ModalHeader
        toggle={ event => setOpenModal(false) }
      >

        <div className="float-left mr-2">
          <img src={ googleAuthLogo } style={{ width: 55 }} className='mr-1' alt='Google Authenticator'/>
        </div>

        <div className="pl-2">
          <p className="h5 mb-0 text-left">Verificación en dos pasos</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="mb-4 sign-steps">
          <div className="step-item mb-4">
            <div className="number-step float-left font-weight-bold h2 text-white">1</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Si no tienes la aplicación puedes descargarla en tu teléfono desde uno de estos links:
              </div>
              <div className="text-center mt-2">
                <a
                  href='https://itunes.apple.com/cl/app/google-authenticator/id388497605'
                  target='_blank'
                  style={{ height: 55 }}
                  className='mt-2 mr-2'
                >
                  <img src={ appStoreBadge } alt="App Store"/>
                </a>

                <a
                  href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                  target='_blank'
                  style={{ height: 55 }}
                  className='mt-2 mr-2'
                >
                  <img src={ googlePlayBadge } alt="Google Play"/>
                </a>
              </div>
            </div>
          </div>

          <div className="step-item mb-4">
            <div className="number-step float-left font-weight-bold h2 text-white">2</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Abre Google Authenticator y escanea este código con la aplicación:
              </div>
              <div className="text-center mt-2">
                <QRCodeSVG
                  value={ `otpauth://totp/Controldoc?secret=${ user.otp_secret_key }` }
                />

              </div>
              <div className="mt-3">
                O ingresa manualmente el siguiente código en la aplicación

                <div className="mt-2 text-center">
                  <p className="lead font-weight-bold">
                    { user.otp_secret_key }
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="step-item mb-4">
            <div className="number-step float-left font-weight-bold h2 text-white">3</div>
            <div className="pl-5 text-left">
              <div className="mb-0">
                Google Authenticator te mostrará el PIN de confirmación que debes ingresar en ControlDoc
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={event => setOpenModal(false) }>
          { I18n.t('actions.close') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}
