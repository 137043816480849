import React from "react";
import I18n from 'i18n-js';

export const pageListRenderer = ({ pages, onPageChange }) => {

  const handleClick = (e, page) => {
    e.preventDefault();
    onPageChange(page);
  };

  const toRemove = ['<','<<','>>','>']
  const pageWithoutIndication = _.filter(pages, (p, index) => !_.includes(toRemove, p.page))

  return (
    <div className="d-flex justify-content-center">
      <nav className="pagy-bootstrap-nav" aria-label="pager">
        <ul className="pagination">
          {
            pageWithoutIndication.map((p, index) => {
              return (
                <li key={ index } role="presentation" className={ `page-item ${p.active ? 'active' : '' } ${ p.disabled ? 'prev disabled' : '' }`}>
                  <a
                    role="menuitem"
                    data-page={ p.page }
                    className={ `page-link` }
                    style={{ cursor: 'pointer' }}
                    onMouseDown={ (e) => handleClick(e, p.page) }
                  >
                    { p.page }
                  </a>
                </li>
              )
            })
          }
        </ul>
      </nav>
    </div>
  );
};

const sizePerPageOptionRenderer = ({
  text,
  page,
  onSizePerPageChange
}) => (
  <li
    key={ text }
    role="presentation"
    className="dropdown-item"
  >
    <a
      className="d-block"
      href="#"
      tabIndex="-1"
      role="menuitem"
      data-page={ page }
      onMouseDown={ (e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      } }
    >
      { text }
    </a>
  </li>
);

export const options = props => {
  let _options = {
    pageListRenderer,
    sizePerPage: props?.sizePerPage || 10,
    onSizePerPageChange: props?.onSizePerPageChange,
    alwaysShowAllBtns: true,
    // hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    prePageText: <div dangerouslySetInnerHTML={{__html: I18n.t('pagy.nav.prev')}} />,
    nextPageText: <div dangerouslySetInnerHTML={{__html: I18n.t('pagy.nav.next')}} />,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '75', value: 75 }
    ],
    sizePerPageOptionRenderer: sizePerPageOptionRenderer
  }

  if(props?.totalSize || props?.totalSize === 0){
    _options = _.merge(_options, { totalSize: props.totalSize })
  }

  if(props?.page){
    _options = _.merge(_options, { page: props.page })
  }

  return _options
}
