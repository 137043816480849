$(function(){
  const workflowForm = document.querySelector('#new_workflow_process, #edit_workflow_process');

  if(workflowForm){
    const requireDisclaimerCheckbox = document.getElementById('workflow_process_require_disclaimer')

    const disclaimerContainer = document.getElementById('disclaimer_container')

    requireDisclaimerCheckbox.addEventListener('change', event => {
      const checked = event.target.checked;

      toggleDisclaimerContainer(checked);
    })

    const toggleDisclaimerContainer = (requireDisclaimer) => {
      if(requireDisclaimer){
        disclaimerContainer.classList.remove('d-none')
      } else {
        disclaimerContainer.classList.add('d-none')
      }
    }

    toggleDisclaimerContainer(requireDisclaimerCheckbox.checked);
  }
});
