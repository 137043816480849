import axios from "axios";

export const create = (currentEntityTypeId, formData, callback) => {
  var promise = axios({
    method: 'POST',
    url: Routes.abstract_check_list_processes_path(currentEntityTypeId, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const update = (params, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.abstract_check_list_process_path({id: params.id, current_entity_type_id: params.currentEntityTypeId, format: "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const share = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.share_abstract_check_list_process_path({...{...requestParams}, format: "json", _options: true}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
