import React, {
  useCallback,
  useContext,
  useMemo
} from 'react'

import {
  Handle,
  Position,
  NodeResizer,
  NodeToolbar,
  useReactFlow,
  getConnectedEdges
} from 'reactflow';

import I18n from 'i18n-js';

import WorkflowDiagramContext from '../../diagram_context';

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const WorkflowCustomNode = ({children, config, node }) => {
  const { id, data, isConnectable, selected } = node;
  const { type } = config;

  const {
    setNodes, getNodes, getNode,
    getEdges,
    deleteElements,
  } = useReactFlow();

  const {
    callbacks: {
      isNodeDeletable,
      onNodeUpdate,
      selectNodeOnEdit
    },
    refs: { offCanvasNodeFormRef }
  } = useContext(WorkflowDiagramContext);

  const nodeIsConnectable = () => {
    if(type == 'input'){
      const edges = getEdges();
      const connectedEdges = getConnectedEdges([node], edges)
      return connectedEdges < 1;
    } else {
      return true
    }
  }

  const drawHandleSourceOption = () => {
    if(type == 'input' || type == 'default'){
      return(
        <Handle
          type="source"
          position={ Position.Right }
          className='bg-primary'
          style={{ ...DEFAULT_HANDLE_STYLE }}
          isConnectable={ nodeIsConnectable() }
        />
      )
    }
  }

  const drawHandleTargetOption = () => {
    if(type == 'default' || type == 'output'){
      return(
        <Handle
          type="target"
          position={ Position.Left }
          className='bg-primary'
          style={{ ...DEFAULT_HANDLE_STYLE }}
          isConnectable={isConnectable}
        />
      )
    }
  }

  const drawEditButton = useCallback(() => {
    return(
      <button
        className="btn btn-sm btn-info mr-2"
        role="button"
        onClick={ e => selectNodeOnEdit(getNode(id)) }
      >
        <i className="fas fa-edit"></i>
      </button>
    )
  }, [])

  const drawDeleteButton = useCallback(() => {
    if(isNodeDeletable(node)){
      return(
        <button
          className="btn btn-sm btn-danger mr-2"
          onClick={ handleDeleteNode }
        >
          <i className="fas fa-trash-alt"></i>
        </button>
      )
    }
  }, [])

  const handleDeleteNode = () => {
    let node = getNode(id);

    deleteElements({nodes: [node]})
  }

  const onResizeEnd = useCallback((event, params) => {
    let _node = getNode(id);

    _node.width = params.width
    _node.height = params.height

    onNodeUpdate(_node);
  }, [])

  return (
    <>
      <NodeResizer
        minWidth={ 200 }
        minHeight={ 100 }
        isVisible={ selected }
        onResizeEnd={ onResizeEnd }
        keepAspectRatio={ true }
      />
      { children }

      { drawHandleSourceOption() }
      { drawHandleTargetOption() }

      <NodeToolbar isVisible={ selected } position={ Position.Top }>
        { drawEditButton() }
        { drawDeleteButton() }
      </NodeToolbar>
    </>
  );
}

export default WorkflowCustomNode;

