import React, { useState, useMemo } from 'react';

import I18n from 'i18n-js';

import DocumentAttributes from '../../document/attributes';
import { DocumentSignerConfigProvider } from '../../document/signer_config_context';

import { index as indexAbstractEntity } from '../entity/axios';
import {
  create as createDocument,
  update as updateDocument
} from './axios';

const AbstractDocumentForm = props => {
  const [_document, setDocument] = useState(props?.document || {})

  const customFieldEmailNotifications = useMemo(() => {
    const entityTypes = [
      ... props?.currentEntityType.related_entity_types,
      props?.currentEntityType
    ]

    let emailCustomFields = {}

    _.each(entityTypes, entityType => {
      const customFieldEmailNotifications = _.filter(
        entityType.custom_fields, { is_notification_email: true }
      )

      if(customFieldEmailNotifications.length > 0){
        emailCustomFields[entityType.plural_name] =  customFieldEmailNotifications
      }
    })
    return emailCustomFields;
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <DocumentAttributes
          configuration={{
            entity: {
              key: 'entities',
              label: props?.currentEntityType?.singular_name || I18n.t('activerecord.attributes.abstract/document.entity_id'),
              placeholder: `-- Selecciona un Recurso --`
            },
            formName: 'abstract_document',
            context: 'abstract',
            defaultRequestParams: { current_entity_type_id: props?.currentEntityType?.hashid }
          }}
          actions={{
            indexEntities: indexAbstractEntity,
            createDocument: createDocument,
            updateDocument: updateDocument,
            showDocumentPath: Routes.abstract_document_path,
            indexDocumentsPath: Routes.abstract_documents_path({ current_entity_type_id: props?.currentEntityType?.hashid }),
            // showCheckListTaskPath: Routes.laboral_check_list_hiring_task_path
          }}
          _document={ _document }
          data={{
            ...props?.data,
            ...{ customFieldEmailNotifications: customFieldEmailNotifications}
          }}
          currentUser={ props?.currentUser || {} }
          currentCustomer={ props?.currentCustomer || {} }
          currentEntityType={ props?.currentEntityType || {} }
          callbackSetDocument={ setDocument }
        />
      </div>
    </div>
  )
}

export default AbstractDocumentForm;