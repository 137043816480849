import React, { useEffect, useState, useCallback } from "react";
import LaboralBulkLoadEmployeeFileInput from './file_input';
import LaboralBulkLoadEmployeeListHidden from './list_hidden';
import LaboralBulkLoadEmployeeTableErrors from './table_errors';
import { validateEmail, validateName } from '../../../../helper/form';
import {
  alertConfirmDelete,
  getDataFilter
} from './config';
import {
  validateIdentifierRepeat,
  validateEmailRepeat
} from './validator';
import { options } from '../../../../helper/pagination';
import { defaultTableHeaderStyle } from "../../../../helper/bootstrap-table/helper";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import I18n from 'i18n-js';
import swal from "sweetalert2";
import _ from "lodash";

const LaboralBulkLoadEmployeeForm = (props) => {
  const formName = 'laboral_bulk_load_employee'
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [isUpdate, setIsupdate] = useState(false);
  const [isValid, setValid] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)
  const _controldoc_id = 'controldoc_id'
  const _headers_concat = _.concat(props.defaultHeaders, _.map(props.customFieldsHeaders, 'code'), _controldoc_id)
  const _security_layers = props?.securityLayers
  // se excluye el primer elemento ya que ahora se posiciona al final de todos los campos
  const headers = _.slice(_headers_concat, 1, _headers_concat.length)
  const [statesFilters, setStatesFilters] = useState([])


  useEffect(() => {
    setFilter(getDataFilter(data, "created"))
  }, [data])

  useEffect(() => {
    if(isValid === true){
      updateData(data)
      setValid(false)
    }
  }, [isValid])

  useEffect(() => {
    if(isUpdate === true){
      setFilter(getDataFilter(data, "created"))
      setIsupdate(false)
    }
  }, [isUpdate])

  useEffect(() => {
    _.isEmpty(statesFilters) ? addStatesFilters() : null
  }, [statesFilters]);

  const addStatesFilters = () => {
    let _state_name = { "field": "name", "value": false };
    let _state_email = { "field": "value", "value": false };
    let _state_identifier = { "field": "identifier", "value": false };
    let _state_security_layer = { "field": "security_layer", "value": false };
    let _states_filter = _.map(props?.customFieldsHeaders, function (_field) {
      return { "field": _field.code, "value": false };
    })
    setStatesFilters(_.concat(_state_name, _state_email, _states_filter, _state_identifier, _state_security_layer));
  }

  const validateEmptyFields = (item) => {
    return (!validateEmail(item.value) || !validateName(item.name))
  }

  const validateBlankSpaces = (item) => {
    return (validateEmail(item.value) || validateName(item.name) || validateName(item.identifier) || validateName(item.controldoc_id))
  }

  const handleValues = (_data) => {
    _data = _.filter(_data, function(item, index) {
      if(validateBlankSpaces(item)){
        return item
      }
    })
    const dataRepeatIdentifier = repeatDataFilter(_data, "identifier");
    const dataRepeatEmail = repeatDataFilter(_data, "value");

    _.each(_data, function(item, index) {
      if(!validateName(item.controldoc_id)){
        item.state = "created";
      }

      let repeatIdentifier = !!_.find(dataRepeatIdentifier, { row: item.row } );
      let repeatEmail = !!_.find(dataRepeatEmail, { row: item.row } );

      if((repeatIdentifier && item.row === repeatIdentifier.row) ||
        (repeatEmail && item.row === repeatEmail.row) ||
        validateEmptyFields(item)){

        item.state = "error";
      }
    });
    updateData(_data);
  }

  const onChangeCellValues = (e,index, row, key) =>{
    let value = e?.target?.value
    data[index][key] = value

    if(key == "identifier" || key == "value"){
      let repeatedData = _.find(data, (item)=>item == row)
      if((!!repeatedData && row.row === repeatedData.row) ||
      validateEmptyFields(row)){
        row.state = "error";
      }
    }

    setIsupdate(true)
  }

  const handleSecurityLayerValues = (e, row) => {
    let value = e.target.value
    let _data = _.each(data, function(_row){
      _.eq(_row, row) ? _row.security_layer = value : null
    })
    updateData(_data)
  }

  const repeatDataFilter = (_data, option) => {
    return _.filter(_data, (value, currentIndex, _array) => {
      return _.findIndex(_array, obj => {
        let index = _.findIndex(_array, item => JSON.stringify(item[option]) === JSON.stringify(value[option]));
        if(index === currentIndex){
          return value;
        }
      })
    });
  }

  const customFieldSelectOptions = useCallback((listOptions) => {
    return listOptions.map((option) => {
      return (
        <option
          key={ _.uniqueId("_custom_fields") }
          value={ option }
        >
          { option }
        </option>
      );
    });
  },[])

  const customFieldSelectInput = (value, row, index, custom) => {
    if(custom.data_type === "list"){
      return (
        <Input
          type="select"
          key={ _.uniqueId("_custom_fields") }
          value={ value }
          onChange={ e => onChangeCellValues(e, index, row, custom.code) }
        >
          <option key={ _.uniqueId("_custom_fields") } value=""></option>
          { customFieldSelectOptions(custom.list_options) }
        </Input>
      );
    }else{
      return (
        <Input
          type="text"
          key={ _.uniqueId("_custom_fields") }
          onBlur={ e => onChangeCellValues(e, index, row, custom.code) }
          defaultValue={ value }
        />
      );
    }
  }

  const getCustomFields = (props) => {
    let _custom_fields = _.map(props.customFieldsHeaders)
    let items = []
    let btn_column = [{
      dataField: "row",
      text: I18n.t('actions.actions'),
      headerStyle: {...defaultTableHeaderStyle, width: '100px'},
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color="danger"
              outline
              className="border-0"
              onClick={ (e) => handleDestroyEmployee(row) }
            >
              <i className="fas fa-trash-alt" />
            </Button>
            { drawSpanEmployeeNew(row) }
          </div>
        );
      }
    }]

    _.each(_custom_fields, function(custom){
      let item = {
        dataField: custom.code,
        text: custom.code,
        headerStyle: defaultTableHeaderStyle,
        validator: (newValue, row, column) => {
          if(!validateName(newValue)){
            setValid(true);
            return row.state = "error";
          }
          return true;
        },
        formatter: function(value,row,index) {
          return customFieldSelectInput(value, row, index, custom)
        },
        filter: textFilter({
          placeholder: `Ingrese ${ _.lowerCase(custom.code) }`
        }),
        headerFormatter: headerTitleCustom
      }
      items.push(item)
    })

    return _.concat(items, btn_column);
  }

  const fieldSecurityLayer = () => {
    if(_.includes(headers, 'security_layer')){
      return{
        dataField: 'security_layer',
        text: 'Capa de seguridad',
        headerStyle: defaultTableHeaderStyle,
        editable: false,
        formatter: function(value,row,index) {
          return (
            <Input
              key={_.uniqueId("security_layer_input_")}
              id={`${row.row}-security_layer`}
              type="select"
              defaultValue={ value }
              onChange={e => handleSecurityLayerValues(e, row)}
            >
             <option value="">- Selecciona una capa de seguridad -</option>
              { drawValueSecurityLayerSelect() }
            </Input>
          );
        },
        headerFormatter: headerTitleCustom,
        filter: textFilter({
          placeholder: `Ingrese ${_.lowerCase(I18n.t("activerecord.attributes.laboral/employee.security_layer_id"))}`
        })
      }
    }
  }

  const drawValueSecurityLayerSelect = () => {
    return _.map(_security_layers, function(_layer){
      return (
        <option
          key={ _.uniqueId(`_layer_option_`) }
          value={ _layer }
        >
          { _layer }
        </option>
      )
    })
  }

  const headerTitleCustom = (column, colIndex, {filterElement}) => {
    let _filter = _.find(statesFilters, {"field": column.dataField});
    return(
      <div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">{ column.text }</p>
          <div>
            <button onClick={e => handleAddStateFilter(column, filterElement)} className="btn btn-link m-0 p-0" type="button">
              <i className={ _filter?.value ? "far fa-times-circle mt-1 fs-6 text-white" : "fas fa-search mt-1 fs-6 text-white"}></i>
            </button>
          </div>
        </div>
        <div className="row">
          { drawInputSearch(column, filterElement) }
        </div>
      </div>
    )
  }

  const customFilterEmployeeName = (filterVal, data) => {
    if (filterVal) {
      return _.filter(data, function (employee) {
        return _.includes(
          _.toUpper(employee.name),
          _.toUpper(filterVal)
        )
          ? employee
          : null;
      })
    }
    return data;
  }

  const handleAddStateFilter = (column, filterElement) => {
    let _changeStateFilter = statesFilters;
    let _field = _.remove(_changeStateFilter, { field: column.dataField })
    _field[0].value = !_field[0].value;
    if (!_field[0].value) {
      filterElement.props.filterState.filterVal = ""
    }
    setStatesFilters(_.concat(_changeStateFilter, _field));
  }

  const drawInputSearch = (column, filterElement) => {
    let _field = _.find(statesFilters, { field: column.dataField });
    if (_field?.value) {
      return (
        <div >
          {filterElement}
        </div>
      )
    }
  }

  const firstPartColumns = [
    {
      dataField: 'controldoc_id',
      text: 'ControldocID',
      headerStyle: { display: 'none' },
      style: { display: 'none' },
      formatter: function(value,row,index) {
        return (<Input type="hidden" defaultValue={ value } readOnly/>);
      }
    }, fieldSecurityLayer(),
    {
      dataField: 'identifier',
      text: I18n.t('activerecord.attributes.laboral/employee.identifier'),
      headerStyle: defaultTableHeaderStyle,
      validator: (newValue, row, column) => {
        if((row.identifier.trim() !== newValue.trim()) && validateIdentifierRepeat(newValue.trim(), data)){
          setValid(true);
          return row.state = "error";
        }
        return true;
      },
      formatter: function(value,row,index) {
        return (<Input type="text" defaultValue={ value } onBlur={e => onChangeCellValues(e, index,row, "identifier")} />);
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        placeholder: `Ingrese ${_.lowerCase(I18n.t("activerecord.attributes.laboral/employee.identifier"))}`
      })
    },
    {
      dataField: 'value',
      text: I18n.t('activerecord.attributes.laboral/employee.email'),
      headerStyle: {...defaultTableHeaderStyle, with: '300px'},
      validator: (newValue, row, column) => {
        if(!validateName(newValue) || !validateEmail(newValue) || ((row.value !== newValue) && validateEmailRepeat(newValue, data))){
          setValid(true);
          return row.state = "error";
        }
        return true;
      },
      formatter: function(value,row,index) {
        return (<Input type="email" defaultValue={ value } onBlur={e => onChangeCellValues(e, index, row, "value")} required/>);
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        placeholder: `Ingrese ${_.lowerCase(I18n.t("activerecord.attributes.laboral/employee.email"))}`,
      })
    },
    {
      dataField: 'name',
      text: I18n.t('activerecord.attributes.laboral/employee.name'),
      headerStyle: {...defaultTableHeaderStyle, with: '300px'},
      validator: (newValue, row, column) => {
        if(!validateName(newValue)){
          setValid(true);
          return row.state = "error";
        }
        return true;
      },
      formatter: function(value,row,index) {
        return (<Input type="text" defaultValue={ value } onBlur={e => onChangeCellValues(e, index, row, "name")} required/>);
      },
      headerFormatter: headerTitleCustom,
      filter: textFilter({
        onFilter: customFilterEmployeeName,
        placeholder: `Ingrese ${_.lowerCase(I18n.t("activerecord.attributes.laboral/employee.name"))}`,
      })
    }
  ];

  //En caso de que no contenga datos de security_layer remueve el elemento vacio || undefined
  const columns = _.concat(_.compact(firstPartColumns), getCustomFields(props))

  const drawSpanEmployeeNew = (row) => {
    if(!validateName(row.controldoc_id)) {
      return(
        <div className="d-inline-block m-2">
          <span id="tooltipTonNewEmployee">
            <i className="fas fa-user-plus text-info"/>
          </span>
          <UncontrolledTooltip key={  _.uniqueId(row.row) } placement="bottom" target="tooltipTonNewEmployee">
            { I18n.t('actions.create') } { I18n.t('activerecord.models.laboral/employee.one') }
          </UncontrolledTooltip>
        </div>
      )
    }
  }

  const handleDestroyEmployee = (row) => {
    swal.fire(alertConfirmDelete()).then((result) => {
      if(result.isConfirmed){
        let filteredArray = _.filter(data, item => (item.row === row.row) ? item.is_deleted = true : item );
        updateData(filteredArray);
      }
    })
  };

  const drawEmployees = () => {
    if(filter && filter?.length > 0) {
      return(
        <div className="row">
          <h2 className="card-title mb-2">
            { I18n.t('laboral.bulk.load.employees.form.table.title') } ({ `${ filter?.length }`}):
          </h2>
          <BootstrapTable
            keyField='row'
            sort={{ dataField: 'name', order: 'asc' }}
            data={ filter }
            filter={filterFactory()}
            columns={ columns }
            wrapperClasses="table-responsive height-600"
            pagination={ paginationFactory(options) }
            hover
            noDataIndication={ I18n.t('laboral.bulk.load.employees.form.without_records') }
          />
        </div>
      )
    }
  }

   const handleConfirmSubmit = (e) => {
    e.preventDefault();
    swal.fire({
      title: 'Realizar carga masiva de trabajadores',
      html: '¿Estás seguro de realizar la carga masiva?',
      cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
      confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
      reverseButtons: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-success ml-3',
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false,
    }).then( result => {
      if(result.isConfirmed){
        let _form = document.getElementById("new_laboral_bulk_load_employee")
        setDisabledSubmitButton(true)
        _form.submit()
      }
    })
  }

  const drawTextSubmitButton = () => {
    if(disabledSubmitButton){
      return I18n.t('actions.saving')
    } else {
      return I18n.t('actions.save')
    }
  }

  const drawBtnSubmit = () => {
    if( filter?.length > 0 ){
      return(
        <button
          type='submit'
          className='btn btn-success ml-2'
          onClick={ e => handleConfirmSubmit(e) }
          disabled={ disabledSubmitButton }
        >
          { drawTextSubmitButton() }
        </button>
      )
    }
  }

  const handleRetryEmployee = (newData) => {
    setIsupdate(true);
    updateData(newData);
  }

  const updateData = (_data) => {
    setData(_data);
  }

  return(
    <div className="row">
      <form action={Routes.laboral_bulk_load_employees_path()} encType="multipart/form-data" method="post" id="new_laboral_bulk_load_employee">
        <input type="hidden" name="authenticity_token" value={window.compliance.token} />
        <div className="col-12">
          <LaboralBulkLoadEmployeeFileInput
            headers={ headers }
            handleValues={ handleValues }
            formName={ formName }
            customFieldsHeaders= { props?.customFieldsHeaders || [] }
          />
        </div>
        <div className="col-12">
          <LaboralBulkLoadEmployeeListHidden
            customFieldsHeaders= { _.map(props.customFieldsHeaders, 'code') }
            formName={ formName }
            filter={ filter }
          />
        </div>
        <div className="col-12">
          <LaboralBulkLoadEmployeeTableErrors
            data={ data }
            customFieldsHeaders={ _.map(props.customFieldsHeaders) }
            handleRetryEmployee={ handleRetryEmployee }
            isValid={ isValid }
            isUpdate={ isUpdate }
          />
        </div>
        <div className="col-12">
          { drawEmployees() }
        </div>
        <FormGroup className='text-end'>
          <a
            href={ Routes.laboral_bulk_load_employees_path() }
            className='btn btn-default'
          >
            { I18n.t('actions.back') }
          </a>
          { drawBtnSubmit() }
        </FormGroup>
      </form>
    </div>
  )
}

export default LaboralBulkLoadEmployeeForm;
