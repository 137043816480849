import React, { useState, useEffect, useRef } from 'react';

import I18n from "i18n-js";

import {
  Button
} from 'reactstrap';

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTableCustomFilter from "../../helper/bootstrap-table/table_custom_filter";
import {
  pageListRenderer,
  options as optionPagination,
} from "../../helper/pagination";

import useFilterrific from "../../hooks/useFilterrific";

import useFilterRequestParams from "../../hooks/useFilterRequestParams";

import { documentsEnableToSign  } from './axios';

const PortalAccessDocumentBulkDocumentSignForm = props => {

  const bootstrapTable = useRef(null);

  const totalSizeRef = useRef(10)

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documents, setDocuments] = useState([])

  const [filterrific, setFilterrific] = useFilterrific({});
  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const { controldocSignUrl } = props;

  useEffect(() => {
    getDocuments()
  }, [])

  useEffect(() => {
    getDocuments();
  }, [requestFilterParams])

  // Start Getter
  const getDocuments = () => {
    documentsEnableToSign(requestFilterParams, response => {
      totalSizeRef.current = response.data.total;
      setDocuments(response.data.documents);
    })
  }
  // End Getter



  // Start Handle

  const handleSubmit = () => {
    let formData = getFormData();
    let queryString = new URLSearchParams(formData).toString();

    window.location.href = `${ controldocSignUrl }/documents/review_bulk?${ queryString }`;
  }

  const getFormData = () => {
    let formData = new FormData();

    _.each(selectedDocuments, function(document, index){
      formData.append(`bulk_review[document_ids][]`, document.controldoc_id || '')
    })

    return formData;
  }

  const handleSelectedDocument = (row, isSelected) => {
    let _selectedDocuments = [ ... selectedDocuments ]

    if(isSelected){
      _selectedDocuments.push(row)
    } else {
      _.remove(_selectedDocuments, document => String(document.id) == String(row.id))
    }

    setSelectedDocuments(_selectedDocuments)
  }

  const handleSelectAll = (rows, isSelected) => {
    let _selectedDocuments = [ ... selectedDocuments ]

    if(isSelected){
      _selectedDocuments = _.compact(
        _.concat(_selectedDocuments, rows)
      );
    } else {
      _.pullAll(_selectedDocuments, rows);
    }

    setSelectedDocuments(_selectedDocuments)
  }

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  };
  // End Handle


  let columns = [
    {
      dataField: "document_type.name",
      text: I18n.t("activerecord.attributes.document.document_type"),
      formatter: (value,row,index) => value || I18n.t('no_entry')
    },
    {
      dataField: "label",
      text: I18n.t("activerecord.attributes.document.label"),
      formatter: (value,row,index) => value || I18n.t('no_entry')
    },
    {
      dataField: "created_at",
      text: I18n.t("activerecord.attributes.document.created_at"),
      formatter: (value,row,index) => value || I18n.t('no_entry')
    }
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: _.map(selectedDocuments, 'id'),
    onSelect: (row, isSelect, rowIndex, e) => {
      handleSelectedDocument(row, isSelect)
    },
    onSelectAll: (isSelect, rows, e) => {
      handleSelectAll(rows, isSelect);
    },
  };


  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-body">
            <div className="alert alert-info">
              <i className="fas fa-info-circle" /> Debe seleccionar al menos 2
              documentos para firmar!
            </div>

            <BootstrapTableCustomFilter
              remote={true}
              filterrific={filterrific}
              setFilterrific={setFilterrific}
              keyField="id"
              selectRow={ selectRow }
              data={ documents }
              columns={ columns }
              pagination={ paginationFactory(optionPagination({
                totalSize: totalSizeRef.current,
                sizePerPage: requestFilterParams?.per_page,
              })) }
              // classes="table-responsive"
              onTableChange={ handleTableChange }
              _ref={ bootstrapTable }
            />
          </div>
        </div>

        <div className="text-end">
          <Button
            color='success'
            disabled={ selectedDocuments.length < 2 }
            onClick={ e => handleSubmit() }
          >
            { I18n.t('actions.sign') }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PortalAccessDocumentBulkDocumentSignForm;
