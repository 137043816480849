import React, {
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  FormGroup,
  Button,
  Form,
  Label,
  Input
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import FieldError from '../../../helper/FieldError';

import {
  inputId,
  inputName,
  clearInputFiles
} from '../../../helper/form';

import {
  fieldItemIsRequired,
  fieldItemHtmlInputType
} from '../../../workflow/requirement_stage/helper'

import WorkflowRequirementStageAttributes from '../../../workflow/requirement_stage/attributes';
import WorkflowRequirementStageFormItemFieldAttributes from '../../../workflow/requirement_stage_form_item/field_attributes';
import WorkflowRequirementStageFormItemFileAttributes from '../../../workflow/requirement_stage_form_item/file_attributes';

const KarinLawWorkflowRequirementAttributes = props => {
  const {
    requirement,
    configuration: {
      formName = 'workflow_requirement',
      defaultRequestParams
    },
    routes: {
      indexRequirementsPath,
      showRequirementPath
    },
    services: {
      createRequirement: createRequirementService
    },
    data: {
      karinLaw: {
        reportedByOptions,
        complaintReasonOptions
      }
    },
    callbacks: {
      onSetRequirement
    }
  } = props;

  // const [requirement, onSetRequirement] = useState(props?.requirement || {})

  // ---------- START OUTPUT FIELDS ----------
  // Metodos para insertar los requerimientos asociados a los grupos de ley karin
  const onChangeOutputFieldItem = (fieldItem, itemId) => {
    onSetRequirement(prevState => {
      return {
        ...prevState,
        current_requirement_stage: {
          ...prevState.current_requirement_stage,
          output_form: {
            ... prevState.current_requirement_stage.output_form,
            field_items: _.map(prevState.current_requirement_stage.output_form.field_items, (_fieldItem, _index) => {
              if(_fieldItem.item.id == itemId){
                return fieldItem
              } else {
                return _fieldItem
              }
            })
          }
        }
      }
    })
  }

  const outputFieldItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.field_items, fieldItem => {
      return fieldItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.field_items])

  const outputFieldItemAttributes = groupName => {
    let fieldItems = outputFieldItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fieldItems, (fieldItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFieldAttributes
          key={ `WorkflowRequirementStageFormItemFieldAttributes-${ fieldItem.item.id }` }
          fieldItem={ fieldItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][field_items_attributes][${ fieldItem.item.id }]`, index: index}}
          callbacks={{
            onChangeFieldItem: onChangeOutputFieldItem
          }}
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------

  // ---------- START OUTPUT FILES ----------
  // Metodos para insertar los requerimientos asociados a los grupos de ley karin
  const onChangeOutputFileItem = (fileItem, itemId) => {
    onSetRequirement(prevState => {
      return {
        ...prevState,
        current_requirement_stage: {
          ...prevState.current_requirement_stage,
          output_form: {
            ... prevState.current_requirement_stage.output_form,
            file_items: _.map(prevState.current_requirement_stage.output_form.file_items, (_fileItem, _index) => {
              if(_fileItem.item.id == itemId){
                return fileItem
              } else {
                return _fileItem
              }
            })
          }
        }
      }
    })
  }

  const outputFileItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.file_items, fileItem => {
      return fileItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.file_items])

  const outputFileItemAttributes = groupName => {
    let fileItems = outputFileItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fileItems, (fileItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFileAttributes
          key={ `WorkflowRequirementStageFormItemFileAttributes-${ fileItem.item.id }` }
          fileItem={ fileItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][file_items_attributes][${ fileItem.item.id }]`, index: index}}
          callbacks={{
            onChangeFileItem: onChangeOutputFileItem
          }}
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------




  const onChangeRequirement = (event, key) => {
    const value = event?.target?.value;

    onSetRequirement(prevState => {
      return { ... prevState, [key]: value}
    })
  }

  const onChangeRequirementStage = (event, key) => {
    const value = event?.target?.value;

    let currentRequirementStage = {
      ... requirement.current_requirement_stage,
      [key]: value
    }

    onChangeRequirement(
      { target: { value: currentRequirementStage } },
      'current_requirement_stage'
    )
  }

  const reportedByRepresentative = useMemo(() => {
    return requirement.reported_by == 'representative'
  }, [requirement.reported_by])

  // ---------- START REPORTED BY FIELDS ----------
  const reportedBySelectOptions = useMemo(() => {
    return _.map(reportedByOptions, reportedBy => {
      return {
        value: reportedBy,
        label: I18n.t(`activerecord.attributes.karin_law/workflow/requirement.reported_by.${ reportedBy }`)
      }
    })
  }, [])

  const reportedBySelectedOption = useMemo(() => {
    if(requirement.reported_by){
      return {
        value: requirement.reported_by,
        label: I18n.t(`activerecord.attributes.karin_law/workflow/requirement.reported_by.${ requirement.reported_by }`)
      }
    } else {
      return null
    }
  }, [requirement.reported_by])

  const reportedByInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.reported_by') || [] }>
          <Select
            required={ true }
            id={ inputId(formName, 'reported_by') }
            name={ inputName(formName, 'reported_by') }
            invalid={ _.has(requirement?.errors, 'reported_by') }
            options={ reportedBySelectOptions }
            value={ reportedBySelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'reported_by') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const reportedByFields = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    {/*<i className="fas fa-signature mr-2"/>*/}
                    { I18n.t('activerecord.attributes.karin_law/workflow/requirement.reported_by.one') }
                  </div>
                </div>
              </div>
              <div className="card-body">
                { reportedByInputSelect() }
              </div>
            </div>
        </div>
      </div>
    )
  }
  // ---------- END REPORTED BY FIELDS ----------


  // ---------- START COMPLAINT REASON ----------
  const complaintReasonSelectOptions = useMemo(() => {
    return _.map(complaintReasonOptions, complaintReason => {
      return {
        value: complaintReason,
        label: I18n.t(`activerecord.attributes.karin_law/workflow/requirement.complaint_reason.${ complaintReason }`)
      }
    })
  }, [])

  const complaintReasonSelectedOption = useMemo(() => {
    if(requirement.complaint_reason){
      return {
        value: requirement.complaint_reason,
        label: I18n.t(`activerecord.attributes.karin_law/workflow/requirement.complaint_reason.${ requirement.complaint_reason }`)
      }
    } else {
      return null
    }
  }, [requirement.complaint_reason])

  const complaintReasonInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.complaint_reason') || [] }>
          <Select
            required={ true }
            id={ inputId(formName, 'complaint_reason') }
            name={ inputName(formName, 'complaint_reason') }
            invalid={ _.has(requirement?.errors, 'complaint_reason') }
            options={ complaintReasonSelectOptions }
            value={ complaintReasonSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'complaint_reason') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const complaintReasonFields = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    {/*<i className="fas fa-signature mr-2"/>*/}
                    { I18n.t('activerecord.attributes.karin_law/workflow/requirement.complaint_reason.one') }
                  </div>
                </div>
              </div>
              <div className="card-body">
                { complaintReasonInputSelect() }
              </div>
            </div>
        </div>
      </div>
    )
  }
  // ---------- END COMPLAINT REASON ----------

  // ---------- START VICTIM FIELDS ----------
  const victimNameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.victim_name') || [] }>
          <Label
            htmlFor={ inputId(formName, 'victim_name') }
            className={ 'required' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_name') }
          </Label>
          <Input
            required={ true }
            onChange={ e => onChangeRequirement(e, 'victim_name') }
            value={ requirement.victim_name || '' }
            id={ inputId(formName, 'victim_name') }
            name={ inputName(formName, 'victim_name') }
            invalid={ _.has(requirement, 'errors.victim_name') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const victimIdentificationNumberInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.victim_identification_number') || [] }>
          <Label
            htmlFor={ inputId(formName, 'victim_identification_number') }
            className={ 'required' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_identification_number') }
          </Label>
          <Input
            required={ true }
            onChange={ e => onChangeRequirement(e, 'victim_identification_number') }
            value={ requirement.victim_identification_number || '' }
            id={ inputId(formName, 'victim_identification_number') }
            name={ inputName(formName, 'victim_identification_number') }
            invalid={ _.has(requirement, 'errors.victim_identification_number') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const victimEmailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.victim_email') || [] }>
          <Label
            htmlFor={ inputId(formName, 'victim_email') }
            className={ 'required' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_email') }
          </Label>
          <Input
            required={ true }
            onChange={ e => onChangeRequirement(e, 'victim_email') }
            value={ requirement.victim_email || '' }
            id={ inputId(formName, 'victim_email') }
            name={ inputName(formName, 'victim_email') }
            invalid={ _.has(requirement, 'errors.victim_email') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const victimFields = () => {

    const title = I18n.t('karin_law.workflow.requirements.form.victim.title')
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                <div className="font-weight-bold">
                  {/*<i className="fas fa-signature mr-2"/>*/}
                  { title }
                </div>
              </div>
            </div>
            <div className="card-body">
              { victimNameInput() }
              { victimIdentificationNumberInput() }
              { victimEmailInput() }
              { outputFieldItemAttributes(title) }
              { outputFileItemAttributes(title) }
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ---------- END VICTIM FIELDS ----------

  // ---------- START PERPETRATOR FIELDS ----------
  const perpetratorNameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.perpetrator_name') || [] }>
          <Label
            htmlFor={ inputId(formName, 'perpetrator_name') }
            className={ 'required' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.perpetrator_name') }
          </Label>
          <Input
            required={ true }
            onChange={ e => onChangeRequirement(e, 'perpetrator_name') }
            value={ requirement.perpetrator_name || '' }
            id={ inputId(formName, 'perpetrator_name') }
            name={ inputName(formName, 'perpetrator_name') }
            invalid={ _.has(requirement, 'errors.perpetrator_name') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const perpetratorEmailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.perpetrator_email') || [] }>
          <Label
            htmlFor={ inputId(formName, 'perpetrator_email') }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.perpetrator_email') }
          </Label>
          <Input
            onChange={ e => onChangeRequirement(e, 'perpetrator_email') }
            value={ requirement.perpetrator_email || '' }
            id={ inputId(formName, 'perpetrator_email') }
            name={ inputName(formName, 'perpetrator_email') }
            invalid={ _.has(requirement, 'errors.perpetrator_email') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const perpetratorFields = () => {
    const title = I18n.t('karin_law.workflow.requirements.form.perpetrator.title')
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                <div className="font-weight-bold">
                  {/*<i className="fas fa-signature mr-2"/>*/}
                  { title }
                </div>
              </div>
            </div>
            <div className="card-body">
              { perpetratorNameInput() }
              { perpetratorEmailInput() }
              { outputFieldItemAttributes(title) }
              { outputFileItemAttributes(title) }
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ---------- END PERPETRATOR FIELDS ----------

  // ---------- START REPRESENTATIVE FIELDS ----------

  const representativeRequired = useMemo(() => {
    return reportedByRepresentative
  }, [requirement.reported_by])


  const representativeNameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.representative_name') || [] }>
          <Label
            htmlFor={ inputId(formName, 'representative_name') }
            className={ representativeRequired ? 'required' : '' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_name') }
          </Label>
          <Input
            required={ representativeRequired }
            onChange={ e => onChangeRequirement(e, 'representative_name') }
            value={ requirement.representative_name || '' }
            id={ inputId(formName, 'representative_name') }
            name={ inputName(formName, 'representative_name') }
            invalid={ _.has(requirement, 'errors.representative_name') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const representativeIdentificationNumberInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.representative_identification_number') || [] }>
          <Label
            htmlFor={ inputId(formName, 'representative_identification_number') }
            className={ representativeRequired ? 'required' : '' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_identification_number') }
          </Label>
          <Input
            required={ representativeRequired }
            onChange={ e => onChangeRequirement(e, 'representative_identification_number') }
            value={ requirement.representative_identification_number || '' }
            id={ inputId(formName, 'representative_identification_number') }
            name={ inputName(formName, 'representative_identification_number') }
            invalid={ _.has(requirement, 'errors.representative_identification_number') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const representativeEmailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.representative_email') || [] }>
          <Label
            htmlFor={ inputId(formName, 'representative_email') }
            className={ representativeRequired ? 'required' : '' }
          >
            { I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_email') }
          </Label>
          <Input
            required={ representativeRequired }
            onChange={ e => onChangeRequirement(e, 'representative_email') }
            value={ requirement.representative_email || '' }
            id={ inputId(formName, 'representative_email') }
            name={ inputName(formName, 'representative_email') }
            invalid={ _.has(requirement, 'errors.representative_email') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const representativeFields = () => {
    if(reportedByRepresentative){
      const title = I18n.t('karin_law.workflow.requirements.form.representative.title')
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                    <div className="font-weight-bold">
                      {/*<i className="fas fa-signature mr-2"/>*/}
                      { title }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  { representativeNameInput() }
                  { representativeIdentificationNumberInput() }
                  { representativeEmailInput() }
                  { outputFieldItemAttributes(title) }
                  { outputFileItemAttributes(title) }
                </div>
              </div>
          </div>
        </div>
      )
    }
  }
  // ---------- END REPRESENTATIVE FIELDS ----------

  // ---------- START SUBMIT ----------
  const onSuccessSubmit = useCallback((_requirement) => {
    let requestParams = {
      ...defaultRequestParams,
      id: _requirement.hashid,
      _options: true,
      format: ''
    }

    if(_requirement.token){
      requestParams = { ... requestParams, token: _requirement.token }
    }

    window.location = showRequirementPath(requestParams)
  }, [])

  const onConfirmSubmit = event => {
    event.preventDefault();

    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirements.form.confirm.title'),
      html: I18n.t('workflow.requirements.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    });
  }

  const onSubmit = () => {
    const formData = requirementFormData()

    createRequirementService(defaultRequestParams, formData, response => {
      if(response.status == 201){
        onSuccessSubmit(response.data)
      } else {
        clearInputFiles()
        onSetRequirement(response.data);
      }
    })
  }

  const requirementFormData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[process_id]`, requirement.process_id || '');
    _formData.append(`${ formName }[accept_disclaimer]`, requirement.accept_disclaimer ? 1 : 0);

    _formData.append(`${ formName }[reported_by]`, requirement.reported_by || '');

    _formData.append(`${ formName }[victim_name]`, requirement.victim_name || '');
    _formData.append(`${ formName }[victim_identification_number]`, requirement.victim_identification_number || '');
    _formData.append(`${ formName }[victim_email]`, requirement.victim_email || '');

    _formData.append(`${ formName }[representative_name]`, requirement.representative_name || '');
    _formData.append(`${ formName }[representative_identification_number]`, requirement.representative_identification_number || '');
    _formData.append(`${ formName }[representative_email]`, requirement.representative_email || '');

    _formData.append(`${ formName }[perpetrator_name]`, requirement.perpetrator_name || '');
    _formData.append(`${ formName }[perpetrator_email]`, requirement.perpetrator_email || '');

    // CurrentRequirementStage
    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`
    const currentRequirementStage = requirement.current_requirement_stage;

    _formData.append(`${ currentRequirementStageFormName }[id]`, currentRequirementStage.id || '');
    _formData.append(`${ currentRequirementStageFormName }[current_stage_id]`, currentRequirementStage.current_stage_id || '');
    _formData.append(`${ currentRequirementStageFormName }[observations]`, currentRequirementStage.observations || '');

    if(currentRequirementStage.node_type == 'input'){
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, '');
    } else {
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, currentRequirementStage.aasm_state_event || '');
    }


    _formData.append(`${ currentRequirementStageFormName }[output_form_attributes][id]`, currentRequirementStage.output_form.id || '')

    // START OUTPUT FIELD ITEMS
    const outputFieldItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][field_items_attributes]`;
    const outputFieldItems = currentRequirementStage.output_form.field_items || [];
    _.each(outputFieldItems, (fieldItem, index) => {
      _formData.append(`${ outputFieldItemFormName }[${ index }][id]`, fieldItem?.id || '');

      _formData.append(`${ outputFieldItemFormName }[${ index }][item_id]`, fieldItem.item_id || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || 'text');
      _formData.append(`${ outputFieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false );
      _formData.append(`${ outputFieldItemFormName }[${ index }][group_name]`, fieldItem.group_name || '' );
      _formData.append(`${ outputFieldItemFormName }[${ index }][helping_text]`, fieldItem.helping_text || '' );
      _formData.append(`${ outputFieldItemFormName }[${ index }][name]`, fieldItem.name || '' );

      _formData.append(`${ outputFieldItemFormName }[${ index }][value]`, fieldItem?.value || '');
    });
    // END OUTPUT FIELD ITEMS

    // START OUTPUT FILE ITEMS
    const outputFileItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][file_items_attributes]`;
    const outputFileItems = currentRequirementStage.output_form.file_items || [];
    _.each(outputFileItems, (fileItem, index) => {
      _formData.append(`${ outputFileItemFormName }[${ index }][id]`, fileItem?.id || '');

      _formData.append(`${ outputFileItemFormName }[${ index }][value]`, fileItem?.value || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][item_id]`, fileItem.item_id || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][data_type]`, fileItem.data_type || 'text');
      _formData.append(`${ outputFileItemFormName }[${ index }][is_required]`, fileItem.is_required || false );
      _formData.append(`${ outputFileItemFormName }[${ index }][group_name]`, fileItem.group_name || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][helping_text]`, fileItem.helping_text || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][name]`, fileItem.name || '' );

      if(fileItem.file){
        _formData.append(`${ outputFileItemFormName }[${ index }][file]`, fileItem?.file || '');
      }
    })
    // END OUTPUT FILE ITEMS

    return _formData;
  }

  // ---------- END SUBMIT ----------
  const backButton = useMemo(() => {
    if(!requirement.is_public){
      return(
        <a
          href={ indexRequirementsPath(defaultRequestParams) }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>
      )
    }
  })

  return(
    <Form onSubmit={ onConfirmSubmit }>
      <div className="row">
        <div className="col-12">
          { reportedByFields() }
          { complaintReasonFields() }
          { victimFields() }
          { representativeFields() }
          { perpetratorFields() }

          <WorkflowRequirementStageAttributes
            requirementStage={ requirement.current_requirement_stage }
            configuration={{
              formName: `${ formName }[current_requirement_stage_attributes]`,
              context: 'karin_law'
            }}
            callbacks={{
              onChangeRequirementStage: onChangeRequirementStage
            }}
          />
        </div>
      </div>

      <FormGroup className='text-end'>
        { backButton }

        <Button
          color='success'
          className='ml-2'
          type='submit'
        >
          { I18n.t('actions.send') }
        </Button>
      </FormGroup>
    </Form>
  )

}

export default KarinLawWorkflowRequirementAttributes;
