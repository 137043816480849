import React from 'react';

import {
  Button
} from 'reactstrap'

import I18n from 'i18n-js';

import EntityFolderCheckListRequirementAttributes from './attributes';

const EntityFolderCheckListRequirementListForm = props => {
   const {
    folder,
    requirements,
    data,
    configuration,
    callbacks: { callbackSetFolder }
  } = props;

  const onChangeRequirement = (requirement, index) => {
    if(_.isFunction(callbackSetFolder)){
      callbackSetFolder(prevState => {
        const _requirements = prevState.requirements;
        _requirements[index] = requirement;

        return {
          ...prevState,
          requirements: _requirements
        }
      })
    }
  }

  const onClickNewRequirement = () => {
    const requirementAttribute = {
      _destroy: false
    }

    if(_.isFunction(callbackSetFolder)){
      callbackSetFolder(prevState => {
        return {
          ... prevState,
          requirements: [
            ... prevState.requirements,
            requirementAttribute
          ]
        }
      })
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { _.map(requirements, (requirement, index) => {
          const destroyClass = requirement._destroy ? 'd-none' : ''

          return(
            <div
              className={ `card card-material ${ destroyClass }` }
              key={ `EntityFolderCheckListRequirementAttribute${ index }` }
            >
              <div className="card-body">
                <EntityFolderCheckListRequirementAttributes
                  index={ index }
                  requirement={ requirement }
                  data={ data }
                  configuration={{
                    ...configuration,
                    formName: `${ configuration.formName }[${ index }]`
                  }}
                  callbacks={{
                    callbackOnChangeRequirement: onChangeRequirement
                  }}
                />
              </div>
            </div>
          )
        })}

        <div className="text-end">
          <Button
            outline
            color='success'
            className='ml-2'
            size={ 'sm' }
            onClick={ event => onClickNewRequirement() }
          >
            { `${ I18n.t('actions.add') } documento` }
          </Button>
        </div>
      </div>
    </div>
  )
}


export default EntityFolderCheckListRequirementListForm;
