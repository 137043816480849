import React, {
  useState,
} from 'react';

import {
  Row,
  Col,

  Card,
  CardBody,
  CardHeader,

  Button,

  Collapse
} from 'reactstrap';

import I18n from 'i18n-js';

import UserProfileSignatureDynamicPinAttributes from './signature_dynamic_pin_attributes';
import UserProfileSignatureCustomPinAttributes from './signature_custom_pin_attributes';

import UserProfileDisableSignatureAttributes from './disable_signature_attributes';

const UserProfileSignatureInformationAttributes = props => {

  const {
    user,
    configuration: {
      formName = 'profile'
    } = {},
    callbacks
  } = props;

  const enableSignatureContent = () => {
    if(user.signature_method == 'disabled'){
      return(
        <Card>
          <CardHeader>
            <p className="h5 card-title font-weight-bold mb-0">
              Habilitar verificación en dos pasos
            </p>
          </CardHeader>
          <CardBody>
            <p className="lead">
              Es necesario
              <strong className='font-weight-bold'> activar la verificación en dos pasos de tu cuenta </strong>
              para poder ver y firmar tus documentos.
            </p>

            <p className="lead">
              La verificación en dos pasos te permitirá firmar documentos electrónicamente cada vez que lo necesites, estés donde estés con un PIN de 6 digitos.
            </p>

            <hr/>

            <Row>
              <Col>
                <p className="h6 text-left mb-3 font-weight-lighter">
                  <strong className='d-block font-weight-bold'>Elige la forma de verificación:</strong>
                </p>
              </Col>
            </Row>

            <Row>
              <Col col='12'>
                <UserProfileSignatureCustomPinAttributes
                  user={ user }
                  callbacks={ callbacks }
                />
              </Col>
              <Col xs='12'>
                <UserProfileSignatureDynamicPinAttributes
                  user={ user }
                  callbacks={ callbacks }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    }
  }

  const disableSignatureContent = () => {
    if(user.signature_method != 'disabled'){
      return(
        <UserProfileDisableSignatureAttributes
          user={ user }
        />
      )
    }
  }

  return(
    <>
      { enableSignatureContent() }
      { disableSignatureContent() }
    </>
  )
}

export default UserProfileSignatureInformationAttributes;
