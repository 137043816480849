import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BulkRequestZipDocumentTable from "../../../../../bulk/request/zip/document/table";
import BootstrapTableSortHeader from "../../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../../helper/bootstrap-table/filter/filter_header";
import { defaultTableHeaderStyleWhite } from "../../../../../helper/bootstrap-table/helper";

import { index as indexCorporateAgreementDocument, getByIds as getCorporateAgreementDocumentByIds } from "../../../../agreement_document/axios";
import { create } from "../../../../../corporate/bulk/request/zip/document/axios";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

import { limitCharacters } from "../../../../../helper/form"

const CorporateBulkRequestZipDocumentForm = props => {
  const { companies, documentTypes, aasmStates } = props?.filters;
  const { currentCustomer } = props;
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);

  // Ref
  const bootstrapTable = useRef(null);

  const columns = [
    {
      dataField: "entity.identifier",
      text: I18n.t("activerecord.attributes.corporate/agreement.identifier"),
      filterByFilterrific: "search_by_agreement_identifier",
      sortByFilterrific: "agreement_identifier_",
      myFilter: 'agreement.identifier',
      filter: textFilter({
        placeholder: `Ingrese el identificador del ${I18n.t(
          "activerecord.models.corporate/agreement.one"
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.client.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement.client"),
      filterByFilterrific: 'by_client',
      headerStyle: defaultTableHeaderStyleWhite,
      filter: selectFilter({
        placeholder: `Selecciona un ${_.lowerCase(I18n.t("activerecord.attributes.corporate/agreement.client"))}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name
        }))
      }),
      formatter: (value, row, index) => value || I18n.t('unassigned'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTable }  }
        />
      ),
    },
    {
      dataField: "entity.provider.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement.provider"),
      headerStyle: defaultTableHeaderStyleWhite,
      filterByFilterrific: 'by_provider',
      filter: selectFilter({
        placeholder: `Selecciona un ${_.lowerCase(I18n.t("activerecord.attributes.corporate/agreement.provider"))}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name
        }))
      }),
      formatter: (value, row, index) => value || I18n.t('unassigned'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTable }  }
        />
      ),
    },
    {
      dataField: "entity.start_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement.start_date"),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: function (value, row, index) {
        return (
          <p>{ value || I18n.t("no_entry") }</p>
        )
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTable }  }
        />
      ),
    },
    {
      dataField: "entity.end_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement.end_date"),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: function (value, row, index) {
        return(
          <p>{ value || "Indefinido" }</p>
        )
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTable }  }
        />
      ),
    },
    {
      dataField: "label",
      text: I18n.t("activerecord.attributes.corporate/agreement_document.label"),
      filterByFilterrific: 'search_by_label',
      headerStyle: defaultTableHeaderStyleWhite,
      sortByFilterrific: "label_",
      myFilter: 'document.label',
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      filter: textFilter({}),
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: I18n.t("activerecord.attributes.corporate/agreement_document.document_type"),
      filterByFilterrific: "by_document_type",
      sortByFilterrific: "document_type_name_",
      myFilter: 'document_type.name',
      filter: selectFilter({
        options: _.map(documentTypes, document_type => ({
          value: document_type.id,
          label: document_type.name,
        })),
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement_document.created_at"),
      filterByFilterrific: 'search_by_created_at',
      filter: textFilter({
        placeholder: `Ingrese la ${I18n.t(
          "activerecord.attributes.corporate/agreement_document.created_at"
        )}`,
      }),
      sortByFilterrific: "created_at_to_s_",
      myFilter: 'document.created_at_to_s',
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "aasm_state",
      text: I18n.t("activerecord.attributes.document.aasm_state.one"),
      filterByFilterrific: "by_aasm_state",
      sortByFilterrific: "aasm_state_",
      myFilter: 'document.aasm_state',
      filter: selectFilter({
        placeholder: `Seleccione un estado`,
        options: aasmStates,
      }),
      formatter: (value, row, index) => I18n.t(`activerecord.attributes.document.aasm_state.${ value }`),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      )
    },

  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `agreement.custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const columnsAgreementDocuments = _.concat(
    // getEmployeeSecurityLayerColumn(),
    columns,
    getCustomFields(props?.customFields)
  );

  const tableConfirmDocument = (documents, drawDeleteSelectedDocument) => {
    return (
      <div className="d-flex">
        <table className="table mt-2 table-responsive-md">
          <thead>
            <tr>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement_document.label") }
              </th>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement_document.document_type") }
              </th>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement.client") }
              </th>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement.provider") }
              </th>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement.start_date") }
              </th>
              <th>
                { I18n.t("activerecord.attributes.corporate/agreement.end_date")}
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(documents, function (document, index) {
              return (
                <tr key={`tr-list-documents-${document.id}`}>
                  <td>{document?.label || I18n.t("no_entry")}</td>
                  <td>{document?.document_type?.name}</td>
                  <td>{document?.entity?.client?.company_config?.name || I18n.t('unassigned')}</td>
                  <td>{document?.entity?.provider?.company_config?.name || I18n.t('unassigned')}</td>
                  <td>{document?.entity?.start_date_to_s || I18n.t("no_entry")}</td>
                  <td>{document?.entity?.end_date_to_s || I18n.t("undefined")}</td>
                  <td>{drawDeleteSelectedDocument(document)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <BulkRequestZipDocumentTable
        showBulkMyFilter={ false }
        bulkRequest={bulkRequest}
        columns={columnsAgreementDocuments}
        _ref={bootstrapTable}
        tableConfirmDocument={tableConfirmDocument}
        configuration={{
          context: "corporate",
          defaultRequestParams: {},
        }}
        actions={{
          indexDocument: indexCorporateAgreementDocument,
          createService: create,
          getDocumentByIds: getCorporateAgreementDocumentByIds
        }}
        buttonActions={{
          show: Routes.corporate_bulk_request_zip_document_path,
          back: Routes.corporate_bulk_request_zip_documents_path(),
        }}
      />
    </div>
  );
};

export default CorporateBulkRequestZipDocumentForm;
