import React, { useState, useEffect } from "react";

import I18n from 'i18n-js';

import Creatable from 'react-select/creatable';
import { validateEmail } from "../../helper/form";
import _ from "lodash";

const CheckListSharedProcessForm = props => {
  const {
    checkListProcess,
    currentUser,
    sharedProcesses,
    callbackSharedProcesses,
    context,
   } = props;

  const { shareCheckListProcess, showCheckListProcessPath } = props?.actions
  const formName = "check_list_process"

  const defaultRequestParams = (context == 'abstract') ? { current_entity_type_id: props?.currentEntityType?.hashid, id: checkListProcess?.hashid } : { id: checkListProcess?.hashid }

  const handleSharedToSelect = (selectedOptions, type) => {
    let _sharedProcesses = [... sharedProcesses]
    let emailIndex = _.findIndex(_sharedProcesses, { "shared_to_email": (type?.option?.label || type?.removedValue?.label )})

    if(type?.action == 'create-option' && validateEmail(type?.option?.label)){
      if(emailIndex >= 0){
        _sharedProcesses[emailIndex]["_destroy"] = false
      }else{
        _sharedProcesses.push({
          "id": "",
          "shared_to_email": type.option.label,
        })
      }
    }

    if(type?.action == 'remove-value' && emailIndex >= 0){
      _sharedProcesses[emailIndex]["_destroy"] = true;
    }

    callbackSharedProcesses(_sharedProcesses)
  }

  const getOptionsSharedTo = () => {
    let activeSharedProcesses = _.filter(sharedProcesses, s =>{ return s._destroy != true})
    return _.map(activeSharedProcesses, function(sharedProcess){
      return { value: sharedProcess.id, label: sharedProcess?.shared_to_email }
    })
  }

  const handleConfirmShare = (e) => {
    swalWithBootstrap.fire({
      title: I18n.t('check_list.processes.form.share.confirm.title'),
      html: I18n.t('check_list.processes.form.share.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        handleSubmit();
      }
    })
  }

  const handleSubmit = () => {
    let formData = getFormData();

    shareCheckListProcess(defaultRequestParams, formData, response => {
      if(response.status == 201 || response.status == 200 ){
        swalWithBootstrap.fire(
          'Compartido!',
          'Se ha enviado la notificación a los usuarios correspondientes',
          'success'
        )

        window.location = showCheckListProcessPath({...defaultRequestParams, _options: true});
      }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    _.each(sharedProcesses, function(sharedProcess, index){
      formData.append(`${ formName }[check_list_shared_processes_attributes][${ index }][id]`, sharedProcess?.id || '');
      formData.append(`${ formName }[check_list_shared_processes_attributes][${ index }][shared_by_id]`, currentUser?.id || '');
      formData.append(`${ formName }[check_list_shared_processes_attributes][${ index }][shared_to_email]`, sharedProcess?.shared_to_email || '');
      formData.append(`${ formName }[check_list_shared_processes_attributes][${ index }][_destroy]`, sharedProcess?._destroy || false);
    })

    return formData;
  }

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Compartir Check List</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Creatable
          isMulti
          formatCreateLabel={ ()=> "agregar" }
          isClearable={ true }
          onChange={ (options, type) => handleSharedToSelect(options, type) }
          value={ getOptionsSharedTo() }
          placeholder={ `-- ${ I18n.t('check_list.shared_processes.form.select.placeholder') } --` }
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" data-bs-dismiss="modal">
          { I18n.t("actions.close") }
        </button>
        <button type="button" className="btn btn-success" onClick={ e => handleConfirmShare() }>
          { I18n.t("actions.share") }
        </button>
      </div>
    </>
  )
}

export default CheckListSharedProcessForm;