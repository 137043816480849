import React from "react";
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import Select from 'react-select'

import FieldError from "../../helper/FieldError";

import { inputName, selectStyle } from '../../helper/form';

import I18n from 'i18n-js';
import _ from "lodash";

const CheckListDocumentTypeForm = (props) => {
  const {
    index,
    formName,
    errors,
    checkListDocumentType,
    selectedDocumentTypeOptions,
    isOptionDisabled,
    callbackSetCheckListDocumentType,
    btnActions: {
      btnRemove
    }
  } = props;

  const getSelectedDocumentTypeOption = () => {
    if (checkListDocumentType?.document_count > 1){
      let selected = _.find(selectedDocumentTypeOptions, { value: checkListDocumentType?.document_type_id })

      return selected ? selected : null
    }
  }

  const onChangeCheckListDocumentType = (value, key) => {
    let _checkListDocumentType = { ...checkListDocumentType };
    let documentTypeId = _checkListDocumentType.document_type_id

    _checkListDocumentType[key] = value

    callbackSetCheckListDocumentType(_checkListDocumentType, documentTypeId)
  }

  const documentTypesInputSelect = () => {
    return (
      <FormGroup>
        <Label for={ inputName(formName, `document_type_${ index }`) }>
          { I18n.t('activerecord.models.document_type.one') }
        </Label>
        <Select
          className="mb-2"
          name={ inputName(formName, `document_count_type_${ index }`) }
          placeholder="-- Selecciona el tipo de documento --"
          styles={ selectStyle }
          options={ selectedDocumentTypeOptions }
          value={ getSelectedDocumentTypeOption() }
          onChange={ e =>  onChangeCheckListDocumentType(e?.value, 'document_type_id') }
          isOptionDisabled={ option => isOptionDisabled(option) }
        />
      </FormGroup>
    )
  }

  const documentCountPerDocumentTypeInput = () => {
    return (
      <FormGroup>
        <FieldError errors={ errors?.errors?.document_count || [] }>
          <Label for={ inputName(formName, `document_count_${ index }`) }>
            { I18n.t('check_list.processes.form.labels.document_count_amount') }
          </Label>
          <Input
            name={ inputName(formName, `document_count_${ index }`) }
            type="number"
            max="3"
            min="2"
            defaultValue={ checkListDocumentType?.document_count }
            onBlur={ (e) => onChangeCheckListDocumentType(e?.target?.value, 'document_count') }
            invalid={ _.has(errors?.errors, 'document_count') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  return(
    <div className="card bg-real-light pt-3 px-4 d-flex justify-content-center">
      <div className="row">
        <div className="col-md-1 d-flex justify-content-end align-items-start order-md-3 order-1">
          { btnRemove(checkListDocumentType) }
        </div>
        <div className="col-md-6">
          { documentTypesInputSelect() }
        </div>
        <div className="col-md-5">
          { documentCountPerDocumentTypeInput() }
        </div>
      </div>
    </div>
  )
}

export default CheckListDocumentTypeForm;