import React from 'react';
import {  FormFeedback, Input, Label,Button} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import swal from "sweetalert2";
import { options } from '../helper/pagination';
import _ from 'lodash';
import I18n from 'i18n-js';

const BulkLoadTagTableErrors = ({ tagBulkErrors,setTagBulkErrors,handleValues, drawValueCategorySelect, iconNewTag, isExistNameTag, handleValidateTagId}) =>{

  const getColumns = () =>{
    return [
      {
        dataField: 'controldoc_id',
        text: 'ControldocId',
        headerStyle: {display : 'none'},
        style: {display : 'none'},
        formatter : function(value,row,index) {
          return (<Input key={_.uniqueId("e_controldoc_id")} type="hidden" defaultValue={ value } readOnly/>);
        }
      }, {
        dataField: 'is_processing',
        text: 'Is Processing',
        headerStyle: {display : 'none'},
        style: {display : 'none'},
        formatter : function(value,row,index) {
          return (<Input key={_.uniqueId("e_is_processing_input")} type="hidden" defaultValue="false" readOnly/>);
        }
      }, {
        dataField: 'category',
        text: 'Categoria',
        formatter : function(value,row,index) {
          return(
            <>

            <Input
              key={_.uniqueId("e_category_input")}
              type="select"
              id={`${row.row}e-category`}
              className='form-control-border'
              onChange={ e =>handleValues(e,index,row,true)}
              defaultValue={ value.split(" / ")[0] }
              invalid={ value == "" ? true : false }
              required
            >
              { drawValueCategorySelect() }
            </Input>
              <FormFeedback>{ I18n.t('bulk_load_tags.form.field_empty') }</FormFeedback>
            </>
          )
        }
      }, {
        dataField: 'name',
        text: 'Nombre Etiqueta',
        formatter : function(value,row,index) {
          return (
            <>
            <Input
              key={_.uniqueId("e_name_input")}
              type="text"
              id={`${row.row}e-name`}
              onBlur={ e =>handleValues(e,index,row,true)}
              defaultValue={ value }
              invalid={ value == "" ? true : isExistNameTag(value) }
              required
            />
              <FormFeedback>
                {
                  value == "" ?
                    I18n.t('bulk_load_tags.form.field_empty') :
                    I18n.t('bulk_load_tags.form.duplicate_name')
                }
              </FormFeedback>
            </>
          );
        }
      }, {
        dataField: "actions",
        text: "Acciones",
        editable: false,
        headerStyle: { width: "150px" },
        formatter: (cellContent, row) => {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                key={ _.uniqueId("fix") }
                color="success"
                outline
                className="border-0"
                onClick={ (e) => handleValidateTagId(row) }
              >
                <i className="fa fa-redo-alt"/>
              </Button>
              <Button
                color="danger"
                outline
                className="border-0"
                onClick={ (e) => handleDestroyTagError(row.row) }
              >
                <i className="fas fa-trash-alt" />
              </Button>
              { iconNewTag(row) }
            </div>
          );
        }
      }
    ]
  }

  const handleDestroyTagError = (row, direct = false) => {
    if(!direct){
      swal.fire({
        title: 'Eliminar fila',
        text: "¿Estás seguro de eliminar la fila?",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'swal2-confirm btn btn-success ml-3',
          cancelButton: 'swal2-cancel btn btn-secondary'
        },
        buttonsStyling: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
        cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let _tagBulkErrors = [...tagBulkErrors]
          _.remove(_tagBulkErrors, item=>{return item.row == row})
          setTagBulkErrors(_tagBulkErrors);
          swal.fire(
            'Eliminado!',
            'La fila ha sido elimina correctamente',
            'success'
          )
        }
      })
    }else{
      let _tagBulkErrors = [...tagBulkErrors]
      _.remove(_tagBulkErrors, item=>{return item.row == row})
      setTagBulkErrors(_tagBulkErrors);
    }
  };

  const drawTagBulkErrors = () =>{
    if(tagBulkErrors && tagBulkErrors?.length > 0) {
      return(
        <div className="row">
          <h2 className="card-title text-danger mb-2">{ I18n.t('bulk_load_tags.form.records_errors') } ({ `${tagBulkErrors?.length}`}):</h2>
          <BootstrapTable
            key={ _.uniqueId("errors") }
            keyField='row'
            data={ tagBulkErrors }
            columns={ getColumns() }
            wrapperClasses="table-responsive"
            headerClasses="bg-primary border border-primary rounded"
            pagination={ paginationFactory(options) }
            hover
            size="sm"
            noDataIndication="No existen registros"
          />
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { drawTagBulkErrors() }
      </div>
    </div>
  )
}

export default BulkLoadTagTableErrors;
