import React, { useState } from "react";
import {
  Button
} from 'reactstrap';
import I18n from 'i18n-js';

const DocumentPreview = (props) => {
  const file = props?.file;

  const handleClickPreview = (e) => {
    window.open(URL.createObjectURL(file), 'blank')
  }

  const drawButtonPreview = () => {
    if(file){
      return(
        <Button
          onClick={ (e) => handleClickPreview(e) }
          type="button"
          color="primary"
          className="btn-sm my-2"
        >
          { I18n.t(`actions.preview`)  } { I18n.t(`activerecord.models.document.one`) }
        </Button>
      )
    }
  }

  return (
    <>
      { drawButtonPreview() }
    </>
  );
};

export default DocumentPreview;
