import React from "react";
import DocumentTypeForm from "../../document_type/form";
import {
  create as createDocumentType,
  update as updateDocumentType
} from "./axios";

const CorporateDocumentTypeForm = props => {

  return(
    <>
      <DocumentTypeForm
        documentType={ props?.documentType || {} }
        data={ props?.data || {} }
        configuration={{
          context: 'corporate',
          formName: 'corporate_document_type',
          defaultRequestParams: {}
        }}
        actions={{
          createDocumentType: createDocumentType,
          updateDocumentType: updateDocumentType,
          indexDocumentTypesPath: Routes.corporate_document_types_path,
          showDocumentTypePath: Routes.corporate_document_type_path
        }}
      />
    </>
  )
}

export default CorporateDocumentTypeForm;

