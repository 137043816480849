import React, {
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

import I18n from 'i18n-js';
import Select from 'react-select'

import {
  inputId,
  inputName,
} from '../../helper/form';

import {
  tooltipText
} from '../../helper/common';

import FieldError from '../../helper/FieldError';

const EntityFolderCheckListRequirementAttributes = props => {
  const {
    requirement,
    index,
    configuration: {
      formName,
      entity: entityConfiguration,
      folder: folderConfiguration,
      destroy: destroyConfiguration
    },
    data: {
      documentTypes,
      recurrencePeriods
    },
    callbacks: { callbackOnChangeRequirement }
  } = props;

  const onChangeRequirement = (event, key) => {
    let value = event.target.value;

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }

    let _requirement = { ... requirement }

    if(key == 'is_recurrent' && !value){
      _requirement.recurrence_period = null;
      _requirement.recurrence_start_at = null;
      _requirement.recurrence_end_at = null;
      _requirement.recurrence_deadline_upload_days = null;
      _requirement.deadline_upload_notification_at = null;
    }

    if(key == 'is_recurrent' && value){
      _requirement.deadline_upload_at = '';
      _requirement.deadline_upload_notification_at = null;
    }

    if(key == 'require_deadline_upload' && !value){
      _requirement.recurrence_deadline_upload_days = null;
      _requirement.deadline_upload_at = null;
      _requirement.deadline_upload_notification_at = null;
    }

    _requirement[key] = value;

    if(_.isFunction(callbackOnChangeRequirement)){
      callbackOnChangeRequirement(_requirement, index)
    }
  }

  // ---------- START ENTITY INPUT ----------
  const entityInput = () => {
    if(!entityConfiguration?.hideInput){
      return(
        <FormGroup>
          <FieldError errors={ [] }>
            <Label>
              { entityConfiguration?.label }
            </Label>
            <Input
              value={ requirement?.folder?.entity?.name }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const folderInput = () => {
    if(!folderConfiguration?.hideInput){
      return(
        <FormGroup>
          <FieldError errors={ [] }>
            <Label>
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.folder') }
            </Label>
            <Input
              value={ requirement?.folder?.name }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- END ENTITY INPUT ----------
  // ---------------------------------------
  // ---------- START DOCUMENT TYPE ----------
  const documentTypeSelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.document_type_id') || [] }>
          <Label
            for={ inputId(formName, 'document_type_id') }
            className='required'
          >
            { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.document_type_id') }
          </Label>

          <Select
            id={ inputId(formName, 'document_type_id') }
            name={ inputName(formName, 'document_type_id') }
            invalid={ _.has(requirement, 'errors.document_type_id') }
            value={ documentTypeSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, "document_type_id") }
            options={ documentTypeSelectOptions }
            placeholder={ I18n.t('inputs.select.placeholder') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const documentTypeSelectedOption = useMemo(() => {
    let selected = _.find(documentTypes, { id: requirement?.document_type_id } )

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }, [requirement?.document_type_id])

  const documentTypeSelectOptions = useMemo(() => {
    return _.map(documentTypes, dt => ({ label: dt.name, value: dt.id }))
  }, [])
  // ---------- END DOCUMENT TYPE ----------
  // ---------------------------------------
  // ---------- START NAME ----------
  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.name') || [] }>
          <Label>
            { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.name') }
          </Label>

          <Input
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            invalid={ _.has(requirement, 'errors.name') }
            value={ requirement?.name || '' }
            onChange={ event => onChangeRequirement(event, 'name') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NAME ----------
  // ---------------------------------------
  // ---------- START REQUIRE DEADLINE UPLOAD ----------
  const requireDeadlineUploadSwitchInput = () => {
    return(
      <FormGroup>
        <div className="custom-switch">
          <FieldError errors={ _.get(requirement, 'errors.require_deadline_upload') || [] }>
            <Input
              className="custom-control-input"
              type="checkbox"
              id={ inputId(formName, 'require_deadline_upload') }
              name={ inputName(formName, 'require_deadline_upload') }
              invalid={ _.has(requirement?.errors, 'require_deadline_upload') }
              onChange={ e => onChangeRequirement(e, 'require_deadline_upload') }
              checked={ requirement?.require_deadline_upload || false }
            />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'require_deadline_upload') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.require_deadline_upload') }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // ---------- END REQUIRE DEADLINE UPLOAD ----------
  // ---------------------------------------
  // ---------- START DEADLINE UPLOAD ADVANCED CONFIGURATION ----------
  const deadlineUploadAdvancedConfiguration = () => {
    if(requirement.require_deadline_upload){
      return(
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card hover-card card-material">
              <div className="card-header px-2 px-md-3 __with_collapsible-icons border-0">
                <a aria-controls="collapse" aria-expanded="true" className="font-weight-bold text-dark text-decoration-none d-block" data-bs-toggle="collapse" href="#deadlineUploadAdvancedConfiguration" role="button">
                  <i className="fas fa-cog mr-2"></i>
                  Configuración de Deadline
                </a>
              </div>
              <div className="collapse border-top show" id="deadlineUploadAdvancedConfiguration">
                <div className="card-body lh-sm">
                  { function(){
                    if(requirement.is_recurrent){
                      return(
                        <div className="row">
                          <div className="col">
                            { recurrenceDeadlineUploadDays() }
                          </div>
                          <div className="col">
                            { deadlineUploadNotificationAtInput() }
                          </div>
                        </div>
                      )
                    } else {
                      return(
                        <div className="row">
                          <div className="col">
                            { deadlineUploadAtInput() }
                          </div>
                          <div className="col">
                            { deadlineUploadNotificationAtInput() }
                          </div>
                        </div>
                      )
                    }
                  }()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  // ---------- END START DEADLINE UPLOAD ADVANCED CONFIGURATION ----------
  // ---------------------------------------
  // ---------- START DEADLINE_UPLOAD_AT ----------
  const deadlineUploadAtInput = () => {
    if(!requirement.is_recurrent){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.deadline_upload_at') || [] }>
            <Label
              className='required'
              for={ inputId(formName, 'deadline_upload_at') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.deadline_upload_at') }
            </Label>

            <Input
              type='datetime-local'
              id={ inputId(formName, 'deadline_upload_at') }
              name={ inputName(formName, 'deadline_upload_at') }
              invalid={ _.has(requirement, 'errors.deadline_upload_at') }
              value={ deadlineUploadatFormatedValue || '' }
              onChange={ event => onChangeRequirement(event, 'deadline_upload_at') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const deadlineUploadNotificationAtInput = () => {
    if(requirement.require_deadline_upload){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.deadline_upload_notification_at') || [] }>
            <Label
              className='required'
              for={ inputId(formName, 'deadline_upload_notification_at') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.deadline_upload_notification_at') }

              { tooltipText( I18n.t('entity_folder.check_list_requirements.deadline_upload_notification_at.info') ) }
            </Label>

            <Input
              type='number'
              id={ inputId(formName, 'deadline_upload_notification_at') }
              name={ inputName(formName, 'deadline_upload_notification_at') }
              invalid={ _.has(requirement, 'errors.deadline_upload_notification_at') }
              value={ requirement.deadline_upload_notification_at || '' }
              onChange={ event => onChangeRequirement(event, 'deadline_upload_notification_at') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const deadlineUploadatFormatedValue = useMemo(() => {
    if(requirement.deadline_upload_at){
      return moment(requirement.deadline_upload_at).format('YYYY-MM-DD HH:mm');
    }else {
      return ''
    }

  }, [requirement.deadline_upload_at])
  // ---------- END DEADLINE_UPLOAD_AT  ----------
  // ---------------------------------------
  // ---------- START IS RECURRENT  ----------
  const isRecurrentSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ _.get(requirement, 'errors.is_recurrent') || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'is_recurrent') }
              id={ inputId(formName, 'is_recurrent') }
              invalid={ _.has(requirement, 'errors.is_recurrent') }
              onChange={ event => onChangeRequirement(event, 'is_recurrent') }
              checked={ requirement?.is_recurrent || false }
            />
             <Label
                className='custom-control-label'
                for={ inputId(formName, 'is_recurrent') }
              >
                { I18n.t(`activerecord.attributes.entity_folder/check_list_requirement.is_recurrent`) }
              </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // ---------- END IS RECURRENT ----------
  // ---------------------------------------
  // ---------- START RECURRENCE PERIOD ----------
  const recurrencePeriodSelectInput = () => {
    if(requirement.is_recurrent){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.recurrence_period') || [] }>
            <Label
              className='required'
              for={ inputId(formName, 'recurrence_period') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.recurrence_period.one') }
            </Label>

            <Select
              id={ inputId(formName, 'recurrence_period') }
              name={ inputName(formName, 'recurrence_period') }
              invalid={ _.has(requirement, 'errors.recurrence_period') }
              value={ recurrencePeriodSelectedOption }
              onChange={ e => onChangeRequirement({ target: { value: e?.value }}, "recurrence_period") }
              options={ recurrencePeriodSelectOptions }
              placeholder={ I18n.t('inputs.select.placeholder') }
            />
          </FieldError>
        </FormGroup>
      )

    }
  }

  const recurrencePeriodSelectedOption = useMemo(() => {
    const selected = recurrencePeriods[requirement.recurrence_period]

    if(selected){
      return { value: requirement.recurrence_period, label: recurrencePeriods[requirement.recurrence_period] }
    } else {
      return null
    }
  }, [requirement?.recurrence_period])

  const recurrencePeriodSelectOptions = useMemo(() => {
    return _.map(recurrencePeriods, (label, key) => {
      return { label: label, value: key}
    })
  }, [])
  // ---------- END RECURRENCE PERIOD ----------
  // ---------------------------------------
  // ---------- START RECURRENCE START AT ----------
  const recurrenceStartAtInput = () => {
    if(requirement.is_recurrent){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.recurrence_start_at') || [] }>
            <Label
              className='required'
              for={ inputId(formName, 'recurrence_start_at') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.recurrence_start_at') }
            </Label>

            <Input
              type='date'
              id={ inputId(formName, 'recurrence_start_at') }
              name={ inputName(formName, 'recurrence_start_at') }
              invalid={ _.has(requirement, 'errors.recurrence_start_at') }
              value={ requirement?.recurrence_start_at || '' }
              onChange={ event => onChangeRequirement(event, 'recurrence_start_at') }
            />
          </FieldError>
        </FormGroup>
      )

    }
  }
  // ---------- END RECURRENCE START AT ----------
  // ---------------------------------------
  // ---------- START RECURRENCE START AT ----------
  const recurrenceEndAtInput = () => {
    if(requirement.is_recurrent){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.recurrence_end_at') || [] }>
            <Label
              for={ inputId(formName, 'recurrence_end_at') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.recurrence_end_at') }
              { tooltipText(I18n.t('entity_folder.check_list_requirements.recurrence_end_at.info')) }
            </Label>

            <Input
              type='date'
              id={ inputId(formName, 'recurrence_end_at') }
              name={ inputName(formName, 'recurrence_end_at') }
              invalid={ _.has(requirement, 'errors.recurrence_end_at') }
              value={ requirement?.recurrence_end_at || '' }
              onChange={ event => onChangeRequirement(event, 'recurrence_end_at') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- END RECURRENCE START AT ----------
  // ---------------------------------------
  // ---------- START RECURRENCE DEADLINE UPLOAD DAYS ----------
  const recurrenceDeadlineUploadDays = () => {
    if(requirement.is_recurrent){
      return(
        <FormGroup>
          <FieldError errors={ _.get(requirement, 'errors.recurrence_deadline_upload_days') || [] }>
            <Label
              className='required'
              for={ inputId(formName, 'recurrence_deadline_upload_days') }
            >
              { I18n.t('activerecord.attributes.entity_folder/check_list_requirement.recurrence_deadline_upload_days') }
              { tooltipText(I18n.t('entity_folder.check_list_requirements.recurrence_deadline_upload_days.info')) }
            </Label>

            <Input
              type='number'
              id={ inputId(formName, 'recurrence_deadline_upload_days') }
              name={ inputName(formName, 'recurrence_deadline_upload_days') }
              invalid={ _.has(requirement, 'errors.recurrence_deadline_upload_days') }
              value={ requirement?.recurrence_deadline_upload_days || '' }
              onChange={ event => onChangeRequirement(event, 'recurrence_deadline_upload_days') }
            />
          </FieldError>
        </FormGroup>
      )

    }
  }
  // ---------- END RECURRENCE DEADLINE UPLOAD DAYS ----------
  // ---------------------------------------
  // ---------- START RECURRENCE ADVANCED CONFIGURATION ----------
  const recurrenceAdvancedConfiguration = () => {
    if(requirement.is_recurrent){
      return(
        <div className="row">
          <div className="col-xl-4 col-md-12">
            { recurrencePeriodSelectInput() }
          </div>
          <div className="col-xl-4 col-md-6">
            { recurrenceStartAtInput() }
          </div>
          <div className="col-xl-4 col-md-6">
            { recurrenceEndAtInput() }
          </div>
        </div>
      )
    }
  }
  // ---------- END RECURRENCE ADVANCED CONFIGURATION ----------
  // ---------------------------------------
  // ---------- START ALLOW CLONE ----------
  const allowCloneSwitchInput = () => {
    if(!requirement.parent_requirement_id){
      return(
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ _.get(requirement, 'errors.allow_clone') || [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'allow_clone') }
                id={ inputId(formName, 'allow_clone') }
                invalid={ _.has(requirement, 'errors.allow_clone') }
                onChange={ event => onChangeRequirement(event, 'allow_clone') }
                checked={ requirement?.allow_clone || false }
              />
               <Label
                  className='custom-control-label'
                  for={ inputId(formName, 'allow_clone') }
                >
                  { I18n.t(`activerecord.attributes.entity_folder/check_list_requirement.allow_clone`) }
                </Label>
            </FieldError>
          </div>
        </FormGroup>
      )
    }
  }
  // ---------- END ALLOW CLONE ----------
  // ---------------------------------------
  // ---------- START DESTROY BUTTON ----------
  const destroyButton = () => {
    if(!destroyConfiguration?.hideButton){
      return(
        <div className="text-end">
          <Button
            color='danger'
            size='sm'
            onClick={ event => onChangeRequirement({target: { value: true}}, '_destroy') }
          >
            { I18n.t('actions.destroy') }
          </Button>
        </div>
      )
    }
  }
  // ---------- END DESTROY BUTTON ----------

  return(
    <div className='row'>
      <div className="col-12">
        { entityInput() }
        { folderInput() }

        { documentTypeSelectInput() }
        { nameInput() }

        { isRecurrentSwitchInput() }
        { recurrenceAdvancedConfiguration() }

        { requireDeadlineUploadSwitchInput() }
        { deadlineUploadAdvancedConfiguration() }
        { allowCloneSwitchInput() }
        { destroyButton() }
      </div>
    </div>
  )
}

export default EntityFolderCheckListRequirementAttributes;
