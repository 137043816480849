import React from 'react';
import * as XLSX from 'xlsx';
import swal from "sweetalert2";
import { Input, Label} from 'reactstrap';
import _ from 'lodash';
import I18n from 'i18n-js';

const BulkLoadTagFile = ({ setTagBulk, setTagBulkErrors, formName, setCategorySelect }) => {

  const handleFile = (_target) => {
    let file = _target;
    let page = [];
    let pageErrors = [];
    let isXlsx = file.value.split("\\").pop().includes(".xlsx");

    if(file != "" && file.type =="file" && isXlsx){
      let reader = new FileReader();
      reader.readAsArrayBuffer(file.files[0]);
      reader.onloadend = (e) => {
        let newData = new Uint8Array(e.target.result);
        let workbook = XLSX.read(newData,{type: "array"});

        let firstSheet = workbook.SheetNames[0];
        let sheet = workbook.Sheets[firstSheet]['!ref'];
        let _range = XLSX.utils.decode_range(sheet);
          _range.s.c = 0;
          _range.e.c = 2;
        let new_range = XLSX.utils.encode_range(_range);
        let excelInJSON = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], {range: new_range, blankRows: true, defval: ''});

        _.each(excelInJSON, function(row){
          let item = {};
          let col = 0;
          _.forEach(row, function(value,key){
            if (row.hasOwnProperty(key)){
              let newKey = ""
              switch(col) {
                case 1:
                  newKey = "category"
                  break;
                case 2:
                  newKey = "name"
                  break;
                default:
                  newKey = "controldoc_id"
              }
              item[newKey] = row[key];
              col++;
            }
            item.row = row.__rowNum__;
            item.uniqueId = _.uniqueId("id");
            item.category_name = "";
          })

          item.controldoc_id = item.controldoc_id.toString().trim();

          if(notEmptyItem(item)){
            page.push(item);
          }else if(item.controldoc_id =="" && item.name == "" && item.category == ""){
          }else if(item.name == "" || item.category == ""){
            pageErrors.push(item);
          }
        });

        let _filled = _.map(page, (hiring) => {
          return [JSON.stringify(hiring), hiring]
        });

        let _pageArr = new Map(_filled); // Pares de clave y valor
        let _pageFilled = [..._pageArr.values()]; // Conversión a un array

        let dataNotRepeat =_.filter(_pageFilled,(valorActual, indiceAltual,arreglo)=>{
          return _.findIndex(arreglo, (valorDeArreglo) =>{
            let indice = _.findIndex(arreglo, valorDeArreglo => verify( valorDeArreglo, valorActual ))
            if(indice !== indiceAltual){
              return valorDeArreglo;
            }
          })
        })

        setCategorySelect(getCategories(dataNotRepeat));
        setTagBulk(dataNotRepeat);
        setTagBulkErrors(pageErrors);
      }
    }else{
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El documento debe ser formato excel',
      })
    }
  }

  const getCategories = ( tag) =>{
    let arrayTag = [];
    _.forIn(tag, function(value, key){
      let id = value.category.split("/")[0].trim()
      let tag = value.category.split("/")[1].trim()
      arrayTag.push({"id":id, "name": tag})
    })
    return _.uniqBy(arrayTag,"id");
  }

  const notEmptyItem = (item)=>{
    return (
      item.name != "" &&
      item.category != ""
    )
  }

  const verify =( valorDeArreglo, valorActual) =>{
    return JSON.stringify(valorDeArreglo.name) ===
      JSON.stringify(valorActual.name) &&
      JSON.stringify(valorDeArreglo.controldoc_id) ===
      JSON.stringify(valorActual.controldoc_id) &&
      JSON.stringify(valorDeArreglo.category) ===
      JSON.stringify(valorActual.category)
  }

  return(
    <div className="row">
      <div className="col-12 mb-4">
        <Label>{ I18n.t('bulk_load_tags.form.upload_excel') }</Label>

        <Input
          type="file"
          name={`${formName}[file]`}
          id="bulk_load_tag_file"
          placeholder="Archivo de Excel"
          onChange={ (e) => handleFile(e.target) }
        />
        <small>
          { I18n.t('bulk_load_tags.form.template') } &nbsp;
          <a
            href={ Routes.download_example_bulk_load_tags_path()}
            target='_blank'
          >{ I18n.t('bulk_load_tags.form.download') }</a>
        </small>
      </div>
    </div>
  )
}
export default BulkLoadTagFile;
