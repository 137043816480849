import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext
} from "react";

import { Button, FormGroup, Label, Input } from "reactstrap";
import I18n from 'i18n-js';
// Form
import { useDrag, useDrop } from "react-dnd";

import FieldError from '../helper/FieldError';
import {
  inputId,
  inputName,
  drawTooltipHelp,
  tooltipInitializaer
} from '../helper/form';

import SignerAttributes from './attributes';
import SignerMemberAttributes from './member_attributes';
import SignerEmployeeAttributes from './employee_attributes';
import SignerCompanyAttributes from './company_attributes';
import SignerEmailNotificationSignAttributes from './email_notification_sign_attributes';

import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerForm = React.memo((props) => {

  let signer = useMemo(() => { return props.signer}, [props.signer]);

  const formName = props?.formName || 'signer';

  const {
    callbackSetSigner,
    index,
  } = props;

  useEffect(() => {
    tooltipInitializaer()
  }, [props])

  const {
    currentUser,
    currentCustomer,
    currentEntityType,
    data,
    context
  } = useContext(DocumentFormContext)

  const {
    resource
  } = useContext(DocumentSignerConfigContext)

  // Start Handle
  const handleSigner = (event, key) => {
    let _signer = { ... signer };

    let value = event?.target?.value;
    let type = event?.target?.type

    if(type == 'checkbox'){
      value = event?.target?.checked;
    }

    if(key == 'employee_sign' && value){
      _signer.email = null;
      _signer.name = null;
      _signer.role = _signer.role || I18n.t("signers.form.worker");
      _signer.member_sign = false;
      _signer.company_sign = false;

      _signer.custom_field_id = null;
      _signer.email_notification_sign = false;
    }

    if(key == 'company_sign' && value){
      _signer.email = null;
      _signer.name = null;
      _signer.role = null;
      _signer.member_sign = false;
      _signer.employee_sign = false;
      _signer.company_email = 'client_company_email';

      _signer.custom_field_id = null;
      _signer.email_notification_sign = false;
    }

    if(key == 'member_sign' && value){
      _signer.email = null;
      _signer.name = null;
      _signer.role = null;
      _signer.employee_sign = false;
      _signer.company_sign = false;
      _signer.company_email = null;

      _signer.custom_field_id = null;
      _signer.email_notification_sign = false;
    }

    if(key == 'email_notification_sign' && value){
      _signer.email = null;
      _signer.name = null;
      _signer.role = I18n.t('signers.form.default_role_email_notification');
      _signer.employee_sign = false;
      _signer.company_sign = false;
      _signer.company_email = null;
      _signer.member_sign = false
    }

    _signer[key] = value;

    if(_.isFunction(callbackSetSigner)){
      callbackSetSigner(_signer, index);
    }
  }

  const handleDestroy = event => {
    swalWithBootstrap.fire({
      title: I18n.t('signers.destroy.confirm.title'),
      html: I18n.t('signers.destroy.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        handleSigner({
          target: { value: true },
        }, '_destroy')
      }
    })
  }
  // End Handle

  // Start Drag Signer
  const draggableRef = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: "signer-card",
    hover(item, monitor) {
      if (!draggableRef.current || item.index === index) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      const hoverBoundingRect = draggableRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if(_.isFunction(props?.handleMoveSigner)){
        item.index = hoverIndex;
        props?.handleMoveSigner(dragIndex, hoverIndex);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "signer-card",
    item: {
      id: signer.order,
      index: index,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(draggableRef));

  // End Drag Signer

  // Start Draw
  const drawOrder = () => {
    if(resource.require_signers_order){
      return(
        <span className="text-muted">
          { I18n.t("signers.form.order") }
          &nbsp;
          { signer.order + 1 }
        </span>
      )
    }
  }

  const drawMemberSignSwitchInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <div className="custom-switch">
            <Input
              className="custom-control-input"
              type="checkbox"
              onChange={ e => handleSigner(e, "member_sign") }
              checked={ signer.member_sign || false }
              id={ inputId(formName, `member_sign`) }
              name={ inputName(formName, "member_sign") }
              value="1"
              style={{ display: "contents" }}
              disabled={ resource.isPersisted }
            />

            <Label
              className="custom-control-label"
              for={ inputId(formName, `member_sign`) }
            >
              { I18n.t("signers.form.member_sign") }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.member_sign_info')) }
            </Label>
          </div>
        </FormGroup>
      </div>
    )
  }

  const drawEmployeeSignSwitchInput = () => {
    if(context === 'laboral' || context == 'laboral_bulk'){
      return(
        <div className="col-md-4">
          <FormGroup>
            <div className="custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                onChange={ e => handleSigner(e, "employee_sign") }
                checked={ signer.employee_sign || false }
                id={ inputId(formName, `employee_sign`) }
                name={ inputName(formName, "employee_sign") }
                value="1"
                style={{ display: "contents" }}
                disabled={ resource.isPersisted }
              />

              <Label
                className="custom-control-label"
                for={ inputId(formName, `employee_sign`) }
              >
                { I18n.t("activerecord.attributes.signer.employee_sign") }
                { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.employee_sign_info')) }
              </Label>
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const drawCompanySignSwitchInput = () => {
    if(context == 'corporate' || context === 'corporate_bulk'){
      return(
        <div className="col-md-4">
          <FormGroup>
            <div className="custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                onChange={ e => handleSigner(e, "company_sign") }
                checked={ signer.company_sign || false }
                id={ inputId(formName, `company_sign`) }
                name={ inputName(formName, "company_sign") }
                value="1"
                style={{ display: "contents" }}
                disabled={ resource.isPersisted }
              />

              <Label
                className="custom-control-label"
                for={ inputId(formName, `company_sign`) }
              >
                { I18n.t("activerecord.attributes.signer.company_sign") }
              </Label>
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const drawEmailNotificationSignSwitchInput = () => {
    if(context === 'abstract_bulk' || (context == 'abstract' && resource.entity_id)){
      return(
        <div className="col-md-4">
          <FormGroup>
            <div className="custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                onChange={ e => handleSigner(e, "email_notification_sign") }
                checked={ signer.email_notification_sign || false }
                id={ inputId(formName, `email_notification_sign`) }
                name={ inputName(formName, "email_notification_sign") }
                value="1"
                style={{ display: "contents" }}
                disabled={ resource.isPersisted }
              />

              <Label
                className="custom-control-label"
                for={ inputId(formName, `email_notification_sign`) }
              >
                { I18n.t("activerecord.attributes.signer.select_email_notification_sign", { entityType: currentEntityType.singular_name }) }
              </Label>
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const drawIsViewerSwitchInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <div className="custom-switch">
            <Input
              className="custom-control-input"
              type="checkbox"
              onChange={ e => handleSigner(e, "is_viewer") }
              checked={ signer.is_viewer || false }
              id={ inputId(formName, `is_viewer`) }
              name={ inputName(formName, "is_viewer") }
              value="1"
              style={{ display: "contents" }}
              disabled={ resource.isPersisted }
            />

            <Label
              className="custom-control-label"
              for={ inputId(formName, `is_viewer`) }
            >
              { I18n.t("activerecord.attributes.signer.is_viewer") }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.is_viewer_info')) }
            </Label>
          </div>
        </FormGroup>
      </div>
    )
  }
  const drawSignerAttributes = () => {
    let _signerAttributes = {
      callbackSetSigner: callbackSetSigner,
      formName: formName,
      signer: signer,
      index: index
    }

    if(signer?.member_sign){
      return(
        <SignerMemberAttributes { ... { ... _signerAttributes } }/>
      )
    } else if(signer.employee_sign){
      return(
        <SignerEmployeeAttributes { ... { ... _signerAttributes } }/>
      )
    } else if(signer.company_sign){
      return(
        <SignerCompanyAttributes { ... { ... _signerAttributes } }/>
      )
    } else if(signer.email_notification_sign){
      return(
        <SignerEmailNotificationSignAttributes { ... { ... _signerAttributes } }/>
      )
    } else {
      return(
        <SignerAttributes { ... { ... _signerAttributes } }/>
      )
    }
  }
  // End Draw

  const containerClassDndContent = () => {
    let klasses = []

    if(resource.require_signers_order){ klasses.push('dnd-content') }
    if(isDragging){ klasses.push('is-dragging') }
    if(isOver){ klasses.push('is-over') }

    return _.join(klasses, ' ');
  }

  return(
    <div className={ `row ${ signer._destroy ? 'd-none' : '' }` }>
      <div className="col-12">
        <div
          className={ `card bg-real-light signer-card ${ containerClassDndContent() }`}
          ref={ draggableRef }
        >
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>
                <i className="fas fa-user-edit text-muted mr-2"></i>
                { drawOrder() }
              </div>
              <div className="text-end">
                <Button
                  color='danger'
                  size='sm'
                  onClick={ e => handleDestroy(e) }
                  outline
                  disabled={ resource.isPersisted }
                  title={ I18n.t('signers.form.remove_signer') }
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </div>

            <div className="row">
              <Input
                type="hidden"
                id={ inputId(formName, 'id') }
                name={ inputName(formName, 'id') }
                value={ signer?.id || '' }
              />

              <Input
                type="hidden"
                id={ inputId(formName, 'order') }
                name={ inputName(formName, 'order') }
                value={ signer?.order || 0 }
              />

              { drawEmployeeSignSwitchInput() }
              { drawCompanySignSwitchInput() }
              { drawEmailNotificationSignSwitchInput() }
              { drawMemberSignSwitchInput() }
              { drawIsViewerSwitchInput() }
              {/*{ drawRequireIdentityVerification() }*/}
            </div>

            <div className="row">
              { drawSignerAttributes() }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

export default SignerForm;
