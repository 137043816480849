import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react';

import {
  FormGroup,
  Input,
  Label,
  FormText
} from 'reactstrap';

import I18n from 'i18n-js';

import {
  inputId,
  inputName,
  drawTooltipHelp,
  tooltipInitializaer
} from '../helper/form';

import {
  drawTooltipEmptyToken,
  drawTooltipRequireSigners,
} from '../helper/controldoc_sign/integration';

import FieldError from '../helper/FieldError';

import DocumentFormContext from '../document/context/document_form_context';
import DocumentSignerConfigContext from '../document/context/signer_config_context';

const DocumentSignerConfigAttributes = React.memo((props) => {
  const formName = props?.formName || 'signers';
  const { handleResource } = props

  const resource = props?.resource;

  const {
    currentUser,
    currentCustomer,
    context
  } = useContext(DocumentFormContext)

  const documentSignerContext = useContext(DocumentSignerConfigContext)

  const isPersisted = documentSignerContext.resource.isPersisted;

  // Se debe mostrar el switch de FAO solo cuando el texton
  // Es laboral o corporate
  // Estos contextos se refieren al formulario individual de carga de documentos.

  const showFaoSwitch = useMemo(() => {
    return (context == 'laboral' || context == 'corporate' || context == 'abstract' || context == 'abstract_bulk')
  }, [])

  useEffect(() => {
    tooltipInitializaer()
  }, [resource.require_signers]);

  const drawRequireSignersSwitchInput = () => {
    return(
      <div className="col-md-12">
        <FormGroup className="mb-0">
          <div className="custom-switch">
            <FieldError errors={ resource?.errors?.require_signers || [] }>
              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_signers') }
                name={ inputName(formName, 'require_signers') }
                invalid={ _.has(resource?.errors, 'require_signers') }
                onChange={ e => handleResource(e, 'require_signers') }
                checked={ resource?.require_signers || false }
                value={ '1' }
                style={{ display: 'contents' }}
                disabled={ isPersisted || resource?.keep_format }
              />

              <Label
                className="custom-control-label"
                for={ inputId(formName, 'require_signers') }
              >
                { I18n.t(`activerecord.attributes.document.require_signers`) }
              </Label>

              { drawTooltipRequireSigners(currentUser?.controldoc_sign_api_params) }
              { drawTooltipEmptyToken(currentUser?.controldoc_sign_api_params) }
              {resource?.keep_format &&
                <FormText color="info" className="mt-0 lh-sm">
                  <i className="fas fa-info mr-2"></i>
                  Al mantener formato del archivo no es posible agregar firmantes
                </FormText>
              }
            </FieldError>
          </div>
        </FormGroup>
      </div>
    )
  }

  const drawRequireFaoSwitchInput = () => {
    if(resource?.require_signers && currentCustomer?.enable_fao && showFaoSwitch){
      return(
        <div className="list-group-item">
          <div className="custom-switch">
            <FieldError errors={ resource?.errors?.require_fao || [] }>
              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_fao') }
                name={ inputName(formName, 'require_fao') }
                invalid={ _.has(resource?.errors, 'require_fao') }
                onChange={ e => handleResource(e, 'require_fao') }
                checked={ resource?.require_fao || false }
                value={ '1' }
                disabled={ isPersisted }
              />
              <Label
                className="custom-control-label"
                for={ inputId(formName, 'require_fao') }
              >
                { I18n.t(`activerecord.attributes.document.require_fao`) }
                { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.require_fao_info')) }
              </Label>
            </FieldError>
          </div>
        </div>
      )
    }
  }

  const drawRequireSignersOrderSwitchInput = () => {
    if(resource.require_signers) {
      return(
        <div className="custom-switch">
          <FieldError errors={ resource?.errors?.require_signers_order || [] }>
            <Input
              className="custom-control-input"
              type="checkbox"
              id={ inputId(formName, 'require_signers_order') }
              name={ inputName(formName, 'require_signers_order') }
              invalid={ _.has(resource?.errors, 'require_signers_order') }
              onChange={ e => handleResource(e, 'require_signers_order') }
              checked={ resource?.require_signers_order || false }
              value={ '1' }
              disabled={ isPersisted }
            />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'require_signers_order') }
            >
              { I18n.t(`activerecord.attributes.document.require_signers_order`) }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.require_signers_order_info')) }
            </Label>
            {resource?.require_signers && resource?.require_fao &&
              <FormText color="info" className="mt-0 lh-sm">
                <i className="fas fa-info mr-2"></i>
                Al usar firma Avanzada el orden de firmantes queda activo por defecto.
              </FormText>
            }
          </FieldError>
        </div>
      )
    }
  }

  const drawRequireSignatureDeadlineSwitchInput = () => {
    if (resource?.require_signers){
      return(
        <div className="custom-switch">
          <FieldError errors={ resource?.errors?.require_signature_deadline || [] }>
            <Input
              className="custom-control-input"
              type="checkbox"
              id={ inputId(formName, 'require_signature_deadline') }
              name={ inputName(formName, 'require_signature_deadline') }
              invalid={ _.has(resource?.errors, 'require_siganture_deadline') }
              onChange={ e => handleResource(e, 'require_signature_deadline') }
              checked={ resource?.require_signature_deadline || false }
              value={ '1' }
            />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'require_signature_deadline') }
            >
              { I18n.t(`activerecord.attributes.document.require_signature_deadline`) }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.require_signature_deadline_info')) }
            </Label>
          </FieldError>
        </div>
      )
    }
  }

  const drawSignatureDeadlineAtInput = () => {
    if(resource?.require_signers && resource?.require_signature_deadline){
      return(
        <div className="mb-0">
          <FieldError errors={ resource?.errors?.signature_deadline_at || [] }>
            <Input
              type='datetime-local'
              id={ inputId(formName, 'signature_deadline_at') }
              name={ inputName(formName, 'signature_deadline_at') }
              invalid={ _.has(resource?.errors, 'signature_deadline_at') }
              onChange={ e => handleResource(e, 'signature_deadline_at') }
              value={ resource?.signature_deadline_at ? moment(resource.signature_deadline_at).format('YYYY-MM-DD HH:mm') : '' }
            />
          </FieldError>
        </div>
      )
    }
  }

  const drawGenerateViewerTable = () => {
    if(resource?.require_signers){
      return(
        <div className="row">
          <div className="col-12">
            <div className="custom-switch">
              <FieldError errors={ resource?.errors?.generate_viewer_table || [] }>
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  id={ inputId(formName, 'generate_viewer_table') }
                  name={ inputName(formName, 'generate_viewer_table') }
                  invalid={ _.has(resource?.errors, 'require_siganture_deadline') }
                  onChange={ e => handleResource(e, 'generate_viewer_table') }
                  checked={ resource?.generate_viewer_table || false }
                  value={ '1' }
                  disabled={ isPersisted }
                />
                <Label
                  className="custom-control-label"
                  for={ inputId(formName, 'generate_viewer_table') }
                >
                  { I18n.t(`activerecord.attributes.document.generate_viewer_table`) }
                  { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.generate_viewer_table_info')) }
                </Label>
              </FieldError>
            </div>
          </div>
        </div>
      )
    }
  }

  return(
    <div className="row">
      { drawRequireSignersSwitchInput() }
      <div className="col-12">
        {resource?.require_signers &&
          <div className="card overflow-hidden">
            <div className="list-group list-group-flush">
              <div className="list-group-item fw-bold bg-real-light">
                <i className="fas fa-signa"></i>
                Configuración de Firma de documento
              </div>
              { drawRequireFaoSwitchInput() }
              <div className="list-group-item">
                { drawRequireSignersOrderSwitchInput() }
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col-md-6">
                    { drawRequireSignatureDeadlineSwitchInput() }
                  </div>
                  <div className="col-md-6">
                    { drawSignatureDeadlineAtInput() }
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                { drawGenerateViewerTable() }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
})

export default DocumentSignerConfigAttributes;
