// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "../styles/application.scss";
import "bootstrap";

import 'sweetalert';

import I18n from 'i18n-js/index.js.erb'
import "chartkick/chart.js"

import 'modal_responder';

import './toggle_grid_view.js';

import './workflow/process.js';

const _ = require('lodash');

require('admin-lte/dist/js/adminlte');
require('packs/components');
require('react-bootstrap-table-next/dist/react-bootstrap-table2.min.css');
require("@nathanvda/cocoon")

window.moment = require('moment');

window.Routes = require('js-routes/index.js.erb');

Rails.start()
ActiveStorage.start()

const images = require.context('../images', true)
window.bootstrap = require('bootstrap');

window.swal = require('sweetalert2')
import swal from 'sweetalert2';

window.swalWithBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success ml-3',
    cancelButton: 'btn btn-secondary'
  },
  showCancelButton: true,
  cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
  confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
  reverseButtons: true,
  buttonsStyling: false
});

window.swalToastBootstrap = swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = swal.stopTimer;
    toast.onmouseleave = swal.resumeTimer;
  }
});

$(function(){
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var npsModalResult = new bootstrap.Modal(document.getElementById('npsResultModal'), {})

  if(npsModalResult){
    npsModalResult.show()
  }
});

$(function(){
  window.copyText = (target) => {
    const text = target.getAttribute('data-copy-text');
    const textElement = target.previousElementSibling;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        togleTooltip(target, 'Copiado!', 'Copiar');
      }).catch(() => {
        console.error('Failed to copy using clipboard API.');
        fallbackCopy(text, target);
      });
    } else {
      fallbackCopy(text, target);
    }
    highlightText(textElement);
  }

  function fallbackCopy(text, target) {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    togleTooltip(target, 'Copiado!', 'Copiar');
  }

  function togleTooltip(target, successTitle, originalTitle) {
    const tooltip = bootstrap.Tooltip.getInstance(target) || new bootstrap.Tooltip(target);

    target.setAttribute('title', successTitle);
    tooltip._fixTitle();
    tooltip.show();

    setTimeout(() => {
      target.setAttribute('title', originalTitle);
      tooltip._fixTitle();
      tooltip.hide();
    }, 1000);
  }

  function highlightText(element) {
    const range = document.createRange();
    range.selectNodeContents(element);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}())
