import React, {useCallback} from 'react';
import {  FormFeedback, Input, Label,Button} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import swal from "sweetalert2";
import { options } from '../../../../helper/pagination';
import { defaultTableHeaderStyle } from "../../../../helper/bootstrap-table/helper";
import _ from 'lodash';

const LaboralBulkLoadHiringTableErrors = ({ hiringBulkErrors,setHiringBulkErrors,handleValues,isExistHiringId, drawValueEmployeeSelect, drawValueCompanySelect, handleValidateHiringId ,iconNewHiring, customFieldsHeaders}) =>{

  const onChangeCellValues = (e,index, key) =>{
    let value = e?.target?.value
    hiringBulkErrors[index][key] = value
  }

  const customFieldSelectOptions = useCallback((listOptions) => {
    return listOptions.map((option) => {
      return (
        <option
          key={ _.uniqueId("e_hiring_id_input") }
          value={ option }
        >
          { option }
        </option>
      );
    });
  },[])

  const customFieldSelectInput = (value, index, row, custom) => {
    if(custom.data_type === "list"){
      return (
        <Input
          type="select"
          key={ _.uniqueId("e_hiring_id_input") }
          value={ value }
          onChange={ e => onChangeCellValues(e, index, custom.code) }
        >
          <option key={ _.uniqueId("e_hiring_id_input") } value=""></option>
          { customFieldSelectOptions(custom.list_options) }
        </Input>
      );
    }else{
      return (
        <Input
          key={ _.uniqueId("e_hiring_id_input") }
          type="text"
          id={ `${row.row}e-hiring_id` }
          onBlur={ e =>handleValues(e,index,row,true) }
          defaultValue={ value }
        />
      );
    }
  }

  const getCustomFields = (customFieldsHeaders) => {
    let _custom_fields = customFieldsHeaders
    let items = []
    let actions_column = getColumnsActions();
    _.each(_custom_fields, function(custom){
      let item = {
        dataField: custom.code,
        text: custom.code,
        headerStyle: defaultTableHeaderStyle,
        formatter : function(value,row,index) {
          return customFieldSelectInput(value, index, row, custom)
        }
      }
      items.push(item)
    })

    return _.concat(items, actions_column);
  }

  const getColumnsActions = () => {
    return [
      {
        dataField: "actions",
        text: "Acciones",
        editable: false,
        headerStyle: {...defaultTableHeaderStyle, width: "100px"},
        formatter: (cellContent, row) => {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                key={ _.uniqueId("fix") }
                color="success"
                outline
                className="border-0"
                onClick={ (e) => handleValidateHiringId(row) }
              >
                <i className="fa fa-redo-alt"/>
              </Button>
              <Button
                color="danger"
                outline
                className="border-0"
                onClick={ (e) => handleDestroyHiringError(row.row) }
              >
                <i className="fas fa-trash-alt" />
              </Button>
              { iconNewHiring(row) }
            </div>
          );
        }
      }
    ]
  }

  const firstPartColumns = [
    {
      dataField: 'controldoc_id',
      text: 'ControldocId',
      headerStyle: {display : 'none'},
      style: {display : 'none'},
      formatter : function(value,row,index) {
        return (<Input key={_.uniqueId("e_controldoc_id")} type="hidden" defaultValue={ value } readOnly/>);
      }
    }, {
      dataField: 'is_processing',
      text: 'Is Processing',
      headerStyle: {display : 'none'},
      style: {display : 'none'},
      formatter : function(value,row,index) {
        return (<Input key={_.uniqueId("e_is_processing_input")} type="hidden" defaultValue="false" readOnly/>);
      }
    }, {
      dataField: 'hiring_id',
      text: 'Identificador',
      headerStyle: defaultTableHeaderStyle,
      formatter : function(value,row,index) {
        return (
          <>
          <Input
            key={_.uniqueId("e_hiring_id_input")}
            type="text"
            id={`${row.row}e-hiring_id`}
            onBlur={ e =>handleValues(e,index,row,true)}
            defaultValue={ value }
            invalid={ value != "" ? isExistHiringId(value) : false }
            required
          />
            <FormFeedback>{ "Identificador duplicado" }</FormFeedback>
          </>
        );
      }
    }, {
      dataField: 'employee_id',
      text: 'Trabajador',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input
            key={_.uniqueId("e_employee_id_input")}
            type="select"
            id={`${row.row}e-employee_id`}
            placeholder="-- Seleccionar Trabajador --"
            className='form-control-border'
            onChange={ e =>handleValues(e,index,row,true)}
            defaultValue={ value.split(" /")[0] }
          >
            { drawValueEmployeeSelect() }
          </Input>
        )
      },
    }, {
      dataField: 'company_id',
      text: 'Empresa',
      headerStyle: {...defaultTableHeaderStyle, width: "300px"},
      formatter : function(value,row,index) {
        return(
          <Input
            key={_.uniqueId("e_company_id_input")}
            type="select"
            style={{width: 250 + "px"}}
            id={`${row.row}e-company_id`}
            placeholder="-- Seleccionar Empresa --"
            onChange={ e =>handleValues(e,index,row, true)}
            className='form-control-border'
            defaultValue={ value.split(" /")[0]  }
          >
            { drawValueCompanySelect() }
          </Input>
        )
      }
    }, {
      dataField: 'start_date',
      text: 'Fecha Inicio',
      headerStyle: defaultTableHeaderStyle,
      formatter : function(value,row,index) {
        return(
        <>
          <Input type="date"
            key={_.uniqueId("e_start_date_input")}
            id={`${row.row}e-start_date`}
            className='form-control-border'
            onChange={ e =>handleValues(e,index,row,true)}
            required
            defaultValue={ value }
            invalid={ value == "" ? true : false }
          />
          <FormFeedback>No debe ser vacio</FormFeedback>
        </>
        )
      }
    }, {
      dataField: 'end_date',
      text: 'Fecha Termino',
      headerStyle: defaultTableHeaderStyle,
      formatter : function(value,row,index) {
        return(
        <>
          <Input type="date"
            key={_.uniqueId("e_end_date_input")}
            id={`${row.row}e-end_date`}
            className='form-control-border'
            onChange={ e =>handleValues(e,index,row,true)}
            defaultValue={ value }
            invalid={ compareDates(row) }
          />
          <FormFeedback>No puede ser menor que la fecha de inicio</FormFeedback>
        </>
        )
      }
    }
  ]

  const compareDates = (row) => {
    return row.start_date > row.end_date
  }

  const columns = _.concat(firstPartColumns, getCustomFields(customFieldsHeaders))

  const handleDestroyHiringError = (row, direct = false) => {
    if(!direct){
      swal.fire({
        title: 'Eliminar fila',
        text: "¿Estás seguro de eliminar la fila?",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'swal2-confirm btn btn-success ml-3',
          cancelButton: 'swal2-cancel btn btn-secondary'
        },
        buttonsStyling: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
        cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let _hiringBulkErrors = [...hiringBulkErrors]
          _.remove(_hiringBulkErrors, item=>{return item.row == row})
          setHiringBulkErrors(_hiringBulkErrors);
          swal.fire(
            'Eliminado!',
            'La fila ha sido elimina correctamente',
            'success'
          )
        }
      })
    }else{
      let _hiringBulkErrors = [...hiringBulkErrors]
      _.remove(_hiringBulkErrors, item=>{return item.row == row})
      setHiringBulkErrors(_hiringBulkErrors);
    }
  };

  const drawHiringBulkErrors = () =>{
    if(hiringBulkErrors && hiringBulkErrors?.length > 0) {
      return(
        <div className="row">
          <h2 className="card-title text-danger mb-2">Registros con Errores ({ `${hiringBulkErrors?.length}`}):</h2>
          <BootstrapTable
            key={ _.uniqueId("errors") }
            keyField='row'
            data={ hiringBulkErrors }
            columns={ columns }
            wrapperClasses="table-responsive height-600"
            pagination={ paginationFactory(options) }
            hover
            noDataIndication="No existen registros"
          />
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { drawHiringBulkErrors() }
      </div>
    </div>
  )
}

export default LaboralBulkLoadHiringTableErrors;
