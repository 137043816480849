import axios from "axios";

export const create = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.corporate_document_types_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });
  promise
  .then(response => {
    callback(response);
  })
  .catch(error => {
    callback(error.response);
  });
}

export const update = (id, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.corporate_document_type_path(id, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
