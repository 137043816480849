import React, {
  useState,
  useEffect,
  useMemo
} from 'react';

import LaboralBulkLoadHiringDocumentFileInput from './file_input'
import I18n from 'i18n-js';

import Select from 'react-select'

import { create } from "./axios";
import { inputId, inputName, scrollToTop, drawTooltipHelp } from '../../../../helper/form';

import DocumentSignerConfigAttributes from '../../../../document/signer_config_attributes';
import SignerFormList from '../../../../signer/form_list';

import {
  onChangeDocumentHelper
} from '../../../../document/helper'

import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

import FieldError from '../../../../helper/FieldError';

import DocumentFormContext from '../../../../document/context/document_form_context';
import DocumentSignerConfigContext from '../../../../document/context/signer_config_context'

const LaboralBulkLoadHiringDocumentForm = props => {

  const documentTypes = props?.documentTypes || [];
  const formName = 'laboral_bulk_load_hiring_document';
  const current_user = props?.current_user || {};

  const localeKlass = 'laboral/bulk/load/hiring_document';
  const formKlass = 'Laboral::Bulk::Load::HiringDocument'

  const { data } = props;

  const [bulkLoad, setBulkLoad] = useState(props?.bulkLoad || {})

  const [errors, setErrors] = useState({})
  const [errorsFile, setErrorsFiles] = useState([])
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  const [hiringDocuments, setHiringDocuments] = useState([])

  const documentFormContextValue = useMemo(() => {
    return {
      currentUser: props?.currentUser,
      currentCustomer: props?.currentCustomer,
      context: 'laboral_bulk',
      data: props?.data
    }
  }, [])

  const documentSignerConfigContextValue = useMemo(() => {
    return {
      resource: {
        require_signers: bulkLoad?.require_signers,
        require_signers_order: bulkLoad?.require_signers_order,
        require_fao: bulkLoad?.require_fao,
        isPersisted: false
      },
    }
  }, [
    bulkLoad?.require_signers,
    bulkLoad?.require_signers_order,
    bulkLoad?.require_fao,
  ])

  const handleBulkLoad = (event, key) => {
    let value = event?.target?.value;

    let bulkChanges = {}

    if(event?.target?.type == 'checkbox'){
      value = event?.target?.checked;
    }

    bulkChanges = {
      ... bulkChanges,
      ... onChangeDocumentHelper(bulkLoad, key, value, data?.documentTypes)
    }

    bulkChanges[key] = value

    setBulkLoad(prevState => {
      return { ... prevState, ...bulkChanges }
    })
  }

  const getFormData = () => {
    let _formData = new FormData();
    _formData.append(`${formName}[label]`, bulkLoad?.label || '');
    _formData.append(`${formName}[document_type_id]`, bulkLoad?.document_type_id || '');
    _formData.append(`${ formName }[requires_expire]`, bulkLoad?.requires_expire || false);
    _formData.append(`${ formName }[expires_at]`, bulkLoad?.expires_at || '');

    _formData.append(`${ formName }[require_signers]`, bulkLoad.require_signers ? '1' : '0')
    // _formData.append(`${ formName }[require_fao]`, bulkLoad?.require_fao || false);
    _formData.append(`${ formName }[require_signers_order]`, bulkLoad.require_signers_order ? '1' : '0')
    _formData.append(`${ formName }[require_signature_deadline]`, bulkLoad.require_signature_deadline ? '1' : '0')
    _formData.append(`${ formName }[signature_deadline_at]`, bulkLoad.signature_deadline_at || "")
    _formData.append(`${ formName }[generate_viewer_table]`, bulkLoad.generate_viewer_table ? '1' : '0')
    _formData.append(`${ formName }[keep_format]`, bulkLoad.keep_format ? '1' : '0')


    _.each(hiringDocuments, function(hrDocuments, index){
      _formData.append(`${ formName }[items_attributes][${index}][hiring_id]`, hrDocuments?.hiring?.id || '')
      _formData.append(`${ formName }[items_attributes][${index}][file]`, hrDocuments?.file?.src?.file || '')
    });

    _.each(bulkLoad.signers, function(signer, index){
      _formData.append(`${ formName }[signers_attributes][${ index }][id]`, signer?.id || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][email]`, signer?.email || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][name]`, signer?.name || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][role]`, signer?.role || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][order]`, signer?.order);
      _formData.append(`${ formName }[signers_attributes][${ index }][employee_sign]`, signer?.employee_sign || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][member_sign]`, signer?.member_sign || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][_destroy]`, signer?._destroy || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][is_viewer]`, signer?.is_viewer || false);
    })

    return _formData
  }

  const handleSubmit = () => {
    let formData = getFormData();
    setDisabledSubmitButton(true)

    create(formData, response => {
      if(response.status == 201){
        window.location = Routes.laboral_bulk_load_hiring_document_path(response.data.id);
      } else if(response.status == 422){
        setDisabledSubmitButton(false);
        setBulkLoad(response.data);
        scrollToTop();
      }
    })
  }

  const drawErrors = (key) => {
    if(_.has(bulkLoad?.errors, key)) {
      return _.map(bulkLoad.errors[key], function (error) {
        return (
          <FormFeedback
            key={ _.uniqueId(`${key}_error_`) }
          >
            { error }
          </FormFeedback>
        );
      });
    }
  }

  const drawErrorSigner = () => {
    if(_.has(bulkLoad?.errors, 'signers')){
      return(
        <div className="alert alert-danger">
          { _.map(bulkLoad?.errors?.signers, function(_error){
            return(
              <span key={ _.uniqueId('signer_error') } >{ _error }</span>
            )
          })}
        </div>
      )
    }
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: 'Realizar carga masiva',
      html: '¿Estás seguro de realizar la carga masiva?',
    }).then( result => {
      if(result.isConfirmed){
        handleSubmit()
      }
    })
  }

  const drawHiringErrors = () => {
    if(_.has(errors, 'items')){
      return(
        <ul className='list-unstyled text-danger' style={{ fontSize: '0.875em'}}>
          { _.map(errors['items'], function(_error){
            return(
              <li key={ _.uniqueId('hiring-error-') }>{ _error }</li>
            )
          })}

        </ul>
      )
    }
  }

  const drawInstructions = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="alert alert-info">
            <p className='mb-0'>
              <i className="fas fa-info-circle mr-2"></i>
              Para agregar archivos se debe arrastrar y soltar o hacer click en Agregar Archivos (max. 100 archivos de 10mb c/u).
            </p>
            <p className='mb-0'>
              <i className="fas fa-info-circle mr-2"></i>
              Recuerde que el nombre del archivo debe ser el identificador de la contratación (ej. C-1-C-000001.pdf)
            </p>
          </div>
        </div>
      </div>
    )
  }

  const drawSigners = () => {
    if(bulkLoad.require_signers){
      return(
        <div className="row mb-3">
          <div className="col-12">
            { drawErrorSigner() }
            <SignerFormList
              formName={ `${ formName }[signers_attributes]` }
              signers={ bulkLoad.signers || [] }
              setResource={ setBulkLoad }
            />
          </div>
        </div>
      )
    }
  }

  const drawTextSubmitButton = () => {
    if(disabledSubmitButton){
      return I18n.t('actions.saving')
    } else {
      return I18n.t('actions.save')
    }
  }

  const drawDocumentErrors = () => {
    if(_.has(bulkLoad?.errors, 'items')){
      return(
        <div className="alert alert-danger">
          { bulkLoad?.errors['items'][0] }
        </div>
      )
    }
  }


  // Start Document Type
  const getDocumentTypeSelectedOption = () => {
    let selected = _.find(data?.documentTypes, { id: bulkLoad?.document_type_id } )

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }

  const drawSelectDocumentTypeOptions = () => {
    return _.map(data?.documentTypes || [], documentType => ({ value: documentType.id, label: documentType.name }))
  }
  // End Document Type

  const drawKeepFormatSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ bulkLoad?.errors?.keep_format || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'keep_format') }
              id={ inputId(formName, 'keep_format') }
              invalid={ _.has(bulkLoad?.errors, 'keep_format') }
              onChange={ e => handleBulkLoad(e, 'keep_format') }
              checked={ bulkLoad?.keep_format || false }
              value={ '1' }
              style={{ display: 'contents' }}
              disabled={ bulkLoad?.require_signers }
            />
            <Label
              className='custom-control-label'
              for={ inputId(formName, 'keep_format') }
            >
              { I18n.t(`activerecord.attributes.document.keep_format`) }
              { drawTooltipHelp(I18n.t('documents.new.keep_format_help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // Start Requires Expire
  const drawRequiresExpireSwitchInput = () => {
    return(
      <FormGroup>
        <div className="custom-switch">
          <FieldError errors={ bulkLoad?.errors?.requires_expire || [] }>
            <Input
              className='custom-control-input'
              type="checkbox"
              id={ inputId(formName, 'requires_expire') }
              name={ inputName(formName, 'requires_expire') }
              invalid={ _.has(bulkLoad?.errors, 'requires_expire') }
              onChange={ e => handleBulkLoad(e, 'requires_expire') }
              checked={ bulkLoad?.requires_expire || false }
              value={ '1' }
              style={{ display: 'contents' }}
            />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'requires_expire') }
            >
              { I18n.t(`activerecord.attributes.laboral/bulk/load/hiring_document.requires_expire`) }
              { drawTooltipHelp(I18n.t('laboral.hiring_documents.new.expires_date_help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }
  // End Requires Expire

  // Start Expires at
  const drawExpiresAtInput = () => {
    if(bulkLoad.requires_expire){
      return(
        <FormGroup>
          <FieldError errors={ bulkLoad?.errors?.expires_at || [] }>
            <Input
              type='date'
              id={ inputId(formName, 'expires_at') }
              name={ inputName(formName, 'expires_at') }
              invalid={ _.has(bulkLoad?.errors, 'expires_at') }
              onChange={ e => handleBulkLoad(e, 'expires_at') }
              value={ bulkLoad?.expires_at || '' }
            />

          </FieldError>
        </FormGroup>
      )
    }
  }
  // End Expires at

  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <DocumentSignerConfigContext.Provider value={ documentSignerConfigContextValue }>
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ bulkLoad?.errors?.document_type_id || [] }>
                <Label
                  className='required'
                  for={ inputId(formName, 'document_type_id') }
                >
                  { I18n.t('activerecord.attributes.laboral/bulk/load/hiring_document.document_type') }
                </Label>
                <Select
                  id={ inputId(formName, 'document_type_id') }
                  name={ inputName(formName, 'document_type_id') }
                  invalid={ _.has(bulkLoad?.errors, 'document_type_id') }
                  value={ getDocumentTypeSelectedOption() }
                  onChange={ e => handleBulkLoad({ target: { value: e?.value }}, "document_type_id") }
                  options={ drawSelectDocumentTypeOptions() }
                  isClearable={ true }
                  placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.document.document_type_id') } --` }
                />
              </FieldError>
            </FormGroup>

            <FormGroup>
              <Label
                for={ inputId(formName, 'label') }
              >
                { I18n.t('activerecord.attributes.laboral/bulk/load/hiring_document.label') }
              </Label>
              { drawTooltipHelp(I18n.t('documents.form.label_tooltip')) }
              <Input
                type='text'
                onChange={ e => handleBulkLoad(e, 'label') }
                value={ bulkLoad?.label || '' }
                invalid={ _.has(errors, 'label') }
                id={ inputId(formName, 'label') }
              />
              { drawErrors("label") }
            </FormGroup>

            { drawKeepFormatSwitchInput() }
            { drawRequiresExpireSwitchInput() }
            { drawExpiresAtInput() }

            <DocumentSignerConfigAttributes
              handleResource={ handleBulkLoad }
              resource={ bulkLoad }
            />

            { drawSigners() }

            { drawInstructions() }

            { drawDocumentErrors() }

            <LaboralBulkLoadHiringDocumentFileInput
              setErrorsFiles={ setErrorsFiles }
              errorsFile={ errorsFile }
              hiringDocuments={ hiringDocuments }
              setHiringDocuments={ setHiringDocuments }
              allowedFormatFiles={ props?.allowedFormatFiles }
            />

            { drawHiringErrors() }

            <FormGroup className='text-end'>
              <a
                href={ Routes.laboral_bulk_load_hiring_documents_path() }
                className='btn btn-default'
              >
                { I18n.t('actions.back') }
              </a>

              <button
                type='button'
                className='btn btn-success ml-2'
                onClick={ handleConfirmSubmit }
                disabled={ disabledSubmitButton }
              >
                { drawTextSubmitButton() }
              </button>
            </FormGroup>
          </div>
        </div>
      </DocumentSignerConfigContext.Provider>
    </DocumentFormContext.Provider>

  )
}

export default LaboralBulkLoadHiringDocumentForm;
