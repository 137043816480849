import axios from 'axios';

export const update = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.laboral_shortlist_mapping_configs_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
