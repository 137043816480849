import React, { useState, renderToString } from 'react';
import I18n from 'i18n-js';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';

const BulkRequestDocumentConfigPreviewTemplate = props => {

  const [ openModal, setOpenModal ] = useState(false)
  const [ currentEntity, setCurrentEntity] = useState({})

  let notMapped = 'Variable no mapeada';
  let emptyField = 'Campo vacío'
  let customDateNoText = I18n.t('activerecord.errors.models.controldoc_form/template_mapping_link.attributes.resource_attribute.invalid_custom_date');

  const {
    entitiesSelected,
    bulkRequestConfig,
    data
  } = props;

  const {
    systemVars,
    templates,
    customFields,
    dateFormats,
  } = data;

  const template = _.find(templates, template => { return String(template.id) == bulkRequestConfig?.template_id })

  const handleCurrentHiring = (event) => {
    let value = event.target.value;
    let entity = _.find(entitiesSelected, entity => { return String(entity.id) == String(value) })

    setCurrentEntity(entity);
  }

  const drawHiringsInputSelect = () => {
    if(entitiesSelected.length > 0){
      return(
        <Input
          type='select'
          value={ currentEntity?.id }
          onChange={ e => handleCurrentHiring(e) }
        >
          <option value="">- Selecciona una contratación -</option>
          {
            _.map(entitiesSelected, function(entity, index){
              return(
                <option
                  key={ _.uniqueId(`entity-option-${ entity.id }`) }
                  value={ entity.id }
                >
                  { entity.name }
                </option>
              )
            })
          }
        </Input>
      )
    }
  }

  const drawMappingLinks = () => {
    if(currentEntity.id){
      return _.map(mappingLinkWithEntityValue(), function(data, index){
        return(
          <tr
            key={`tr-mapping-link-${ index }`}
          >
            <td>{ data?.var }</td>
            <td>{ drawValueInMappingLinks(data?.value) }</td>
          </tr>
        )
      })
    }
  }

  let valueWithError = (value) => {
    return value == notMapped || value == emptyField || value == customDateNoText
  }

  const drawValueInMappingLinks = value => {
    if( valueWithError(value)){
      return (
        <div>
          <i className="fas fa-times text-danger mr-1"></i>
          <strong>{ value }</strong>
        </div>
      )
    } else {
      return value
    }
  }

  const getVarToEntity = (mappingLink) => {
    let varType = template.vars[mappingLink.name_var].type

    if(!mappingLink.resource || !mappingLink.resource_attribute){
      return notMapped
    } else if(mappingLink.is_custom_date && varType == 'date'){
      return customDateNoText
    } else if(mappingLink.resource =='ControldocForm::TemplateSystemVar'){
      return systemVars[mappingLink?.resource_attribute] || ''
    } else if(mappingLink.resource && mappingLink.resource_attribute){
      let resourceAttribute = getFormatedResourceAttribute(mappingLink)

      return getFormatedCustomField(resourceAttribute, varType) || emptyField
    }
  }

  const mappingLinkWithEntityValue = () => {
    let _mappingLinksWithValue = [];

    _.each(template.vars, function(data, key){
      let mappingLink = _.find(template.template_mapping_links, { name_var: key });

      _mappingLinksWithValue.push({
        resource: mappingLink?.resource,
        resource_attribute: mappingLink?.resource_attribute,
        var: data['var'],
        name_var: key,
        value: getVarToEntity(mappingLink),
      })
    });

    return _mappingLinksWithValue;
  }

  const getFormatedCustomField = (resourceAttribute, varType) => {
    let customField = _.find(customFields, {"code": _.last(_.split(resourceAttribute, "."))})
    let attributeValue = _.get(currentEntity, resourceAttribute)

    if(attributeValue && customField && customField?.data_type == "date" && varType != 'date'){
      let date = _.find(dateFormats, {"format": customField?.format})
      attributeValue = moment(attributeValue, "DD/MM/YYYY").locale('es').format(date?.display)
    }

    return attributeValue
  }

  const getFormatedResourceAttribute = (mappingLink) => {
    let resource = mappingLink.resource;
    let resourceAttribute = mappingLink.resource_attribute;
    if(resource == 'Laboral::Employee' && _.includes(resourceAttribute, "employee_config.")){
      resourceAttribute = _.replace(resourceAttribute, "employee_config.", "employee.")
    } else if(resource == 'Laboral::Employee'){
      resourceAttribute = `employee.${ resourceAttribute }`
    }

    if(_.includes(resource,  'Abstract::Entity') && _.includes(resource, "entity-type")){
      let abstractEntityTypeId = _.split(resource, '#')[2]

      resourceAttribute = findAbstractResourceAttribute(abstractEntityTypeId, resourceAttribute)
    }

    return resourceAttribute;
  }

  const findAbstractResourceAttribute = (abstractEntityTypeId, resourceAttribute) => {
    let indexRelatedEntity = _.findIndex(currentEntity.related_entities, { abstract_entity_type_id: _.toInteger(abstractEntityTypeId) })
    let abstractResourceAttribute = `related_entities.${ indexRelatedEntity }.${ resourceAttribute }`

    if(currentEntity.abstract_entity_type_id == abstractEntityTypeId){
      abstractResourceAttribute = resourceAttribute
    }

    return abstractResourceAttribute
  }

  const formatedTemplateContent = () => {
    if(currentEntity?.id){
      let _mappingLinkWithValues = mappingLinkWithEntityValue();

      let content = template?.content;

      if(_mappingLinkWithValues){
        _.each(_mappingLinkWithValues, function(data){
          let value = data.value;
          content = content.replaceAll(data['var'], drawValueInTemplateContent(data))
        })
      }

      return content;
    } else {
      return template.content;
    }
  }

  const drawValueInTemplateContent = data => {
     if(valueWithError(data?.value)){
      return (
        `<span class='text-danger'>\
          <i class="fas fa-times text-danger mr-1"></i>\
          ${ data?.value } - ${ data?.var }\
        </span>`
      )
    } else {
      return data?.value
    }
  }

  const drawModal = () => {
    if(openModal){
      return (
        <div >
          <Modal
            isOpen={ openModal }
            fullscreen
          >
            <ModalHeader toggle={ e => setOpenModal (false) }>{ template?.name }</ModalHeader>
            <ModalBody>
              <div className='row preview-template'>
                <div className='col-md-6'>
                  <div className='mb-4'>
                    { drawHiringsInputSelect() }
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered text-nowrap">
                      <thead className="alert-light text-dark">
                        <tr>
                          <th width='50%'>
                            { I18n.t("controldoc_form.templates.table.var") }
                          </th>
                          <th width='50%'>
                            { I18n.t("controldoc_form.templates.table.value") }
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { drawMappingLinks() }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='col-md-6 px-4'>
                  <div className='paper'>
                    <div className="document-page" dangerouslySetInnerHTML={{__html: containerTemplateContent() }} />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" className="mx-auto" onClick={ e => setOpenModal(false) }>Cerrar</Button>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
  }

  const templateLayoutHeaderContent = () => {
    return template?.template_layout?.header
  }

  const containerTemplateContent = () => {
    return `${ templateLayoutHeaderContent() } ${ formatedTemplateContent() }`
  }

  return(
    <div className="row">
      <div className="col-12 text-end">
        <Button
          className='mb-3'
          color='success'
          size='40%'
          onClick={ e => setOpenModal(true) }
          outline
        >
          { I18n.t('actions.preview') }
        </Button>
      </div>

      { drawModal() }
    </div>
  )
}

export default BulkRequestDocumentConfigPreviewTemplate;

