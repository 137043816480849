import React, {
  useState,
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../helper/form';

import {
  fileItemIsRequired
} from '../requirement_stage/helper';

const WorkflowRequirementStageFormItemFileAttributes = props => {
  const {
    requirementStage,
    fileItem,
    configuration: {
      formName, index, defaultRequestParams
    },
    callbacks: {
      onChangeFileItem: callbackOnChangeFileItem
    }
  } = props;

  const showShortlistPath = props?.actions?.showShortlistPath || ""

  const [uploadNewFile, setUploadNewFile] = useState(!requirementStage?.prev_requirement_stage_id)

  const previewPdfUrlShortlist = useMemo(() => {
    let hashid = requirementStage?.shortlist_assessment_load?.hashid

    return hashid && _.isFunction(showShortlistPath) ? showShortlistPath({...{...defaultRequestParams, id: hashid, format: 'pdf', _options: true }}) : false

  }, [requirementStage?.shortlist_assessment_load])

  const isRequired = useMemo(() => {
    return fileItemIsRequired(fileItem)
  }, [fileItem.is_required])

  const onChangeFileItem = (event) => {
    const value = event.target.files[0];

    let _fileItem = { ... fileItem };
    _fileItem['file'] = value;


    callbackOnChangeFileItem(_fileItem, _fileItem.item.id)
  }

  const helpingText = () => {
    if(fileItem.helping_text){
      return(
        <span className='text-muted small'>{ fileItem.helping_text }</span>
      )
    }
  }

  const uploadNewFileOrUseEntryFileInput = () => {
    if(uploadNewFile){
      return(
        <>
          <Input
            type={ 'file' }
            required={ isRequired }
            onChange={ e => onChangeFileItem(e) }
            invalid={ _.has(fileItem.errors, 'file') }
            id={ inputId(formName, 'value') }
            name={ inputName(formName, 'value') }
          />
          { useInputFileToOutputFileButton() }
          { helpingText() }
          { templateAttachment }
        </>
      )
    } else {
      return(
        <>
          <Select
            options={ inputFileSelectOptions }
            onChange={ event => onChangeFileReferenceTo(event) }
            value={ inputFileSelectedOption }
            placeholder="Seleccionar un archivo"
          />
          { useInputFileToOutputFileButton() }
        </>
      )
    }
  }

  const onChangeFileReferenceTo = event => {

    const value = event.value

    let _fileItem = { ... fileItem };
    _fileItem['reference_to'] = value;


    callbackOnChangeFileItem(_fileItem, _fileItem.item.id)
  }

  const inputFileSelectedOption = useMemo(() => {

    if(requirementStage){
      const selectedOption = _.find(requirementStage.input_form.file_items, file => {
        return String(file.id) == String(fileItem.reference_to)
      })

      if(selectedOption){
        return { value: selectedOption.id, label: selectedOption.name }
      } else {
        return null
      }
    }
  }, [fileItem.reference_to])

  const inputFileSelectOptions = useMemo(() => {
    if(requirementStage){
      return _.map(requirementStage.input_form.file_items, file => {
        return {
          value: file.id,
          label: file.name
        }
      })
    }
  }, [])

  const onChangeUploadNewFileOrUseInputFile = () => {
    if(uploadNewFile){
      setUploadNewFile(false);

      let _fileItem = { ... fileItem };
      _fileItem['file'] = null;

      callbackOnChangeFileItem(_fileItem, _fileItem.item.id)
    } else {
      setUploadNewFile(true)
      fileItem.reference_to = null;
    }
  }

  const useInputFileToOutputFileButton = () => {
    if (requirementStage?.prev_requirement_stage_id) {
      const buttonText = uploadNewFile ? 'Utilizar archivo de entrada' : 'Subir nuevo archivo';
      
      return (
        <div className="text-end pt-2">
          <button onClick={onChangeUploadNewFileOrUseInputFile} type="button" className="btn btn-outline-primary btn-sm">
            {buttonText}
          </button>
        </div>
      );
    }
  };

  const inputFile = () => {
    if(!!previewPdfUrlShortlist){
      return (
        <iframe
          width="100%"
          height="500"
          src={ previewPdfUrlShortlist || '' }
          name={ fileItem.name }
          title={ fileItem.name }
          type="application/pdf"
        >
        </iframe>
      )
    } else {
      return(
        <FormGroup>
          <FieldError errors={ _.get(fileItem, 'errors.file') || [] } >
            <Label
              className={ isRequired ? 'required' : ''}
              htmlFor={ inputId(formName, 'value') }
            >
              { fileItem.name }
            </Label>
            { uploadNewFileOrUseEntryFileInput() }
          </FieldError>
        </FormGroup>
      )
    }
  }

  const templateAttachment = useMemo(() => {
    if(fileItem.blob_url){
      return(
        <div>
          <span className='text-muted small'>
            Plantilla de documento:&nbsp;
            <a
              href={ fileItem.blob_url }
              download={ true }
            >
              { I18n.t('actions.download') }
            </a>
          </span>
        </div>
      )
    }
  }, [fileItem.attachment])

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          { inputFile() }
        </FormGroup>
      </div>
    </div>
  )
}

export default React.memo(WorkflowRequirementStageFormItemFileAttributes);
