import React, { useEffect, useContext } from 'react';
import { Button, FormGroup, Label, Input } from "reactstrap";

import I18n from 'i18n-js';

import FieldError from '../helper/FieldError';

import {
  inputId,
  inputName,
} from '../helper/form';

import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerCompanyAttributes = props => {

  const {
    signer, formName, callbackSetSigner, index
  } = props;

  const {
    data,
    context,
    currentUser,
    currentCustomer,
    entities
  } = useContext(DocumentFormContext)

  const {
    resource
  } = useContext(DocumentSignerConfigContext)

  useEffect(() => {
    // Si el contexto es corporate, buscará el entity seleccionado
    if(context == 'corporate'){
      let _signer = { ... signer };

      let entity = _.find(entities, function(_entity){
        return String(_entity.id) == String(resource?.entity_id)
      });

      if(entity && signer.company_sign){
        let _type = signer.company_email == 'client_company_email' ? 'client' : 'provider';

        _signer.email = entity?.agreement_config[_type]?.company_config?.legal_representative_email || '';
        _signer.name = entity?.agreement_config[_type]?.company_config?.legal_representative_name || '';

        if(_.isFunction(callbackSetSigner)){
          callbackSetSigner(_signer, index);
        }
      }
    }
  }, [resource?.entity_id, signer?.company_email])

  const handleSigner = (event, key) => {
    let value = event.target.value;
    let _signer = { ... signer };

    _signer[key] = value;

    if(_.isFunction(callbackSetSigner)){
      callbackSetSigner(_signer, index);
    }
  }

  const drawEmailInput = () => {
    return(
      <div className="col-6">
        <FormGroup>
          <FieldError errors={ signer?.errors?.email || [] } >
            <Label
              for={ inputId(formName, 'email') }
            >
              { I18n.t("activerecord.attributes.signer.email") }
            </Label>

            <Input
              type='email'
              value={ signer?.email || "" }
              onChange={ e => handleSigner(e, "email") }
              name={ inputName(formName, "email") }
              id={ inputId(formName, "email") }
              invalid={ _.has(signer?.errors, "email") }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawIdentificationNumberInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-6">
          <FormGroup>
            <FieldError errors={ signer?.errors?.identification_number || [] } >
              <Label
                for={ inputId(formName, 'identification_number') }
              >
                { I18n.t("activerecord.attributes.signer.identification_number") }
              </Label>

              <Input
                type='text'
                value={ signer?.identification_number || "" }
                onChange={ e => handleSigner(e, "identification_number") }
                name={ inputName(formName, "identification_number") }
                id={ inputId(formName, "identification_number") }
                invalid={ _.has(signer?.errors, "identification_number") }
                disabled={ true }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }

  }

  const drawNameInput = () => {
    return(
      <div className="col-6">
        <FormGroup>
          <FieldError errors={ signer?.errors?.name || [] } >
            <Label
              for={ inputId(formName, 'name') }
            >
              { I18n.t("activerecord.attributes.signer.name") }
            </Label>

            <Input
              value={ signer?.name || "" }
              onChange={ e => handleSigner(e, "name") }
              name={ inputName(formName, "name") }
              id={ inputId(formName, "name") }
              invalid={ _.has(signer?.errors, "name") }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawFirstSurnameInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-6">
          <FormGroup>
            <FieldError errors={ signer?.errors?.first_surname || [] } >
              <Label
                for={ inputId(formName, 'first_surname') }
              >
                { I18n.t("activerecord.attributes.signer.first_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.first_surname || "" }
                onChange={ e => handleSigner(e, "first_surname") }
                name={ inputName(formName, "first_surname") }
                id={ inputId(formName, "first_surname") }
                invalid={ _.has(signer?.errors, "first_surname") }
                disabled={ true }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawLastSurnameInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-6">
          <FormGroup>
            <FieldError errors={ signer?.errors?.last_surname || [] } >
              <Label
                for={ inputId(formName, 'last_surname') }
              >
                { I18n.t("activerecord.attributes.signer.last_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.last_surname || "" }
                onChange={ e => handleSigner(e, "last_surname") }
                name={ inputName(formName, "last_surname") }
                id={ inputId(formName, "last_surname") }
                invalid={ _.has(signer?.errors, "last_surname") }
                disabled={ true }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawRoleInput = () => {
    return(
      <div className="col-6">
        <FormGroup>
          <FieldError errors={ signer?.errors?.role || [] } >
            <Label
              for={ inputId(formName, 'role') }
            >
              { I18n.t("activerecord.attributes.signer.role") }
            </Label>

            <Input
              value={ signer?.role || "" }
              onChange={ e => handleSigner(e, "role") }
              name={ inputName(formName, "role") }
              id={ inputId(formName, "role") }
              invalid={ _.has(signer?.errors, "role") }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawCompanyClientOrProviderInput = () => {
    return(
      <div className="col-12 ml-3">
        <FormGroup>
          <div className="form-check">
            <Input
              type="radio"
              className="form-check-input"
              name={ inputName(formName, "company_email") }
              id={ inputId(formName, "client_company_email") }
              value={ "client_company_email" }
              checked={ signer.company_email == "client_company_email" }
              onChange={ e => handleSigner(e, "company_email") }
              invalid={ _.has(signer?.errors, "company_email") }
            />
            <Label
              className="form-check-label"
              for={ inputId(formName, "client_company_email") }
            >
              { I18n.t("activerecord.attributes.signer.legal_rep_client") }
            </Label>
          </div>

          <div className="form-check">
            <Input
              type="radio"
              className="form-check-input"
              name={ inputName(formName, "company_email") }
              id={ inputId(formName, "provider_company_email") }
              value={ "provider_company_email" }
              checked={ signer.company_email == "provider_company_email" }
              onChange={ e => handleSigner(e, "company_email") }
              invalid={ _.has(signer?.errors, "company_email") }
            />
            <Label
              className="form-check-label"
              for={ inputId(formName, "provider_company_email") }
            >
              { I18n.t("activerecord.attributes.signer.legal_rep_provider") }
            </Label>
          </div>
        </FormGroup>
      </div>
    )
  }

  const drawBulkLabel = () => {
    if(context == 'corporate_bulk' && signer?.company_email){
      return(
        <p className="fs-4">
          { I18n.t("signers.form.send_doc_company") }&nbsp;
          { I18n.t(`activerecord.attributes.signer.${ signer.company_email }`) }.
        </p>
      )
    }
  }

  return(
    <div className="row">
      { drawCompanyClientOrProviderInput() }
      { drawBulkLabel() }
      { drawEmailInput() }
      { drawIdentificationNumberInput() }
      { drawNameInput() }
      { drawFirstSurnameInput() }
      { drawLastSurnameInput() }
      { drawRoleInput() }
    </div>
  )
}

export default SignerCompanyAttributes;
