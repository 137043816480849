import React, { useState } from 'react';

import {
  Row,
  Col,

  Card,
  CardHeader,
  CardBody,

  Collapse,

  Button
} from 'reactstrap';

import UserProfileSignatureDynamicPinAttributes from './signature_dynamic_pin_attributes';
import UserProfileSignatureCustomPinAttributes from './signature_custom_pin_attributes';


const UserProfileDisableSignatureAttributes = props => {
  const {
  } = props;

  const [user, setUser] = useState(props?.user || {})

  const disableMethod = () => {

    if(user.signature_method == 'dynamic_pin'){
      return(
        <UserProfileSignatureDynamicPinAttributes
          user={ user }
        />
      )
    } else if(user.signature_method == 'custom_pin'){
      return(
        <UserProfileSignatureCustomPinAttributes
          user={ user }
        />
      )
    }
  }


  return(
      <>
        <Card>
          <CardHeader className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center'>
            <p className="h5 mb-md-0">
              <i className="fas fa-check-circle fa-lg text-success mr-2"/>
              Verificación en dos pasos habilitada
            </p>

            <Button color='warning'>
              Deshabilitar
            </Button>
          </CardHeader>
        </Card>

        <Collapse isOpen={ true }>
          <Card>
            <CardHeader>
              <p className="h5 card-title font-weight-bold mb-0">
                Deshabilitar verificación en dos pasos
              </p>
            </CardHeader>

            <CardBody>
              <div className="alert alert-warning">
                <p className="h5">
                  <i className="icon fas fa-exclamation-triangle"></i> Importante
                </p>

                <span>Recuerda que deshabilitar la verificación en dos pasos implica que ya no podrás ver ni firmar documentos electrónicamente con tu cuenta.</span>
              </div>

              <p className="lead">
                Si requieres desactivar la verificación en dos pasos de tu cuenta sigue los siguientes pasos:
              </p>

              <hr/>

              <Row>
                <Col>
                  <p className="h6 text-left mb-3 font-weight-lighter">
                    <strong className='d-block font-weight-bold'>Elige la forma de verificación:</strong>
                  </p>

                  { disableMethod() }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Collapse>
      </>
    )
}

export default UserProfileDisableSignatureAttributes;
