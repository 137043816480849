import React, {
  useState,
  useMemo,
  useEffect
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button,
  Form
} from 'reactstrap'

import I18n from 'i18n-js';
import CreatableSelect from 'react-select/creatable';

import {
  inputId,
  inputName,
  validateEmail
} from '../../helper/form';

import FieldError from '../../helper/FieldError';

const EntityFolderCheckListSharedAttributes = props => {
  const [sharedObject, setSharedObject] = useState(props?.sharedObject || {})

  const {
    configuration: {
      formName,
      entity: entityConfiguration,
      defaultRequestParams
    },
    data: {
      sharedMethods,
      sharedAccesses,
      folders
    },
    actions: {
      updateShared,
      createShared,
      showEntityPath
    }
  } = props;

  const [notifyToOptions, setNotifyToOptions] = useState([]);

  const isPersisted = useMemo(() => {
    return !!sharedObject.id
  }, [])

  const isPersistedWithPassword = useMemo(() => {
    return isPersisted && !!sharedObject.shared_password_digest && sharedObject.shared_access == 'with_password';
  }, [])

  useEffect(() => {
    if(_.has(sharedObject.errors, 'shared_password_confirmation') || _.has(sharedObject.errors, 'shared_password')){
      const collapsePassword = new bootstrap.Collapse('#collapsePassword');

      collapsePassword.show();
    }
  }, [])

  const onChangeShared = ( event, key ) => {
    let value = event.target.value;
    let _sharedObject = { ... sharedObject };

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }


    if(key == 'shared_access' && value == 'without_password'){
      _sharedObject.shared_password = '';
      _sharedObject.shared_password_confirmation = '';
    }

    _sharedObject[key] = value


    setSharedObject(_sharedObject)
  }

  const onChangeSharedFolderIds = (event, value) => {
    const checked = event.target.checked;
    let folderIds = [ ...sharedObject.folder_ids ];

    if(checked){
      folderIds.push(value)
    } else {
      _.remove(folderIds, id => id == value)
    }

    setSharedObject(prevState => {
      return { ... prevState, 'folder_ids': folderIds }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, sharedObject.id || '')
    formData.append(`${ formName }[entity_id]`, sharedObject.entity_id || '')
    formData.append(`${ formName }[name]`, sharedObject.name || '')
    formData.append(`${ formName }[shared_method]`, sharedObject.shared_method || '')
    formData.append(`${ formName }[shared_password]`, sharedObject.shared_password || '')
    formData.append(`${ formName }[shared_password_confirmation]`, sharedObject.shared_password_confirmation || '')
    formData.append(`${ formName }[shared_access]`, sharedObject.shared_access || '')

    _.each(sharedObject.folder_ids, id => {
      formData.append(`${ formName }[folder_ids][]`, id)
    })

    if(_.some(sharedObject.notify_to)){
      _.each(sharedObject.notify_to, email => {
        formData.append(`${ formName }[notify_to][]`, email)
      })
    } else {
      formData.append(`${ formName }[notify_to][]`, '');
    }

    return formData;
  }

  const onConfirmSubmit = (event) => {
    event.preventDefault();
    swalWithBootstrap.fire({
      title: I18n.t('entity_folder.check_list_shareds.form.confirm.title'),
      html: I18n.t('entity_folder.check_list_shareds.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit()
      }
    })
  }

  const onSubmit = () => {
    const formData = getFormData();

    if(sharedObject.id){
      const requestParams = { ... defaultRequestParams, id: sharedObject.id }

      updateShared(requestParams, formData, response => {
        if(response.status == 200){
          window.location = showEntityPath({ ... defaultRequestParams, id: sharedObject.entity.hashid, _options: true });
        } else {
          setSharedObject(response.data);
        }
      })
    } else {
      createShared(defaultRequestParams, formData, response => {
        if(response.status == 201){
          window.location = showEntityPath({ ... defaultRequestParams, id: sharedObject.entity.hashid, _options: true, anchor: 'modalCheckListShareds' });
        } else {
          setSharedObject(response.data);
        }
      })
    }
  }

  // ---------- START INPUT ----------
  const entityInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ [] } >
          <Label className='required'>
            { entityConfiguration?.label }
          </Label>
          <Input
            value={ sharedObject?.entity?.name }
            disabled={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END INPUT ----------

  // ----------------------------------------

  // ---------- START NAME ----------
  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(sharedObject, 'errors.name') || [] }>
          <Label for={ inputId(formName, 'name') } className='required'>
            { I18n.t('activerecord.attributes.entity_folder/check_list_shared.name') }
          </Label>
          <Input
            value={ sharedObject?.name || '' }
            onChange={ event => onChangeShared(event, 'name') }
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            invalid={ _.has(sharedObject, 'errors.name')}
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NAME ----------

  // ----------------------------------------

  // ---------- START SHARE METHODS ----------
  const sharedMethodsRadioInputs = () => {
    return(
      <div className='row'>
        <div className="col-12">
          <fieldset>
            <FieldError errors={ _.get(sharedObject, 'errors.shared_method') || [] }>
              <legend className="mb-0 fs-6 text-muted">
                <i className="fas fa-share-alt mr-2 fa-md"></i>
                Compartir carpeta vía:
              </legend>
              <div className="row">
                { _.map(sharedMethods, (value, key) => {
                  return(
                    <div className="col-6" key={ `${ formName }_shared_method_${ key }` } >
                      <div className="custom-control custom-radio">
                        <Input
                          type='radio'
                          name={ inputName(formName, 'shared_method') }
                          checked={ sharedObject.shared_method == key }
                          className='custom-control-input'
                          id={ inputId(formName, `shared_method_${ key }`) }
                          value={ key }
                          onChange={ event => onChangeShared(event, 'shared_method') }
                        />

                        <Label
                          htmlFor={ inputId(formName, `shared_method_${ key }`) }
                          className='custom-control-label'
                        >
                          { value }
                        </Label>
                      </div>
                    </div>
                  )
                })}
              </div>
            </FieldError>
          </fieldset>
        </div>
      </div>
    )
  }
  // ---------- END SHARE METHODS ----------

  // ----------------------------------------

  // ---------- START SHARE ACCESSES ----------
  const collapsePasswordLink = (key) => {
    if(key == 'with_password' && isPersistedWithPassword && sharedObject.shared_access == 'with_password'){
      return(
        <small>
          <a href='#collapsePassword' data-bs-toggle='collapse' aria-expanded="false" >
            (Cambiar contraseña)
          </a>
        </small>
      )
    }
  }

  const sharedAccessesRadio = () => {
    return(
      <div className='row'>
        <div className="col-12">
          <fieldset>
            <FieldError errors={ _.get(sharedObject, 'errors.shared_access') || [] }>
              <legend className="mb-0 fs-6 text-muted">
                <i className="fas fa-key mr-2 fa-md"></i>
                Proteger acceso a carpeta?
              </legend>
              <div className="row">
                { _.map(sharedAccesses, (value, key) => {
                  return(
                    <div className="col-6" key={ `${ formName }_shared_access_${ key }` } >
                      <div className="custom-control custom-radio">
                        <Input
                          type='radio'
                          name={ inputName(formName, 'shared_access') }
                          checked={ sharedObject.shared_access == key }
                          className='custom-control-input'
                          id={ inputId(formName, `shared_access_${ key }`) }
                          value={ key }
                          onChange={ event => onChangeShared(event, 'shared_access') }
                        />

                        <Label
                          htmlFor={ inputId(formName, `shared_access_${ key }`) }
                          className='custom-control-label'
                        >
                          { value }
                        </Label>
                      </div>
                    </div>
                  )
                })}
              </div>
            </FieldError>
          </fieldset>
        </div>
      </div>
    )
  }
  // ---------- END SHARE ACCESSES ----------

  // ----------------------------------------

  // ---------- START PASSWORD ----------

  const enableCollapsePassword = useMemo(() => {
    return isPersistedWithPassword;
  }, [])

  const sharedPasswordInputs = () => {
    const hide = sharedObject.shared_access !== 'with_password'
    return(
      <div className={ `row ${ hide ? 'd-none' : '' }` }>
        <div className={ `col-12 ${ enableCollapsePassword ? 'collapse' : '' }` } id={ enableCollapsePassword ? 'collapsePassword' : '' }>
          <div className="card bg-real-light mt-2">
            <div className="card-body">
              <FormGroup>
                <FieldError errors={ _.get(sharedObject, 'errors.shared_password') }>
                  <Label for={ inputId(formName, 'shared_password') } >
                    { I18n.t('activerecord.attributes.entity_folder/check_list_shared.shared_password') }
                  </Label>
                  <Input
                    type='password'
                    onChange={ event => onChangeShared(event, 'shared_password') }
                    value={ sharedObject.shared_password || ''}
                    id={ inputId(formName, 'shared_password') }
                    name={ inputName(formName, 'shared_password') }
                    autoComplete='false'
                    invalid={ _.has(sharedObject, 'errors.shared_password') }
                  />
                </FieldError>
              </FormGroup>

              <FormGroup>
                <FieldError errors={ _.get(sharedObject, 'errors.shared_password_confirmation') }>
                  <Label for={ inputId(formName, 'shared_password_confirmation') } >
                    { I18n.t('activerecord.attributes.entity_folder/check_list_shared.shared_password_confirmation') }
                  </Label>
                  <Input
                    type='password'
                    onChange={ event => onChangeShared(event, 'shared_password_confirmation') }
                    value={ sharedObject.shared_password_confirmation || '' }
                    id={ inputId(formName, 'shared_password_confirmation') }
                    name={ inputName(formName, 'shared_password_confirmation') }
                    autoComplete='false'
                    invalid={ _.has(sharedObject, 'errors.shared_password_confirmation') }
                  />
                </FieldError>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ---------- END PASSWORD ----------

  // ----------------------------------------

  // ---------- START FOLDERS ----------

  const folderIdsErrors = () => {
    if(_.has(sharedObject.errors, 'folders')){
      return(
        <div className="col-12">
          <div className="alert alert-danger">
            <ul className='mb-0'>
              { _.map(sharedObject.errors.folders, error => {
                return(
                  <li key={ _.uniqueId('folder_id_errors_') }>{ error }</li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    }
  }
  const folderIdsCheckBoxs = () => {
    return(
      <div className="row">
        { folderIdsErrors() }
        { _.map(folders, folder => {
          return(
            <div className="col-6" key={ `${ formName }_folder_ids_${ folder.id }` }>
              <div className="custom-control custom-switch">
                <Input
                  type='checkbox'
                  value={ folder.id }
                  className='custom-control-input'
                  checked={ _.includes(sharedObject.folder_ids, folder.id) }
                  name={ `${ inputName( formName, 'folder_ids')  }[]` }
                  id={ inputId( formName, `folder_ids_${ folder.id }`) }
                  onChange={ event => onChangeSharedFolderIds(event, folder.id) }
                />
                <Label
                  className='custom-control-label label-bold'
                  htmlFor={ inputId( formName, `folder_ids_${ folder.id }`) }
                >
                  { folder.name }
                </Label>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  // ---------- END FOLDERS ----------

  // ----------------------------------------

  // ---------- START NOTIFY TO ----------

  const onChangeNotifyTo = (selecteds) => {
    let _sharedObject = { ... sharedObject };

    const emails = _.map(selecteds, 'value');

    setSharedObject(prevState => {
        return {
          ... prevState,
          notify_to: emails
        }
      })
  }

  const onCreateNotifyTo = (label) => {
    const labelToLower = _.toLower(label);

    if(validateEmail(labelToLower)){

      const newOption = { label: labelToLower, value: labelToLower}

      setNotifyToOptions(prevState => {
        return [ ... prevState, newOption]
      });

      setSharedObject(prevState => {
        return {
          ... prevState,
          notify_to: [ ... prevState.notify_to, labelToLower]
        }
      })
    }
  }

  const notifyToInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ sharedObject?.errors?.notify_to || [] }>
          <Label
            for={ inputId(formName, 'notify_to') }
          >
            { I18n.t('activerecord.attributes.entity_folder/check_list_shared.notify_to') }
          </Label>

          <CreatableSelect
            id={ inputId(formName, 'notify_to') }
            name={ inputName(formName, 'notify_to') }
            invalid={ _.has(sharedObject?.errors, 'notify_to') }
            value={ notifyToSelectedOptions }
            onChange={ e => onChangeNotifyTo(e) }
            options={ notifyToSelectOptions() }
            isClearable={ true }
            onCreateOption={ onCreateNotifyTo }
            isMulti
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
          />
        </FieldError>
      </FormGroup>
    )
  }

  const notifyToSelectOptions = () => {
    return notifyToOptions
  }

  const notifyToSelectedOptions = useMemo(() => {
    return _.map(sharedObject.notify_to, email => ({ label: email, value: email}))
  }, [sharedObject.notify_to])
  // ---------- END NOTIFY TO ----------

  // ----------------------------------------


  return(
    <Form onSubmit={ event => onConfirmSubmit(event) }>
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
            <div className="card-body lh-sm">
              { entityInput() }
              { nameInput() }
              { notifyToInput() }

              <ul className="list-group">
                <li className="list-group-item">
                  { sharedAccessesRadio() }
                  { sharedPasswordInputs() }
                </li>
              </ul>
            </div>
          </div>

          <div className="card card-material">
            <div className="card-body lh-sm">
              { folderIdsCheckBoxs() }
            </div>
          </div>

          <div className="text-end">
            <a
              href={ showEntityPath({ ... defaultRequestParams, id: sharedObject.entity.hashid, _options: true }) }
              className='btn btn-default'
            >
              { I18n.t('actions.back') }
            </a>

            <Button
              color='success'
              className='ml-2'
              type='submit'
              // onClick={ event => onConfirmSubmit() }
            >
              { I18n.t('actions.save') }
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default EntityFolderCheckListSharedAttributes;
