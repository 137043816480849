import React, { useEffect, useState } from "react";
import I18n from "i18n-js";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { options as paginationOption } from "../../helper/pagination";
import TableFilter from "./TableFilter";
import { textFilter } from "react-bootstrap-table2-filter";
import { saveLaboralHiringComplianceProcess } from "./Axios";
import FieldError from "../../helper/FieldError";
import filterFactory from "react-bootstrap-table2-filter";

import { drawInputSearch, handleStateFilters } from "../../helper/bootstrap-table/filter/helper";

const HiringComplianceProcessForm = props => {
  const {
    compliance,
    companies,
    requirements,
    hirings,
    complianceHirings,
    complianceRequirements,
  } = props;

  const { id, company_id } = compliance;

  const [processName, setProcessName] = useState(compliance.name || "");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [hiringsFiltered, setHiringsFiltered] = useState(hirings);
  const [filter, setFilter] = useState({});
  const [statesFilters, setStatesFilters] = useState([])
  const [selectedHirings, setSelectedHirings] = useState(
    complianceHirings || []
  );
  const [formErrors, setFormErrors] = useState({});

  const columnsHirings = [
    {
      dataField: "company.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
      headerStyle: { width: "350px" },
    },
    {
      dataField: "employee.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.employee")}`,
      headerStyle: { width: "350px" },
    },
    {
      dataField: "start_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`,
      headerStyle: { width: "350px" },
    },
    {
      dataField: "end_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`,
      headerStyle: { width: "350px" },
      formatter: function (value, row, index) {
        return value || "Indefinido";
      },
    },
  ];

  useEffect(() => {
    if (company_id) {
      let company = _.find(companies, c => c[1] == company_id);
      setSelectedCompany({ value: company[1], label: company[0] });
    }
    if (!_.isEmpty(complianceHirings)) {
      let newRequirements = _.map(complianceRequirements, requirement => {
        return { value: requirement.id, label: requirement.name };
      });
      setSelectedRequirements(newRequirements);
    }
  }, []);

  useEffect(() => {
    handleSubmitFilter();
  }, [filter]);

  const handleSelectHiring = (isSelected, hiring) => {
    let newSelectedHirings = [...selectedHirings];

    if (isSelected) {
      newSelectedHirings.push(hiring);
    } else {
      _.remove(newSelectedHirings, hr => String(hr.id) == String(hiring.id));
    }

    setSelectedHirings(newSelectedHirings);
  };

  const handleSelectAllHirings = (isSelected, hirings) => {
    let newSelectedHirings = [...selectedHirings];

    if (isSelected) {
      _.each(hirings, h => newSelectedHirings.push(h));
    } else {
      let ids = _.map(hirings, h => String(h.id));
      _.remove(newSelectedHirings, function (hiring, index) {
        return _.includes(ids, String(hiring.id));
      });
    }

    setSelectedHirings(newSelectedHirings);
  };

  const handleConfirmSubmit = e => {
    e.preventDefault();
    swalWithBootstrap
      .fire({
        title: I18n.t(
          `laboral.hiring_compliance_processes.form.${
            compliance.id ? "update" : "create"
          }.confirm.title`
        ),
        html: I18n.t(
          `laboral.hiring_compliance_processes.form.${
            compliance.id ? "update" : "create"
          }.confirm.message`
        ),

      })
      .then(result => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });
  };

  const handleSubmit = () => {
    setFormErrors({});
    let formData = new FormData();

    if (id) {
      formData.append("laboral_hiring_compliance_process[id]", id);
    }
    formData.append(
      "laboral_hiring_compliance_process[company_id]",
      selectedCompany?.value
    );
    formData.append("laboral_hiring_compliance_process[name]", processName);

    _.map(selectedHirings, h => {
      formData.append("laboral_hiring_compliance_process[entity_ids][]", h.id);
    });

    _.map(selectedRequirements, r => {
      formData.append(
        "laboral_hiring_compliance_process[document_type_ids][]",
        r.value
      );
    });

    saveLaboralHiringComplianceProcess(formData, response => {
      if (_.includes([200, 201], response.status)) {
        window.location = Routes.laboral_hiring_compliance_process_path(
          response.data.id
        );
      } else {
        setFormErrors(response.data);
      }
    });
  };

  const handleOptionFilter = (key, event) => {
    const value = event.target.value;
    let _filter = { ...filter };
    _filter[key] = value;

    setFilter(_filter);
  };

  const clearFilter = () => {
    setFilter({});
  };

  const handleSubmitFilter = () => {
    let _hiringFiltered = hirings;

    if (_.has(filter, "company_id") && filter["company_id"]) {
      _hiringFiltered = _.filter(
        _hiringFiltered,
        h => String(h.company.id) == filter["company_id"]
      );
    }

    if (_.has(filter, "employee_name") && filter["employee_name"]) {
      _hiringFiltered = _.filter(_hiringFiltered, function (hiring) {
        // se daba el caso en que no se encuentra el nombre por el hecho de contener una vocal con acento
        return _.includes(
          _.toUpper(_.deburr(hiring.employee.name)),
          _.toUpper(filter["employee_name"])
        )
          ? hiring
          : null;
      });
    }

    if (_.has(filter, "hiring_state") && filter["hiring_state"]) {
      if (filter.hiring_state == "active") {
        _hiringFiltered = _.filter(_hiringFiltered, function (hiring) {
          return hiring.hiring_config.end_date == null ||
            moment(hiring.hiring_config.end_date).isSameOrAfter(
              moment().format("YYYY-MM-DD")
            )
            ? hiring
            : null;
        });
      } else {
        _hiringFiltered = _.filter(_hiringFiltered, function (hiring) {
          return hiring.hiring_config.end_date != null &&
            moment(hiring.hiring_config.end_date).isBefore(
              moment().format("YYYY-MM-DD")
            )
            ? hiring
            : null;
        });
      }
    }

    if (
      _.has(filter, "with_start_date_start_date") &&
      filter["with_start_date_start_date"]
    ) {
      let _start_date = moment(
        filter["with_start_date_start_date"],
        "YYYY-MM-DD"
      );
      _hiringFiltered = _.filter(_hiringFiltered, h =>
        moment(h.start_date, "YYYY-MM-DD").isSameOrAfter(_start_date)
      );
    }

    if (
      _.has(filter, "with_start_date_end_date") &&
      filter["with_start_date_end_date"]
    ) {
      let _end_date = moment(filter["with_start_date_end_date"], "YYYY-MM-DD");
      _hiringFiltered = _.filter(_hiringFiltered, h =>
        moment(h.start_date, "YYYY-MM-DD").isSameOrBefore(_end_date)
      );
    }

    if (
      _.has(filter, "with_end_date_start_date") &&
      filter["with_end_date_start_date"]
    ) {
      let _start_date = moment(
        filter["with_end_date_start_date"],
        "YYYY-MM-DD"
      );
      _hiringFiltered = _.filter(_hiringFiltered, h =>
        moment(h.end_date, "YYYY-MM-DD").isSameOrAfter(_start_date)
      );
    }

    if (
      _.has(filter, "with_end_date_end_date") &&
      filter["with_end_date_end_date"]
    ) {
      let _end_date = moment(filter["with_end_date_end_date"], "YYYY-MM-DD");
      _hiringFiltered = _.filter(_hiringFiltered, h =>
        moment(h.end_date, "YYYY-MM-DD").isSameOrBefore(_end_date)
      );
    }

    setHiringsFiltered(_hiringFiltered);
  };

  const headerTitleCustom = (column, colIndex, { sortElement, filterElement }) => {
    let _filter = _.find(statesFilters, { "field": column.dataField });
    return(
      <div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">{ column.text }</p>
          <div>
            <button type='button' onClick={e => setStatesFilters(handleStateFilters(statesFilters, column, filterElement)) } className="btn btn-link m-0 p-0">
              <i className={ !!_filter?.value ? "far fa-times-circle mt-1 fs-6" : "fas fa-search mt-1 fs-6" }></i>
            </button>
          </div>
        </div>
        <div className="row">
          { drawInputSearch(statesFilters, column, filterElement) }
        </div>
      </div>
    )
  }

  const getCustomFields = (customFields) => {
    return _.map(customFields, function(custom){
      return {
        dataField: `custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        headerStyle: { width: "200px" },
        filter: textFilter({
          placeholder: `Ingrese ${_.lowerCase(custom.label)}`
        }),
        headerFormatter: headerTitleCustom,
        formatter: function (value, row, index) {
          return (
            <p>{ value || `${I18n.t("no_entry")}` }</p>
          )
        },
      }
    })
  }

  const selectCompanyOptions = () => {
    return _.map(companies, company => {
      return { value: company[1], label: company[0] };
    });
  };

  const selectRequirementsOptions = () => {
    return _.map(requirements, requirement => {
      return { value: requirement.id, label: requirement.name };
    });
  };

  const drawFilterButton = () => {
    return (
      <div className="text-right mb-3 ">
        <Button
          color="info"
          className="mr-1 mb-2 mb-md-0 ml-2 text-right"
          data-widget="control-sidebar"
        >
          {I18n.t("actions.filter")}
        </Button>
      </div>
    );
  };

  const columns = _.concat(_.compact(columnsHirings), getCustomFields(props?.customFields))

  return (
    <div>
      <Card className="card-outline card-primary">
        <CardHeader> Procesar Datos </CardHeader>
        <CardBody>
          <FormGroup>
            <Label className="required">Empresa</Label>
            <FieldError errors={formErrors.company}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                options={selectCompanyOptions()}
                placeholder="-- Selecciona una Empresa --"
                onChange={e => setSelectedCompany(e)}
                value={selectedCompany}
              />
            </FieldError>
          </FormGroup>
          <FormGroup className="mt-3">
            <Label className="required">Nombre del Proceso</Label>
            <FieldError errors={formErrors.name}>
              <Input
                type="text"
                value={processName}
                onChange={e => setProcessName(e.target.value)}
                invalid={formErrors.name ? true : false}
              />
            </FieldError>
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="card-outline card-primary">
        <CardHeader> Tipos de Documentos </CardHeader>
        <CardBody>
          {!_.isEmpty(requirements) ? (
            <FieldError errors={formErrors.document_types}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                isMulti
                options={selectRequirementsOptions()}
                placeholder="-- Selecciona tipos de Documentos --"
                onChange={e => setSelectedRequirements(e)}
                value={selectedRequirements}
              />
            </FieldError>
          ) : (
            <Alert color="primary">
              <i className="fas fa-info-circle mr-2" />
              <span>
                {I18n.t(
                  "laboral.hiring_compliance_processes.form.document_types.without_results"
                )}
              </span>
            </Alert>
          )}
        </CardBody>
      </Card>
      <Card className="card-outline card-primary">
        <CardHeader> Trabajadores </CardHeader>
        <CardBody>
          {!_.isEmpty(hirings) ? (
            <>
              {formErrors.entities && (
                <Alert color="danger">
                  {_.map(formErrors.entities, error => error)}
                </Alert>
              )}
              <TableFilter
                filter={filter}
                handleOptionFilter={handleOptionFilter}
                companies={companies}
                clearFilter={clearFilter}
                handleSubmitFilter={handleSubmitFilter}
              />
              {drawFilterButton()}
              <BootstrapTable
                keyField="id"
                selectRow={{
                  mode: "checkbox",
                  clickToSelect: true,
                  selected: _.map(selectedHirings || [], h => h.id),
                  onSelect: (row, isSelect, rowIndex, e) => {
                    handleSelectHiring(isSelect, row);
                  },
                  onSelectAll: (isSelect, rows, e) => {
                    handleSelectAllHirings(isSelect, rows);
                  },
                }}
                data={hiringsFiltered}
                filter={filterFactory()}
                columns={columns}
                pagination={paginationFactory(paginationOption)}
                wrapperClasses="table-responsive"
              />
            </>
          ) : (
            <Alert color="primary">
              <i className="fas fa-info-circle mr-2" />
              <span>
                {I18n.t(
                  "laboral.hiring_compliance_processes.form.entities.without_results"
                )}
              </span>
            </Alert>
          )}
        </CardBody>
      </Card>
      <FormGroup className="text-end">
        <Button
          type="button"
          href={Routes.laboral_hiring_compliance_processes_path()}
          color="default"
        >
          {I18n.t("actions.back")}
        </Button>
        <Button color="success" className="ml-2" type="submit" onClick={e => handleConfirmSubmit(e)}>
          {I18n.t(
            `laboral.hiring_compliance_processes.form.${
              compliance.id ? "update" : "create"
            }.confirm.title`
          )}
        </Button>
      </FormGroup>
    </div>
  );
};

export default HiringComplianceProcessForm;
