import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import logo from 'images/landing/compliance.png';
import CommentSection from './comments';
import { PublicTutorialTourVirtualRequestBudgetModalButton } from './request_budget_modal';

const TourHeader = ({ tour, currentTourCapsule, toggleRequestBudgetModal }) => {
  return (
    <div className="tour-header sticky-top bg-white shadow-sm">
      <Navbar color="white" light className="d-flex align-items-center">
        <NavbarBrand href="/" className="d-flex align-items-center justify-content-center mr-auto">
          <img src={logo} alt="logo" width="45" height="45" />
          <h1 className="fw-bold mb-0 fs-5 d-inline-block ml-3 lh-1 text-wrap">
            <small className="d-block text-muted fs-6">
              <i className="fas fa-video fa-md mr-2"></i>
              {tour.name ? tour.name : "Tour Virtual"}
            </small>
            {currentTourCapsule.capsule.name}
          </h1>
        </NavbarBrand>
        <div className="d-block d-md-inline">
          <button className="btn btn-secondary btn-sm d-inline-block d-md-none mr-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPlayList" aria-controls="offcanvasPlayList">
            <i className="fas fa-th-list mr-2"></i>
            PlayList
          </button>
          {tour.resourceable_type === "Crm::Lead" &&
            <PublicTutorialTourVirtualRequestBudgetModalButton
              className="btn btn-success btn-sm d-inline-block d-none md-d-block mr-2"
              toggle={toggleRequestBudgetModal}
            />
          }
          {/*<button className="btn btn-primary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <i className="far fa-comment mr-2"></i>
            Comentarios
          </button>*/}
        </div>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header shadow-sm">
            <h5 className="offcanvas-title fw-bold" id="offcanvasNavbarLabel">
              <i className="far fa-comment mr-2"></i>
              Comentarios
            </h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body p-0 vh-100">
            <CommentSection />
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default TourHeader;
