import React, { useMemo } from 'react';

import WorkflowRequirementCommentBox from '../../../../workflow/requirement/comment/box'

const PortalWorkflowRequirementCommentBox = props => {
  const {
    requirement,
    currentCustomer,
    configuration: {
      tenant
    }
  } = props;

  const commentDefaultRequestParams = {
    tenant: tenant,
    resource_id: requirement.hashid,
    customer_id: currentCustomer.hashid
  }

  const requirementDefaultRequestParams = {
    tenant: tenant,
    id: requirement.hashid
  }


  const downloadOutputFileItemPath = useMemo(() => {
    let route = ''

    if(requirement.module_type == 'Abstract::Workflow::Requirement'){
      route = Routes.download_output_file_item_portal_abstract_workflow_requirement_stage_path
    } else if(requirement.module_type == 'KarinLaw::Workflow::Requirement'){
      route = Routes.download_output_file_item_portal_karin_law_workflow_requirement_stage_path
    }

    return route;
  }, [])

  const showInfoStage = useMemo(() => {
    return requirement.module_type != 'KarinLaw::Workflow::Requirement'
  }, [requirement])


  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementCommentBox
          requirement={ requirement }
          configuration={{
            commentDefaultRequestParams: commentDefaultRequestParams,
            requirementDefaultRequestParams: requirementDefaultRequestParams,
            context: 'portal',
            showInfoStage: showInfoStage
          }}
          routes={{
            downloadAttachCommentPath: Routes.download_attach_portal_comment_workflow_requirement_path,
            // downloadOutputFilePath: Routes.download_output_file_item_portal_workflow_requirement_stage_path,
            downloadOutputFilePath: downloadOutputFileItemPath,
            indexCommentPath: Routes.portal_comment_workflow_requirements_path,
            createCommentPath: Routes.portal_comment_workflow_requirements_path
          }}
        />
      </div>
    </div>
  )
}

export default PortalWorkflowRequirementCommentBox;
