import React, { useCallback } from 'react';

import {
  Row,
  Col,
  Button
} from 'reactstrap';

import UserProfileFormAttributes from '../../user/profile/form_attributes';

const PortalProfileForm = props => {

  const {
    user,
    data,
    configuration: {
      context = ''
    } = {}
  } = props;

  const redirectDashboardPortalButton = () => {
    if(
      context == 'portal' &&
      data?.currentCustomer &&
      user.completed_personal_information &&
      user.completed_signature_information
    ){
      return(
        <div className="text-end">
          <a
            href={ Routes.portal_path(data.currentCustomer.portal_configuration.tenant) }
          >
            Ir al portal
          </a>
        </div>
      )
    }
  }

  const callbackAfterProfileCompleted = useCallback(() => {
    window.location = Routes.portal_path(data.currentCustomer.portal_configuration.tenant)
  }, [])

  return(
    <Row>
      <Col>
        <UserProfileFormAttributes
          user={ user }
          data={ data }
          callbacks={{
            callbackAfterProfileCompleted: callbackAfterProfileCompleted
          }}
        />

        { redirectDashboardPortalButton() }
      </Col>
    </Row>
  )
}

export default PortalProfileForm;
