import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, FormGroup, Label, Input } from "reactstrap";

import I18n from 'i18n-js';

import FieldError from '../helper/FieldError';

import {
  inputId,
  inputName,
} from '../helper/form';

import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerEmailNotificationSignAttributes = props => {

  const {
    signer, formName, callbackSetSigner, index
  } = props;

  const {
    data,
    context,
    currentUser,
    currentCustomer,
    entities
  } = useContext(DocumentFormContext)

  const { customFieldEmailNotifications } = data;

  const {
    resource
  } = useContext(DocumentSignerConfigContext)

  const handleSigner = (event, key) => {
    let value = event.target.value;
    let _signer = { ... signer };

    _signer[key] = value;

    if(_.isFunction(callbackSetSigner)){
      callbackSetSigner(_signer, index);
    }
  }

  const emailNotificationGroups = useMemo(() => {
    if( context == 'abstract'){
      let entity = _.find(entities, function(_entity){
        return String(_entity.id) == String(resource?.entity_id)
      });

      let relatedEntities = [...entity?.related_entities]
      let _emailNotificationGroups = {}

      const getCustomFieldValue = (entity, customFieldGrouped) => {

        return _.map(customFieldGrouped, customField => {
          let _email = _.get(entity, `custom_fields.${customField.code}`)
          return { email: _email, label: `${_email || I18n.t("no_entry") } - ${customField?.label}`}
        })
      }

      _.each(customFieldEmailNotifications, function(customFieldGrouped, key){
        if (entity.abstract_entity_type.plural_name == key){
          _emailNotificationGroups[key] = getCustomFieldValue(entity, customFieldGrouped)
        }else{
          _.each(relatedEntities, function(related_entity){
            if (related_entity.abstract_entity_type.plural_name == key){
              _emailNotificationGroups[key] = getCustomFieldValue(related_entity, customFieldGrouped)
            }
          })
        }
      })

      return _emailNotificationGroups || [];
    }
  }, [resource.entity_id])

  const drawCustomFieldSelectInput = () => {
    if( context == 'abstract_bulk'){
      return(
        <div className="col-12">
          <FormGroup>
            <FieldError errors={ signer?.errors?.custom_field_id || [] }>
              <Input
                type='select'
                value={ signer?.custom_field_id || '' }
                onChange={ e => handleSigner(e, 'custom_field_id') }
                invalid={ _.has(signer?.errors, "custom_field_id") }
              >
                <option value=""></option>
                { _.map(customFieldEmailNotifications, function(customFieldGrouped, key){
                  return(
                    <optgroup
                      key={ _.uniqueId('optgroup') }
                      label={ key }
                    >
                      { _.map(customFieldGrouped, function(customField, index){
                        return(
                          <option
                            value={ customField.id }
                            key={ `custom-field-option-${ customField.id }` }
                          >
                            { customField.label }
                          </option>
                        )
                      })}
                    </optgroup>
                  )
                })}
              </Input>

            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawAbstractEntityEmailNotificationSelectInput = () => {
    if( context == 'abstract' ){
      return(
        <div className="col-12">
          <FormGroup>
            <FieldError errors={ signer?.errors?.email || [] }>
              <Input
                type='select'
                value={ signer?.email || '' }
                onChange={ e => handleSigner(e, 'email') }
                invalid={ _.has(signer?.errors, "email") }
              >
                <option value=""></option>
                { _.map(emailNotificationGroups, function(emailNotifications, key){
                  return(
                    <optgroup
                      key={ _.uniqueId('optgroup') }
                      label={ key }
                    >
                      { _.map(emailNotifications, function(obj, index){
                        return(
                          <option
                            value={ obj?.email }
                            key={ `email-notification-option-${ obj?.email }` }
                          >
                            { obj?.label }
                          </option>
                        )
                      })}
                    </optgroup>
                  )
                })}
              </Input>

            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawRoleInput = () => {
    return(
      <div className="col-6">
        <FormGroup>
          <FieldError errors={ signer?.errors?.role || [] } >
            <Label
              for={ inputId(formName, 'role') }
            >
              { I18n.t("activerecord.attributes.signer.role") }
            </Label>

            <Input
              value={ signer?.role || "" }
              onChange={ e => handleSigner(e, "role") }
              name={ inputName(formName, "role") }
              id={ inputId(formName, "role") }
              invalid={ _.has(signer?.errors, "role") }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  return(
    <div className="row">
      { drawCustomFieldSelectInput() }
      { drawAbstractEntityEmailNotificationSelectInput() }
      { drawRoleInput() }
    </div>
  )
}

export default SignerEmailNotificationSignAttributes;
