import React, { useState, useRef, useEffect } from "react";
import ControldocFormImageImagePicker from "../image/image_picker";

const ControldocFormTemplateLayoutEditable = props => {
  const { editorRef, context, imagePicker } = props;
  const [ openFilePicker, setOpenFilePicker ] = useState(false);

  const configEditable = {
    selector: `.template-layout-${ context }`,
    menubar: false,
    max_height: 200,
    language: "es",
    relative_urls : false,
    remove_script_host : false,
    entity_encoding : "raw",
    placeholder: `Configura tu ${ context == 'header' ? 'Cabecera' : 'Pie de Página' } del documento`,
    plugins: ['lists', 'autolink', 'table'],
    toolbar: "bold italic underline forecolor backcolor | undo redo | link | table | alignleft aligncenter alignright alignjustify | uploadFile | numberPage",
    table_toolbar: 'tableprops',
    table_default_attributes: { border: '2' },
    table_default_styles: {'width': '100%', 'boder-color': '#000000', 'border-style': 'solid'},
    // valid_elements: 'strong,em,span[style],a[href],ul,ol,li,h2[style],br,p[style],img[src|alt|width|height|style],table,tbody,tr,td',
    setup: editor => {
      editor.on("init", e => { editorRef.current = editor })
      editor.ui.registry.addButton('uploadFile', {
        text: 'Imágenes',
        icon: 'image',
        onAction: _ => {
          setOpenFilePicker(true)
        }
      });
      editor.ui.registry.addButton('numberPage', {
        text: 'Número de página',
        onAction: _ => {
          editor.insertContent("[[numberPage]]")
        }
      })
    }
  };

  useEffect(() => {
    tinyMCE.init(configEditable);
  }, [])

  return (
    <>
      <ControldocFormImageImagePicker
        openFilePicker={ openFilePicker }
        setOpenFilePicker={ setOpenFilePicker }
        imageList={ imagePicker?.imageList }
        tinymceEditor={ editorRef.current }
        allowedFormatImagePicker={ imagePicker?.allowedFormatFiles }
        imageTag={ {"height": '90px', "width": 'auto'} }
      />
      <div
        className={ `template-layout-${ context }` }
        dangerouslySetInnerHTML={{__html: (props?.templateLayout || {})[context] }}
        style={{ border: '1px dashed', maxHeight: '200px' }}
      >
      </div>
    </>
  )
}

export default ControldocFormTemplateLayoutEditable;
