import React, { useMemo, useCallback } from "react";
import {
  Button
} from 'reactstrap'

import { drawTooltipHelp } from '../../helper/form';
import CheckListDocumentTypeForm from './form'

import I18n from 'i18n-js';
import _ from "lodash";

const CheckListDocumentTypeList = (props) =>{
  const {
    formName,
    checkListProcess,
    setCheckListProcess,
    selectedDocumentTypeOptions,
    errors
  } = props;

  const checkListDocumentTypes = checkListProcess.check_list_document_types

  const onChangeCheckListDocumentType = useCallback((checkListDocumentType, documentTypeId) => {
    setCheckListProcess(prevState => {
      let _checkListDocumentTypes = [ ...prevState?.check_list_document_types ]
      // se utiliza el documentTypeId para restaurar el valor de documentType anterior no se
      // utiliza index porque la busqueda no es exacta (se maneja el _destroy)
      _.find(_checkListDocumentTypes, { document_type_id: documentTypeId }).document_count = 1

      _checkListDocumentTypes = _.map(_checkListDocumentTypes, dt => {
        return dt.document_type_id == checkListDocumentType.document_type_id ? {...dt, ...checkListDocumentType} : dt
      })

      return { ...prevState, check_list_document_types: _checkListDocumentTypes };
    })
  }, [checkListProcess])

  // ------------- START Actions Buttons ---------------
  const onAddCheckListDocumentType = (index) => {
    let _checkListDocumentTypes = [...checkListDocumentTypes]

    // modifica el document count para que sea visible al listarla
    _.first(_.filter(_checkListDocumentTypes, dt => { return dt?.document_count < 2 && dt?._destroy == (0 || false) })).document_count = 2

    setCheckListProcess(prevState => {
      return {...prevState, check_list_document_types: _checkListDocumentTypes }
    })
  }

  const checkListDocumentTypeAddButton = () => {
    return(
      <Button
        type="button"
        size="sm"
        outline
        color="success"
        onClick={ (e) => onAddCheckListDocumentType() }
        disabled={ isAddButtonDisabled }
      >
        <i className="fas fa-copy mr-2"></i>
        { I18n.t('check_list.processes.form.labels.add_document_type') }
      </Button>
    )
  }

  const onRemoveCheckListDocumentType = (checkListDocumentType) => {
    setCheckListProcess(prevState => {
      const _checkListDocumentTypes = prevState.check_list_document_types.map(item =>
        (item.document_type_id === checkListDocumentType.document_type_id && !item._destroy)
          ? { ...item, document_count: 1 }
          : item
      )

      return { ...prevState, check_list_document_types: _checkListDocumentTypes }
    })
  }

  const checkListDocumentTypeRemoveButton = (checkListDocumentType) => {
    return(
      <button
        type="button"
        title={ I18n.t('check_list.processes.form.labels.delete_document_type') }
        className="btn btn-outline-danger btn-sm"
        onClick={ (e) => onRemoveCheckListDocumentType(checkListDocumentType) }
      >
        <i className="fas fa-trash"></i>
      </button>
    )
  }
  // ------------- END Actions Buttons ---------------

  // --------------- START Disableds -----------------
  const isOptionDisabled = useCallback((option) => {
    return _.some(checkListDocumentTypes, dt => { return dt?.document_type_id == option?.value && dt?.document_count > 1 }) ;
  }, [checkListDocumentTypes])

  const isAddButtonDisabled = useMemo(() => {
    return _.filter(checkListDocumentTypes, check=> { return check?._destroy == (0 || false) && check?.document_count > 1})?.length >= selectedDocumentTypeOptions?.length
  }, [checkListDocumentTypes, selectedDocumentTypeOptions])

  // ------------- END Disableds ---------------

  const checkListDocumentTypeForms = () => {
    let _checkListDocumentTypes = _.filter(checkListDocumentTypes, dt => { return dt?.document_count > 1 && dt?._destroy == (0 || false)})

    return _.map(_checkListDocumentTypes, (checkListDocumentType, index) => {
      return (
        <CheckListDocumentTypeForm
          key={ `check_list_document_type_${ index }` }
          index={ index }
          formName={ formName }
          errors={ _.find(errors, {document_type_id: checkListDocumentType?.document_type_id}) }
          checkListDocumentType={ checkListDocumentType }
          selectedDocumentTypeOptions={ selectedDocumentTypeOptions }
          isOptionDisabled={ isOptionDisabled }
          callbackSetCheckListDocumentType={ onChangeCheckListDocumentType }
          btnActions={{
            btnRemove: checkListDocumentTypeRemoveButton
          }}
        />
      )
    })
  }

  return(
    <div className="card card-outline card-primary">
      <div className="card-header">
        { I18n.t(`check_list.processes.form.labels.documents_per_document_type`) }
        { drawTooltipHelp( I18n.t(`check_list.processes.requires_document_count_per_document_type.tooltip_info`) )}
      </div>
      <div className="card-body">
        { checkListDocumentTypeForms() }
      </div>

      <div className="col-md-12 mb-3">
        { checkListDocumentTypeAddButton() }
      </div>
    </div>
  )
};

export default CheckListDocumentTypeList;