import React from 'react';
import { FormGroup, Input, Label, List } from 'reactstrap';
import { drawErrors } from '../../../../helper/form';
import I18n from 'i18n-js';

const SelectEmailNotifications = ({value, customFieldEmailNotifications, handleConfigData, errors}) => {

  return(
    <div className="row">
      <div className="col-xs-12">
        <FormGroup>
          <Input
            type='select'
            value={ value || "" }
            onChange={ e => handleConfigData(e, 'custom_field_id') }
            invalid={ _.has(errors, 'custom_field_id') }
          >
            <option
              value={ "" }
              key={ _.uniqueId(`notification-option-`) }
            >
              { "Selecciona un representante" }
            </option>

            { _.map(customFieldEmailNotifications, function(customFields, key){
              if(customFields.length > 0){
                return(
                  <optgroup
                    label={ key }
                    key={ _.uniqueId('group-email-notification-option-') }
                  >
                    { _.map(customFields, function(customField, index){
                      return(
                        <option
                          value={ customField.id }
                          key={ `email-notification-option-${ customField.id }` }
                        >
                          { customField.label }
                        </option>
                      )
                    })}
                  </optgroup>
                )
              }
            })}
          </Input>
          { drawErrors(errors, 'custom_field_id') }
        </FormGroup>
      </div>
    </div>
  )
}

export default SelectEmailNotifications;
