import React, {
  useState
} from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Files from "react-butterfiles";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  InputGroup
} from 'reactstrap';

import axios from 'axios';

import I18n from 'i18n-js';

import FieldError from '../../../helper/FieldError';

import {
  clearInputFiles,
  inputId,
  inputName
} from '../../../helper/form';

const commentObject = {
  body: '',
  files: []
}

const maxSizeMb = 5;
const maxFilesCount = 5;

const filesTypeErrorMessages = type => {
  switch (type){
    case 'unsupportedFileType':
      return 'Formato del archivo no soportado'
    case 'maxSizeExceeded':
      return `El archivo excede el tamaño máximo de ${ maxSizeMb }mb`
    case 'Without':
      return 'No hemos encontado contratación para el archivo'
    case 'multipleMaxCountExceeded':
    return `No puedes cargar más de ${ maxFilesCount } archivos`
    default:
    return 'Error en el archivo'
  }
}

const WorkflowRequirementCommentForm = props => {
  const formName = 'comment'

  const {
    requirement,
    routes,
    callbacks: {
      pushComment: callbackPushComment
    },
    configuration: {
      commentDefaultRequestParams
    }
  } = props;

  const [attachedFiles, setAttachedFiles] = useState([]);

  const createPath = routes.createCommentPath({ ... commentDefaultRequestParams, format: "json" })

  const [comment, setComment] = useState(commentObject);

  const onChangeComment = (key) => {
    let value = event.target.value;
    let type = event.target.type;

    setComment(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onUploadFiles = files => {
    const srcs = _.map(files, 'src.file');

    setComment(prevState => {
      return {
        ...prevState,
        files: [ ... prevState.files, ...srcs]
      }
    })
  }

  const createService = (callback) => {
    let promise = axios({
      method: 'post',
      url: createPath,
      data: commentFormData(),
      headers: {
        "X-CSRF-TOKEN": window.compliance.token
      }
    });

    promise.then(response => {
      callback(response)
    }).catch(error => {
      callback(error.response)
    })
  }

  const onSubmitComment = () => {
    event.preventDefault();

    createService(response => {
      if(response.status == 201){
        setComment(commentObject)
        callbackPushComment(response.data);

        swalToastBootstrap.fire({
          icon: 'success',
          title: I18n.t('comments.create.successfully')
        });
      } else {
        setComment(prevState => {
          return { ... prevState, errors: response.data.errors }
        })

        _.each(response.data.errors, error => {
          swalToastBootstrap.fire({
            icon: 'error',
            title: error
          });
        })

      }
    })
  }

  const commentFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, comment.id || '');
    formData.append(`${ formName }[body]`, comment.body || '');

    _.each(comment.files, file => {
      formData.append(`${ formName }[files][]`, file || '')
    })

    return formData;
  }

  const fileList = () => {
    if(comment.files.length > 0){
      return(
        <WorkflowRequirementCommentFiles
          files={ comment.files }
          callbacks={{
            onChangeComment: onChangeComment
          }}
        />
      )
    }
  }

  return(
    <>
      <Form onSubmit={ e => onSubmitComment() }>
        <Files
          multiple={ true }
          maxSize={ `${ maxSizeMb }mb` }
          multipleMaxSize={ `${ maxSizeMb * maxFilesCount }mb`}
          multipleMaxCount={ maxFilesCount }
          onError={ errors => {
            _.each(errors, error => {
              swalToastBootstrap.fire({
                icon: 'error',
                title: filesTypeErrorMessages(error.type)
              });
            })
          }}
          onSuccess={ response => {
            onUploadFiles(response)
          }}
        >
          { ( { browseFiles, getDropZoneProps }) => {
            return(
               <InputGroup>
                <Button
                  outline
                  color='secondary'
                  type='button'
                  onClick={ browseFiles }
                >
                  <i className="fas fa-paperclip fa-lg"></i>
                </Button>

                <Input
                  type='text'
                  onChange={ e => onChangeComment('body') }
                  value={ comment.body || '' }
                  invalid={ _.has(comment.errors, 'body') }
                  placeholder={ 'Escribe un mensaje' }
                />

                <Button
                  color='primary'
                >
                  <i className="fas fa-paper-plane fa-lg"></i>
                </Button>
              </InputGroup>
            )
          }}
        </Files>
      </Form>

      { fileList() }
    </>
  )
}

export default WorkflowRequirementCommentForm;

const WorkflowRequirementCommentFiles = props => {
  const {
    files,
    callbacks: {
      onChangeComment: callbackOnChangeComment
    }
  } = props;

  const onDeleteFile = (fileIndex) => {

    const _files = _.remove(files, (file, index) => { return index == fileIndex })

    callbackOnChangeComment(
      { target: { value: _files } },
      'files'
    )
  }

  return(
    <div className="row mt-1">
      <div className="col-12">
        { _.map(files, (file, index) => {
          return(
            <WorkflowRequirementCommentFileItem
              key={ _.uniqueId('WorkflowRequirementCommentFileItem-') }
              file={ file }
              index={ index }
              callbacks={{
                onDeleteFile: onDeleteFile
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const WorkflowRequirementCommentFileItem = props => {
  const {
    file,
    index,
    callbacks: {
      onDeleteFile: callbackOnDeleteFile
    }
  } = props;

  return (
    <div
      className="badge badge-pill badge-light py-1 px-2 d-inline-block mr-2 mb-1"
    >
      <i className="fas fa-paperclip mr-2"></i>
      { file.name }
      <button
        className="btn p-0 btn-link ml-2"
        alt="Delete"
        onClick={ event => callbackOnDeleteFile(index) }
      >
        <i className="fas fa-times text-danger"></i>
      </button>
    </div>
  );
}
