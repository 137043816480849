import React, { useMemo } from 'react';

import {
  ReactFlowProvider,
} from "reactflow";

import "reactflow/dist/style.css";

import axios from "axios";

import I18n from 'i18n-js';

import WorkflowBasicFlow from '../../../workflow/basic_flow';

// ---------- START STAGE SERVICE ----------
const createStageService = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.karin_law_workflow_stages_path({...{...requestParams}, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

const updateStageService = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.karin_law_workflow_stage_path({...{...requestParams}, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

const destroyStageService = (requestParams, callback) => {
  var promise = axios({
    method: 'delete',
    url: Routes.karin_law_workflow_stage_path({...{...requestParams}, format: 'json', _options: true }),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

// ---------- END STAGE SERVICE ----------

// ---------- START EDGE SERVICE ----------
const createEdgeService = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.karin_law_workflow_edges_path({...{...requestParams}, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

const updateEdgeService = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.karin_law_workflow_edge_path({...{...requestParams}, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

const destroyEdgeService = (requestParams, callback) => {
  var promise = axios({
    method: 'delete',
    url: Routes.karin_law_workflow_edge_path({...{...requestParams}, format: 'json', _options: true }),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
// ---------- END EDGE SERVICE ----------

// ---------- START GROUP SERVICE ----------
const indexGroupService = (requestParams, callback) => {
  var promise = axios({
    method: 'get',
    url: Routes.karin_law_workflow_groups_path({...{...requestParams}, format: 'json', _options: true }),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
// ---------- START GROUP SERVICE ----------

const KarinLawWorkflowBasicForm = props => {

  const {
    currentUser,
    currentCustomer,
    workflowProcess,
    data
  } = props;

  const processDefaultRequestParams = { id: workflowProcess.hashid }
  const stageDefaultRequestParams = { process_id: workflowProcess.hashid }
  const edgeDefaultRequestParams = { process_id: workflowProcess.hashid }
  const groupDefaultRequestParams = { process_id: workflowProcess.hashid }

  const requirementPublicPath = Routes.new_public_karin_law_workflow_requirement_path({
    customer_id: currentCustomer.hashid,
    process_id: workflowProcess.hashid
  });

  return(
    <div className="vh-100">
      <ReactFlowProvider>
        <WorkflowBasicFlow
          data={ data }
          workflowProcess={ workflowProcess }
          currentCustomer={ currentCustomer }
          configuration={{
            context: 'karin_law',
            process: {
              defaultRequestParams: processDefaultRequestParams,
              formName: 'workflow_process'
            },
            stage: {
              defaultRequestParams: stageDefaultRequestParams,
              formName: 'workflow_stage',
            },
            edge: {
              defaultRequestParams: edgeDefaultRequestParams,
              formName: 'workflow_edge',
            },
            group: {
              defaultRequestParams: groupDefaultRequestParams,
            },
            entity: {
              label: I18n.t('activerecord.models.karin_law/complaint.one')
            }
          }}
          services={{
            stage: {
              createService: createStageService,
              updateService: updateStageService,
              destroyService: destroyStageService
            },
            edge: {
              createService: createEdgeService,
              updateService: updateEdgeService,
              destroyService: destroyEdgeService
            },
            group: {
              indexService: indexGroupService
            }
          }}
          routes={{
            requirementPublicPath: requirementPublicPath
          }}
        />
      </ReactFlowProvider>
    </div>
  )
}

export default KarinLawWorkflowBasicForm;
