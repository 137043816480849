import axios from "axios";

export const create = (currentEntityTypeId, formData, callback) => {
  var promise = axios({
    method: 'POST',
    url: Routes.abstract_bulk_load_entities_path(currentEntityTypeId, {'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
      "Content-Type": "multipart/form-data",
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
