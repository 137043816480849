import React, { useState } from 'react'

import I18n from 'i18n-js';
import {
  Table,
  Button
} from 'reactstrap';

import AbstractFaoMappingFieldItem from '../mapping_field/item';
import {
  create as createFaoMappingConfig,
  update as updateFaoMappingConfig
} from './axios';


const AbstractFaoMappingConfigForm = props => {

  const {
    resources, currentEntityType, customer
  } = props;

  const formName = 'fao_mapping_config';

  const [faoMappingConfig, setFaoMappingConfig] = useState(props?.faoMappingConfig || {})

  const handleFaoMappingField = (faoMappingField, index) => {
    let _faoMappingConfig = { ... faoMappingConfig }
    _faoMappingConfig.fao_mapping_fields[index] = faoMappingField;

    setFaoMappingConfig(_faoMappingConfig);
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('fao.mapping_configs.form.confirm.title'),
      html: I18n.t('fao.mapping_configs.form.confirm.confirm'),
    }).then( result => {
      if(result.isConfirmed){
        handleSubmit()
      }
    })
  }

  const handleSubmit = () => {
    let formData = getFormData();

    if(faoMappingConfig?.id){
      var queryString = { id: faoMappingConfig.id, current_entity_type_id: currentEntityType?.hashid }

      updateFaoMappingConfig(queryString, formData, response => {
        if(response.status == 200){
          window.location = Routes.abstract_fao_mapping_configs_path(currentEntityType.hashid);
        } else {
          setFaoMappingConfig(response.data);
        }
      })
    } else {
      createFaoMappingConfig(currentEntityType?.hashid, formData, response => {
        if(response.status == 201){
          window.location = Routes.abstract_fao_mapping_configs_path(currentEntityType.hashid);
        } else {
          setFaoMappingConfig(response.data);
        }
      })
    }
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, faoMappingConfig?.id || '');

    _.each(faoMappingConfig.fao_mapping_fields, function(faoMappingField, index){
      formData.append(`${ formName }[fao_mapping_fields_attributes][${ index }][id]`, faoMappingField?.id || '');
      formData.append(`${ formName }[fao_mapping_fields_attributes][${ index }][fao_var]`, faoMappingField?.fao_var || '');
      formData.append(`${ formName }[fao_mapping_fields_attributes][${ index }][resource_class]`, faoMappingField?.resource?.resource_class || '');
      formData.append(`${ formName }[fao_mapping_fields_attributes][${ index }][resource_attribute]`, faoMappingField?.resource_attribute || '');
      formData.append(`${ formName }[fao_mapping_fields_attributes][${ index }][related_abstract_entity_type_id]`, faoMappingField?.resource?.related_abstract_entity_type_id || '');
    })

    return formData
  }

  const drawMappingFields = () => {
    return(
      <Table>
        <thead>
          <tr>
            <th>{ I18n.t('activerecord.attributes.fao/mapping_field.fao_var') }</th>
            <th>{ I18n.t('activerecord.attributes.fao/mapping_field.resource_class') }</th>
            <th>{ I18n.t('activerecord.attributes.fao/mapping_field.resource_attribute') }</th>
          </tr>
        </thead>

        <tbody>
          { _.map(faoMappingConfig.fao_mapping_fields, function(faoMappingField, index){
            return(
              <AbstractFaoMappingFieldItem
                key={ _.uniqueId('AbstractFaoMappingFieldItem-') }
                faoMappingField={ faoMappingField }
                resources={ resources }
                index={ index }
                handleFaoMappingField={ handleFaoMappingField }
              />
            )
          })}
        </tbody>
      </Table>
    )
  }

  return(
    <div className="row">
      <div className="col-xs-12">
        <div className="row">
          <div className="col-12">
            <div className="callout callout-danger">
              <h5><i className="fas fa-info-circle"></i> { I18n.t('important') }</h5>
              <div dangerouslySetInnerHTML={{__html: I18n.t('abstract.fao.mapping_configs.form.message.important') }}/>
            </div>
          </div>
        </div>

        { drawMappingFields() }

        <div className="text-end">
          <a
            href={ Routes.abstract_fao_mapping_configs_path(currentEntityType.hashid) }
            className="btn btn-default"
          >
            { I18n.t('actions.back') }
          </a>

          <Button
            className='ml-2'
            color='success'
            onClick={ handleConfirmSubmit }
          >
            { I18n.t('actions.save') }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AbstractFaoMappingConfigForm;
