import React, { useState, useEffect } from 'react';

import I18n from 'i18n-js';
import {
  Label,
  Input
} from 'reactstrap';

import AbstractFaoMappingFieldResourceClassItem from './resource_class_item';
import AbstractFaoMappingFieldResourceAttributeItem from './resource_attribute_item';

const AbstractFaoMappingFieldItem = props => {

  const {
    resources,
    faoMappingField,
    handleFaoMappingField,
    index
  } = props;

  const handleResourceClass = (resource) => {
    let _faoMappingField = { ... faoMappingField };
    _faoMappingField.resource = resource;
    _faoMappingField.resource_class = resource.resource_class;
    _faoMappingField.resource_attribute = null;

    if(_.isFunction(handleFaoMappingField)){
      handleFaoMappingField(_faoMappingField, index)
    }
  }

  const handleResourceAttribute = (resourceAttribute) => {
    let _faoMappingField = { ... faoMappingField };
    _faoMappingField.resource_attribute = resourceAttribute;

    if(_.isFunction(handleFaoMappingField)){
      handleFaoMappingField(_faoMappingField, index)
    }
  }

  return(
    <tr>
      <td width='30%'>
        <Label>
          { I18n.t(`activerecord.attributes.fao/mapping_field.${ faoMappingField.fao_var}`) }
        </Label>
      </td>
      <td width='35%'>
        <AbstractFaoMappingFieldResourceClassItem
          resources={ resources }
          handleResourceClass={ handleResourceClass }
          faoMappingField={ faoMappingField }
        />
      </td>
      <td>
        <AbstractFaoMappingFieldResourceAttributeItem
          resources={ resources }
          handleResourceAttribute={ handleResourceAttribute }
          faoMappingField={ faoMappingField }
        />
      </td>
    </tr>
  )
}

export default AbstractFaoMappingFieldItem;
