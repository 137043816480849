import React, { useState } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (newComment) {
      setComments([...comments, newComment]);
      setNewComment('');
    }
  };

  return (
    <Card className="chat-container chat-in-tour">
      <CardBody>
        <Card className="chat-box">
          <CardBody className="p-2">
            <form onSubmit={handleCommentSubmit}>
              <FormGroup className="mb-0">
                <Label for="commentInput" className="sr-only">
                  Comment
                </Label>
                <Input
                  type="textarea"
                  id="commentInput"
                  placeholder="Escribe un comentario..."
                  value={newComment}
                  onChange={handleCommentChange}
                />
              </FormGroup>
              <Button color="primary" type="submit" size="sm">
                Comentar
              </Button>
            </form>
          </CardBody>
        </Card>
            

        <div>
          {comments.length > 0 ? (
            comments.map((comment, index) => (
              <div key={index} className="message-container chat-card answer">
                <div className="message-card-container mb-2">
                  <Card className="card message-card border-0">
                    <p>{comment}</p>
                    <span className="date-info">comment.author_name - at 00:00</span>
                  </Card>
                </div>
              </div>
            ))
          ) : (
            <CardText>Sin Comentarios</CardText>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default CommentSection;