import React, {
  useState,
  useEffect,
  useMemo
} from 'react';
import I18n from 'i18n-js';

import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

import Select from 'react-select';

import FieldError from '../../../../helper/FieldError';
import DocumentSignerConfigAttributes from '../../../../document/signer_config_attributes';
import SignerFormList from '../../../../signer/form_list';
import AbstractBulkLoadDocumentFileInput from './file_input'

import DocumentFormContext from '../../../../document/context/document_form_context';
import DocumentSignerConfigContext from '../../../../document/context/signer_config_context';

import {
  defaultActionSignerOptions,
  defaultActionDocumentType
} from '../../../../document/helper'

import {
  inputId,
  inputName,
  scrollToTop,
  drawTooltipHelp
} from '../../../../helper/form';

import { create } from "./axios";

const AbstractBulkLoadDocumentForm = props => {
  const formName = "abstract_bulk_load_document";

  const {
    currentEntityType,
    data: { documentTypes, defaultSigners, entities, allowedFormatFiles }
  } = props;

  const [bulkLoad, setBulkLoad] = useState(props?.bulkLoad || {} )
  const [documents, setDocuments] = useState({ files: [], errors: []})
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  const documentFormContextValue = useMemo(() => {
    return {
      currentUser: props?.currentUser,
      currentCustomer: props?.currentCustomer,
      currentEntityType: props?.currentEntityType,
      context: 'abstract_bulk',
      data: {
        defaultSigners: defaultSigners
      },
    }
  }, [])

  const documentSignerConfigContextValue = useMemo(() => {
    return {
      resource: {
        require_signers: bulkLoad?.require_signers,
        require_signers_order: bulkLoad?.require_signers_order,
        require_fao: bulkLoad?.require_fao,
        isPersisted: false
      },
    }
  }, [
    bulkLoad?.require_signers,
    bulkLoad?.require_signers_order,
    bulkLoad?.require_fao
  ])


  const handleBulkLoad = (event, key) => {
    const type = event.target.type;
    let value = event.target.value;
    let bulkLoadChanges = {};

    if(type == 'checkbox'){
      value = event.target.checked;
    }

    bulkLoadChanges = {
      ... bulkLoadChanges,
      ...defaultActionSignerOptions(bulkLoad, key, value)
    }

    if(key === 'document_type_id'){
      let _data = {
        documentTypes: documentTypes || []
      }

      bulkLoadChanges = {
        ... bulkLoadChanges,
        ...defaultActionDocumentType(bulkLoad, value, _data)
      }
    }

    bulkLoadChanges[key] = value;

    setBulkLoad(prevState => {
      return { ... prevState, ... bulkLoadChanges }
    })
  }

  // ------------------ START DOCUMENT TYPE ------------------

  const documentTypeSelectOptions = useMemo(() => {
    return _.map(documentTypes || [], documentType => ({ value: documentType.id, label: documentType.name }))
  }, [])

  const documentTypeSelectedOption = useMemo(() => {
    let selected = _.find(documentTypes, { id: bulkLoad?.document_type_id })

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }, [bulkLoad?.document_type_id])


  const documentTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ bulkLoad?.errors?.document_type || [] }>
          <Label
            for={ inputId(formName, 'document_type') }
          >
            { I18n.t("activerecord.attributes.document.document_type_id") }
          </Label>
          <Select
            id={ inputId(formName, 'document_type_id') }
            name={ inputName(formName, 'document_type_id') }
            invalid={ _.has(bulkLoad?.errors, 'document_type_id') }
            isSearcheable={ true }
            isClearable={ true }
            options={ documentTypeSelectOptions }
            value={ documentTypeSelectedOption }
            onChange={ e => handleBulkLoad({ target: { value: e?.value }}, 'document_type_id') }
            placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.document.document_type_id') } --` }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ------------------ END DOCUMENT TYPE ------------------

  // ------------------ START LABEL ------------------
  const labelInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ bulkLoad?.errors?.label || []} >
          <Label
            htmlFor={ inputId(formName, 'label') }
          >
            { I18n.t('activerecord.attributes.abstract/bulk/load/document.label') }
          </Label>
          { drawTooltipHelp(I18n.t('documents.form.label_tooltip')) }
          <Input
            type='text'
            onChange={ e => handleBulkLoad(e, 'label') }
            value={ bulkLoad?.label || '' }
            invalid={ _.has(bulkLoad.errors, 'label') }
            id={ inputId(formName, 'label') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ------------------ END LABEL ------------------

  // ------------------ START KEEP FORMAT ------------------
  const keepFormatSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ bulkLoad?.errors?.keep_format || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'keep_format') }
              id={ inputId(formName, 'keep_format') }
              invalid={ _.has(bulkLoad?.errors, 'keep_format') }
              onChange={ e => handleBulkLoad(e, 'keep_format') }
              checked={ bulkLoad?.keep_format || false }
              value={ '1' }
              style={{ display: 'contents' }}
              disabled={ bulkLoad?.require_signers }
            />
            <Label
              className='custom-control-label'
              for={ inputId(formName, 'keep_format') }
            >
              { I18n.t(`activerecord.attributes.document.keep_format`) }
              { drawTooltipHelp(I18n.t('documents.new.keep_format_help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }

  // ------------------ END KEEP FORMAT ------------------

  // ------------------ STRT SIGNERS ------------------
  const signerFormList = () => {
    if(bulkLoad.require_signers){
      return(
        <div className="row">
          <div className="col-12">
            <SignerFormList
              signers={ bulkLoad.signers || [] }
              formName={ `${ formName }[signers_attributes]` }
              setResource={ setBulkLoad }
            />
          </div>
        </div>
      )
    }
  }

  // ------------------ END SIGNERS ------------------

  // ------------------ START FILES ------------------
  const fileInstructions = useMemo(() => {
    return(
      <div className="row mt-2">
        <div className="col-12">
          <div className="alert alert-info">
            <p className='mb-0'>
              <i className="fas fa-info-circle mr-2"></i>
              Para agregar archivos se debe arrastrar y soltar o hacer click en Agregar Archivos (max. 100 archivos de 10mb c/u).
            </p>
            <p className='mb-0'>
              <i className="fas fa-info-circle mr-2"></i>
              Recuerde que el nombre del archivo debe ser el identificador de la contratación (ej. C-1-C-000001.pdf)
            </p>
          </div>
        </div>
      </div>
    )
  }, [])

  // ------------------ END FILES ------------------


  const textSubmitButton = useMemo(() => {
    if(disabledSubmitButton){
      return I18n.t('actions.saving')
    } else {
      return I18n.t('actions.save')
    }
  }, [disabledSubmitButton])

  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: 'Realizar carga masiva de documentos',
      html: '¿Estás seguro de realizar la carga masiva?',
    }).then( result => {
      if(result.isConfirmed){
        onSubmit()
      }
    })
  }

  const onSubmit = () => {
    let formData = getFormData();

    setDisabledSubmitButton(true)

    create(currentEntityType.hashid, formData,  response => {
      if(response.status == 201){
        window.location = Routes.abstract_bulk_load_document_path(currentEntityType.hashid, response.data.id);
      } else if(response.status == 422){
        setDisabledSubmitButton(false);
        setBulkLoad(response.data);
        scrollToTop();
      }
    })
  }

  const getFormData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[document_type_id]`, bulkLoad?.document_type_id || '');
    _formData.append(`${ formName }[label]`, bulkLoad?.label || '');
    _formData.append(`${ formName }[require_signers]`, bulkLoad.require_signers ? '1' : '0')
    _formData.append(`${ formName }[require_signers_order]`, bulkLoad.require_signers_order ? '1' : '0')
    _formData.append(`${ formName }[require_signature_deadline]`, bulkLoad?.require_signature_deadline || false);
    _formData.append(`${ formName }[signature_deadline_at]`, bulkLoad?.signature_deadline_at || '');
    _formData.append(`${ formName }[require_fao]`, bulkLoad?.require_fao ? '1' : '0');
    _formData.append(`${ formName }[keep_format]`, bulkLoad.keep_format ? '1' : '0')

    _.each(documents.files, function(_document, index){
      _formData.append(`${ formName }[items_attributes][${index}][entity_id]`, _document?.entity?.id || '')
      _formData.append(`${ formName }[items_attributes][${index}][file]`, _document?.file?.src?.file || '')
    });

    _.each(bulkLoad.signers, function(signer, index){
      _formData.append(`${ formName }[signers_attributes][${ index }][id]`, signer?.id || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][email]`, signer?.email || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][first_surname]`, signer?.first_surname || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][last_surname]`, signer?.last_surname || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][identification_number]`, signer?.identification_number || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][name]`, signer?.name || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][role]`, signer?.role || '');
      _formData.append(`${ formName }[signers_attributes][${ index }][order]`, signer?.order);
      _formData.append(`${ formName }[signers_attributes][${ index }][employee_sign]`, signer?.employee_sign || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][member_sign]`, signer?.member_sign || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][email_notification_sign]`, signer?.email_notification_sign || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][_destroy]`, signer?._destroy || false);
      _formData.append(`${ formName }[signers_attributes][${ index }][custom_field_id]`, signer?.custom_field_id || '');
    })

    return _formData
  }

  return(
    <DocumentFormContext.Provider value={ documentSignerConfigContextValue }>
      <DocumentSignerConfigContext.Provider value={ documentSignerConfigContextValue }>
        <div className="row">
          <div className="col-12">
            { documentTypeInputSelect() }

            { labelInput() }

            { keepFormatSwitchInput() }

            <DocumentSignerConfigAttributes
              handleResource={ handleBulkLoad }
              resource={ bulkLoad }
              formName={ formName }
            />

            { signerFormList() }

            { fileInstructions }

            <AbstractBulkLoadDocumentFileInput
              entities={ entities }
              documents={ documents }
              setDocuments={ setDocuments }
              allowedFormatFiles={ allowedFormatFiles }
            />

            <FormGroup className='text-end'>
              <a
                href={ Routes.abstract_bulk_load_documents_path(currentEntityType.hashid) }
                className='btn btn-default'
              >
                { I18n.t('actions.back') }
              </a>

              <button
                type='button'
                className='btn btn-success ml-2'
                onClick={ onConfirmSubmit }
                disabled={ disabledSubmitButton }
              >
                { textSubmitButton }
              </button>
            </FormGroup>
          </div>
        </div>
      </DocumentSignerConfigContext.Provider>
    </DocumentFormContext.Provider>
  )
}

export default AbstractBulkLoadDocumentForm;
