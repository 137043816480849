import axios from 'axios';

export const create = (currentEntityTypeId, formData, callback) => {
  var promise = axios({
    method: 'POST',
    url: Routes.abstract_fao_mapping_configs_path(currentEntityTypeId, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const update = (queryString, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.abstract_fao_mapping_config_path({...{...queryString }, format: "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
