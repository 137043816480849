import React, {
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  FormGroup,
  Button,
  Form,
  Label,
  Input
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import FieldError from '../../../helper/FieldError';

import {
  inputId,
  inputName,
  clearInputFiles
} from '../../../helper/form';

import {
  fieldItemIsRequired,
  fieldItemHtmlInputType
} from '../../../workflow/requirement_stage/helper'

import WorkflowRequirementStageAttributes from '../../../workflow/requirement_stage/attributes';
import WorkflowRequirementStageFormItemFieldAttributes from '../../../workflow/requirement_stage_form_item/field_attributes';
import WorkflowRequirementStageFormItemFileAttributes from '../../../workflow/requirement_stage_form_item/file_attributes';

const EconomicCrimeWorkflowRequirementAttributes = props => {

  const {
    requirement,
    configuration: {
      formName = 'workflow_requirement',
      defaultRequestParams
    },
    routes: {
      indexRequirementsPath,
      showRequirementPath
    },
    services: {
      createRequirement: createRequirementService
    },
    callbacks: {
      onSetRequirement
    },
    data: {
      economicCrime: {
        complaintTypes,
        informer: {
          identificationTypes: informerIdentificationTypes
        }
      }
    }
  } = props;

  // ---------- START OUTPUT FIELDS ----------
  const onChangeOutputFieldItem = (fieldItem, itemId) => {
    onSetRequirement(prevState => {
      return {
        ...prevState,
        current_requirement_stage: {
          ...prevState.current_requirement_stage,
          output_form: {
            ... prevState.current_requirement_stage.output_form,
            field_items: _.map(prevState.current_requirement_stage.output_form.field_items, (_fieldItem, _index) => {
              if(_fieldItem.item.id == itemId){
                return fieldItem
              } else {
                return _fieldItem
              }
            })
          }
        }
      }
    })
  }

  const outputFieldItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.field_items, fieldItem => {
      return fieldItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.field_items])

  const outputFieldItemAttributes = groupName => {
    let fieldItems = outputFieldItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fieldItems, (fieldItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFieldAttributes
          key={ `WorkflowRequirementStageFormItemFieldAttributes-${ fieldItem.item.id }` }
          fieldItem={ fieldItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][field_items_attributes][${ fieldItem.item.id }]`, index: index}}
          callbacks={{
            onChangeFieldItem: onChangeOutputFieldItem
          }}
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------

  // ---------- START OUTPUT FILES ----------
  // Metodos para insertar los requerimientos asociados a los grupos de delitos ecoomicos
  const onChangeOutputFileItem = (fileItem, itemId) => {
    onSetRequirement(prevState => {
      return {
        ...prevState,
        current_requirement_stage: {
          ...prevState.current_requirement_stage,
          output_form: {
            ... prevState.current_requirement_stage.output_form,
            file_items: _.map(prevState.current_requirement_stage.output_form.file_items, (_fileItem, _index) => {
              if(_fileItem.item.id == itemId){
                return fileItem
              } else {
                return _fileItem
              }
            })
          }
        }
      }
    })
  }

  const outputFileItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.file_items, fileItem => {
      return fileItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.file_items])

  const outputFileItemAttributes = groupName => {
    let fileItems = outputFileItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fileItems, (fileItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFileAttributes
          key={ `WorkflowRequirementStageFormItemFileAttributes-${ fileItem.item.id }` }
          fileItem={ fileItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][file_items_attributes][${ fileItem.item.id }]`, index: index}}
          callbacks={{
            onChangeFileItem: onChangeOutputFileItem
          }}
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------

  const onChangeRequirement = (event, key) => {
    const value = event?.target?.value;

    onSetRequirement(prevState => {
      return { ... prevState, [key]: value}
    })
  }

  const onChangeRequirementStage = (event, key) => {
    const value = event?.target?.value;

    let currentRequirementStage = {
      ... requirement.current_requirement_stage,
      [key]: value
    }

    onChangeRequirement(
      { target: { value: currentRequirementStage } },
      'current_requirement_stage'
    )
  }

  // ---------- START COMPLAINT TYPE FIELDS ----------

  const complaintTypeIsAnonymous = useMemo(() => {
    return requirement.complaint_type == 'anonymous'
  }, [requirement.complaint_type])

  const complaintTypeIsIdentified = useMemo(() => {
    return requirement.complaint_type == 'identified'
  }, [requirement.complaint_type])

  const complaintTypeSelectOptions = useMemo(() => {
    return _.map(complaintTypes, complaintType => {
      return {
        value: complaintType,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.complaint_type.${ complaintType }`)
      }
    })
  }, [])

  const complaintTypeSelectedOption = useMemo(() => {
    if(requirement.complaint_type){
      return {
        value: requirement.complaint_type,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.complaint_type.${ requirement.complaint_type }`)
      }
    } else {
      return null
    }
  }, [requirement.complaint_type])

  const complaintTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.complaint_type') || [] }>
          <Select
            id={ inputId(formName, 'complaint_type') }
            name={ inputName(formName, 'complaint_type') }
            invalid={ _.has(requirement?.errors, 'complaint_type') }
            options={ complaintTypeSelectOptions }
            value={ complaintTypeSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'complaint_type') }
            // required={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const complaintTypeFields = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    {/*<i className="fas fa-signature mr-2"/>*/}
                    { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.complaint_type.one') }
                  </div>
                </div>
              </div>
              <div className="card-body">
                { complaintTypeInputSelect() }
              </div>
            </div>
        </div>
      </div>
    )
  }

  // ---------- END COMPLAINT TYPE FIELDS ----------

  // ---------- START INFORMER FIELDS ----------

  const informerIdentificationTypeSelectOptions = useMemo(() => {
    return _.map(informerIdentificationTypes, identificationType => {
      return {
        value: identificationType,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.${ identificationType }`)
      }
    })
  }, [])

  const informerIdentificationTypeSelectedOption = useMemo(() => {
    if(requirement.informer_identification_type){
      return {
        value: requirement.informer_identification_type,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.${ requirement.informer_identification_type }`)
      }
    } else {
      return null
    }
  }, [requirement.informer_identification_type])

  const informerIdentificationTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_identification_type') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_identification_type') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.one') }
          </Label>
          <Select
            id={ inputId(formName, 'informer_identification_type') }
            name={ inputName(formName, 'informer_identification_type') }
            invalid={ _.has(requirement?.errors, 'informer_identification_type') }
            options={ informerIdentificationTypeSelectOptions }
            value={ informerIdentificationTypeSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'informer_identification_type') }
            // required={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerIdentificationNumberInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_identification_number') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_identification_number') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_identification_number') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'informer_identification_number') }
            name={ inputName(formName, 'informer_identification_number') }
            invalid={ _.has(requirement?.errors, 'informer_identification_number') }
            value={ requirement.informer_identification_number || '' }
            onChange={ e => onChangeRequirement(e, 'informer_identification_number') }
            // required={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerNameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_name') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_name') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_name') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'informer_name') }
            name={ inputName(formName, 'informer_name') }
            invalid={ _.has(requirement?.errors, 'informer_name') }
            value={ requirement.informer_name || '' }
            onChange={ e => onChangeRequirement(e, 'informer_name') }
            // required={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerIdentificationEmailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_email') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_email') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_email') }
          </Label>

          <Input
            type='email'
            id={ inputId(formName, 'informer_email') }
            name={ inputName(formName, 'informer_email') }
            invalid={ _.has(requirement?.errors, 'informer_email') }
            value={ requirement.informer_email || '' }
            onChange={ e => onChangeRequirement(e, 'informer_email') }
            // required={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerFields = () => {

    const title = I18n.t('economic_crime.workflow.requirements.form.informer.title')

    if (complaintTypeIsIdentified){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                    <div className="font-weight-bold">
                      {/*<i className="fas fa-signature mr-2"/>*/}
                      { title }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  { informerIdentificationTypeInputSelect() }
                  { informerIdentificationNumberInput() }
                  { informerNameInput() }
                  { informerIdentificationEmailInput() }
                  { outputFieldItemAttributes(title) }
                  { outputFileItemAttributes(title) }
                </div>
              </div>
          </div>
        </div>
      )
    }
  }

  // ---------- START INFORMER FIELDS ----------


  // ---------- START SUBMIT ----------
  const onSuccessSubmit = useCallback((_requirement) => {
    let requestParams = {
      ...defaultRequestParams,
      id: _requirement.hashid,
      _options: true,
      format: ''
    }

    if(_requirement.token){
      requestParams = { ... requestParams, token: _requirement.token }
    }

    window.location = showRequirementPath(requestParams)
  }, [])

  const onConfirmSubmit = event => {
    event.preventDefault();

    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirements.form.confirm.title'),
      html: I18n.t('workflow.requirements.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    });
  }

  const onSubmit = () => {
    const formData = requirementFormData()

    createRequirementService(defaultRequestParams, formData, response => {
      if(response.status == 201){
        onSuccessSubmit(response.data)
      } else {
        clearInputFiles()
        onSetRequirement(response.data);
      }
    })
  }

  const requirementFormData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[process_id]`, requirement.process_id || '');
    _formData.append(`${ formName }[accept_disclaimer]`, requirement.accept_disclaimer ? 1 : 0);

    _formData.append(`${ formName }[complaint_type]`, requirement.complaint_type || '');
    _formData.append(`${ formName }[informer_identification_type]`, requirement.informer_identification_type || '');
    _formData.append(`${ formName }[informer_identification_number]`, requirement.informer_identification_number || '');
    _formData.append(`${ formName }[informer_name]`, requirement.informer_name || '');
    _formData.append(`${ formName }[informer_email]`, requirement.informer_email || '');

    // CurrentRequirementStage
    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`
    const currentRequirementStage = requirement.current_requirement_stage;

    _formData.append(`${ currentRequirementStageFormName }[id]`, currentRequirementStage.id || '');
    _formData.append(`${ currentRequirementStageFormName }[current_stage_id]`, currentRequirementStage.current_stage_id || '');
    _formData.append(`${ currentRequirementStageFormName }[observations]`, currentRequirementStage.observations || '');

    if(currentRequirementStage.node_type == 'input'){
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, '');
    } else {
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, currentRequirementStage.aasm_state_event || '');
    }


    _formData.append(`${ currentRequirementStageFormName }[output_form_attributes][id]`, currentRequirementStage.output_form.id || '')

    // START OUTPUT FIELD ITEMS
    const outputFieldItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][field_items_attributes]`;
    const outputFieldItems = currentRequirementStage.output_form.field_items || [];
    _.each(outputFieldItems, (fieldItem, index) => {
      _formData.append(`${ outputFieldItemFormName }[${ index }][id]`, fieldItem?.id || '');

      _formData.append(`${ outputFieldItemFormName }[${ index }][item_id]`, fieldItem.item_id || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || 'text');
      _formData.append(`${ outputFieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false );
      _formData.append(`${ outputFieldItemFormName }[${ index }][group_name]`, fieldItem.group_name || '' );
      _formData.append(`${ outputFieldItemFormName }[${ index }][helping_text]`, fieldItem.helping_text || '' );
      _formData.append(`${ outputFieldItemFormName }[${ index }][name]`, fieldItem.name || '' );

      _formData.append(`${ outputFieldItemFormName }[${ index }][value]`, fieldItem?.value || '');
    });
    // END OUTPUT FIELD ITEMS

    // START OUTPUT FILE ITEMS
    const outputFileItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][file_items_attributes]`;
    const outputFileItems = currentRequirementStage.output_form.file_items || [];
    _.each(outputFileItems, (fileItem, index) => {
      _formData.append(`${ outputFileItemFormName }[${ index }][id]`, fileItem?.id || '');

      _formData.append(`${ outputFileItemFormName }[${ index }][value]`, fileItem?.value || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][item_id]`, fileItem.item_id || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][data_type]`, fileItem.data_type || 'text');
      _formData.append(`${ outputFileItemFormName }[${ index }][is_required]`, fileItem.is_required || false );
      _formData.append(`${ outputFileItemFormName }[${ index }][group_name]`, fileItem.group_name || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][helping_text]`, fileItem.helping_text || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][name]`, fileItem.name || '' );

      if(fileItem.file){
        _formData.append(`${ outputFileItemFormName }[${ index }][file]`, fileItem?.file || '');
      }
    })
    // END OUTPUT FILE ITEMS

    return _formData;
  }

  // ---------- END SUBMIT ----------
  const backButton = useMemo(() => {
    if(!requirement.is_public){
      return(
        <a
          href={ indexRequirementsPath(defaultRequestParams) }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>
      )
    }
  })

  return(
    <Form onSubmit={ onConfirmSubmit }>
      <div className="row">
        <div className="col-12">
          { complaintTypeFields() }

          { informerFields() }

          <WorkflowRequirementStageAttributes
            requirementStage={ requirement.current_requirement_stage }
            configuration={{
              formName: `${ formName }[current_requirement_stage_attributes]`,
              context: 'economic_crime'
            }}
            callbacks={{
              onChangeRequirementStage: onChangeRequirementStage
            }}
          />
        </div>
      </div>

      <FormGroup className='text-end'>
        { backButton }

        <Button
          color='success'
          className='ml-2'
          type='submit'
        >
          { I18n.t('actions.send') }
        </Button>
      </FormGroup>
    </Form>
  )
}

export default EconomicCrimeWorkflowRequirementAttributes;
