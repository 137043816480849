import React, { useState, useRef } from "react";
import ControldocFormTemplateLayoutForm from "../../../controldoc_form/template_layout/form";
import {
  create as createTemplateLayout,
  update as updateTemplateLayout,
  preview as previewTemplateLayout
} from "./axios";

const AbstractControldocFormTemplateLayoutForm = props => {
  return(
    <ControldocFormTemplateLayoutForm
      templateLayout={ props?.templateLayout || {} }
      imagePicker={ props?.imagePicker }
      configuration={{
        formName: 'abstract_controldoc_form_template_layout',
        context: 'abstract'
      }}
      actions={{
        createTemplateLayout: createTemplateLayout,
        updateTemplateLayout: updateTemplateLayout,
        previewTemplateLayout: previewTemplateLayout,
        showTemplateLayoutPath: Routes.abstract_controldoc_form_template_layout_path,
        indexTemplateLayoutPath: Routes.abstract_controldoc_form_template_layouts_path
      }}
      currentEntityType={ props?.currentEntityType || {} }
    />
  )
}

export default AbstractControldocFormTemplateLayoutForm;
