import React, { useContext } from 'react';

import WorkflowCustomEdge from './custom_edge';
import WorkflowCustomEdgeNoEditable from './custom_edge_no_editable';

import WorkflowDiagramContext from '../../diagram_context';

const BidirecionalEdge = edge => {
  const {
    readOnly
  } = useContext(WorkflowDiagramContext);

  const CustomEdgeComponent = readOnly ? WorkflowCustomEdgeNoEditable : WorkflowCustomEdge;

  return (
    <CustomEdgeComponent
      edge={ edge }
      config={{ type: 'bidirectional' }}
    />
  );
}

export default React.memo(BidirecionalEdge);
