import React, { useState, useEffect } from 'react';

import I18n from 'i18n-js';
import {
  FormGroup,
  Input
} from 'reactstrap';

import FieldError from '../../../helper/FieldError';

const AbstractFaoMappingFieldResourceAttributeItem = props => {
  const { resources, faoMappingField, handleResourceAttribute } = props;

  const [resourceAttributes, setResourceAttributes] = useState({});

  useEffect(() => {
    let _resourceAttributes = {};

    if(faoMappingField.resource != null){
      _resourceAttributes = faoMappingField?.resource?.values || {}
    }

    setResourceAttributes(_resourceAttributes)

  }, [faoMappingField])


  const drawOptGroups = () => {
    return _.map(resourceAttributes, (values, key) => {
      return(
        <optgroup
          label={ I18n.t(`fao.mapping_fields.form.opt_group.${ key }.one`) }
          key={ _.uniqueId('resource-attribute-group-') }
        >
          { drawOptions(values) }
        </optgroup>
      )
    })
  }

  const drawOptions = (options) => {
    return _.map(options, attribute => {
      return(
        <option
          value={ attribute.key }
          key={ _.uniqueId('resource-attribute-option-') }
        >
          { attribute.label }
        </option>
      )
    })
  }

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ faoMappingField?.errors?.resource_attribute || []}>
            <Input
              type="select"
              onChange={ e => handleResourceAttribute(e?.target?.value) }
              value={ faoMappingField?.resource_attribute || '' }
              invalid={ _.has(faoMappingField?.errors, 'resource_attribute') }
            >
              <option value=""></option>
              { drawOptGroups() }
            </Input>

          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

export default AbstractFaoMappingFieldResourceAttributeItem;

