import React, { useState } from 'react';
import I18n from 'i18n-js';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import FieldError from '../../helper/FieldError';

import { respond } from './axios';

const NpsResultModalForm = props => {

  const [npsResult, setNpsResult] = useState(props?.npsResult || {})

  const modalProps = { size: 'lg', contentClassName: 'nps-modal' }

  const optionNumbers = Array.from(
    {length: 10},
    (item, index) => item = index + 1
  );

  const handleNpsResult = ( event, key) => {
    let _npsResult = { ... npsResult };

    _npsResult[key] = event?.target?.value;

    setNpsResult(_npsResult);
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append('nps_result[result]', npsResult?.result || '');
    formData.append('nps_result[observations]', npsResult?.observations || '');

    return formData;
  }

  const handleRespond = event => {
    let formData = getFormData();

    respond(npsResult.id, formData, response => {
      if(response.status == 200){
        window.location = location.href
      } else {
        setNpsResult(response.data);
      }
    })
  }

  const drawImageOption = (value) => {
    if(npsResult.scale_config?.type == 'image'){
      return (<img className={ `nps_img_${ value }` } alt='' />)
    }
  }

  const drawLabelOption = (label) => {
    if(npsResult.scale_config?.translate){
      return I18n.t(`nps.records.scales.${ npsResult.scale }.${ label }`)
    } else {
      return label
    }
  }

  const drawRadioButtons = () => {
    return(
      <div className="row">
        <div className={ `col-12 nps-form nps-form-${ npsResult?.scale_config?.type } options-${ _.snakeCase(npsResult?.scale) }` }>
          { _.map(npsResult.scale_config?.values, function(value, label){
            return(
              <FormGroup className={ `form-check nps-form-check-${ npsResult?.scale_config?.type }` } key={ `nps_result_result_${ value }` }>
                <Input
                  type="radio"
                  name="nps_result[result]"
                  id={ `nps_result_result_${ value }` }
                  className={ `nps_input nps_result_result_${ value }` }
                  value={ value }
                  onClick={ e => handleNpsResult(e, 'result') }
                />

                <Label
                  for={ `nps_result_result_${ value }` }
                  className={ `form-check-label nps_label_${ value }` }
                >
                  <span>
                    { drawLabelOption(label) }
                  </span>
                  { drawImageOption(value) }
                </Label>
              </FormGroup>
            )
          })}
        </div>
      </div>
    )
  }

  const drawModalForm = () => {
    if(npsResult?.id){
      return(
        <Modal isOpen={ true } { ... modalProps }>
          <ModalHeader >{ npsResult?.nps_record?.name }</ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: npsResult?.nps_record?.description }}></div>
            { drawRadioButtons() }

            <FormGroup>
              <FieldError errors={ npsResult?.errors?.observations || [] }>
                <Label>
                  { I18n.t('activerecord.attributes.nps/result.observations') }
                </Label>
                <Input
                  type='textarea'
                  onChange={ e => handleNpsResult(e, 'observations') }
                  value={ npsResult?.observations || '' }
                  invalid={ _.has(npsResult?.errors, 'observations') }
                />
              </FieldError>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={ handleRespond }
            >
              Responder
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
  }

  return(
    <>{ drawModalForm() }</>
  )
};

export default NpsResultModalForm;
