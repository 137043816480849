import React, { useState, useEffect } from "react";
import I18n from "i18n-js";

const BootstrapTableSortHeader = props => {
  let { order, column } =  props;

  const drawSortCarets = () => {
    if(!order){
      return(
        <button className="btn btn-link m-0 py-0 px-2" type='button'>
          <i className="fas fa-sort"></i>
        </button>
      )
    }else if( order === 'asc'){
      return(
        <button className="btn btn-link m-0 py-0 px-2" type='button'>
          <i className="fas fa-sort-up"></i>
        </button>
      )
    }else if( order === 'desc'){
      return(
        <button className="btn btn-link m-0 py-0 px-2" type='button'>
          <i className="fas fa-sort-down"></i>
        </button>
      )
    }
  }

  return(
    <>
      { drawSortCarets() }
    </>
  )
}

export default BootstrapTableSortHeader;
