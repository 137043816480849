import React, {
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  Table,
  FormGroup,
  Button
} from 'reactstrap';

import I18n from 'i18n-js';
import Select from 'react-select';

import FieldError from '../../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../../helper/form'

import {
  update as updateService
} from './axios';

const LaboralShortlistMappingConfigForm = props => {
  const  {
    data
  } = props;

  const configuration = { formName: 'shortlist_mapping_config' }

  const {
    formName
  } = configuration

  const [shortlistMappingConfig, setShortlistMappingConfig] = useState(props.shortlistMappingConfig || {})

  const defaultRequestParams = {}

  const onChangeMappingField = (_mappingField, index) => {
    setShortlistMappingConfig(prevState => {
      return {
        ... prevState,
        mapping_fields: _.map(prevState.mapping_fields, (mf, i) => {
          if(index == i){
            mf = _mappingField
          }

          return mf
        })
      }
    })
  }

  const onConfirmSubmit = (event) => {
    swalWithBootstrap.fire({
      title: I18n.t('shortlist.mapping_configs.form.confirm.title'),
      html: I18n.t('shortlist.mapping_configs.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const mappingConfigFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, shortlistMappingConfig.id || '');

    _.each(shortlistMappingConfig.mapping_fields, (mappingField, index) => {
      const mappingFieldFormName = `${ formName }[mapping_fields_attributes][${ index }]`;

      formData.append(`${ mappingFieldFormName }[id]`, mappingField.id || '');
      formData.append(`${ mappingFieldFormName }[shortlist_var]`, mappingField.shortlist_var || '');
      formData.append(`${ mappingFieldFormName }[resource_class]`, mappingField.resource_class || '');
      formData.append(`${ mappingFieldFormName }[resource_attribute]`, mappingField.resource_attribute || '');
    })

    return formData
  }

  const onSubmit = () => {
    const formData = mappingConfigFormData();

    updateService(defaultRequestParams, formData, response => {
      if(response.status == 200){
        window.location = Routes.edit_laboral_shortlist_mapping_configs_path();
      } else {
        setShortlistMappingConfig(response.data);
      }
    })
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-material">
          <div className="card-body">
            <Table>
              <thead>
                <tr>
                  <th>Campo Shortlist</th>
                  <th>Recurso</th>
                  <th>Atributo</th>
                </tr>
              </thead>
              <tbody>
                { _.map(shortlistMappingConfig.mapping_fields, (mappingField, index) => {
                  return(
                    <LaboralShortlistMappingConfigFields
                      key={ `LaboralShortlistMappingConfigFields-${ mappingField.shortlist_var }` }
                      mappingField={ mappingField }
                      configuration={{...configuration, formName: `${ configuration.formName }[mapping_fields_attributes][${ index }]`} }
                      data={ data }
                      index={ index }
                      callbacks={{
                        onChangeMappingField: onChangeMappingField
                      }}
                    />
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="text-end">
          <Button
            color='success'
            onClick={ e => onConfirmSubmit() }
          >
            { I18n.t('actions.save') }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LaboralShortlistMappingConfigForm;

const LaboralShortlistMappingConfigFields = props => {

  const {
    mappingField,
    index,
    configuration,
    data: {
      resources: fieldResources
    },
    callbacks: {
      onChangeMappingField: callbackOnChangeMappingField
    }
  } = props;

  const onChangeMappingField = (event, key) => {
    let _mappingField = { ... mappingField }

    _mappingField[key] = event.target.value;

    if(key == 'resource_class') {
      _mappingField.resource_attribute = null;
    }

    callbackOnChangeMappingField(_mappingField, index)
  }

  return(
    <tr>
      <td>
        { I18n.t(`activerecord.attributes.shortlist/mapping_field.${ mappingField.shortlist_var }`) }
      </td>
      <td>
        <LaboralShortlistMappingFieldResourceClassItem
          mappingField={ mappingField }
          resources={ fieldResources }
          configuration={ configuration }
          callbacks={{
            onChangeMappingField: onChangeMappingField
          }}
        />
      </td>
      <td>
        <LaboralShortlistMappingFieldResourceAttributeItem
          mappingField={ mappingField }
          resources={ fieldResources }
          configuration={ configuration }
          callbacks={{
            onChangeMappingField: onChangeMappingField
          }}
        />
      </td>
    </tr>
  )
}

const LaboralShortlistMappingFieldResourceClassItem = props => {
  const {
    mappingField,
    resources,
    configuration: {
      formName
    },
    callbacks: {
      onChangeMappingField: callbackOnChangeMappingField
    }
  } = props;

  const onChangeResourceClass = (event) => {
    callbackOnChangeMappingField(event, 'resource_class')
  }

  const resourceClassSelectOptions = useMemo(() => {
    return _.map(resources, resource => {
      return {
        label: I18n.t(`activerecord.attributes.laboral/shortlist/mapping_field.resources.${ resource.name }`),
        value: resource.resource_class
      }
    })

  }, [resources])

  const resourceClassSelectedOption = useMemo(() => {
    return _.find(resourceClassSelectOptions, { value: mappingField.resource_class } )
  }, [mappingField.resource_class])

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ _.get(mappingField, 'errors.resource_class') || [] }>
            <Select
              id={ inputId(formName, 'resource_class') }
              name={ inputName(formName, 'resource_class') }
              placeholder={ '-- Selecciona un recurso --' }
              options={ resourceClassSelectOptions }
              onChange={ e => onChangeResourceClass({target: { value: e.value }})}
              value={ resourceClassSelectedOption }
              isClearable={ true }
            />
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

const LaboralShortlistMappingFieldResourceAttributeItem = props => {
  const {
    mappingField,
    resources,
    configuration: {
      formName
    },
    callbacks: {
      onChangeMappingField: callbackOnChangeMappingField
    }
  } = props;

  const onChangeResourceAttribute = (event) => {
    callbackOnChangeMappingField(event, 'resource_attribute')
  }

  const resourceAttributeSelectOptions = useMemo(() => {
    const resource = _.find(resources, { resource_class: mappingField.resource_class });

    if(resource){
      return _.map(resource.values, (data, key) => {
        return {
          label: I18n.t(`shortlist.mapping_fields.form.opt_group.${ key }.one`),
          options: _.map(data, attribute => {
            return {
              label: attribute.label,
              value: attribute.key
            }
          })
        }
      })
    } else {
      return []
    }
  }, [mappingField.resource_class]);

  const resourceAttributeSelectedOption = useMemo(() => {
    const options = _.flatMap(resourceAttributeSelectOptions, 'options')

    return _.find(options, { value: mappingField.resource_attribute }) || null
  }, [mappingField.resource_attribute])

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ _.get(mappingField, 'errors.resource_attribute') || [] }>
            <Select
              id={ inputId(formName, 'resource_attribute') }
              name={ inputName(formName, 'resource_attribute') }
              placeholder={ '-- Selecciona un atributo --' }
              options={ resourceAttributeSelectOptions }
              onChange={ e => onChangeResourceAttribute({target: { value: e.value }})}
              value={ resourceAttributeSelectedOption }
              isClearable={ true }
            />
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}
