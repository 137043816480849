import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";
import { Label, Button, FormGroup, Input } from "reactstrap";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";

import { index as indexLaboralControldocFormDocuments } from "../../../controldoc_form/document/axios";
import { scrollToTop, inputId, inputName, limitCharacters } from "../../../../helper/form";

import BulkNotificationFillControldocFormDocumentList from "../../../../bulk/notification/fill/controldoc_form_document_list";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";

const LaboralBulkNotificationFillForm = props => {
  const {
    currentCustomer,
    securityLayers,
    data: {
      companies,
      documentTypes,
      customFields
    }
  } = props;

  const actionsProps = {
    indexFormDocuments: indexLaboralControldocFormDocuments,
  }

  const documentTypeOptions = _.sortBy(_.uniqBy(_.map(documentTypes, dt => dt), "id"));
  const defaultRequestParams = {};
  const bootstrapTableRef = useRef(null);

  const tableColumnSecurityLayer = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `document.entity.employee.security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        filterByFilterrific: `by_employee_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const tablecolumnsDefault = [
    {
      dataField: "document.entity.company.name",
      myFilter: "company.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
      filterByFilterrific: "by_company",
      sortByFilterrific: "company_name_",
      filter: selectFilter({
        placeholder: `Seleccione una ${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name,
        })),
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "email",
      text: `${I18n.t("activerecord.attributes.laboral/employee.email")}`,
      filterByFilterrific: "search_by_email",
      sortByFilterrific: "email_",
      myFilter: "controldoc_form_document.email",
      filter: textFilter({
        placeholder: `Escriba un ${I18n.t("activerecord.attributes.laboral/employee.email")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "name",
      text: `${I18n.t("activerecord.attributes.controldoc_form/template.name")} formulario`,
      filterByFilterrific: "search_by_name",
      sortByFilterrific: "name_",
      myFilter: "controldoc_form_document.name",
      filter: textFilter({
        placeholder: `Ingrese un ${I18n.t("activerecord.attributes.controldoc_form/template.name")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.entity.employee.name",
      text: `${ I18n.t("activerecord.attributes.laboral/employee.name")}`,
      filterByFilterrific: "search_by_employee_name",
      myFilter: "employee.name",
      filter: textFilter({
        placeholder: `Ingrese un ${ I18n.t("activerecord.attributes.laboral/employee.name") }`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sort: false,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.entity.identifier",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.identifier")}`,
      filterByFilterrific: "search_by_hiring_identifier",
      sortByFilterrific: "hiring_identifier_",
      myFilter: "hiring.identifier",
      filter: textFilter({
        placeholder: `Ingrese el identificador de la ${I18n.t("activerecord.attributes.laboral/hiring")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.entity.active_to_s",
      text: `Estado de Contratación`,
      filterByFilterrific: "by_hiring_state",
      myFilter: 'hiring.active_to_s',
      filter: selectFilter({
        placeholder: "Seleccione un Estado de Contratación",
        options: [
          { value: 'active', label: "Activo" },
          { value: 'expired', label: "Inactivo" },
        ],
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.entity.start_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`,
      filterByFilterrific: "search_by_hiring_start_date",
      myFilter: 'hiring.start_date_to_s',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.entity.end_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`,
      filterByFilterrific: "search_by_hiring_end_date",
      myFilter: 'hiring.end_date_to_s',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.label",
      text: `${I18n.t("activerecord.attributes.document.label")}`,
      filterByFilterrific: "search_by_document_name",
      myFilter: "document.label",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.document.label")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "document.document_type.name",
      text: `${I18n.t("activerecord.attributes.document.document_type")}`,
      filterByFilterrific: "by_document_type",
      myFilter: "document_type.name",
      filter: selectFilter({
        placeholder: `Seleccione un ${I18n.t("activerecord.attributes.document.document_type")}`,
        options: _.map(documentTypeOptions, type => ({
          value: type.id,
          label: type.name,
        })),
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: `${I18n.t("activerecord.attributes.controldoc_form/document.created_at")}`,
      sortByFilterrific: "created_at_",
      filterByFilterrific: "search_by_created_at",
      myFilter: "controldoc_form_document.created_at_to_s",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.controldoc_form/document.created_at")}`
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
        />
      ),
    },
  ];

  const tableColumnCustomFields = () => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `document.entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `hiring.custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }}
          />
        ),
      };
    });
  };

  const tableColumns = _.concat(
    tableColumnSecurityLayer(),
    tablecolumnsDefault,
    tableColumnCustomFields()
  );

  return (
    <div className="row">
      <div className="col-12">
        <Label className="d-flex justify-content-center h5 my-4">
          Lista de formularios pendientes por completar
        </Label>
        <BulkNotificationFillControldocFormDocumentList
          bootstrapTableConfiguration={{
            ref: bootstrapTableRef,
            tableColumns: tableColumns,
          }}
          configuration={
            defaultRequestParams
          }
          actions={
            actionsProps
          }
        />
      </div>
    </div>
  );
};

export default LaboralBulkNotificationFillForm;
