import React, { useState } from 'react';

const useFilterrific = (filterrific) => {
  const [state, setState] = useState({...filterrific})

  const setFilterrific = (type, filters, matchColumnsWithFilterrific) => {

    let _filterrific = {...state}

    if(type == 'filter'){
      _.each(matchColumnsWithFilterrific?.filters || {}, function(value, key){
        let filterVal = filters.filters[key]

        // En caso de ser un CustomField, se debe operar la información de forma distinta
        if(_.includes(key, "custom_fields.")){
          let customFieldKey = _.last(_.split(key, "."));
          let customFieldQuery = {};

          // Para cuando se borra la busqueda de un customField este no de error y retorne nueva información
          // se daba el caso en que se mantenía la información anterior
          customFieldQuery[customFieldKey] = filterVal?.filterVal || "";

          _filterrific["search_by_key_custom_fields"] = _.merge(
            _filterrific["search_by_key_custom_fields"],
            customFieldQuery
          );
        } else if(value && _filterrific[value] != filterVal?.filterVal ){
          _filterrific[value] = filterVal?.filterVal || ''
        }
      })
    } else if(type == 'sort'){
      let sortBy = matchColumnsWithFilterrific['sort'][filters.sortField]

      if(sortBy){
        _filterrific['sorted_by'] = `${ sortBy }${ filters.sortOrder }`
      }
    }

    if(!_.isEqual(state, _filterrific)){
      setState(_filterrific);
    }
  }

  return [state, setFilterrific];
}

export default useFilterrific;
