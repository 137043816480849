import React, {
  useState,
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import I18n from 'i18n-js';

import {
  inputId,
  inputName,
} from '../../helper/form';

import FieldError from '../../helper/FieldError';

import EntityFolderCheckListRequirementListForm from '../../entity_folder/check_list_requirement/list_form'

const EntityFolderCheckListAttributes = props => {
  const [folder, setFolder] = useState(props?.folder || {})

  const {
    data,
    configuration: {
      formName,
      defaultRequestParams,
      entity: entityConfiguration
    },
    actions: {
      showEntityPath,
      createFolder,
      updateFolder
    }
  } = props;

  const onChangeFolder = (event, key) => {
    let value = event.target.value;

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }

    setFolder(prevState => {
      return { ... prevState, [key]: value}
    })
  }

  const entityInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ [] }>
          <Label>
            { entityConfiguration?.label }
          </Label>
          <Input
            value={ folder?.entity?.name }
            disabled={ true }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const parentFolderInput = () => {
    if(folder.parent_folder_id){
      return(
        <FormGroup>
          <FieldError errors={ [] }>
            <Label>
              { I18n.t('activerecord.attributes.entity_folder/check_list.parent_folder_id') }
            </Label>
            <Input
              value={ folder?.parent_folder?.name }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(folder, 'errors.name') || [] }>
          <Label for={ inputId(formName, 'name') } className='required'>
            { I18n.t('activerecord.attributes.entity_folder/check_list.name') }
          </Label>
          <Input
            value={ folder?.name || '' }
            onChange={ event => onChangeFolder(event, 'name') }
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            invalid={ _.has(folder, 'errors.name')}
          />
        </FieldError>
      </FormGroup>
    )
  }

  const allowNewRequirementsSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ _.get(folder, 'errors.allow_new_requirements') || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'allow_new_requirements') }
              id={ inputId(formName, 'allow_new_requirements') }
              invalid={ _.has(folder, 'errors.allow_new_requirements') }
              onChange={ event => onChangeFolder(event, 'allow_new_requirements') }
              checked={ folder?.allow_new_requirements || false }
            />
             <Label
                className='custom-control-label'
                for={ inputId(formName, 'allow_new_requirements') }
              >
                { I18n.t(`activerecord.attributes.entity_folder/check_list.allow_new_requirements`) }
              </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }

  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('entity_folder.check_lists.form.confirm.title'),
      html: I18n.t('entity_folder.check_lists.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit()
      }
    })
  }

  const onSubmit = () => {
    const formData = getFormData();

    if(folder.id){
      const requestParams = { ... defaultRequestParams, id: folder.id };
      updateFolder(requestParams, formData, response => {
        if(response.status == 200){
          window.location = showEntityPath({ ... defaultRequestParams, id: folder.entity.hashid, _options: true });
        } else {
          setFolder(response.data);
        }
      })
    } else {
      createFolder(defaultRequestParams, formData, response => {
        if(response.status == 201){
          window.location = showEntityPath({ ... defaultRequestParams, id: folder.entity.hashid, _options: true });
        } else {
          setFolder(response.data);
        }
      })
    }
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, folder.id || '')
    formData.append(`${ formName }[entity_id]`, folder.entity_id || '')
    formData.append(`${ formName }[name]`, folder.name || '')
    formData.append(`${ formName }[allow_new_requirements]`, folder.allow_new_requirements || false);
    formData.append(`${ formName }[parent_folder_id]`, folder.parent_folder_id || '');

    _.each(folder.requirements, (requirement, index) => {
      const formNameRequirement = `${ formName }[requirements_attributes][${ index }]`;

      formData.append(`${ formNameRequirement }[id]`, requirement.id || '');
      formData.append(`${ formNameRequirement }[document_type_id]`, requirement.document_type_id || '');
      formData.append(`${ formNameRequirement }[name]`, requirement.name || '');

      formData.append(`${ formNameRequirement }[require_deadline_upload]`, requirement.require_deadline_upload || false);
      formData.append(`${ formNameRequirement }[deadline_upload_notification_at]`, requirement.deadline_upload_notification_at || '');

      formData.append(`${ formNameRequirement }[deadline_upload_at]`, requirement.deadline_upload_at || '');

      formData.append(`${ formNameRequirement }[is_recurrent]`, requirement.is_recurrent || false);
      formData.append(`${ formNameRequirement }[recurrence_period]`, requirement.recurrence_period || '');
      formData.append(`${ formNameRequirement }[recurrence_start_at]`, requirement.recurrence_start_at || '');
      formData.append(`${ formNameRequirement }[recurrence_end_at]`, requirement.recurrence_end_at || '');

      formData.append(`${ formNameRequirement }[recurrence_deadline_upload_days]`, requirement.recurrence_deadline_upload_days || '');
      formData.append(`${ formNameRequirement }[allow_clone]`, requirement.allow_clone || false);
      formData.append(`${ formNameRequirement }[_destroy]`, requirement._destroy || false);
    })

    return formData;
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-material">
          <div className="card-body">
            { entityInput() }
            { parentFolderInput() }
            { nameInput() }

            { allowNewRequirementsSwitchInput() }
          </div>
        </div>

        <div className="card card-material">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                { I18n.t('activerecord.models.entity_folder/check_list_requirement.other') }
              </div>
            </div>
          </div>

          <div className="card-body">
            <EntityFolderCheckListRequirementListForm
              folder={ folder }
              requirements={ folder.requirements }
              data={ data }
              configuration={{
                formName: `${ formName }[requirement_attributes]`,
                entity: {
                  hideInput: true
                },
                folder: {
                  hideInput: true
                }
              }}
              callbacks={{
                callbackSetFolder: setFolder
              }}
            />
          </div>
        </div>

        <div className="text-end">
          <a
            href={ showEntityPath({ ... defaultRequestParams, id: folder.entity.hashid, _options: true }) }
            className='btn btn-default'
          >
            { I18n.t('actions.back') }
          </a>

          <Button
            color='success'
            className='ml-2'
            onClick={ event => onConfirmSubmit() }
          >
            { I18n.t('actions.save') }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EntityFolderCheckListAttributes;
