import I18n from 'i18n-js';

export const alertConfirmDelete = () => {
  let alert = {
    title: I18n.t('laboral.bulk.load.employees.form.config.delete.title'),
    text: I18n.t('laboral.bulk.load.employees.form.config.delete.text'),
    showCancelButton: true,
    customClass: {
      confirmButton: 'swal2-confirm btn btn-success ml-3',
      cancelButton: 'swal2-cancel btn btn-secondary'
    },
    buttonsStyling: false,
    confirmButtonText: `<i class='fa fa-thumbs-up'></i> ${ I18n.t('laboral.bulk.load.employees.form.config.delete.confirm') }`,
    cancelButtonText: `<i class='fa fa-thumbs-down'></i> ${ I18n.t('laboral.bulk.load.employees.form.config.delete.cancel') }`,
    reverseButtons: true
  }
  return alert;
}

export const getDataFilter = (_data, state) => {
  return _.filter(_data, { 'is_deleted': false, 'state': state })
}
