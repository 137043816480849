import axios from 'axios';

export const create = (formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.laboral_bulk_load_hiring_documents_path({ 'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise.then(response => {
    callback(response);
  }).catch(error => {
    callback(error.response);
  });
}
