import React, { useEffect, useState } from 'react'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap'
import I18n from 'i18n-js';

const ControldocFormTemplateMappingLinkResourceItem = props => {
  const { resources, mappingLink, formName, abstractModule } = props;

  const handleResource = event => {
    let value = event?.target?.value;

    if(typeof props?.handleResource === 'function'){
      props.handleResource(value);
    }
  }

  const drawLabelOption = resource => {
    if(_.includes(resource.resource_class, "Abstract::Entity")){
      return resource.name
    } else {
      return I18n.t(`controldoc_form.template_mapping_links.form.resource.${ resource.name }.one`)
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <Input
          type='select'
          onChange={ e => handleResource(e) }
          value={ mappingLink.resource || '' }
          name={ `${ formName }[resource]` }
        >
        <option value="">
          { I18n.t('inputs.select_a_option') }
        </option>
        { _.map(resources, resource => {
          return(
            <option
              value={ resource.resource_class }
              key={ _.uniqueId('resource-item-') }
            >
              { drawLabelOption(resource) }
            </option>
          )
        })}
        </Input>
      </div>
    </div>
  )
}

export default ControldocFormTemplateMappingLinkResourceItem;
