import React, {
  useEffect,
  useCallback,
  useContext,
  useState
} from 'react';

import {
  Button,
  FormGroup
} from 'reactstrap';

import I18n from "i18n-js";

// Start DragAndDrop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
// End DragAndDrop

import CustomFieldOrderForm from './order_form';

const CustomFieldOrderFormList = React.memo((props) => {
  const [customFields, setCustomFields] = useState(props?.customFields || {} )
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false)

  const {
    formName,
    defaultRequestParams,
    actions: {
      indexCustomFieldsPath,
      updateOrderCustomFields
    }
  } = props;

  const setOrderCustomField = useCallback((customFields) => {
    let order = 0;
    _.each(customFields, function(customField){
      if(customField._destroy){
        customField.order = 0;
      } else {
        customField.order = order;
        order += 1;
      }
    })

    return customFields;
  }, [])

  // Start DndProvider
  const handleMoveCustomField = useCallback((dragIndex, hoverIndex) => {
    setCustomFields(prevState => {
      let _customFields = [ ... prevState ];

      const dragCustomField = _customFields[dragIndex];
      _customFields = update(_customFields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCustomField],
        ],
      });

      setOrderCustomField(_customFields)

      return [..._customFields]
    })
  }, []);
  // End DndProvider

  const drawCustomFields = () => {
    if(customFields.length > 0){
      return(
        <DndProvider backend={HTML5Backend}>
          { _.map(customFields, function(customField, index){
            return(
              <CustomFieldOrderForm
                key={ `customFieldForm-${ index }` }
                currentEntityType={ props?.currentEntityType }
                customField={ customField }
                index={ index }
                handleMoveCustomField={ handleMoveCustomField }
                formName={ `${ formName }[${ index }]`}
              />
            )
          })}
        </DndProvider>
      )
    } else {
      return(
        <div className="alert alert-primary text-center">
          <i className="fas fa-info-circle mr-2" aria-hidden="true" title="Información"></i>
          <span>Aún no has agregado Campos personalizados</span>
        </div>
      )
    }
  }

  const onSubmitConfirm = () => {
    swalWithBootstrap.fire({
      title: I18n.t("laboral.custom_fields.order.form.confirm.title"),
      html: I18n.t("laboral.custom_fields.order.form.confirm.message"),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSubmit = () => {
    let _formData = formData();

    setSubmitButtonIsDisabled(true);
    updateOrderCustomFields(defaultRequestParams, _formData, response => {
      if(response.status == 200 || response.status == 201){
        window.location = indexCustomFieldsPath({...defaultRequestParams, _options: true});
      }
    })
  }

  const submitButtonText = () => {
    if(submitButtonIsDisabled){
      return "Guardando información ...";
    } else {
      return I18n.t("actions.save");
    }
  }

  const formData = () => {
    let _formData = new FormData();

    _.each(customFields, (customField, index) => {
      // formData.append(`${ formName }[${index}][id]`, customField?.id || "");
      _formData.append(`${ formName }[${customField.id}][order]`, index || 0);
    })

    return _formData;
  }

  return(
    <div className='container'>
      <div className="row">
        <div className="col-12">
          { drawCustomFields() }
        </div>
      </div>
      <FormGroup>
        <div className="col-12 text-end mt-3">
          <a
            href={  `${indexCustomFieldsPath({...defaultRequestParams})}` }
            className="btn btn-default"
          >
            { I18n.t("actions.back") }
          </a>

          <Button
            type="button"
            className="ml-2"
            color="success"
            onClick={ onSubmitConfirm }
            disabled={ submitButtonIsDisabled }
          >
            { submitButtonText() }
          </Button>
        </div>
      </FormGroup>
    </div>
  )
});

export default CustomFieldOrderFormList;
