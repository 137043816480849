import React, { useMemo } from 'react';

import {
  ReactFlowProvider,
} from "reactflow";

import "reactflow/dist/style.css";

import WorkflowBasicFlow from '../../../workflow/basic_flow';

import {
  create as createStage,
  update as updateStage,
  destroy as destroyStage
} from '../stage/axios';

import {
  create as createEdge,
  update as updateEdge,
  destroy as destroyEdge
} from '../edge/axios';

import {
  index as indexGroup
} from '../group/axios';

const AbstractWorkflowBasicForm = props => {

  const {
    currentUser,
    currentCustomer,
    currentEntityType,
    workflowProcess,
    data
  } = props;

  const processDefaultRequestParams = { current_entity_type_id: currentEntityType.hashid, id: workflowProcess.hashid }
  const stageDefaultRequestParams = { current_entity_type_id: currentEntityType.hashid, process_id: workflowProcess.hashid }
  const edgeDefaultRequestParams = { current_entity_type_id: currentEntityType.hashid, process_id: workflowProcess.hashid }
  const groupDefaultRequestParams = { current_entity_type_id: currentEntityType.hashid, process_id: workflowProcess.hashid }

  const requirementPublicPath = Routes.new_public_abstract_workflow_requirement_path({
    customer_id: currentCustomer.hashid,
    process_id: workflowProcess.hashid
  });

  return(
    <div className="vh-100">
      <ReactFlowProvider>
        <WorkflowBasicFlow
          data={ data }
          workflowProcess={ workflowProcess }
          currentCustomer={ currentCustomer }
          currentEntityType={ currentEntityType }
          configuration={{
            context:'abstract',
            process: {
              defaultRequestParams: processDefaultRequestParams,
              formName: 'workflow_process'
            },
            stage: {
              defaultRequestParams: stageDefaultRequestParams,
              formName: 'workflow_stage',
            },
            edge: {
              defaultRequestParams: edgeDefaultRequestParams,
              formName: 'workflow_edge',
            },
            group: {
              defaultRequestParams: groupDefaultRequestParams,
            },
            entity: {
              label: currentEntityType?.singular_name
            }
          }}
          services={{
            stage: {
              createService: createStage,
              updateService: updateStage,
              destroyService: destroyStage
            },
            edge: {
              createService: createEdge,
              updateService: updateEdge,
              destroyService: destroyEdge
            },
            group: {
              indexService: indexGroup
            }
          }}
          routes={{
            requirementPublicPath: requirementPublicPath
          }}
        />
      </ReactFlowProvider>
    </div>
  )
}

export default AbstractWorkflowBasicForm;
