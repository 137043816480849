import React, { useState } from 'react';

import I18n from 'i18n-js';
import {
  Label,
  Input,
  FormGroup
} from 'reactstrap';

import FieldError from '../../../helper/FieldError';

const AbstractFaoMappingFieldResourceClassItem = props => {
  const { resources, faoMappingField } = props;


  const handleResourceClass = (event) => {
    let value = event?.target.value;

    let _resource = _.find(resources, { name: value })

    if(_.isFunction(props?.handleResourceClass)){
      props?.handleResourceClass(_resource)
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ faoMappingField?.errors?.resource_class || []} >
            <Input
              type="select"
              onChange={ e => handleResourceClass(e) }
              value={ faoMappingField?.resource?.name }
              invalid={ _.has(faoMappingField?.errors, 'resource_class') }
            >
              <option value=""></option>
              { _.map(resources, function(resource){
                return(
                  <option
                    value={ resource.name }
                    key={ _.uniqueId('resource-option-') }
                  >{ resource.name }</option>
                )
              })}
            </Input>
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

export default AbstractFaoMappingFieldResourceClassItem;
