import axios from 'axios';

export const create = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.abstract_bulk_request_zip_documents_path({ ... {...requestParams}, format: 'json' }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise.then(response => {
    callback(response);
  }).catch(error => {
    callback(error.response);
  });
}