import React, { useState, useMemo, useEffect } from 'react';

import BootstrapTable from "react-bootstrap-table-next";
import { Spinner } from "reactstrap";
import LaboralBulkMyFilterForm from "../../laboral/bulk/my_filter/form";
import LaboralBulkMyFilterList from "../../laboral/bulk/my_filter/list";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import I18n from 'i18n-js/index.js.erb'

const BootstrapTableCustomFilter = (props) => {
  const { ExportCSVButton } = CSVExport;
  let {
    showBulkMyFilter,
    remote,
    keyField,
    selectRow,
    data,
    filter,
    columns,
    pagination,
    classes,
    onTableChange,
    // Filas seleccionadas para bulk notification sign
    selectedRows,
    _ref,
    configuration,
    fetchParamsConfig
  } = props;

  const [showSearchFilters, setShowSearchFilters] = useState(showBulkMyFilter)
  const [requireMyFiltersRefresh, setRequireMyFiltersRefresh] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({})
  const [spinner, setSpinner] = useState(false)

  let currFilters = _ref?.current?.filterContext?.currFilters;

  const handleShowSearchFilters = (e) => {
    setShowSearchFilters(!showSearchFilters)
  }

  const isSelectedFilter = useMemo(()=>{
    return !_.isEmpty(selectedFilter)
  }, [selectedFilter])

  const drawBulkMyFilter = () => {
    if(showBulkMyFilter){
      return(
        <div className="card bg-real-light">
          <div className="card-body">
            <div className="row d-flex align-items-center justify-content-between">
              <div className='col-md-8 text-start'>
                <LaboralBulkMyFilterList
                  bootstrapTableRef={_ref}
                  currFilters={ currFilters }
                  fetchParamsConfig={ fetchParamsConfig }
                  selectedFilter={ selectedFilter }
                  setSpinner={ setSpinner }
                  setShowSearchFilters={ setShowSearchFilters }
                  handleShowSearchFilters={ handleShowSearchFilters }
                  setSelectedFilter={ setSelectedFilter }
                  requireMyFiltersRefresh={ requireMyFiltersRefresh }
                  setRequireMyFiltersRefresh={ setRequireMyFiltersRefresh }
                  />
              </div>
              <div className='col-md-4 text-end'>
                <LaboralBulkMyFilterForm
                  bootstrapTableRef={_ref}
                  currFilters={ currFilters }
                  isSelectedFilter={ isSelectedFilter }
                  selectedFilter={ selectedFilter }
                  setSelectedFilter={ setSelectedFilter }
                  setRequireMyFiltersRefresh={ setRequireMyFiltersRefresh }
                />
              </div>
            </div>
            { messageSelectedRowsOnFilter() }
          </div>
        </div>
      )
    }
  }

  const messageSelectedRowsOnFilter = () => {
    if(isSelectedFilter){
      return (
        <div className="row mt-2 text-info">
          <div className="col-md-12">
            <small>
              Se ha aplicado la lista de distribución
              <strong className='px-1'>{ selectedFilter?.name }</strong>
              y se han seleccionado {
                selectRow?.selected?.length
              } items
            </small>
          </div>
        </div>
      )
    }
  }

  const drawSpinner = () => {
    if( spinner ){
      return (
        <div className="d-flex align-items-center justify-content-center overlay-bg-dark">
          <Spinner
            color="primary"
          />
        </div>
      )
    }
  }

  const onButtonFilters = () => {
    if(!showBulkMyFilter){
      return (
        <button
          type='button'
          onClick={ e => handleShowSearchFilters(e) }
          className='btn btn-success mb-2'
        >
          { showSearchFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
        </button>
      )
    }
  }

  const exportToCsvButton = (csvProps) => {
    if(configuration?.isExportedCsv && selectedRows?.length > 0){
      const handleClick = () => {
        csvProps.onExport(selectedRows);
      };
      return (
        <div className='mr-2'>
          <a className="btn btn-outline-primary mr-2" onClick={ handleClick }>{ `${I18n.t('actions.download')} excel`}</a>
        </div>
      )
    }
  };

  return(
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      exportCSV={ {
        onlyExportSelection: true,
        exportAll: true,
        fileName: `${configuration?.filenameCsv}.xlsx`,
      }}
    >
      {
        toolkitProps => (
          <div className="row">
            { drawSpinner() }
            <div className="col-12">
              { drawBulkMyFilter() }
            </div>
              <div className="d-flex justify-content-end mb-3">
                { exportToCsvButton(toolkitProps.csvProps) }
                { onButtonFilters() }
              </div>
            <div className="col-12">
              <BootstrapTable
                { ... {remote,
                  keyField,
                  selectRow,
                  filter,
                  pagination,
                  classes,
                  onTableChange
                  }
                }
                {...toolkitProps.baseProps}
                ref={ _ref }
                showSearchFilters={ showSearchFilters }
                handleShowSearchFilters={ handleShowSearchFilters }
              />
            </div>
          </div>
        )
      }
    </ToolkitProvider>
  )
}


export default BootstrapTableCustomFilter;
