import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";
import { Label, Button, FormGroup, Input } from "reactstrap";

import BulkNotificationSignSignerList from "../../../../bulk/notification/sign/signer_list";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  pageListRenderer,
  options as optionPagination,
} from "../../../../helper/pagination";

// import { create } from "./axios";
import { index as indexLaboralSigners } from "../../../../laboral/signer/axios";
import { scrollToTop, inputId, inputName, limitCharacters } from "../../../../helper/form";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";
import useFilterrific from "../../../../hooks/useFilterrific";

import useFilterRequestParams from "../../../../hooks/useFilterRequestParams";
import { defaultTableHeaderStyleWhite } from "../../../../helper/bootstrap-table/helper";

const LaboralBulkNotificationSignForm = props => {
  const formName = "laboral_bulk_notification_sign";
  // const signers = props?.signers || [];
  const [signers, setSigners] = useState([]);
  const documentTypes = props?.documentTypes;
  const companies = _.sortBy(props.companies);
  const [selectedSigners, setSelectedSigners] = useState([]);
  const [statesFilters, setStateFilters] = useState([]);

  const {currentCustomer, securityLayers} = props;

  // Pagination
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(signers?.length || 10);
  const [filterrific, setFilterrific] = useFilterrific({...filterrific});
  const [perPage, setPerPage] = useState(10);

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const bootstrapTable = useRef(null);

  useEffect(() => {
    getSigners();
  }, [requestFilterParams]);

  const getSigners = () => {
    let _requestFilterParams = { ...requestFilterParams }
    requestFilterParams['filterrific']['by_aasm_state'] = 'pending'

    indexLaboralSigners(_requestFilterParams, response => {
      setSigners(response.data.signers);
      setTotalSize(response.data.total);
    });
  };

  const getEmployeeSecurityLayerColumn = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `document.entity.employee.security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        headerStyle: defaultTableHeaderStyleWhite,
        filterByFilterrific: `by_employee_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const columns = [
    {
      dataField: "document.entity.company.name",
      myFilter: "company.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
      filterByFilterrific: "by_company",
      sortByFilterrific: "company_name_",
      filter: selectFilter({
        placeholder: `Seleccione una ${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name,
        })),
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "email",
      text: `${I18n.t("activerecord.attributes.signer.email")} firmante`,
      filterByFilterrific: "search_by_email",
      sortByFilterrific: "email_",
      myFilter: "signer.email",
      filter: textFilter({
        placeholder: `Escriba un ${I18n.t("activerecord.attributes.signer.email")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "name",
      text: `${I18n.t("activerecord.attributes.signer.name")} firmante`,
      filterByFilterrific: "search_by_name",
      sortByFilterrific: "name_",
      myFilter: "signer.name",
      filter: textFilter({
        placeholder: `Ingrese un ${I18n.t("activerecord.attributes.signer.name")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "role",
      text: `${ I18n.t("activerecord.attributes.signer.role") }`,
      filterByFilterrific: "search_by_role",
      myFilter: "signer.role",
      filter: textFilter({
        placeholder: `Ingrese un ${ I18n.t("activerecord.attributes.signer.role") }`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.employee.name",
      text: `${ I18n.t("activerecord.attributes.laboral/employee.name")}`,
      filterByFilterrific: "search_by_employee_name",
      myFilter: "employee.name",
      filter: textFilter({
        placeholder: `Ingrese un ${ I18n.t("activerecord.attributes.laboral/employee.name") }`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      sort: false,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.identifier",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.identifier")}`,
      filterByFilterrific: "search_by_hiring_identifier",
      sortByFilterrific: "hiring_identifier_",
      myFilter: "hiring.identifier",
      filter: textFilter({
        placeholder: `Ingrese el identificador de la ${I18n.t("activerecord.attributes.laboral/hiring")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.active_to_s",
      text: `Estado de Contratación`,
      filterByFilterrific: "by_hiring_state",
      myFilter: 'hiring.active_to_s',
      filter: selectFilter({
        placeholder: "Seleccione un Estado de Contratación",
        options: [
          { value: 'active', label: "Activo" },
          { value: 'expired', label: "Inactivo" },
        ],
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.start_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`,
      filterByFilterrific: "search_by_hiring_start_date",
      myFilter: 'hiring.start_date_to_s',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.entity.end_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`,
      filterByFilterrific: "search_by_hiring_end_date",
      myFilter: 'hiring.end_date_to_s',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document.label",
      text: `${I18n.t("activerecord.attributes.document.label")}`,
      filterByFilterrific: "search_by_document_name",
      myFilter: "document.label",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.document.label")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: `${I18n.t("activerecord.attributes.document.document_type")}`,
      filterByFilterrific: "by_document_type",
      myFilter: "document_type.name",
      filter: selectFilter({
        placeholder: `Seleccione un ${I18n.t("activerecord.attributes.document.document_type")}`,
        options: _.map(documentTypes, type => ({
          value: type.id,
          label: type.name,
        })),
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: `${I18n.t("activerecord.attributes.signer.created_at")}`,
      sortByFilterrific: "created_at_",
      filterByFilterrific: "search_by_created_at",
      myFilter: "signer.created_at_to_s",
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t("activerecord.attributes.signer.created_at")}`
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `document.entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `hiring.custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const signerColumns = _.concat(
    getEmployeeSecurityLayerColumn(),
    columns,
    getCustomFields(props?.customFields)
  );

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  };

  const drawSelectedSignersInput = () => {
    return (
      <>
        {_.map(selectedSigners, function (signer) {
          return (
            <div key={`signer-input-${signer.id}`}>
              <input
                type="hidden"
                name={`${formName}[items_attributes][][signer_id]`}
                value={signer.id}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12">
        <Label className="d-flex justify-content-center h5 my-4">
          { I18n.t('bulk.notification.signs.form.table.label') }
        </Label>
        <BulkNotificationSignSignerList
          showBulkMyFilter={ true }
          signers={signers}
          configuration={{
            isExportedCsv: true,
            filenameCsv: I18n.t('bulk.notification.signs.form.table.label')
          }}
          fetchParamsConfig={{
            entityKey: 'signers',
            indexEntities: indexLaboralSigners,
            requestParams: { ... requestFilterParams }
          }}
          signerColumns={signerColumns}
          selectedSigners={selectedSigners}
          setSelectedSigners={setSelectedSigners}
          pagination={paginationFactory(optionPagination({
            totalSize: totalSize,
            sizePerPage: requestFilterParams?.per_page,
          })) }
          onTableChange={handleTableChange}
          _ref={bootstrapTable}
        />

        {drawSelectedSignersInput()}
      </div>
    </div>
  );
};

export default LaboralBulkNotificationSignForm;
