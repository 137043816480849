import React from "react";
import {
  Input
} from 'reactstrap';

const BulkLoadTagListHidden = ({ formName, dataHidden }) => {

  const drawnHiringHindden=()=>{
    if(dataHidden?.length > 0){
      return(
        <div>
          { _.map( dataHidden, function(tag){
            return(
              <div key={ _.uniqueId("tag_hidden") }>
                <Input
                  key={_.uniqueId("controldoc_id_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][controldoc_id]` }
                  defaultValue={ tag.controldoc_id }
                />
                <Input
                  key={_.uniqueId("tag_name_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][name]` }
                  defaultValue={ tag.name }
                />
                <Input
                  key={_.uniqueId("tag_category_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][category_id]` }
                  defaultValue={ tag.category.split(" / ")[0] }
                />
                <Input
                  key={_.uniqueId("tag_category_name_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][category_name]` }
                  defaultValue={ tag.category.split(" / ")[1] }
                />
              </div>
            )
          })}
        </div>
      )
    }
  }

  return(
    <div className="row">
      { drawnHiringHindden() }
    </div>
  )
}
export default BulkLoadTagListHidden;
