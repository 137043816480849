import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from "reactstrap";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Files from "react-butterfiles";

import { create as createImage } from './axios';

const ControldocFormImageImagePicker = props => {
  const [openFilePicker, setOpenFilePicker] = useState(props?.openFilePicker || false);
  const [tinymceEditor, setTinymceEditor] = useState(props?.tinymceEditor || null);
  const [imageList, setImageList] = useState(props?.imageList || []);
  const [errorsFileComponent, setErrorsFileComponent] =  useState([])
  const [errorImage, setErrorImage] =  useState([])

  let args = props?.modalArgs || { fullscreen: true }
  const allowedFormatImagePicker = props?.allowedFormatImagePicker || [{ type: "image/*" }]
  const maxSize = 2;

  const { imageTag } = props;


  useEffect(() => {
    setOpenFilePicker(props?.openFilePicker);
    setTinymceEditor(props?.tinymceEditor);
  }, [props])


  const toggle = () => {
    if(_.isFunction(props?.setOpenFilePicker)){
      props.setOpenFilePicker(!openFilePicker);
    } else {
      setOpenFilePicker(!openFilePicker)
    }
  }

  const handleDoubleClickImage = (e) => {
    if(e.detail == 2){
      let image = _.find(imageList, function(_image){
        return String(_image.id) == e.target.id
      });

      tinymceEditor.insertContent(`
        <img
          src="${ image.preview_url}"
          alt="${ image.filename }"
          width="${ imageTag?.width || '300' }"
          height="${ imageTag?.height || 'auto' }"
        />`);
      toggle()
    }
  }

  const drawImageList = () => {
    return(
      <div className="row">
        { _.map(imageList, function(image){
            return(
              <div
                className="col-md-3 col-lg-2 mt-1 mb-2 mh-100"
                key={ `image-list-${ image.id }` }
              >
                <div className="square border border-secundary text-center h-100 p-1">
                  {/*<button className="btn btn-outline-danger btn-sm img-action-btn">
                    <i className="fas fa-trash-alt"></i>
                  </button>*/}
                  <img
                    src={ image.storage_url }
                    className="rounded h-100 w-100"
                    alt={ image.filename }
                    onClick={ e => handleDoubleClickImage(e) }
                    id={ image.id }
                  />
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  const handleErrors = (errors) => {
    setErrorsFileComponent(errors)
  }

  const handleFile = (response) => {
    let formData = new FormData();

    formData.append("controldoc_form_image[image]", response[0].src.file, response[0].src.file.name);

    createImage(formData, response => {
      if(response.status == 201){
        setImageList([...imageList, response.data])
        setErrorImage([])
      } else {
        setErrorImage(response.data)
      }
    })

    setErrorsFileComponent([])
  }

  const helperTypeErrorFile = type => {
    switch (type){
      case 'unsupportedFileType':
        return 'Formato del archivo no soportado'
      case 'maxSizeExceeded':
        return `El archivo excede el tamaño máximo de ${ maxSize }mb`
      default:
      return 'Error en el archivo'
    }
  }

  const drawErrorsFile = () => {
    if(errorsFileComponent.length > 0){
      return(
        <ul className='text-danger'>
          { _.map(errorsFileComponent, function(data){
            return(
              <li
                key={ _.uniqueId('file-error-') }
              >
                { helperTypeErrorFile(data.type) }
              </li>
            )
          })}
        </ul>
      )
    }
  }

  const drawErrorsImage = () => {
    if(_.keys(errorImage).length > 0){
      return(
        <ul className='text-danger'>
          { _.map(errorImage, function(data){
            return(
              <li
                key={ _.uniqueId('image-error-') }
              >
                { data }
              </li>
            )
          })}
        </ul>
      )
    }
  }

  return(
    <Modal isOpen={ openFilePicker } toggle={ toggle } { ... args }>
      <ModalHeader toggle={ toggle }>Subir imagen</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <Files
              multiple={ false }
              maxSize={ `${ maxSize } mb` }
              accept={ _.map(allowedFormatImagePicker, "type") }
              onError={ errors => handleErrors(errors) }
              onSuccess={ response => handleFile(response) }
            >
              { ( { browseFiles, getDropZoneProps }) => {
                return(
                  <div className="row" onClick={ browseFiles }>
                    <div className="col-12">
                      <div className='text-center pt-4 pb-4 text-muted mb-3'
                        {...getDropZoneProps({
                          style: {
                            minHeight: 150,
                            border: "2px lightgray dashed",
                          },
                        })}
                      >
                        <i className="fas fa-file-upload fa-4x"></i>
                        <p className='mt-3'>
                          Arrastra y suelta tus archivos aquí
                        </p>

                        <span className='small'>Formatos permitidos: { _.join(_.map(allowedFormatImagePicker, 'extension'), ' ') }.</span>
                        <br/>
                        <span className='small'>Tamaño máximo del archivo: { maxSize }mb.</span>
                      </div>
                    </div>
                  </div>
                )
              }}

            </Files>
            { drawErrorsFile() }
            { drawErrorsImage() }
          </div>
        </div>

        { drawImageList() }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={ toggle }>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ControldocFormImageImagePicker;
