import React, {
  useEffect,
  useCallback,
  useContext
} from 'react';

import {
  Button
} from 'reactstrap';

import I18n from "i18n-js";

// Start DragAndDrop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
// End DragAndDrop

import DocumentSignerConfigContext from './../document/context/signer_config_context';

import SignerForm from './form';

const SignerFormList = React.memo((props) => {
  const { formName, setResource, signerGroupIndex, signerGroup, signersCount} = props;

  let signers = props?.signers;

  const {
    resource
  } = useContext(DocumentSignerConfigContext);

  const setOrderSigner = useCallback((signers) => {
    let order = 0;
    _.each(signers, function(signer){
      if(signer?._destroy){
        signer.order = 0;
      } else {
        signer.order = order;
        order += 1;
      }
    })

    return signers;
  }, [])

  useEffect(() => {
    if(signers.length == 0){
      handleAddNewSigner()
    }
  }, []);

  const handleSigner = useCallback((signer, index) => {
    setResource(prevState => {
      let __signers = signerGroup ? [...signerGroup.signers] : [ ... prevState.signers ]
      __signers[index] = signer

      if(signer.employee_sign){
        __signers = _.map(__signers, (_s, _i) => {
          if(_s.employee_sign && _i != index){
            _s["employee_sign"] = false
            _s["name"] = null;
            _s["role"] = null;
            _s["email"] = null;

            return { ... _s }
          } else {
            return _s
          }
        })
      }

      __signers = setOrderSigner(__signers);

      if(signerGroup){
        let _signerGroup = {... signerGroup}
        let _signerGroups = [...prevState.signer_groups]

        _signerGroup.signers = __signers
        _signerGroups[signerGroupIndex] = _signerGroup

        return { ... prevState, signer_groups: _signerGroups }
      }else{
        return { ...prevState, signers: __signers};
      }
    })
  }, [resource, signerGroup])

  const handleAddNewSigner = () => {
    let _signers = [ ... signers ];

    let signerAttributes = {
      id: "",
      email: "",
      company_email: "",
      role: "",
      order: signersCount,
      _destroy: false,
      employee_sign: false,
      company_sign: false,
      custom_field_id: "",
      email_notification_sign: false,
      member_sign: false,
    }

    setResource(prevState => {
      // Add new signer for SignerGroup
      if(signerGroup){
        let _signerGroup = { ... signerGroup }
        let _signerGroups = [...prevState.signer_groups]

        _signerGroup.signers = [... _signerGroup.signers, signerAttributes]
        _signerGroups[signerGroupIndex] = _signerGroup

        return { ... prevState, signer_groups: _signerGroups }
      }else{
        if(_.isUndefined(prevState.signers)){
          prevState.signers = []
        }

        return { ... prevState, signers: [...prevState.signers, signerAttributes] }
      }
    })
  }

  // Start DndProvider
  const handleMoveSigner = useCallback((dragIndex, hoverIndex) => {
    setResource(prevState => {
      // De esta forma se obtiene los firmantes mas actualizados ya que por props del signerGroup todavia no se refresca
      let _signers = signerGroup ? [ ... prevState.signer_groups[signerGroupIndex].signers ] : [ ... prevState.signers ];

      const dragSigner = _signers[dragIndex];
      _signers = update(_signers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSigner],
        ],
      });

      _signers = setOrderSigner(_signers)

      if(signerGroup){
        let _signerGroup = { ... prevState.signer_groups[signerGroupIndex] }
        let _signerGroups = [...prevState.signer_groups]

        _signerGroup.signers= _signers
        _signerGroups[signerGroupIndex] = _signerGroup

        return { ... prevState, signer_groups: _signerGroups }

      }else{
        return { ...prevState, signers: _signers }
      }
    })
  }, []);
  // End DndProvider

  const drawSigners = () => {
    if(signers.length > 0){
      return(
        <div className="row">
          <div className="col-12">
            <DndProvider backend={HTML5Backend}>
              { _.map(signers, function(signer, index){
                return(
                  <SignerForm
                    key={ `signerForm-${ index }` }
                    signer={ signer }
                    callbackSetSigner={ handleSigner }
                    index={ index }
                    signerGroup={ signerGroup }
                    handleMoveSigner={ handleMoveSigner }
                    formName={ `${ formName }[${ index }]`}
                  />
                )
              })}
            </DndProvider>
          </div>
        </div>
      )
    } else {
      return(
        <div className="alert alert-primary text-center">
          <i className="fas fa-info-circle mr-2" aria-hidden="true" title="Información"></i>
          <span>Aún no has agregado firmantes</span>
        </div>
      )
    }
  }

  return(
    <div className="row">

      <div className="col-12">
        { drawSigners() }
      </div>

      <div className="col-12">
        <Button
          outline
          color="success"
          size="sm"
          onClick={ e => handleAddNewSigner() }
          disabled={ resource.isPersisted }
        >
          <i className="fas fa-user-plus"></i>
          &nbsp;
          { I18n.t("signers.form.add_signer") }
        </Button>
      </div>
    </div>
  )
});

export default SignerFormList
