import axios from "axios";

export const create = ( requestParams, formData, callback ) => {
  let promise = axios({
    method: 'POST',
    url: Routes.abstract_controldoc_form_template_layouts_path({ ...{... requestParams}, "format": "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response)
    })
    .catch(error => {
      callback(error.response)
    })
};

export const update = (requestParams, formData, callback) => {
  let promise = axios({
    method: 'PATCH',
    url: Routes.abstract_controldoc_form_template_layout_path({ ...{ ... requestParams }, "format": "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const preview = (requestParams, formData, callback) => {
  let promise = axios({
    method: 'POST',
    url: Routes.preview_abstract_controldoc_form_template_layouts_path({ ... { ... requestParams}, options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
    responseType: 'blob'
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
