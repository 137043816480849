import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js';
import {
  Button,
  Form
} from 'reactstrap'

import ControldocFormTemplateMappingLinkItem from './item'


const ControldocFormTemplateMappingLinkForm = props => {
  const formName = `${props.formName}[template_mapping_links_attributes][]`;
  const { template, resources } = props;
  const [mappingLinks, setMappingLinks] = useState(template?.template_mapping_links || [])

  const drawMappingLinks = () => {
    return _.map(mappingLinks, mapping => {
      return(
        <ControldocFormTemplateMappingLinkItem
          mappingLink={ mapping }
          formName={ formName }
          abstractModule={ props?.abstractModule }
          key={ `mapping-link-item-${ mapping.id }` }
          resources={ resources }
        />
      )
    })
  }

  return(
    <div className="table-responsive p-0 mb-0" style={{ "maxHeight": "500px" }}>
      <table className="table table-bordered text-nowrap">
        <thead className="alert-light text-dark">
          <tr>
            <th width='30%'>
              { I18n.t("controldoc_form.templates.table.var") }
              <button
                className="btn btn-link p-0 ml-2"
                data-bs-placement="top"
                data-bs-html="true"
                data-bs-toggle="tooltip"
                title={ I18n.t("controldoc_form.templates.table.info.var") }
                type="button"
              >
                <i className="fas fa-info-circle text-info fa-lg"></i>
              </button>
            </th>
            <th width='30%'>
              { I18n.t("controldoc_form.templates.table.model") }
              <button
                className="btn btn-link p-0 ml-2"
                data-bs-placement="top"
                data-bs-html="true"
                data-bs-toggle="tooltip"
                title={I18n.t("controldoc_form.templates.table.info.model") }
                type="button"
              >
                <i className="fas fa-info-circle text-info fa-lg"></i>
              </button>
            </th>
            <th width='30%'>
              { I18n.t("controldoc_form.templates.table.attribute") }
              <button
                className="btn btn-link p-0 ml-2"
                data-bs-placement="top"
                data-bs-html="true"
                data-bs-toggle="tooltip"
                title={ I18n.t("controldoc_form.templates.table.info.attribute") }
                type="button"
              >
                <i className="fas fa-info-circle text-info fa-lg"></i>
              </button>
            </th>
          </tr>
        </thead>

        <tbody>
          { drawMappingLinks() }
        </tbody>
      </table>
    </div>
  )
}

export default ControldocFormTemplateMappingLinkForm;
