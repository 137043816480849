import React, { useState } from 'react';

import I18n from 'i18n-js';

import DocumentAttributes from '../../document/attributes';
import { DocumentSignerConfigProvider } from '../../document/signer_config_context';

import { index as indexLaboralEmployee } from '../employee/axios';
import {
  create as createDocument,
  update as updateDocument
} from './axios';

const LaboralEmployeeDocumentForm = props => {

  const [_document, setDocument] = useState(props?.document || {})

  return(
    <div className="row">
      <div className="col-12">
        <DocumentAttributes
          configuration={{
            entity: {
              key: 'employees',
              label: I18n.t('activerecord.attributes.laboral/employee_document.entity_id'),
              placeholder: `-- Selecciona una ${ I18n.t('activerecord.attributes.laboral/employee_document.entity_id') } --`
            },
            formName: 'laboral_employee_document',
            context: 'laboral',
            _class: 'Laboral::EmployeeDocument',
            defaultRequestParams: {}
          }}
          actions={{
            indexEntities: indexLaboralEmployee,
            createDocument: createDocument,
            updateDocument: updateDocument,
            showDocumentPath: Routes.laboral_employee_document_path,
            indexDocumentsPath: Routes.laboral_employee_document_path,
          }}
          _document={ _document }
          data={ props?.data }
          currentUser={ props?.currentUser || {} }
          currentCustomer={ props?.currentCustomer || {} }
          callbackSetDocument={ setDocument }
        />
      </div>
    </div>
  )
}

export default LaboralEmployeeDocumentForm;
