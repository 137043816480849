import React from "react";
import DocumentTypeForm from "../../document_type/form";
import {
  create as createDocumentType,
  update as updateDocumentType
} from "./axios";

const AbstractDocumentTypeForm = props => {
  return(
    <>
      <DocumentTypeForm
        documentType={ props?.documentType || {} }
        data={ props?.data || {} }
        currentEntityType={ props?.currentEntityType || {} }
        configuration={{
          context: 'abstract',
          formName: 'abstract_document_type',
          defaultRequestParams: { current_entity_type_id: props?.currentEntityType?.hashid }
        }}
        actions={{
          createDocumentType: createDocumentType,
          updateDocumentType: updateDocumentType,
          indexDocumentTypesPath: Routes.abstract_document_types_path,
          showDocumentTypePath: Routes.abstract_document_type_path
        }}
      />
    </>
  )
}

export default AbstractDocumentTypeForm;
