//Se ocupa este componente para los DefaultSigner (firmantes por defecto)
import React, { useContext } from 'react';
import { Button, FormGroup, Label, Input } from "reactstrap";

import I18n from 'i18n-js';

import FieldError from '../helper/FieldError';

import {
  inputId,
  inputName,
} from '../helper/form';

import Select from 'react-select';

import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerMemberAttributes = props => {
  const {
    signer, formName, callbackSetSigner, index
  } = props;

  const {
    resource
  } = useContext(DocumentSignerConfigContext);

  const {
    data,
    currentCustomer
  } = useContext(DocumentFormContext)

  const handleSigner = (event, key) => {
    let value = event.target.value;
    let _signer = { ... signer };

    if(key == 'email'){
      let defaultSigner = _.find(data?.defaultSigners, { email: value });

      _signer.role = defaultSigner?.role;
      _signer.name = defaultSigner?.name;
    }

    _signer[key] = value;

    if(_.isFunction(callbackSetSigner)){
      callbackSetSigner(_signer, index);
    }
  }

  const drawMember = defaultSigner => {
    let name = _.trim(defaultSigner.name) || I18n.t('no_entry');
    let role = _.trim(defaultSigner.role) || I18n.t('no_entry');

    return `${ defaultSigner.email } / ${ name } / ${ role }`
  }

  const drawSelectDefaultSignerOptions = () => {
    return _.map(data?.defaultSigners || [], defaultSigner => ({ value: defaultSigner.email, label: drawMember(defaultSigner) }))
  }

  const getDefaultSignerSelectedOption = () => {
    let selected = _.find(data?.defaultSigners, { email: signer?.email })

    if(selected){
      return { value: selected.email, label: selected.email }
    } else {
      return null
    }
  }

  const drawEmailInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.email || [] } >
            <Label
              for={ inputId(formName, 'email') }
            >
              { I18n.t("activerecord.attributes.signer.email") }
            </Label>

            <Select
              id={ inputId(formName, 'email') }
              name={ inputName(formName, 'email') }
              isSearchable={ true }
              isClearable={ true }
              options={ drawSelectDefaultSignerOptions() }
              value={ getDefaultSignerSelectedOption() }
              onChange={ e => handleSigner({target: { value: e?.value }}, "email") }
              placeholder={ `-- Selecciona un ${I18n.t('activerecord.models.default_signer.one')} --` }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawIdentificationNumberInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.identification_number || [] } >
              <Label
                for={ inputId(formName, 'identification_number') }
              >
                { I18n.t("activerecord.attributes.signer.identification_number") }
              </Label>

              <Input
                type='text'
                value={ signer?.identification_number || "" }
                onChange={ e => handleSigner(e, "identification_number") }
                name={ inputName(formName, "identification_number") }
                id={ inputId(formName, "identification_number") }
                invalid={ _.has(signer?.errors, "identification_number") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawNameInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.name || [] } >
            <Label
              for={ inputId(formName, 'name') }
            >
              { I18n.t("activerecord.attributes.signer.name") }
            </Label>

            <Input
              value={ signer?.name || "" }
              onChange={ e => handleSigner(e, "name") }
              name={ inputName(formName, "name") }
              id={ inputId(formName, "name") }
              invalid={ _.has(signer?.errors, "name") }
              disabled={ resource.isPersisted }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawFirstSurnameInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.first_surname || [] } >
              <Label
                for={ inputId(formName, 'first_surname') }
              >
                { I18n.t("activerecord.attributes.signer.first_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.first_surname || "" }
                onChange={ e => handleSigner(e, "first_surname") }
                name={ inputName(formName, "first_surname") }
                id={ inputId(formName, "first_surname") }
                invalid={ _.has(signer?.errors, "first_surname") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawLastSurnameInput = () => {
    if(currentCustomer?.enable_fao && resource?.require_fao){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.last_surname || [] } >
              <Label
                for={ inputId(formName, 'last_surname') }
              >
                { I18n.t("activerecord.attributes.signer.last_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.last_surname || "" }
                onChange={ e => handleSigner(e, "last_surname") }
                name={ inputName(formName, "last_surname") }
                id={ inputId(formName, "last_surname") }
                invalid={ _.has(signer?.errors, "last_surname") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawRoleInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.role || [] } >
            <Label
              for={ inputId(formName, 'role') }
            >
              { I18n.t("activerecord.attributes.signer.role") }
            </Label>

            <Input
              value={ signer?.role || "" }
              onChange={ e => handleSigner(e, "role") }
              name={ inputName(formName, "role") }
              id={ inputId(formName, "role") }
              invalid={ _.has(signer?.errors, "role") }
              disabled={ resource.isPersisted }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  return(
    <div className="row">
      { drawEmailInput() }
      { drawIdentificationNumberInput() }
      { drawNameInput() }
      { drawFirstSurnameInput() }
      { drawLastSurnameInput() }
      { drawRoleInput() }
    </div>
  )
}

export default SignerMemberAttributes;
