import React, { useState, useEffect, useCallback } from "react";
import { FormGroup, Input, Label, Button, Row, Col } from "reactstrap";
import Select from "react-select";
import I18n from 'i18n-js';
import { drawTooltipHelp } from '../../../helper/form';

import { index, destroy } from "../../../laboral/bulk/my_filter/axios";

const LaboralBulkMyFilterList = props => {
  const {
    bootstrapTableRef,
    currFilters,
    selectedFilter,
    setSelectedFilter,
    handleShowSearchFilters,
    setShowSearchFilters,
    requireMyFiltersRefresh,
    setRequireMyFiltersRefresh,
    setSpinner,
    fetchParamsConfig
  } = props

  const {
    entityKey,
    indexEntities,
    requestParams,
  } = fetchParamsConfig;

  const [myFilters, setMyFilters] = useState([])
  const [entitiesSelected, setEntitiesSeleted] = useState([])

  useEffect(() => {
    getMyFilters()
  }, [])

  useEffect(() => {
    if(requireMyFiltersRefresh){
      getMyFilters()

      setRequireMyFiltersRefresh(false)
    }
  }, [requireMyFiltersRefresh])

  useEffect(() => {
    if(_.keys(selectedFilter).length > 0){
      // en casos de que cambie a otras listas
      resetCurrentFilters(currFilters)
      onSelectAllEntities(false, entitiesSelected)

      let filters = selectedFilter?.filters?.filters || {};

      _.each(filters, function(value, key){
        let dataField = _.find(bootstrapTableRef.current.table.props.columns, { myFilter: key } );
        if(_.keys(dataField)){
          currFilters[dataField?.dataField] = { filterVal: value || ""}
        }
      })

      setSpinner(true)
      bootstrapTableRef.current.handleRemoteFilterChange(currFilters);

      // permite cargar la data que viene por los filtros de la line 50
      // y luego ejecutar la seleccion para suavisar los cambios
      setTimeout(() => {
        selectedFetchEntities()
      }, 10);
    }
  }, [selectedFilter])

  const selectedFetchEntities = () => {
    requestParams["filterrific"]["show_all"] = true

    indexEntities(requestParams, response => {
      let entities = response?.data[entityKey];

      requestParams["filterrific"]["show_all"] = false
      setEntitiesSeleted(entities)
      onSelectAllEntities(true, entities)
    })
  }

  const resetCurrentFilters = useCallback((currFilters)=>{
    _.forEach(currFilters, (value, key)=>{
      currFilters[key] = { filterVal: "" }
    })
    bootstrapTableRef.current.handleRemoteFilterChange(currFilters)
  }, [])

  const onSelectAllEntities = useCallback((isSelected, entities)=>{
    bootstrapTableRef.current?.selectionContext?.props?.selectRow?.onSelectAll?.(isSelected, entities)
    setSpinner(false)
  }, [bootstrapTableRef])

  const getMyFilters = () => {
    index(response => {
      setMyFilters(response.data.my_filters);
    });
  }

  const myFiltersOptionSelect = () => {
    return _.map(myFilters, f => ( { value: f.id, label: f.name }));
  }

  const myFilterOptionSelected = () => {
    return selectedFilter ? { value: selectedFilter.id, label: selectedFilter.name } : ''
  }

  const handleSelectFilter = (event, action) => {
    let currentFilter = _.isNull(event) ? {} : _.find(myFilters, { id: event.value } );

    if(!_.isEmpty(currentFilter)){
      setShowSearchFilters(false)
    }

    if(action?.action == 'clear'){
      resetCurrentFilters(currFilters);
      handleShowSearchFilters({})
      // para deseleccionar todas las casillas
      onSelectAllEntities(false, entitiesSelected)
    }

    setTimeout(() => {
      setSelectedFilter(currentFilter);
    }, 20);
  }

  const onDestroyFilterButton = () => {
    swalWithBootstrap.fire({
      title: 'Eliminar lista de distribución',
      html: `¿Estás seguro de eliminar la lista <strong>${selectedFilter?.name}</strong>?`,
    }).then( result => {
      if(result.isConfirmed){
        onDestroyFilter()
      }
    })
  }

  const onDestroyFilter = () => {
    let params = { id: selectedFilter?.id}

    destroy(params, response => {
      if(response.status == 200){
        setSelectedFilter({})
        setRequireMyFiltersRefresh(true)
        resetCurrentFilters(currFilters);
        setShowSearchFilters(false)
        onSelectAllEntities(false, entitiesSelected)

        swal.fire(
          "Eliminado!",
          "La lista de distribución ha sido eliminada correctamente",
          "success"
        )
      }
    })
  }

  const drawSelect = () => {
    return(
      <Select
        options={ myFiltersOptionSelect() }
        onChange={ (e, action) => handleSelectFilter(e, action) }
        className="basic-single d-inline"
        classNamePrefix="select"
        isClearable={ true }
        value={ myFilterOptionSelected() }
        placeholder=' -- Seleccione Lista -- '
      />
    )
  }

  const destroyFilterButton = () => {
    if(!_.isEmpty(selectedFilter)){
      return (
        <Button
          className="mt-1"
          size='sm'
          color="danger"
          onClick={ onDestroyFilterButton }
        >
          <i className="fas fa-trash"/>
        </Button>
      )
    }
  }

  return(
    <div>
      <Row>
        <Col md="6">
          <Label>
            <i className="fas fa-clipboard-list mr-2"></i>
            { I18n.t(`bulk.my_filters.title`) }
            { drawTooltipHelp( I18n.t(`bulk.my_filters.tooltip_info`) )}
          </Label>
        </Col>
        <Col md="6">
          <Row>
            <Col md="8">
              { drawSelect() }
            </Col>
            <Col md="2">
              { destroyFilterButton() }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LaboralBulkMyFilterList;
