import axios from "axios";

export const respond = (npsResultId, formData, callback) => {

  var promise = axios({
    method: 'patch',
    url: Routes.respond_nps_result_path(npsResultId, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
