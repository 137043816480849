import React from 'react';

import { updateOrder as updateLaboralCustomFields } from "./axios";
import CustomFieldOrderFormList from '../../custom_field/order_form_list';

const LaboralCustomFieldOrderFormList = (props) => {
  const defaultRequestParams = {};

  return(
    <div className="row">
      <div className="col-12">
        <CustomFieldOrderFormList
          formName='laboral_custom_fields'
          defaultRequestParams={ defaultRequestParams }
          customFields={ props?.customFields }
          actions={{
            indexCustomFieldsPath: Routes.laboral_custom_fields_path,
            updateOrderCustomFields: updateLaboralCustomFields
          }}
        />
      </div>
    </div>
  )
};

export default LaboralCustomFieldOrderFormList;
