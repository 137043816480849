import React, {
  useMemo,
  useEffect,
  useState
} from 'react'

import axios from "axios";

import WorkflowRequirementAttributes from './attributes';
import KarinLawWorkflowRequirementAttributes from '../../karin_law/workflow/requirement/attributes';
import EconomicCrimeWorkflowRequirementAttributes from '../../economic_crime/workflow/requirement/attributes';

import WorkflowRequirementDisclaimer from './disclaimer';

const optionsForModule = props => {
  return {
    karinLaw: {
      defaultRequestParams: {},
      showRoutePath: Routes.karin_law_workflow_requirement_path,
      indexRoutePath: Routes.karin_law_workflow_requirements_path,
      newRoutePath: Routes.new_karin_law_workflow_requirement_path,
      createRoutePath: Routes.karin_law_workflow_requirements_path,
    },
    publicKarinLaw: {
      defaultRequestParams: {
        customer_id: props?.currentCustomer?.hashid,
        process_id: props?.requirement?.process.hashid
      },
      showRoutePath: Routes.created_public_karin_law_workflow_requirement_path,
      // indexRoutePath: null,
      // newRoutePath: null,
      createRoutePath: Routes.public_karin_law_workflow_requirements_path
    },
    portalKarinLaw: {
      defaultRequestParams: {
        tenant: props?.currentCustomer?.portal_configuration?.tenant,
        process_id: props?.requirement.process_id
      },
      showRoutePath: Routes.portal_karin_law_workflow_requirement_path,
      indexRoutePath: Routes.portal_karin_law_workflow_requirements_path,
      newRoutePath: Routes.new_portal_karin_law_workflow_requirement_path,
      createRoutePath: Routes.portal_karin_law_workflow_requirements_path,
    },
    abstract: {
      defaultRequestParams: { current_entity_type_id: props?.currentEntityType?.hashid },
      showRoutePath: Routes.abstract_workflow_requirement_path,
      indexRoutePath: Routes.abstract_workflow_requirements_path,
      newRoutePath: '',
      createRoutePath: Routes.abstract_workflow_requirements_path,
    },
    publicAbstract: {
      defaultRequestParams: {
        customer_id: props?.currentCustomer?.hashid,
        process_id: props?.requirement?.process.hashid
      },
      showRoutePath: Routes.created_public_abstract_workflow_requirement_path,
      // indexRoutePath: '',
      // newRoutePath: '',
      createRoutePath: Routes.public_abstract_workflow_requirements_path,
    },
    portalAbstract: {
      defaultRequestParams: {
        tenant: props?.currentCustomer?.portal_configuration?.tenant,
        process_id: props?.requirement.process_id
      },
      showRoutePath: Routes.portal_abstract_workflow_requirement_path,
      indexRoutePath: Routes.portal_abstract_workflow_requirements_path,
      newRoutePath: Routes.new_portal_abstract_workflow_requirement_path,
      createRoutePath: Routes.portal_abstract_workflow_requirements_path,
    },
    economicCrime: {
      defaultRequestParams: {},
      showRoutePath: Routes.economic_crime_workflow_requirement_path,
      indexRoutePath: Routes.economic_crime_workflow_requirements_path,
      newRoutePath: Routes.new_economic_crime_workflow_requirement_path,
      createRoutePath: Routes.economic_crime_workflow_requirements_path,
    },
    publicEconomicCrime: {
      defaultRequestParams: {
        customer_id: props?.currentCustomer?.hashid,
        process_id: props?.requirement?.process.hashid
      },
      showRoutePath: Routes.created_public_economic_crime_workflow_requirement_path,
      // indexRoutePath: null,
      // newRoutePath: null,
      createRoutePath: Routes.public_economic_crime_workflow_requirements_path
    },
    portalEconomicCrime: {
      defaultRequestParams: {
        tenant: props?.currentCustomer?.portal_configuration?.tenant,
        process_id: props?.requirement.process_id
      },
      showRoutePath: Routes.portal_economic_crime_workflow_requirement_path,
      indexRoutePath: Routes.portal_economic_crime_workflow_requirements_path,
      newRoutePath: Routes.new_portal_economic_crime_workflow_requirement_path,
      createRoutePath: Routes.portal_economic_crime_workflow_requirements_path,
    },
  }
}

const WorkflowRequirementForm = props => {
  const {
    currentUser,
    configuration
  } = props;

  const [requirement, setRequirement] = useState(props?.requirement)

  const formName = 'workflow_requirement';

  const isKarinLaw = useMemo(() => {
    return requirement.module_type == 'KarinLaw::Workflow::Requirement'
  }, [])

  const isAbstract = useMemo(() => {
    return requirement.module_type == 'Abstract::Workflow::Requirement'
  }, [])

  const isEconomicCrime = useMemo(() => {
    return requirement.module_type == 'EconomicCrime::Workflow::Requirement';
  })

  const isPublicContext = useMemo(() => {
    return props?.configuration?.context == 'public'
  }, [])

  const isPortalContext = useMemo(() => {
    return props?.configuration?.context == 'portal'
  }, [])

  const requireAcceptDisclaimer = useMemo(() => {
    return requirement.snapshot_process.require_disclaimer && !requirement.accept_disclaimer
  }, [requirement.accept_disclaimer])

  // -------------------- START PARAMS --------------------

  const currentOptionsModule = useMemo(() => {
    let key = undefined;

    if(isKarinLaw && isPublicContext){
      key = 'publicKarinLaw';
    } else if(isKarinLaw && isPortalContext){
      key = 'portalKarinLaw';
    } else if(isKarinLaw){
      key = 'karinLaw';
    } else if(isAbstract && isPublicContext){
      key = 'publicAbstract';
    } else if(isAbstract && isPortalContext){
      key = 'portalAbstract';
    } else if(isAbstract){
      key = 'abstract';
    } else if(isEconomicCrime && isPublicContext){
      key = 'publicEconomicCrime'
    } else if(isEconomicCrime && isPortalContext){
      key = 'portalEconomicCrime';
    } else if(isEconomicCrime){
      key = 'economicCrime';
    }

    return optionsForModule(props)[key]
  })

  // -------------------- END PARAMS --------------------

  // -------------------- START CALLBACKS --------------------
  const createService = (requestParams, formData, callback) => {
    let promise = axios({
      method: 'post',
      url: currentOptionsModule.createRoutePath({...{...requestParams}, format: 'json', _options: true }),
      data: formData,
      headers: {
        "X-CSRF-TOKEN": window.compliance.token,
      },
    });

    promise
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error.response);
      });
  }
  // -------------------- END CALLBACKS --------------------

  const FormComponent = useMemo(() => {
    if(requireAcceptDisclaimer){
      return WorkflowRequirementDisclaimer
    } else if(isKarinLaw){
      return KarinLawWorkflowRequirementAttributes
    } else if(isEconomicCrime){
      return EconomicCrimeWorkflowRequirementAttributes
    } else {
      return WorkflowRequirementAttributes
    }
  }, [requirement.accept_disclaimer])

  useEffect(() => {
    if(!requirement.process_id){
      window.location = currentOptionsModule.newRoutePath(currentOptionsModule.defaultRequestParams)
    }
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <FormComponent
          requirement={ requirement }
          configuration={{
            formName: formName,
            defaultRequestParams: currentOptionsModule.defaultRequestParams,
          }}
          routes={{
            indexRequirementsPath: currentOptionsModule.indexRoutePath,
            showRequirementPath: currentOptionsModule.showRoutePath
          }}
          services={{
            createRequirement: createService,
          }}
          callbacks={{
            onSetRequirement: setRequirement
          }}
          data={ props.data }
        />
      </div>
    </div>
  )
}

export default WorkflowRequirementForm;
