import axios from "axios";

export const create = (formData, callback) => {
  var promise = axios({
    method: 'POST',
    url: Routes.laboral_controldoc_form_templates_path({ format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const update = (id, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.laboral_controldoc_form_template_path(id, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
