import React from 'react';

import I18n from 'i18n-js';

import EntityFolderCheckListAttributes from '../../../entity_folder/check_list/attributes';

import {
  create as createFolder,
  update as updateFolder
} from './axios';

const AbstractEntityFolderCheckListForm = props => {
  const {
    currentCustomer,
    currentEntityType,
    folder,
    data
  } = props;

  const defaultRequestParams = { current_entity_type_id: currentEntityType.hashid }

  return(
    <div className="row">
      <div className="col-12">
        <EntityFolderCheckListAttributes
          folder={ folder }
          data={ data }
          configuration={{
            entity: {
              label: currentEntityType?.singular_name,
            },
            formName: 'entity_folder_check_list',
            defaultRequestParams: defaultRequestParams
          }}
          actions={{
            showEntityPath: Routes.abstract_entity_path,
            createFolder: createFolder,
            updateFolder: updateFolder

          }}
        />
      </div>
    </div>
  )
}

export default AbstractEntityFolderCheckListForm
