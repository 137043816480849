import axios from "axios";

export const index = (filterRequestParams, callback) => {
  var promise = axios({
    method: 'get',
    url: Routes.corporate_signers_path({ ...{...filterRequestParams }, format: "json", _options: true }),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

