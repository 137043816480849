import React, { useState, useEffect } from "react";
import CheckListSharedProcessForm from "../../../check_list/shared_process/form";
import { share as shareCheckListProcess } from '../process/axios';
import I18n from 'i18n-js';
import _ from "lodash";

const AbstractCheckListSharedProcessForm = props => {
  const [sharedProcesses, setSharedProcesses] = useState(props?.sharedProcesses || [])
  const { checkListProcess, currentUser, currentEntityType } = props;

  return (
    < >
      <CheckListSharedProcessForm
        checkListProcess={ checkListProcess }
        currentUser={ currentUser }
        currentEntityType={ currentEntityType }
        sharedProcesses={ sharedProcesses }
        callbackSharedProcesses={ setSharedProcesses }
        context={ "abstract" }
        actions={{
          shareCheckListProcess: shareCheckListProcess,
          showCheckListProcessPath: Routes.abstract_check_list_process_path
        }}
      />
    </>
  )
}

export default AbstractCheckListSharedProcessForm;