$(function() {
  const modal_holder_selector = '#modal-responder-holder';
  const modalSelectorId = 'modalResponder';

  $(document).on('click', 'a[data-modal]', function() {
    const location = $(this).attr('href');

    $.get(location, function(data){
      $(modal_holder_selector).html(data);

      let modalElement = document.getElementById(modalSelectorId);

      if(modalElement){
        let modalResponder = new bootstrap.Modal(modalElement, {})
        modalResponder.show()
      }

    })

    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function(event){
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal();
    }

    return false;
  });
});
