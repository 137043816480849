import React, { useState, useEffect } from "react";

const FieldError = ({ errors, children, isVisible, styleMargin }) => {
  const [componentErrors, setComponentErrors] = useState(errors);

  useEffect(() => {
    setComponentErrors(errors);
  }, [errors]);

  let display = isVisible ? "block" : isVisible == false ? "none" : "block";

  if (componentErrors && componentErrors.length > 0) {
    return (
      <div style={{ display }}>
        {children}
        <ul
          className="clearfix"
          style={{
            color: "#dc3545",
            listStyle: "none",
            padding: 0,
            marginTop: "0.25rem",
          }}
        >
          {componentErrors.map(function (error, index) {
            return (
              <li
                style={{
                  paddingLeft: "1px",
                  listStylePosition: "inside",
                  fontSize: "0.875em",
                }}
                key={index}
              >
                {error}
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return <div style={{ display }}>{children}</div>;
  }
};

export default FieldError;
