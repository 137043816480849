import React, { useEffect, useState } from 'react'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap'

import FieldError from '../../helper/FieldError';
import I18n from 'i18n-js';

const ControldocFormTemplateMappingLinkResourceAttributeItem = props => {
  const { resources, mappingLink, formName } = props;

  const [resourceAttributes, setResourceAttributes] = useState([]);

  useEffect(() => {
    let _resourceAttributes = [];
    if(mappingLink.resource != null){
      let resource = _.find(resources, resource => String(resource.resource_class) == String(mappingLink.resource))

      _resourceAttributes = resource?.values || [];
    }

    setResourceAttributes(_resourceAttributes);
  }, [mappingLink])

  const drawOptions = (values) => {
    return _.map(values, attribute => {
      return(
        <option
          value={ attribute.key }
          key={ _.uniqueId('resource-attribute-option-') }
        >
          { attribute.label }
        </option>
      )
    })
  }

  const drawOptGroups = () => {
    return _.map(resourceAttributes, (values, key) => {

      let _key = mappingLink.resource == 'ControldocForm::TemplateSystemVar' ? 'system_information' : key;

      return(
        <optgroup
          label={ I18n.t(`controldoc_form.template_mapping_links.form.opt_group.${ _key }.one`) }
          key={ _.uniqueId('resource-attribute-group-') }
        >
          { drawOptions(values) }
        </optgroup>
      )
    })
  }

  const handleResourceAttribute = event => {
    let value = event?.target?.value;

    if(typeof props?.handleResourceAttribute === 'function'){
      props.handleResourceAttribute(value)
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <FieldError errors={ mappingLink?.errors?.resource_attribute || [] }>
          <Input
            type='select'
            invalid={ _.has(mappingLink?.errors, 'resource_attribute') }
            onChange={ e => handleResourceAttribute(e) }
            value={ mappingLink.resource_attribute || '' }
            name={ `${ formName }[resource_attribute]` }
          >
          <option value="">
            { I18n.t('inputs.select_a_option') }
          </option>
          { drawOptGroups() }
          </Input>
        </FieldError>
      </div>
    </div>
  )
}

export default ControldocFormTemplateMappingLinkResourceAttributeItem;
