import React, { useState, useCallback, useEffect } from "react";
import { FormGroup, FormFeedback, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { index, create, update } from "../../../laboral/bulk/my_filter/axios";
import I18n from 'i18n-js';
import FieldError from "../../../helper/FieldError";

import LaboralBulkMyFilterFieldList from './field/list'

const LaboralBulkMyFilterForm = (props) => {
  const formName = props?.formName || 'laboral_bulk_my_filter'

  const {
    bootstrapTableRef,
    currFilters,
    selectedFilter,
    setSelectedFilter,
    isSelectedFilter,
    setRequireMyFiltersRefresh
  } = props;

  const [modal, setModal] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({})

  useEffect(()=>{
    setName(selectedFilter?.name)
  }, [selectedFilter])

  const drawModalForm = () => {
    return (
      <div>
        <Modal isOpen={ modal } toggle={ e => setModal(false) } size="lg">
          <ModalHeader toggle={ e => setModal(false)}>
            <i className="fas fa-cog mr-2"></i>
            Configuración de la Lista de Distribución
          </ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-4'>
                <FormGroup>
                  <Label>Nombre de la lista de distribución</Label>
                  <FieldError errors={errors.name}>
                    <Input
                      defaultValue={ name || "" }
                      onChange={ e => setName(e.target.value) }
                    />
                  </FieldError>
                </FormGroup>
                <LaboralBulkMyFilterFieldList
                  bootstrapTableRef={ bootstrapTableRef }
                  currentFilters= { currFilters }
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={ e => setModal(false) }
            >
              { I18n.t('actions.close') }
            </Button>
            <Button
              color="success"
              type="button"
              onClick={ handleSubmit }
            >
              { I18n.t('actions.save') }
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  const handleSubmit = () => {
    let formData = getFormData();
    let params = { id: selectedFilter?.id }
    setDisabledSubmitButton(true);
    setErrors({});

    if(isSelectedFilter){
      update(params,formData, response => {
        if (response.status == 200) {
          setModal(false)
          swal.fire({
            position: 'center',
            icon: 'success',
            title: I18n.t(
              "bulk.my_filters.update.successfully"
            ),
            showConfirmButton: true,
          })

          setSelectedFilter(response?.data)
        } else {
          setErrors(response.data.errors);
        }
      });
    }else{
      create(formData, response => {
        if (response.status == 201) {
          setModal(false)
          swal.fire({
            position: 'center',
            icon: 'success',
            title: I18n.t(
              "bulk.my_filters.create.successfully"
            ),
            showConfirmButton: true,
          })

          setSelectedFilter(response?.data)
        } else {
          setErrors(response.data.errors);
        }
      });
    }
    setRequireMyFiltersRefresh(true)
  };

  const getFormData = () => {
    let _formData = new FormData();
    _formData.append( `${formName}[name]`, name || "" );

    _.each(currFilters, function(value, key){
      let column = _.find(bootstrapTableRef.current.table.props.columns, { dataField: key });

      if(!_.isEmpty(value)){
        _formData.append(`${ formName }[filters][filters][${ column?.myFilter }]`, value?.filterVal || "");
      }
    })

    return _formData;
  };

  const drawBtnNewFilter = () => {
    let label = isSelectedFilter ? "Editar" : "Crear"

    return(
      <Button
        className='mb-2 ml-2'
        color="info"
        onClick={ e => setModal(true)}
      >
        { `${label} lista de distribución` }
      </Button>
    )
  }

  return(
    <>
      { drawBtnNewFilter() }
      { drawModalForm() }
    </>
  )
}

export default LaboralBulkMyFilterForm;
