import axios from "axios";

export const saveLaboralHiringComplianceProcess = (formData, callback) => {
  let method, url;
  const compliance_id = formData.get("laboral_hiring_compliance_process[id]");

  if (compliance_id) {
    method = "patch";
    url = Routes.laboral_hiring_compliance_process_path(compliance_id, {
      format: "json",
    });
  } else {
    method = "post";
    url = Routes.laboral_hiring_compliance_processes_path({ format: "json" });
  }

  var promise = axios({
    method: method,
    url: url,
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
