import React, {
  useEffect,
  useCallback,
  useContext
} from 'react';

import {
  Button
} from 'reactstrap';

import I18n from "i18n-js";

// Start DragAndDrop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
// End DragAndDrop

import DocumentSignerConfigContext from './../document/context/signer_config_context';

import SignerGroupForm from './form';

const SignerGroupFormList = (props) => {
  const { formName, setResource, signersCount } = props;

  let signerGroups = props?.signerGroups;

  const {
    resource
  } = useContext(DocumentSignerConfigContext);

  const setOrderSignerGroups = useCallback((signerGroups) => {
    let order = signersCount;

    _.each(signerGroups, function(group){
      if(group._destroy){
        group.order = 0;
      } else {
        group.order = order;
        order += 1;
      }
    })

    return signerGroups;
  }, [signersCount])

  useEffect(() => {
    if(signerGroups?.length == 0){
      onClickAddNewSignerGroup()
    }
  }, []);

  useEffect(() => {
    if(resource?.require_signers_order){
      setResource(prevState => {
        let __signerGroups = [ ... prevState.signer_groups ]
        __signerGroups = setOrderSignerGroups(__signerGroups);
        return { ...prevState, signer_groups: __signerGroups};
      })
    }
  }, [signersCount]);

  const onChangeSignerGroups = useCallback((signerGroup, index) => {

    setResource(prevState => {
      let __signerGroups = [ ... prevState.signer_groups ]

      __signerGroups[index] = signerGroup
      __signerGroups = setOrderSignerGroups(__signerGroups);

      return { ...prevState, signer_groups: __signerGroups};
    })
  }, [resource, signersCount])

  const onClickAddNewSignerGroup = () => {
    let _signerGroupsCount = _.filter(signerGroups, group =>{ return group?._destroy ==  false})?.length;
    // cuando se inicializa por primera vez
    let _signersCount = signersCount == 0 ? 1 : signersCount;

    let signerGroupAttributes = {
      id: "",
      name: "Grupo de firmantes",
      require_minimun_signers: false,
      minimun_signers: 0,
      order: _signerGroupsCount + _signersCount,
      _destroy: false,
      signers: [
        {
          id: "",
          email: "",
          company_email: "",
          role: "",
          order: "",
          _destroy: false,
          employee_sign: false,
          company_sign: false,
          custom_field_id: "",
          email_notification_sign: false,
          member_sign: false,
        }
      ]
    }

    setResource(prevState => {
      if(_.isUndefined(prevState.signer_groups)){
        prevState.signer_groups = []
      }

      return { ... prevState, signer_groups: [...prevState.signer_groups, signerGroupAttributes] }
    })
  }

  // Start DndProvider
  const handleMoveSignerGroup = useCallback((dragIndex, hoverIndex) => {
    setResource(prevState => {
      let _signerGroups = [ ... prevState.signer_groups ];

      const dragSignerGroup = _signerGroups[dragIndex];
      _signerGroups = update(_signerGroups, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSignerGroup],
        ],
      });

      _signerGroups = setOrderSignerGroups(_signerGroups)

      return { ...prevState, signer_groups: _signerGroups }
    })
  }, [signersCount]);
  // End DndProvider

  const drawSignerGroups = () => {
    if(signerGroups?.length > 0){
      return(
        <div className="row">
          <div className="col-12">
            <DndProvider backend={HTML5Backend}>
              { _.map(signerGroups, function(signerGroup, index){
                return(
                  <SignerGroupForm
                    key={ `signerGroupForm-${ index }` }
                    signerGroup={ signerGroup }
                    callbackSetSignerGroups={ onChangeSignerGroups }
                    index={ index }
                    setResource={ setResource }
                    handleMoveSignerGroup={ handleMoveSignerGroup }
                    formName={ `${ formName }[${ index }]`}
                  />
                )
              })}
            </DndProvider>
          </div>
        </div>
      )
    } else {
      return(
        <div className="alert alert-primary text-center">
          <i className="fas fa-info-circle mr-2" aria-hidden="true" title="Información"></i>
          <span>Aún no has agregado grupo de firmantes</span>
        </div>
      )
    }
  }


  return(
    <div className="row">

      <div className="col-12">
        { drawSignerGroups() }
      </div>

      <div className="col-12">
        <Button
          outline
          color="info"
          size="sm"
          onClick={ e => onClickAddNewSignerGroup() }
          disabled={ resource.isPersisted }
        >
          <i className="fas fa-users"></i>
          &nbsp;
          { I18n.t("signer_groups.form.add_group") }
        </Button>
      </div>
    </div>
  )
};


export default SignerGroupFormList
