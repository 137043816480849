import React, {
  useMemo
} from 'react';

import WorkflowRequirementCommentBox from '../../../../workflow/requirement/comment/box'

const PublicWorkflowRequirementCommentBox = props => {
  const {
    requirement,
    currentCustomer
  } = props;

  const commentDefaultRequestParams = {
    resource_id: requirement.hashid,
    customer_id: currentCustomer.hashid
  }

  const requirementDefaultRequestParams = {
    customer_id: currentCustomer.hashid,
    process_id: requirement.process.hashid,
    id: requirement.hashid
  }

  const downloadOutputFileItemPath = useMemo(() => {
    let route = '';

    if(requirement.module_type == 'Abstract::Workflow::Requirement'){
      route = Routes.download_output_file_item_public_abstract_workflow_requirement_path
    } else if(requirement.module_type == 'KarinLaw::Workflow::Requirement'){
      route = Routes.download_output_file_item_public_karin_law_workflow_requirement_path
    }

    return route;
  }, [])

  const showInfoStage = useMemo(() => {
    return requirement.module_type != 'KarinLaw::Workflow::Requirement'
  }, [requirement])

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementCommentBox
          requirement={ requirement }
          configuration={{
            commentDefaultRequestParams: commentDefaultRequestParams,
            requirementDefaultRequestParams: requirementDefaultRequestParams,
            context: 'public',
            showInfoStage: showInfoStage
          }}
          routes={{
            downloadAttachCommentPath: Routes.download_attach_public_comment_workflow_requirement_path,
            downloadOutputFilePath: downloadOutputFileItemPath,
            indexCommentPath: Routes.public_comment_workflow_requirements_path,
            createCommentPath: Routes.public_comment_workflow_requirements_path
          }}
        />
      </div>
    </div>
  )
}

export default PublicWorkflowRequirementCommentBox;
