import axios from "axios";

export const create = (formData, callback) => {
  var promise = axios({
    method: "POST",
    url: Routes.controldoc_form_images_path({ format: 'json' }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
