import React, {
  useMemo
} from 'react';

import I18n from "i18n-js";

import {
  FormGroup,
  Label,
  FormText,
  Input,
} from 'reactstrap'

import Select from 'react-select'

import BulkRequestDocumentConfigList from './config/list';

import {
  inputId,
  inputName,
  drawTooltipHelp
} from '../../../helper/form';
import FieldError from '../../../helper/FieldError';

const BulkRequestDocumentConfigTab = React.memo((props) => {
  const {
    bulkRequest,
    setBulkRequest,
    configuration,
    configuration: {
      formName,
      entityHeaderConfigTab,
      entity: entityConfiguration
    },
    data: { documentTypeBatteries }
  } = props;

  const { key: entityKey } = entityConfiguration;


  const bulkRequestEntities = useMemo(() => {
    return bulkRequest[entityKey]
  }, [ bulkRequest[entityKey]] )


  const data = useMemo(() => {
    return { ...props?.data, entitiesSelected: bulkRequest[entityKey] }
  }, [
    props?.data,
    bulkRequest[entityKey]
  ])

  const accordionSelectedEntitiesTable = useMemo(() => {
    return(
      <div className="accordion" id="accordionSelectedEntities">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              { `Has seleccionado ${ bulkRequestEntities.length } ${ entityConfiguration?.label.plural }` }
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionSelectedEntities"
          >
            <div className="accordion-body">
              { function(){
                if(bulkRequestEntities.length > 0){
                  return(
                    <div className="table-responsive">
                      <table className="table mt-2">
                        <thead>
                          <tr>
                            { _.map(entityHeaderConfigTab, (header, index) => {
                              return(
                                <th key={ `headerEntitySelected${ index }` }>{ header.label }</th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          { _.map(bulkRequestEntities, function (entity, index) {
                            return(
                              <tr key={ `trEntitySelected${entity.id}` }>
                                { _.map(entityHeaderConfigTab, (h,i) => {
                                  return(
                                    <td key={ `tdEntitySelected${ entity.id }${h.key}` }>
                                      { _.get(entity, h.key) || I18n.t('no_entry') }
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          } ) }
                        </tbody>
                      </table>
                    </div>
                  )
                }
              }()}
            </div>
          </div>
        </div>
      </div>
    )
  }, [bulkRequest[entityKey]])


  const onChangeDocumentTypeBattery = (value) => {
    let changes = { 'document_type_battery_id': value };

    const selectedDocumentTypeBattery = _.find(documentTypeBatteries, { id: parseInt(value) });

    changes['configs'] = _.map(selectedDocumentTypeBattery.document_types, documentType => {
      return {
        uploaded_by: documentType?.default_bulk_request_config?.uploaded_by || "upload_file_user",
        template_id: documentType?.default_bulk_request_config?.template_id || "",
        document_type_id: documentType.id,
        require_fao: documentType?.require_fao || false,
        require_signers: documentType.require_signers || false,
        require_signers_order: documentType?.require_signers_order || false,
        _destroy: false,
        autocompleted_by_entity: documentType?.default_bulk_request_config?.autocompleted_by_entity || false,
        file: '',
        label: '',
        signers: _.map(documentType.signers, signer => {
          return {
            id: '',
            name: signer.name,
            email: signer.email,
            order: signer.order,
            role: signer.role,
            employee_sign: signer.employee_sign,
            member_sign: signer.member_sign,
            email_notification_sign: signer.email_notification_sign
          }
        })
      }
    })

    setBulkRequest(prevState => {
      return {
        ... prevState,
        ... changes
      }
    })
  }

  const onChangeBulkRequest = (e, key) => {
    const { value, type, checked } = e.target;
    let changes = { [key]: value };

    if (type === 'checkbox') {
      changes = { [key]: checked, ["scheduled_at"]: null };
    }

    setBulkRequest(prevState => {
      return {
        ...prevState,
        ...changes
      };
    });
  };

  const documentTypeBatterySelectInput = () => {
    return(
      <FormGroup className='mt-3'>
        <Label
          for={ inputId(formName, 'document_type_battery_id') }
        >
          { I18n.t('activerecord.models.document_type_battery.other') }
          { drawTooltipHelp(I18n.t('abstract.bulk.request.document_configs.document_type_battery_help')) }
        </Label>

        <Select
          id={ inputId(formName, 'document_type_battery_id') }
          name={ inputName(formName, 'document_type_battery_id') }
          invalid={ _.has(bulkRequest?.errors, 'document_type_battery_id') }
          value={ documentTypeBatterySelectedOption }
          onChange={ e => onChangeDocumentTypeBattery(e.value) }
          options={ documentTypeBatterySelectOptions }
          isClearable={ true }
          placeholder={ `-- Selecciona un ${ I18n.t('activerecord.models.document_type_battery.one') } --` }
        />

        <FormText>{ I18n.t('abstract.bulk.request.document_configs.document_type_battery_form_text') }</FormText>
      </FormGroup>
    )
  }

  const documentTypeBatterySelectedOption = useMemo(() => {
    const selected = _.find(documentTypeBatteries, battery => battery.id == bulkRequest.document_type_battery_id )

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }, [bulkRequest?.document_type_battery_id])

  const documentTypeBatterySelectOptions = useMemo(() => {
    return _.map(documentTypeBatteries, battery => ({ value: battery.id, label: battery.name }) )
  }, [])

  const isScheduleSwitchInput = () => {
    return (
      <div className="row">
        <div className="col-12">
          <FormGroup>
            <FieldError errors={ bulkRequest?.errors?.is_scheduled || []}>
              <div className='custom-switch'>
                <Input
                  className='custom-control-input mr-2'
                  type="checkbox"
                  name={ inputName(formName, "is_scheduled") }
                  id={ inputId(formName, 'is_scheduled') }
                  checked={ bulkRequest?.is_scheduled || false }
                  onChange={ e => onChangeBulkRequest(e, "is_scheduled") }
                  invalid={ _.has(bulkRequest?.errors, 'is_scheduled') }
                />
                <Label for={ inputId(formName, 'is_scheduled') } className="custom-control-label">
                  { I18n.t('activerecord.attributes.abstract/bulk/request/document.is_scheduled') }
                  { drawTooltipHelp(I18n.t('abstract.bulk.request.document_configs.schedule_help')) }
                </Label>
              </div>
            </FieldError>
          </FormGroup>

          { scheduledAtDateTimeInput() }

        </div>
      </div>
    )
  }

  const scheduledAtDateTimeInput = () => {
    if(bulkRequest?.is_scheduled){
      return(
        <FormGroup>
          <FieldError errors={ bulkRequest?.errors?.scheduled_at || []}>
            <Input
              type="datetime-local"
              name={ inputName(formName, "scheduled_at") }
              id={ inputId(formName, 'scheduled_at') }
              value={ bulkRequest?.scheduled_at || '' }
              onChange={ e => onChangeBulkRequest(e, "scheduled_at") }
              invalid={ _.has(bulkRequest?.errors, 'scheduled_at') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { accordionSelectedEntitiesTable }

        { documentTypeBatterySelectInput() }
        { isScheduleSwitchInput() }

        <div className="pt-2">
          <BulkRequestDocumentConfigList
            bulkRequest={ bulkRequest }
            setBulkRequest={ setBulkRequest }
            data={ data }
            configuration={ configuration }
          />
        </div>
      </div>
    </div>
  )
})

export default BulkRequestDocumentConfigTab;
