import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext
} from "react";

import { Button, FormGroup, Label, Input } from "reactstrap";
import I18n from 'i18n-js';
// Form
import { useDrag, useDrop } from "react-dnd";

import FieldError from '../helper/FieldError';
import {
  inputId,
  inputName,
  drawTooltipHelp,
  tooltipInitializaer
} from '../helper/form';

import SignerFormList from "../signer/form_list";
import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerGroupForm = (props) => {
  let signerGroup = useMemo(() => { return props.signerGroup}, [props.signerGroup]);

  const { formName, setResource } = props;

  const {
    callbackSetSignerGroups,
    index,
  } = props;

  useEffect(() => {
    tooltipInitializaer()
  }, [props])

  const {
    resource
  } = useContext(DocumentSignerConfigContext)

  // Start Handle
  const onChangeSignerGroup = (event, key) => {
    let _signerGroup = { ... signerGroup };

    let value = event?.target?.value;
    let type = event?.target?.type

    if(type == 'checkbox'){
      value = event?.target?.checked;
    }

    _signerGroup[key] = value;

    if(_.isFunction(callbackSetSignerGroups)){
      callbackSetSignerGroups(_signerGroup, index);
    }
  }

  const onClickDestroy = event => {
    swalWithBootstrap.fire({
      title: I18n.t('signer_groups.form.destroy.confirm.title'),
      html: I18n.t('signer_groups.form.destroy.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onChangeSignerGroup({
          target: { value: true },
        }, '_destroy')
      }
    })
  }
  // End Handle

  // Start Drag Signer
  const draggableRef = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: "signer-group-card",
    hover(item, monitor) {
      if (!draggableRef.current || item.index === index) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      const hoverBoundingRect = draggableRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if(_.isFunction(props?.handleMoveSignerGroup)){
        item.index = hoverIndex;
        props?.handleMoveSignerGroup(dragIndex, hoverIndex);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "signer-group-card",
    item: {
      id: signerGroup.order,
      index: index,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(draggableRef));

  // End Drag Signer

  // Start Draw
  const drawOrder = () => {
    if(resource.require_signers_order){
      return(
        <span className="text-muted">
          { I18n.t("signer_groups.form.order") }
          &nbsp;
          { signerGroup.order + 1 }
        </span>
      )
    }
  }

  const requireMinimunSignersInput = () => {
    return(
      <FormGroup>
        <div className="custom-switch">
          <Input
            className="custom-control-input"
            type="checkbox"
            onChange={ e => onChangeSignerGroup(e, "require_minimun_signers") }
            checked={ signerGroup.require_minimun_signers || false }
            id={ inputId(formName, `require_minimun_signers`) }
            name={ inputName(formName, "require_minimun_signers") }
            value="1"
            style={{ display: "contents" }}
            disabled={ resource.isPersisted }
          />

          <Label
            className="custom-control-label"
            for={ inputId(formName, `require_minimun_signers`) }
          >
            { I18n.t("activerecord.attributes.signer_group.require_minimun_signers") }
            { drawTooltipHelp(I18n.t('signer_groups.form.require_minimun_signers_info')) }
          </Label>
        </div>
      </FormGroup>
    )
  }

  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(signerGroup, 'errors.name') || [] }>
          <Label for={ inputId(formName, 'name') } className='required'>
            { I18n.t('activerecord.attributes.signer_group.name') }
          </Label>
          <Input
            value={ signerGroup?.name || '' }
            onChange={ event => onChangeSignerGroup(event, 'name') }
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            invalid={ _.has(signerGroup, 'errors.name')}
          />
        </FieldError>
      </FormGroup>
    )
  }

  const minimunSignerInput = () => {
    if(signerGroup.require_minimun_signers){
      return(
        <FormGroup>
          <FieldError errors={ _.get(signerGroup, 'errors.minimun_signers') || [] }>
            <Label for={ inputId(formName, 'minimun_signers') }>
              { I18n.t('activerecord.attributes.signer_group.minimun_signers') }
            </Label>
            <Input
              value={ signerGroup?.minimun_signers || '' }
              type="number"
              min='0'
              onChange={ event => onChangeSignerGroup(event, 'minimun_signers') }
              id={ inputId(formName, 'minimun_signers') }
              name={ inputName(formName, 'minimun_signers') }
              invalid={ _.has(signerGroup, 'errors.minimun_signers')}
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const signerListInput = () => {
    return(
      <React.Fragment>
        <SignerFormList
          signerGroupIndex={ index }
          signerGroup={ signerGroup }
          signers={ signerGroup?.signers || [] }
          formName={ `${ formName }[signers_attributes]`}
          setResource={ setResource }
        />
      </React.Fragment>
    )
  }
  // End Draw

  const containerClassDndContent = () => {
    let klasses = []

    if(resource.require_signers_order){ klasses.push('dnd-content') }
    if(isDragging){ klasses.push('is-dragging') }
    if(isOver){ klasses.push('is-over') }

    return _.join(klasses, ' ');
  }

  return(
    <div className={ `row ${ signerGroup._destroy ? 'd-none' : '' }` }>
      <div className="col-12">
        <div
          className={ `card hover-card signer-group-card ${ containerClassDndContent() }`}
          ref={ draggableRef }
        >
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>
                <i className="fas fa-users text-muted mr-2"></i>
                { drawOrder() }
              </div>
              <div className="text-end">
                <Button
                  color='danger'
                  size='sm'
                  onClick={ e => onClickDestroy(e) }
                  outline
                  disabled={ resource.isPersisted }
                  title={ I18n.t('signer_groups.form.remove_group') }
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </div>

            <div className="row">
              <Input
                type="hidden"
                id={ inputId(formName, 'id') }
                name={ inputName(formName, 'id') }
                value={ signerGroup?.id || '' }
              />

              <Input
                type="hidden"
                id={ inputId(formName, 'order') }
                name={ inputName(formName, 'order') }
                value={ signerGroup?.order || 0 }
              />
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                { nameInput() }
              </div>
              <div className="col-md-4">
                { requireMinimunSignersInput() }
              </div>
              <div className="col-md-4">
                { minimunSignerInput() }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                { signerListInput() }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignerGroupForm;
