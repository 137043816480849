import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import { update as updateLead } from '../../crm/lead/axios'
import I18n from 'i18n-js';

export const PublicTutorialTourVirtualRequestBudgetModalButton = ({ toggle, className }) => {
  return (
    <button className={ className } type="button" onClick={ toggle }>
      Solicitar Cotización
    </button>
  )
}


const PublicTutorialTourVirtualRequestBudgetModal = (props) => {
  const {
    tour,
    isOpen,
    toggle
  } = props;

  const [requestBudgetSent, setRequestBudgetSent] = useState(tour?.resourceable?.aasm_state === "budget_pending");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [observation, setObservation] = useState("");

  const handleSubmitData = () => {
    setLoadingSubmit(true);
    updateLead(
      { id: tour.resourceable_id },
      { event: "to_budget_pending", aasm_state_data: { budget_pending: { observations: observation } } },
      response => {
        if (response.status == 200) {
          window.location = Routes.budget_sent_public_crm_tutorial_tour_path(tour.id)
          setRequestBudgetSent(true);
        } else {
          console.error(response.data.errors)
        }
      });
    setLoadingSubmit(false);
  }

  const contentModal = () => {
    if (requestBudgetSent) {
      return requestBudgetSentSuccess()
    } else {
      return requestBudgetForm()
    }
  }

  const requestBudgetSentSuccess = () => {
    return (
      <p className='m-5'>Solicitud enviada correctamente. Pronto nos pondremos en contacto contigo.</p>
    )
  }

  const requestBudgetForm = () => {
    return (
      <>
        <ModalBody>
          <Label for="exampleText">
            Observacion
          </Label>
          <Input
            id="exampleText"
            name="text"
            type="textarea"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={toggle}>
            {I18n.t('actions.close')}
          </Button>
          <Button
            color='success'
            onClick={handleSubmitData}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                <span>{I18n.t('actions.sending')}</span>
              </>
            ) : (
              I18n.t('actions.send')
            )}
          </Button>
        </ModalFooter>
      </>

    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} >
      <ModalHeader toggle={toggle}>Solicitud de Cotización</ModalHeader>
      {contentModal()}
    </Modal>
  );
}

export default PublicTutorialTourVirtualRequestBudgetModal;
