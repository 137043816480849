import React, {
  useState,
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button
} from 'reactstrap'

import I18n from 'i18n-js';

import FieldError from '../helper/FieldError';

import SignerFormList from '../signer/form_list';

import RequireDefaultBulkRequestConfigForm from './require_default_bulk_request_config_form';

import Select from 'react-select';

import {
  inputId,
  inputName,
  drawTooltipHelp
} from '../helper/form'

import DocumentFormContext from '../document/context/document_form_context';
import DocumentSignerConfigContext from '../document/context/signer_config_context';
import _ from 'lodash';

const DocumentTypeForm = props => {

  const {
    configuration: {
      formName,
      context,
      defaultRequestParams
    },
    actions: {
      createDocumentType,
      updateDocumentType,
      indexDocumentTypesPath,
      showDocumentTypePath
    },
    data: {
      memberships
    }
  } = props;

  const [documentType, setDocumentType] = useState(props?.documentType || {})

  const documentFormContextValue = useMemo(() => {
    return {
      currentUser: props?.currentUser,
      currentCustomer: props?.currentCustomer,
      currentEntityType: props?.currentEntityType,
      context: context,
      data: props?.data,
    }
  }, [])

  const documentSignerConfigContextValue = useMemo(() => {
    return {
      resource: {
        require_signers: documentType?.require_signers,
        require_signers_order: documentType?.require_signers_order,
        isPersisted: false
      },
    }
  }, [
    documentType?.require_signers,
    documentType?.require_signers_order,
  ])

  const handleDocumentType = (event, key) => {
    let value = event?.target?.value;
    const type = event?.target?.type;

    let documentTypeChanges = {}

    if(type == 'checkbox'){
      value = event.target.checked;
    }

    if(key == "require_subscription_member" && !value){
      documentType.membership_ids = []
    }

    documentTypeChanges[key] = value;

    setDocumentType(prevState => {
      return { ...prevState, ... documentTypeChanges }
    })
  }

  // Start Name
  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ documentType?.errors?.name || [] }>
          <Label
            for= { inputId(formName, 'name')}
          >
            { I18n.t(`activerecord.attributes.document_type.name`) }
          </Label>
          <Input
            id={ inputId(formName, 'name') }
            name={ inputName(formName, 'name') }
            invalid={ _.has(documentType?.errors, 'name') }
            value={ documentType?.name || '' }
            onChange={ e => handleDocumentType(e, 'name') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // End Name

  // --------------------------------------------------------

  // Start number_days_to_expire_documents
  const numberDaysToExpireDocumentsInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ documentType?.errors?.number_days_to_expire_documents || [] }>
          <Label
            for= { inputId(formName, 'number_days_to_expire_documents')}
          >
            { I18n.t(`activerecord.attributes.document_type.number_days_to_expire_documents`) }
          </Label>
          { drawTooltipHelp(I18n.t(`document_types.form.number_days_to_expire_documents.tooltip_info`)) }
          <Input
            type='number'
            id={ inputId(formName, 'number_days_to_expire_documents') }
            name={ inputName(formName, 'number_days_to_expire_documents') }
            invalid={ _.has(documentType?.errors, 'number_days_to_expire_documents') }
            value={ documentType?.number_days_to_expire_documents || '' }
            onChange={ e => handleDocumentType(e, 'number_days_to_expire_documents') }
          />
          <small className='text-muted'>
            <span>{ I18n.t(`document_types.form.number_days_to_expire_documents.help`) }</span>
          </small>
        </FieldError>
      </FormGroup>
    )
  }
  // End number_days_to_expire_documents

  // --------------------------------------------------------

  // Start number_days_to_notify_signature_expire
  const numberDaysToNotifySignatureExpireInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ documentType?.errors?.number_days_to_notify_signature_expire || [] }>
          <Label
            for= { inputId(formName, 'number_days_to_notify_signature_expire')}
          >
            { I18n.t(`activerecord.attributes.document_type.number_days_to_notify_signature_expire`) }
          </Label>
          { drawTooltipHelp(I18n.t(`document_types.form.number_days_to_notify_signature_expire.tooltip_info`)) }
          <Input
            type='number'
            id={ inputId(formName, 'number_days_to_notify_signature_expire') }
            name={ inputName(formName, 'number_days_to_notify_signature_expire') }
            invalid={ _.has(documentType?.errors, 'number_days_to_notify_signature_expire') }
            value={ documentType?.number_days_to_notify_signature_expire || '' }
            onChange={ e => handleDocumentType(e, 'number_days_to_notify_signature_expire') }
          />
          <small className='text-muted'>
            <span>{ I18n.t(`document_types.form.number_days_to_notify_signature_expire.help`) }</span>
          </small>
        </FieldError>
      </FormGroup>
    )
  }
  // End number_days_to_notify_signature_expire


  // --------------------------------------------------------

  // Start require_default_bulk_request_config
  const requireDefaultBulkRequestConfigSwitch = () => {
    if(context == 'laboral'){
      return(
        <div className="col-6">
          <FormGroup>
            <div className="custom-control custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_default_bulk_request_config') }
                name={ inputName(formName, 'require_default_bulk_request_config') }
                invalid={ _.has(documentType?.errors, 'require_default_bulk_request_config') }
                onChange={ e => handleDocumentType(e, 'require_default_bulk_request_config') }
                checked={ documentType?.require_default_bulk_request_config || false }
              />
              <Label
                className="custom-control-label"
                for={ inputId(formName, 'require_default_bulk_request_config') }
              >
                { I18n.t(`activerecord.attributes.document_type.require_default_bulk_request_config`) }
              </Label>
              { drawTooltipHelp( I18n.t(`document_types.form.default_bulk_request_config.tooltip_info`) )}
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const requireDefaultBulkRequestConfigForm = () => {
    if(documentType?.require_default_bulk_request_config){
      return(
        <RequireDefaultBulkRequestConfigForm
          defaultBulkRequestConfig={ documentType?.default_bulk_request_config || {} }
          setResource={ setDocumentType }
          data={ props?.data }
          configuration={
            {
              formName: `${ formName }[default_bulk_request_config_attributes]`,
              context: context
            }
          }
        />
      )
    }
  }


  // End require_default_bulk_request_config

  // --------------------------------------------------------

  // Start Signers
  const requireSignersSwitchInput = () => {
    if(context == 'laboral' || context == 'abstract' || context == 'karin_law' || context == 'corporate'){
      return(
        <div className="col-6">
          <FormGroup>
            <div className="custom-control custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_signers') }
                name={ inputName(formName, 'require_signers') }
                invalid={ _.has(documentType?.errors, 'require_signers') }
                onChange={ e => handleDocumentType(e, 'require_signers') }
                checked={ documentType?.require_signers || false }
              />
              <Label
                className="custom-control-label"
                for={ inputId(formName, 'require_signers') }
              >
                { I18n.t(`activerecord.attributes.document_type.require_signers`) }
              </Label>
              { drawTooltipHelp( I18n.t(`activerecord.attributes.document_type.default_signers_help`) ) }
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const requireSignersOrderSwitchInput = () => {
    if(documentType.require_signers) {
      return(
        <div className="col-6">
          <FormGroup>
            <div className="custom-control custom-switch">
              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_signers_order') }
                name={ inputName(formName, 'require_signers_order') }
                invalid={ _.has(documentType?.errors, 'require_signers_order') }
                onChange={ e => handleDocumentType(e, 'require_signers_order') }
                checked={ documentType?.require_signers_order || false }
              />
              <Label
                className="custom-control-label"
                for={ inputId(formName, 'require_signers_order') }
              >
                { I18n.t(`activerecord.attributes.document_type.require_signers_order`) }
              </Label>
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  const signerList = () => {
    if(documentType.require_signers){
      return (
        <div className="row">
          <div className="col-12">
            <div className="card card-material">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    <i className="fas fa-signature mr-2"/>
                    { I18n.t('activerecord.models.default_signer.one') }
                  </div>
                </div>
              </div>
              <div className="card-body">
                <SignerFormList
                  formName={ `${ formName }[default_signers_attributes]` }
                  signers={ documentType.signers }
                  setResource={ setDocumentType }
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  // Start Form Functions
  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('document_types.form.confirm.title'),
      html: I18n.t('document_types.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSubmit = () => {
    const formData = getFormData();

    let requestParams = defaultRequestParams;

    if(documentType?.id){
      requestParams = _.merge(requestParams, { id: documentType.id });

      updateDocumentType(requestParams, formData, response => {
        if(response.status == 200){
          onSubmitRedirect(response)
        } else {
          setDocumentType(response.data);
        }
      })
    } else {
      createDocumentType(requestParams, formData, response => {
        if(response.status == 201){
          onSubmitRedirect(response)
        } else {
          setDocumentType(response.data);
        }
      })
    }
  }

  const onSubmitRedirect = response => {
    if(response?.status == 200 ||  response?.status == 201){
      let requestParams = { ... defaultRequestParams,
        id: response?.data?.hashid,
        _options: true,
        format: ''
      }

      window.location = showDocumentTypePath(requestParams);
    }
  }

  // ---------- START SUBSCRIPTION LIST COMPONENT ----------
  const subscriptionMemberSelectOptions = _.map(memberships, membership => {
    return{ label: membership.user_email, value:membership.id }
  })

  const onChangeSubscriptionMembershipIds = options => {
    let subscriptionMembers = _.map(options, 'value')

    setDocumentType(prevState => ({
      ...prevState,
      ['membership_ids']: [...subscriptionMembers]
    }))
  }

  const subscriptionMemberSelectedOptions = useMemo(() => {
    return _.filter(subscriptionMemberSelectOptions, function(option){ return _.includes(documentType.membership_ids, option.value) })
  }, [documentType.membership_ids])

  const subscriptionMembershipsSelectInput = () => {
    if(documentType?.require_subscription_member){
      return(
        <Select
          className="mt-2"
          isMulti
          name={ inputName(`${ formName }`, 'membership_ids') }
          options={ subscriptionMemberSelectOptions }
          value={ subscriptionMemberSelectedOptions }
          onChange={ options => onChangeSubscriptionMembershipIds(options) }
          placeholder="Agregar..."
        />
      )
    }
  }

  const requireSubscriptionMemberSwitchInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ documentType?.errors?.require_subscription_member || []}>
          <div className="custom-control custom-switch">

              <Input
                className="custom-control-input"
                type="checkbox"
                id={ inputId(formName, 'require_subscription_member') }
                name={ inputName(formName, 'require_subscription_member') }
                invalid={ _.has(documentType?.errors, 'require_subscription_member') }
                checked={ documentType?.require_subscription_member }
                onChange={ e => handleDocumentType(e, 'require_subscription_member') }
                />
            <Label
              className="custom-control-label"
              for={ inputId(formName, 'require_subscription_member') }
            >
              { I18n.t(`activerecord.attributes.document_type.require_subscription_member`) }
            </Label>
            { drawTooltipHelp( I18n.t(`document_types.form.require_subscription_member.tooltip_info`) )}
            <i className="fas fa-exclamation-triangle fa-lg text-danger ml-2"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={ I18n.t(`document_types.form.require_subscription_member.tooltip_warning`) }>
            </i>
          </div>
          { subscriptionMembershipsSelectInput() }
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END SUBSCRIPTION LIST COMPONENT ----------

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, documentType?.id || '');
    formData.append(`${ formName }[name]`, documentType?.name || '');
    formData.append(`${ formName }[require_signers]`, documentType?.require_signers || false);
    formData.append(`${ formName }[require_signers_order]`, documentType?.require_signers_order || false);
    formData.append(`${ formName }[number_days_to_expire_documents]`, documentType?.number_days_to_expire_documents || '0');
    formData.append(`${ formName }[require_default_bulk_request_config]`, documentType?.require_default_bulk_request_config || false);
    formData.append(`${ formName }[number_days_to_notify_signature_expire]`, documentType?.number_days_to_notify_signature_expire || '0');
    formData.append(`${ formName }[require_subscription_member]`, documentType?.require_subscription_member);

    if(documentType?.membership_ids.length > 0) {
      _.each(documentType?.membership_ids || [], membershipId => {
        formData.append(`${ formName }[membership_ids][]`, membershipId)
      })
    }else {
      formData.append(`${ formName }[membership_ids][]`, [])
    }

    if (documentType.require_signers === false) {
      _.each(documentType.signers, function(default_signer) {
        default_signer._destroy = true;
      })
    }

    _.each(documentType.signers, function(signer, index){
      formData.append(`${ formName }[signers_attributes][${ index }][id]`, signer?.id || '');
      formData.append(`${ formName }[signers_attributes][${ index }][name]`, signer?.name || '');
      formData.append(`${ formName }[signers_attributes][${ index }][email]`, signer?.email || '');
      formData.append(`${ formName }[signers_attributes][${ index }][role]`, signer?.role || '');
      formData.append(`${ formName }[signers_attributes][${ index }][order]`, signer?.order || '0');
      formData.append(`${ formName }[signers_attributes][${ index }][employee_sign]`, signer?.employee_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][company_sign]`, signer?.company_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][company_email]`, signer?.company_email || '');
      formData.append(`${ formName }[signers_attributes][${ index }][member_sign]`, signer?.member_sign || false);
      formData.append(`${ formName }[signers_attributes][${ index }][_destroy]`, signer?._destroy || false);
    })

    if(documentType?.require_default_bulk_request_config){
      let defaultBulkRequestConfig = documentType?.default_bulk_request_config || {}

      formData.append(`${ formName }[default_bulk_request_config_attributes][id]`, defaultBulkRequestConfig?.id || '');
      formData.append(`${ formName }[default_bulk_request_config_attributes][autocompleted_by_entity]`, defaultBulkRequestConfig?.autocompleted_by_entity || false);
      formData.append(`${ formName }[default_bulk_request_config_attributes][uploaded_by]`, defaultBulkRequestConfig?.uploaded_by || '');
      formData.append(`${ formName }[default_bulk_request_config_attributes][template_id]`, defaultBulkRequestConfig?.template_id || '');
    } else {
      let defaultBulkRequestConfig = documentType?.default_bulk_request_config || {}

      formData.append(`${ formName }[default_bulk_request_config_attributes][id]`, defaultBulkRequestConfig?.id || '');
      formData.append(`${ formName }[default_bulk_request_config_attributes][_destroy]`, true);
    }

    return formData;
  }
  // End Form Functions


  // ---------- START ADVANCED CONFIGURATION ----------
  const advancedConfiguration = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card hover-card card-material">
            <div className="card-header px-2 px-md-3 __with_collapsible-icons border-0">
              <a aria-controls="collapseAdvancedConfiguration" aria-expanded="false" className="font-weight-bold text-dark text-decoration-none d-block" data-bs-toggle="collapse" href="#collapseAdvancedConfiguration" role="button">
                <i className="fas fa-cog mr-2"></i>
                Configuración Avanzada
              </a>
            </div>
            <div className="collapse border-top" id="collapseAdvancedConfiguration">
              <div className="card-body lh-sm">
                { numberDaysToExpireDocumentsInput() }
                { numberDaysToNotifySignatureExpireInput() }
                <div className="row">
                  { requireSignersSwitchInput() }
                  { requireDefaultBulkRequestConfigSwitch() }
                  { requireSignersOrderSwitchInput() }
                  { requireSubscriptionMemberSwitchInput() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ---------- END ADVANCED CONFIGURATION ----------

  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <DocumentSignerConfigContext.Provider value={ documentSignerConfigContextValue }>
        <div className="row">
          <div className="col-12">
            <div className="card card-outline">
              <div className="card-body">
                { nameInput() }
              </div>
            </div>

            { advancedConfiguration() }


            { requireDefaultBulkRequestConfigForm() }
            { signerList() }
          </div>

          <div className="col-12">
            <FormGroup className='text-end'>
              <a
                href={ indexDocumentTypesPath({ ... defaultRequestParams }) }
                className='btn btn-default'
              >
                { I18n.t('actions.back') }
              </a>

              <Button
                color='success'
                className='ml-2'
                onClick={ e => onConfirmSubmit()  }
              >
                { I18n.t('actions.save') }
              </Button>
            </FormGroup>
          </div>
        </div>

      </DocumentSignerConfigContext.Provider>
    </DocumentFormContext.Provider>

  )
}

export default DocumentTypeForm;
