import React from 'react';
import {
  FormFeedback
} from 'reactstrap';
import I18n from 'i18n-js';

export const drawTooltipEmptyToken = integration_params => {
  if(!integration_params?.controldoc_sign_api_token){
    return(
      <button
        type="button"
        className="btn btn-link p-0 ml-2"
        data-bs-html={ true }
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={
          I18n.t('laboral.hiring_documents.form.require_signers.helper.without_controldoc_sign_integration',{
            url: Routes.edit_profiles_path()
          })
        }
      >
        <i className="fas fa-info-circle text-info fa-lg"></i>
      </button>
    )
  }
}

export const drawTooltipRequireSigners = integration_params => {
  if(
    integration_params?.controldoc_sign_api_email &&
    integration_params?.controldoc_sign_api_token
  ){
    return(
      <button
        type="button"
        className="btn btn-link p-0 ml-2"
        data-bs-html={ true }
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={ I18n.t('laboral.hiring_documents.form.require_signers.helper.title') }
      >
        <i className="fas fa-info-circle text-info fa-lg"></i>
      </button>
    )
  }
}

export const disableRequireSignerInput = integration_params => {
  return(
    !integration_params?.controldoc_sign_api_email ||
    !integration_params?.controldoc_sign_api_token
  )
}

export const drawSalesInfoControlDocSign = integration_params => {
  if(
    !integration_params?.controldoc_sign_api_email ||
    !integration_params?.controldoc_sign_api_token
  ){
    return(
      <div className="col-md-12">
        <div className="gradient-border-box">
          <div className="alert alert-light bg-white p-3 d-flex align-items-md-center material-shadow">
            <i className="fas fa-file-signature fa-2x fa-pull-left gradient-text-4t"></i>
            <div>
              Desbloquea la <strong>Firma Electronica Simple o Avanzada</strong> con <strong>ControlDoc Sign</strong>.
              <p className="mb-0 small">
                Comunícate al correo{" "}
                <a href="mailto:ventas@controldoc.cl" className="font-weight-bold text-dark text-decoration-none">ventas@controldoc.cl</a>{" "}
                para consultar valores y formas de pago.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
