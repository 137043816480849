import React from 'react';
import { UncontrolledAlert, Label, List } from 'reactstrap';
import I18n from 'i18n-js';

const ListMessages = (props) => {

  return(
    <UncontrolledAlert color = "danger">
      <Label>{ I18n.t("errors.messages.error_excel") }</Label>
      <div className='row'>
        <div dangerouslySetInnerHTML={{__html: I18n.t("errors.messages.error_columns_excel") }} />
        <div className='col-md-6'>
          <Label> Columnas permitidas</Label>

          <List className='danger' type = "unstyled">
            {
              _.map(props?.headers, function(header){
                return(
                  <ul key={ _.uniqueId("ul-header-")} >
                    <li>{ header }</li>
                  </ul>
                )
              })
            }
          </List>
        </div>
        <div className='col-md-6'>
          <Label> Columnas en el excel</Label>
          <List className='danger' type = "unstyled">
            {
              _.map(props?.headersFile, function(column){
                return(
                  <ul key={ _.uniqueId("ul-column-")}>
                    <li>{ column }</li>
                  </ul>
                )
              })
            }
          </List>
        </div>
      </div>
    </UncontrolledAlert>

  )
}

export default ListMessages;
