import React, { useState, useRef } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";

import ControldocFormTemplateLayoutEditable from "./editable";
import I18n from "i18n-js";

import { inputId, inputName } from "../../helper/form";

import FieldError from "../../helper/FieldError";
import ControldocFormTemplateLayoutPreview from './preview';
import _ from "lodash";

const ControldocFormTemplateLayoutForm = props => {
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const bodyExample = ' \
  <body> \
    <h1 class="text-center">Contrato</h1> \
    <p style="font-size: 20px; margin-bottom: 50px">Este texto corresponde a un ejemplo de un documento con la finalidad de poder previsualizar la configuración de la cabecera y el pie de página del documento. Es importante que te asegures que tu configuración se ve de forma correcta con el botón "Previsualizar" con el fin de evitar errores en los documentos.</p> \
  </body> \
  '
  const { configuration, actions } = props;
  const { formName, context } = configuration;
  const {
    createTemplateLayout,
    updateTemplateLayout,
    previewTemplateLayout,
    showTemplateLayoutPath,
    indexTemplateLayoutPath,
  } = actions;

  const [ templateLayout, setTemplateLayout ] = useState(props?.templateLayout || {});
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewPdfUrl, setPreviewPdfUrl] = useState(null);

  const defaultRequestParams = (context == 'abstract') ? { current_entity_type_id: props?.currentEntityType?.hashid } : {}

  const handleData = (e, key) => {
    let _template_layout = { ... templateLayout };

    let value = e?.target?.value;

    _template_layout[key] = value;
    setTemplateLayout(_template_layout);
  }

  const handlePreview = () => {
    let formData = getFormData();

    previewTemplateLayout(defaultRequestParams, formData, response => {
      if(response.status == 200){
        let blob = new Blob([response.data], {type: "application/pdf"});
        let pdfUrl = URL.createObjectURL(blob);
        setPreviewPdfUrl(pdfUrl);
        setShowPreviewModal(true)
      } else {
        console.error("Invalid JSON: " + response);
      }
    })
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('controldoc_form.template_layouts.form.confirm.title'),
      html: I18n.t('controldoc_form.template_layouts.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        handleSave();
      }
    })
  }

  const handleSave = () => {
    let formData = getFormData();

    let requestParams = defaultRequestParams;

    if(templateLayout.id){
      requestParams = _.merge(requestParams, { id: templateLayout.id });
      updateTemplateLayout(requestParams, formData, response => {
        if(response.status == 200){
          handleSubmitRedirect(response);
        } else {
          setTemplateLayout(response.data);
        }
      })
    } else {
      createTemplateLayout(requestParams, formData, response => {
        if(response.status == 201){
          handleSubmitRedirect(response)
        } else if(response.status == 422) {
          setTemplateLayout(response.data);
          handleIntegrationErrorMessage(response.data?.errors)
        }
      })
    }
  }

  const handleSubmitRedirect = response => {
    let requestParams = defaultRequestParams;
    if(response.status == 201 || response.status == 200){
      requestParams = { ... requestParams,
        id: response?.data?.hashid,
        _options: true,
        format: ''
      }

      window.location = showTemplateLayoutPath(requestParams)
    }
  }

  const handleIntegrationErrorMessage = errors => {
    swalWithBootstrap.fire({
      icon: "error",
      title: I18n.t('controldoc_form.template_layouts.form.error'),
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: "<i class='fa fa-thumbs-up'></i> Entendido",
      text: errors.base[0],
    });
  };

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, templateLayout?.id || '')
    formData.append(`${ formName }[name]`, templateLayout?.name || '')
    formData.append(`${ formName }[header]`, headerRef?.current?.getContent() || '')
    formData.append(`${ formName }[footer]`, footerRef?.current?.getContent() || '')

    return formData;
  }

  return(
    <div className="row">
      <div className="col-12">
        <ControldocFormTemplateLayoutPreview
          showPreviewModal={ showPreviewModal }
          setShowPreviewModal={ setShowPreviewModal }
          previewPdfUrl={ previewPdfUrl }
        />

        <div className="card">
          <div className="card-body">
            <FormGroup>
              <FieldError errors={ templateLayout?.errors?.name || [] }>
                <Label
                  for={ inputId(formName, 'name') }
                >
                  { I18n.t(`activerecord.attributes.controldoc_form/template_layout.name`) }
                </Label>

                <Input
                  id={ inputId(formName, 'name') }
                  name={ inputName(formName, 'name') }
                  invalid={ _.has(templateLayout?.errors, 'name') }
                  value={ templateLayout?.name || '' }
                  onChange={ e => handleData(e, 'name') }
                />
              </FieldError>
            </FormGroup>
            <ControldocFormTemplateLayoutEditable
              editorRef={ headerRef }
              context={ 'header' }
              imagePicker={ props?.imagePicker }
              templateLayout={ templateLayout }
            />
            <div
              className="text-muted my-5"
              dangerouslySetInnerHTML={{__html: bodyExample }}
            />
            <ControldocFormTemplateLayoutEditable
              editorRef={ footerRef }
              context={ 'footer' }
              imagePicker={ props?.imagePicker }
              templateLayout={ templateLayout }
            />
          </div>
        </div>
      </div>
      <FormGroup className="text-end">
        <a
          href={ indexTemplateLayoutPath(defaultRequestParams) }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>

        <Button
          color='primary'
          className='ml-2'
          onClick={ e => handlePreview() }
        >
          { I18n.t('actions.preview') }
        </Button>

        <Button
          color='success'
          className='ml-2'
          onClick={ e => handleConfirmSubmit() }
        >
          { I18n.t('actions.save') }
        </Button>
      </FormGroup>
    </div>
  )
}

export default ControldocFormTemplateLayoutForm;
