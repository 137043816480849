import axios from 'axios';

export const create = (formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.laboral_bulk_my_filters_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise.then(response => {
    callback(response);
  }).catch(error => {
    callback(error.response);
  });
}

export const update = (params, formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: Routes.laboral_bulk_my_filter_path({id: params.id, format: "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise.then(response => {
    callback(response);
  }).catch(error => {
    callback(error.response);
  });
}

export const index = (callback) => {

  var promise = axios({
    method: 'get',
    url: Routes.laboral_bulk_my_filters_path({ format: "json"}),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const destroy = (requestParams, callback) => {
  var promise = axios({
    method: 'delete',
    url: Routes.laboral_bulk_my_filter_path({...{...requestParams}, format: 'json', _options: true }),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

