import axios from "axios";

export const create = (formData, callback) => {
  var promise = axios({
    method: 'POST',
    url: Routes.corporate_check_list_agreement_processes_path({ format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const update = (id, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.corporate_check_list_agreement_process_path(id, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const share = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.share_corporate_check_list_agreement_process_path(requestParams, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};