import React from 'react';

import WorkflowRequirementStageShortlistInputForm from '../../../../workflow/requirement_stage/input_form';

import {
  update
} from '../axios'

const AbstractWorkflowRequirementStageShortlistInputForm = props => {
  const {
    currentUser,
    currentCustomer,
    currentEntityType,
    requirementStage,
    data
  } = props;

  const defaultRequestParams = { current_entity_type_id: currentEntityType.hashid }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <WorkflowRequirementStageShortlistInputForm
              requirementStage={ requirementStage }
              configuration={{
                defaultRequestParams: defaultRequestParams
              }}
              actions={{
                update: update,
                showPath: Routes.abstract_workflow_requirement_stage_path
              }}
              data={ data }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AbstractWorkflowRequirementStageShortlistInputForm;
