import React, {
  useRef,
  useMemo
} from 'react';

import I18n from "i18n-js";

import {
  UncontrolledTooltip
} from 'reactstrap';

import { textFilter, selectFilter } from "react-bootstrap-table2-filter";

import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";

import BulkRequestDocumentForm from '../../../../bulk/request/document/form';

import DocumentFormContext from '../../../../document/context/document_form_context';

import { limitCharacters } from "../../../../helper/form";

import {
  index as indexEntities
} from "../../../agreement/axios";

import {
  create as createBulkRequest
} from './axios';

const CorporateBulkRequestAgreementDocumentForm = props => {
  const bootstrapTableRef = useRef(null);

  const {
    bulkRequest,
    currentUser,
    currentCustomer,
    data: {
      companies,
      customFields
    }
  } = props;

  const actionsProps = {
    indexEntities: indexEntities,
    createBulkRequest: createBulkRequest,
    showBulkRequestPath: Routes.corporate_bulk_request_agreement_document_path,
    indexBulkRequestPath: Routes.corporate_bulk_request_agreement_documents_path
  };

  const defaultRequestParams = {};

  const context = 'corporate_bulk'

  const entityConfiguration = {
    key: 'agreements',
    form: {
      entityItemsAttributes: {
        key: 'agreement_items_attributes',
        entityId: 'agreement_id'
      },
    },
    label: {
      singular: I18n.t("activerecord.models.corporate/agreement.one"),
      plural: I18n.t("activerecord.models.corporate/agreement.other")
    },
    navButtonTitle: I18n.t("activerecord.models.corporate/agreement.other")
  }

  const documentFormContextValue = {
    currentUser: currentUser || {},
    currentCustomer: currentCustomer || {},
    isPersisted: false,
    context: "corporate_bulk",
    data: props?.data,
    entities: [],
  }

  const entityHeaderConfigTab = useMemo(() => {
    return _.flattenDeep([
      {
        label: I18n.t("activerecord.attributes.corporate/agreement.client"),
        key: 'agreement_config.client.company_config.name'
      },
      {
        label: I18n.t("activerecord.attributes.corporate/agreement.provider"),
        key: 'agreement_config.provider.company_config.name'
      },
      {
        label: I18n.t("activerecord.attributes.corporate/agreement.start_date"),
        key: 'start_date_to_s'
      },
      {
        label: I18n.t("activerecord.attributes.corporate/agreement.end_date"),
        key: 'end_date_to_s'
      }
    ])
  }, [])


  // ---------------- START TABLE ----------------




  const headerCompanyEmail = (value, email, row) => {
    return(
      <>
        <p className="mb-0">{ value }</p>
        <div className="text-muted small">
          <i className="far fa-envelope mr-2"></i>
          {function(){
            if(!email){
              return(
                <div className="d-inline-block">
                  <span id="tooltipTonAlert">
                    <i className="fas fa-exclamation-circle text-danger"/>
                  </span>
                  <UncontrolledTooltip key={  _.uniqueId(row.id) } placement="bottom" target="tooltipTonAlert">
                    { I18n.t('activerecord.errors.models.corporate/company.attributes.config') }
                  </UncontrolledTooltip>
                </div>
              )
            }
          }()}
        </div>
      </>
    )

  }

  const tableColumnsDefault = [
    {
      dataField: "agreement_config.client.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement.client"),
      filterByFilterrific: 'by_client',
      headerStyle: {
        width: "200px",
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      filter: selectFilter({
        placeholder: `Selecciona un ${_.lowerCase(I18n.t("activerecord.attributes.corporate/agreement.client"))}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name
        }))
      }),
      formatter: function (value, row, index) {
        const email = row.agreement_config.client.company_config.legal_representative_email;
        return headerCompanyEmail(value, email, row)
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }  }
        />
      ),
    },
    {
      dataField: "agreement_config.provider.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement.provider"),
      headerStyle: {
        width: "200px",
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      filterByFilterrific: 'by_provider',
      filter: selectFilter({
        placeholder: `Selecciona un ${_.lowerCase(I18n.t("activerecord.attributes.corporate/agreement.provider"))}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name
        }))
      }),
      formatter: function (value, row, index) {
        let email = row.agreement_config.provider.company_config.legal_representative_email;
        return headerCompanyEmail(value, email, row)
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }  }
        />
      ),
    },
    {
      dataField: "start_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement.start_date"),
      headerStyle: {
        width: "200px",
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: function (value, row, index) {
        return (
          <p>{ value }</p>
        )
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }  }
        />
      ),
    },
    {
      dataField: "end_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement.end_date"),
      headerStyle: {
        width: "200px",
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: function (value, row, index) {
        return(
          <p>{ value || "Indefinido" }</p>
        )
      },
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef }  }
        />
      ),
    },
  ];

  const tableColumnsCustomFields = () => {
    return _.map(customFields, function(customField){
      return {
        dataField: `custom_fields.${ customField.code }`,
        text: _.capitalize(customField.label),
        headerStyle: {
          width: "200px",
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        // filterByFilterrific: `custom_fields.${ customField.code }`,
        // filter: textFilter({
        //   placeholder: `Ingrese ${_.lowerCase(customField.label) }`
        // }),
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable: bootstrapTableRef } }
          />
        ),
        formatter:(value, row, index) => { return limitCharacters(value) },
      }
    })
  }

  const tableColumns = _.concat(
    tableColumnsDefault,
    tableColumnsCustomFields()
  )

  // ---------------- END TABLE ----------------
  return(
    <DocumentFormContext.Provider value={ documentFormContextValue }>
      <div className="row">
        <div className="col-12">
          <BulkRequestDocumentForm
            bulkRequest={ bulkRequest }
            bootstrapTableConfiguration={{
              ref: bootstrapTableRef,
              tableColumns: tableColumns
            }}
            configuration={{
              showBulkMyFilter: false,
              defaultRequestParams: defaultRequestParams,
              entity: entityConfiguration,
              entityHeaderConfigTab: entityHeaderConfigTab,
              context: context,
              formName: 'corporate_bulk_request_agreement_document'
            }}
            actions={
              actionsProps
            }
            data={ props?.data }
          />
        </div>
      </div>
    </DocumentFormContext.Provider>
  )
};

export default CorporateBulkRequestAgreementDocumentForm;
