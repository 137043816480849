import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js';

import { inputId, inputName, drawErrors } from '../../../helper/form';

import {
  Button,
  Form,
  Input,
  FormGroup,
  Label
} from 'reactstrap'

const ControldocSignDocumentMetaInformationForm = props => {
  const formName = 'meta_information';
  const resources = props?.resources;
  const [metaInformation, setMetaInformation] = useState(props?.metaInformation);
  const [resource, setResource] = useState({});

  useEffect(() => {
    if(metaInformation?.resource){
      setResource(getResource(metaInformation?.resource))
    }
  }, [])

  const handleData = (e, key) => {
    let _metaInformation = { ... metaInformation };
    let value = e?.target?.value || '';

    if(key === 'resource'){
      setResource(getResource(value));
    }

    _metaInformation[key] = value;
    setMetaInformation(_metaInformation);
  }

  const getResource = (value) => {
    return _.find(resources, resource=>{ return resource?.resource_class == value });
  }

  const drawResource = () => {
    return(
      <div className="row">
        <div className="col-12 mb-2">
          <Label
            for={ inputId(formName, 'resource') }
          >
            { I18n.t('activerecord.attributes.controldoc_sign/document/meta_information.resource') }
          </Label>
          <Input
            id={ inputId(formName, 'resource') }
            name={ inputName(formName, 'resource') }
            type='select'
            onChange={ e => handleData(e, 'resource') }
            value={ metaInformation?.resource || ""}
            invalid={ _.has(metaInformation?.errors, 'resource') }
          >
            <option value="">
              { I18n.t('inputs.select_a_option') }
            </option>
            { _.map(resources, resource => {
              return(
                <option
                  value={ resource.resource_class }
                  key={ _.uniqueId('resource-item-') }
                >
                  { resource.label}
                </option>
              )
            })}
          </Input>
          { drawErrors(metaInformation?.errors, 'resource') }
        </div>
      </div>
    )
  }

  const drawResourceAttribute = () => {
    if(metaInformation?.resource){
      return (
        <div className="row">
          <div className="col-12">
            <Label
              for={ inputId(formName, 'resource_attribute') }
            >
              { I18n.t('activerecord.attributes.controldoc_sign/document/meta_information.resource_attribute') }
            </Label>
            <Input
              id={ inputId(formName, 'resource_attribute') }
              name={ inputName(formName, 'resource_attribute') }
              type='select'
              onChange={ e => handleData(e, 'resource_attribute') }
              value={ metaInformation?.resource_attribute || '' }
              invalid={ _.has(metaInformation?.errors, 'resource_attribute') }
            >
              <option value="">
                { I18n.t('inputs.select_a_option') }
              </option>
              { _.map(resource.resource_attributes, attribute => {
                return(
                  <option
                    value={ attribute.key }
                    key={ _.uniqueId('resource-item-') }
                  >
                    { _.capitalize(attribute.label) }
                  </option>
                )
              })}
            </Input>
            { drawErrors(metaInformation?.errors, 'resource_attribute') }
          </div>
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-header">
            { I18n.t('activerecord.models.controldoc_sign/document/meta_information.one') }
          </div>
          <div className="card-body">
            <FormGroup>
              <Label
                for={ inputId(formName, 'name') }
              >
                { I18n.t('activerecord.attributes.controldoc_sign/document/meta_information.name') }
              </Label>
              <Input
                className='mb-2'
                id={ inputId(formName, 'name') }
                name={ inputName(formName, 'name') }
                onChange={ e => handleData(e, "name") }
                value={ metaInformation?.name || ''}
                type='text'
              />

              { drawResource() }
              { drawResourceAttribute() }
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ControldocSignDocumentMetaInformationForm;
