import React from 'react';

import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  useReactFlow,
  MiniMap
} from 'reactflow';

import WorkflowDefaultNode from '../common/custom_nodes/default_node';
import WorkflowInputNode from '../common/custom_nodes/input_node';
import WorkflowOutputNode from '../common/custom_nodes/output_node';

import BidirecionalEdge from '../common/custom_edges/bidireccional_edge';
import DirectionalEdge from '../common/custom_edges/direccional_edge';

import { WorkflowDiagramProvider } from '../diagram_context';

const nodeTypes = {
  default: WorkflowDefaultNode,
  input: WorkflowInputNode,
  output: WorkflowOutputNode,
}

const edgeTypes = {
  bidirectional: BidirecionalEdge,
  directional: DirectionalEdge
}

import {
  defaultEdgeOptions
} from '../common/default_options'

const WorkflowRequirementRoadMap = props => {

  const {
    requirement
  } = props;

  const currentStageId = requirement.current_requirement_stage.stage.id;

  const nodes = _.map(requirement.stages, stage => {
    if(stage.id == currentStageId){
      stage.config_react_flow.data['isCurrent'] = true
    }

    return stage.config_react_flow;
  });

  const edges = _.map(requirement.edges, 'config_react_flow');

  return(
    <div className="vh-100">
      <ReactFlowProvider>
        <WorkflowDiagramProvider value={{
            readOnly: true,
            showPointer: props?.showPointer
          }
        }>
          <div style={{ 'width': '100%', 'height': '100%', 'minHeight': '100vh' }}>
            <ReactFlow
              nodes={ nodes }
              edges={ edges }
              nodeTypes={ nodeTypes }
              edgeTypes={ edgeTypes }
              defaultEdgeOptions={ defaultEdgeOptions }
              className="intersection-flow"
              minZoom={ 0.2 }
              maxZoom={ 4 }
              fitView
            >
              <Background />
              <Controls />
              <MiniMap/>
            </ReactFlow>
          </div>
        </WorkflowDiagramProvider>
      </ReactFlowProvider>
    </div>
  )
}

export default WorkflowRequirementRoadMap;
