import React from 'react';

import { updateOrder as updateAbstractCustomFields } from "./axios";
import CustomFieldOrderFormList from '../../custom_field/order_form_list';

const AbstractCustomFieldOrderFormList = (props) => {
  const defaultRequestParams = { current_entity_type_id: props?.currentEntityType.hashid };

  return(
    <div className="row">
      <div className="col-12">
        <CustomFieldOrderFormList
          formName='abstract_custom_fields'
          currentEntityType={ props?.currentEntityType }
          defaultRequestParams={ defaultRequestParams }
          customFields={ props?.customFields }
          actions={{
            indexCustomFieldsPath: Routes.abstract_custom_fields_path,
            updateOrderCustomFields: updateAbstractCustomFields
          }}
        />
      </div>
    </div>
  )
};

export default AbstractCustomFieldOrderFormList;
