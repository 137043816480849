import React, { useState, useEffect } from "react";

import I18n from "i18n-js";

import { FormGroup, Label, Input, Button } from "reactstrap";

const TableFilter = props => {
  const filter = props?.filter;
  const handleOptionFilter = props?.handleOptionFilter;
  const companies = props?.companies || [];

  const drawFilterCompanies = () => {
    return (
      <FormGroup>
        <Label>{I18n.t("laboral.hirings.filter.by_company")}</Label>
        <Input
          type="select"
          onChange={e => handleOptionFilter("company_id", e)}
          value={filter["company_id"] || ""}
        >
          <option value=""></option>
          {_.map(companies, function (company, index) {
            return (
              <option
                value={company[1]}
                key={`company-filter-select-${company[1]}`}
              >
                {company[0]}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  const drawFilterEmployeeName = () => {
    return (
      <div>
        <Label className="mb-0">
          {I18n.t("laboral.hirings.filter.by_employee")}
        </Label>

        <FormGroup className="mb-1">
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.employee_name")}</Label>
          </small>
          <Input
            type="text"
            onChange={e => handleOptionFilter("employee_name", e)}
            value={filter["employee_name"] || ""}
          />
        </FormGroup>
      </div>
    );
  };

  const drawFilterHiringState = () => {
    return (
      <div>
        <Label className="mb-0">
          {I18n.t("laboral.hirings.filter.by_hiring")}
        </Label>

        <FormGroup className="mb-1">
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.hiring_state")}</Label>
          </small>
          <Input
            type="select"
            onChange={e => handleOptionFilter("hiring_state", e)}
            value={filter["hiring_state"] || ""}
          >
            <option value=""></option>
            <option value="active">Activa</option>
            <option value="inactive">Inactiva</option>
          </Input>
        </FormGroup>
      </div>
    );
  };

  const drawFilterStartDate = () => {
    return (
      <div>
        <Label className="mb-0">
          {I18n.t("laboral.hirings.filter.with_start_date")}
        </Label>

        <FormGroup className="mb-1">
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.start_date")}</Label>
          </small>
          <Input
            type="date"
            onChange={e => handleOptionFilter("with_start_date_start_date", e)}
            value={filter["with_start_date_start_date"] || ""}
          />
        </FormGroup>

        <FormGroup>
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.end_date")}</Label>
          </small>

          <Input
            type="date"
            onChange={e => handleOptionFilter("with_start_date_end_date", e)}
            value={filter["with_start_date_end_date"] || ""}
          />
        </FormGroup>
      </div>
    );
  };

  const drawFilterEndDate = () => {
    return (
      <div>
        <Label className="mb-0">
          {I18n.t("laboral.hirings.filter.with_end_date")}
        </Label>

        <FormGroup className="mb-1">
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.start_date")}</Label>
          </small>
          <Input
            type="date"
            onChange={e => handleOptionFilter("with_end_date_start_date", e)}
            value={filter["with_end_date_start_date"] || ""}
          />
        </FormGroup>

        <FormGroup>
          <small className="text-muted">
            <Label>{I18n.t("laboral.hirings.filter.end_date")}</Label>
          </small>

          <Input
            type="date"
            onChange={e => handleOptionFilter("with_end_date_end_date", e)}
            value={filter["with_end_date_end_date"] || ""}
          />
        </FormGroup>
      </div>
    );
  };

  return (
    <aside className="control-sidebar control-sidebar-light">
      <div className="control-sidebar-content">
        <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
          <h5 className="font-weight-bold mb-0">
            {I18n.t("common.dashboard.control_sidebar.title")}
          </h5>
          <div className="text-end">
            <button
              aria-label="Close"
              className="btn-close"
              data-widget="control-sidebar"
              type="button"
            ></button>
          </div>
        </div>
        <div className="p-3">
          {drawFilterCompanies()}
          {drawFilterEmployeeName()}
          {drawFilterHiringState()}

          {drawFilterStartDate()}
          {drawFilterEndDate()}

          <div className="row">
            <div className="col-12 text-right mt-3">
              <a href="#" onClick={e => props?.clearFilter()}>
                {I18n.t("actions.clean_filter")}
              </a>
            </div>

            <div className="col-12 text-right mt-3">
              <Button
                color="primary"
                outline
                block
                onClick={e => props?.handleSubmitFilter()}
              >
                {I18n.t("actions.search")}
              </Button>

              <Button
                color="light"
                data-widget="control-sidebar"
                block
                className="mt-2"
              >
                {I18n.t("actions.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default TableFilter;
