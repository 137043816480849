import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import screenfull from 'screenfull';

const TourControls = ({ videoRef }) => {

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer().playVideo();
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer().pauseVideo();
    }
  };

  const handleRewind = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(0);
      videoRef.current.getInternalPlayer().playVideo();
    }
  };

  const handleStop = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer().pauseVideo();
      videoRef.current.seekTo(0);
    }
  };

  const handleFullscreen = () => {
    const playerElement = videoRef.current.getInternalPlayer().getIframe();

    if (playerElement) {
      if (playerElement.requestFullscreen) {
        playerElement.requestFullscreen();
      } else if (playerElement.mozRequestFullScreen) { // Firefox
        playerElement.mozRequestFullScreen();
      } else if (playerElement.webkitRequestFullscreen) { // Chrome, Safari y Opera
        playerElement.webkitRequestFullscreen();
      } else if (playerElement.msRequestFullscreen) { // IE/Edge
        playerElement.msRequestFullscreen();
      }
    }
    toggleTooltip('fullscreen');
  };

  const fullScreenButton = () => {
    if(screenfull.isEnabled){
      return(
        <Button className="text-center" title="Pantalla Completa" onClick={handleFullscreen}>
          <i className="fas fa-expand-alt"></i>
        </Button>
      )
    }
  }
  
  return (
    <ButtonGroup className="video-controls">
      <Button className="text-center" title="Reproducir" onClick={handlePlay}>
        <i className="fas fa-play"></i>
      </Button>
      <Button className="text-center" title="Pausar" onClick={handlePause}>
        <i className="fas fa-pause"></i>
      </Button>
      <Button className="text-center" title="Reiniciar" onClick={handleRewind}>
        <i className="fas fa-undo"></i>
      </Button>
      <Button className="text-center" title="Detener" onClick={handleStop}>
        <i className="fas fa-stop"></i>
      </Button>
      { fullScreenButton() }
    </ButtonGroup>
  );
};

export default TourControls;
