import React, { useEffect, useState } from "react";

import CheckListProcessForm from '../../../check_list/process/form';

import { create, update } from './axios';

const AbstractCheckListProcessForm = props => {

  const currentEntityType = props?.currentEntityType || {}

  return(
    <div className="row">
      <div className="col-12">
        <CheckListProcessForm
          context="abstract"
          checkListProcess={ props?.checkListProcess }
          documentTypes={ props?.documentTypes }
          dataFilters={ props?.dataFilters }
          currentEntityType={ currentEntityType }
          customFields={ props?.customFields }
          actions={{
            createAction: create,
            updateAction: update,
            showPath: Routes.abstract_check_list_process_path,
            indexPath: Routes.abstract_check_list_processes_path({ current_entity_type_id: currentEntityType.hashid })
          }}
        />
      </div>
    </div>
  )
};

export default AbstractCheckListProcessForm;
