import React, {
  useMemo
} from 'react'

import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import I18n from 'i18n-js';
import Select from 'react-select';

import FieldError from '../helper/FieldError';

import {
  inputId,
  inputName,
} from '../helper/form'

const RequireDefaultBulkRequestConfigForm = React.memo((props) => {

  const {
    defaultBulkRequestConfig,
    setResource,
    data,
    configuration: { formName, context }
  } = props;

  const handleDefaultBulkRequestConfig = (event, key) => {
    let value = event.target.value;

    setResource(prevState => {
      return {
        ...prevState,
        default_bulk_request_config: {
          ... prevState.default_bulk_request_config, [key]: value
        }
      }
    })
  }

  const uploadFileUserRadio = () => {
    return(
      <div className="form-check">
        <Input
          type='radio'
          className='form-check-input'
          name={ inputName(formName, 'uploaded_by') }
          id={ inputId(formName, 'uploaded_by_upload_file_user') }
          value={ 'upload_file_user' }
          checked={ defaultBulkRequestConfig?.uploaded_by == 'upload_file_user' }
          onChange={ e => handleDefaultBulkRequestConfig(e, 'uploaded_by') }
          invalid={ _.has(defaultBulkRequestConfig.errors, 'uploaded_by') }
        />
        <Label
          className='form-check-label'
          for={ inputId(formName, 'uploaded_by_upload_file_user') }
        >
          { I18n.t('activerecord.attributes.laboral/document_type.uploaded_by.upload_file_user') }
        </Label>
      </div>
    )
  }

  const uploadFileEmployeeRadio = () => {
    if(context == 'laboral'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_employee') }
            value={ 'upload_file_employee' }
            checked={ defaultBulkRequestConfig?.uploaded_by == 'upload_file_employee' }
            onChange={ e => handleDefaultBulkRequestConfig(e, 'uploaded_by') }
            invalid={ _.has(defaultBulkRequestConfig.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_employee') }
          >
            { I18n.t('activerecord.attributes.laboral/document_type.uploaded_by.upload_file_employee') }
          </Label>
        </div>
      )
    }
  }

  const uploadFileClientRadio = () => {
    if(context == 'corporate'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_client') }
            value={ 'upload_file_client' }
            checked={ defaultBulkRequestConfig?.uploaded_by == 'upload_file_client' }
            onChange={ e => handleDefaultBulkRequestConfig(e, 'uploaded_by') }
            invalid={ _.has(defaultBulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_client') }
          >
            { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.uploaded_by.upload_file_client') }
          </Label>
        </div>
      )
    }
  }

  const uploadFileProviderRadio = () => {
    if(context == 'corporate'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_provider') }
            value={ 'upload_file_provider' }
            checked={ defaultBulkRequestConfig?.uploaded_by == 'upload_file_provider' }
            onChange={ e => handleDefaultBulkRequestConfig(e, 'uploaded_by') }
            invalid={ _.has(defaultBulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_provider') }
          >
            { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.uploaded_by.upload_file_provider') }
          </Label>
        </div>
      )
    }
  }

  // ------------------ Start ControlDocForms Template ------------------

  const controlDocFormTemplateRadio = () => {
    return(
      <div className="form-check">
        <Input
          type='radio'
          className='form-check-input'
          name={ inputName(formName, 'uploaded_by') }
          id={ inputId(formName, 'template') }
          value={ 'controldoc_form_template' }
          checked={ defaultBulkRequestConfig?.uploaded_by == 'controldoc_form_template' }
          onChange={ e => handleDefaultBulkRequestConfig(e, 'uploaded_by') }
          invalid={ _.has(defaultBulkRequestConfig.errors, 'uploaded_by') }
        />
        <Label
          className='form-check-label'
          for={ inputId(formName, 'template') }
        >
          { I18n.t('activerecord.attributes.laboral/document_type.uploaded_by.upload_controldoc_form') }
        </Label>
      </div>
    )
  }

  const controlDocFormAutoCompletedByEntityOptions = () => {
    if(defaultBulkRequestConfig.uploaded_by == 'controldoc_form_template'){
      return(
        <FormGroup className='ml-3'>
          <div className="form-check">
            <Input
              type='radio'
              className='form-check-input'
              name={ inputName(formName, 'autocompleted_by_entity') }
              id={ inputId(formName, 'autocompleted_by_entity_false') }
              value={ "0" }
              checked={ defaultBulkRequestConfig?.autocompleted_by_entity == false }
              onChange={ e => handleDefaultBulkRequestConfig(e, 'autocompleted_by_entity') }
            />
            <Label
              className='form-check-label'
              for={ inputId(formName, 'autocompleted_by_entity_false') }
            >
              { I18n.t('activerecord.attributes.laboral/document_type.autocompleted_by_entity_false') }
            </Label>
          </div>

          <div className="form-check">
            <Input
              type='radio'
              className='form-check-input'
              name={ inputName(formName, 'autocompleted_by_entity') }
              id={ inputId(formName, 'autocompleted_by_entity_true') }
              value={ "1" }
              checked={ defaultBulkRequestConfig.autocompleted_by_entity == true }
              onChange={ e => handleDefaultBulkRequestConfig(e, 'autocompleted_by_entity') }
              invalid={ _.has(defaultBulkRequestConfig?.errors, 'autocompleted_by_entity') }
            />
            <Label
              className='form-check-label'
              for={ inputId(formName, 'autocompleted_by_entity_true') }
            >
              { I18n.t('activerecord.attributes.laboral/document_type.autocompleted_by_entity_true') }
            </Label>
          </div>
        </FormGroup>
      )
    }
  }

  const controlDocFormTemplateSelect = () => {
    if(defaultBulkRequestConfig.uploaded_by == 'controldoc_form_template'){
      return(
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ defaultBulkRequestConfig?.errors?.template_id || [] }>
                <Label
                  for={ inputId(formName, 'template_id') }
                >
                  { I18n.t('activerecord.attributes.default_bulk_request_config.template_id') }
                </Label>

                <Select
                  id={ inputId(formName, 'template_id') }
                  name={ inputName(formName, 'template_id') }
                  value={ controlDocFormTemplateSelectedOption }
                  onChange={ e => handleDefaultBulkRequestConfig({ target: { value: e?.value } }, "template_id") }
                  options={ controlDocFormTemplateSelectOptions }
                  isClearable={ true }
                  placeholder={ '-- Selecciona una plantilla --' }
                  invalid={ _.has(defaultBulkRequestConfig?.errors, 'template_id') }
                />
              </FieldError>
            </FormGroup>
          </div>
        </div>
      )
    }
  }

  const controlDocFormTemplateSelectedOption = useMemo(() => {
    let selected = _.find(data?.templates || [] , { id: defaultBulkRequestConfig?.template_id })

    if(selected){
      return { label: selected?.name, value: selected?.id }
    } else {
      return null
    }
  }, [defaultBulkRequestConfig?.template_id])

  const controlDocFormTemplateSelectOptions = useMemo(() => {
    return _.map(data?.templates || [], function(template){
      return { value: template.id, label: template.name }
    })
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-header">
            Configuración predeterminada
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                { uploadFileUserRadio() }
                { uploadFileEmployeeRadio() }
                { uploadFileClientRadio() }
                { uploadFileProviderRadio() }
                { controlDocFormTemplateRadio() }

                { controlDocFormAutoCompletedByEntityOptions() }
                { controlDocFormTemplateSelect() }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default RequireDefaultBulkRequestConfigForm;
