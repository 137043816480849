import React, {
  useMemo,
  useContext
} from 'react';

import WorkflowCustomEdge from './custom_edge';
import WorkflowCustomEdgeNoEditable from './custom_edge_no_editable';

import WorkflowDiagramContext from '../../diagram_context';

const DirectionalEdge = edge => {

  const {
    readOnly
  } = useContext(WorkflowDiagramContext);

  const CustomEdgeComponent = readOnly ? WorkflowCustomEdgeNoEditable : WorkflowCustomEdge;

  return (
    <CustomEdgeComponent
      edge={ edge }
      config={ { type: 'directional' } }
    />
  );
}
export default React.memo(DirectionalEdge);
