import React, { useEffect, useState } from 'react';
import BulkLoadTagFileInput from './file_input';
import BulkLoadTagListHidden from './list_hidden';
import BulkLoadTagTableErrors from './table_errors';
import { Input, Button, UncontrolledTooltip} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import swal from "sweetalert2";
import { options } from '../helper/pagination';
import _ from 'lodash';
import I18n from 'i18n-js';


const BulkLoadTagForm = () => {
  const formName = 'bulk_load_tag'
  const [tagBulk, setTagBulk] = useState([])
  const [tagBulkErrors, setTagBulkErrors] = useState([])
  const [categorySelect, setCategorySelect] = useState([])
  const [dataHidden, setDataHidden] = useState([])
  const [updateTag, setUpdateTag] = useState(false)

  useEffect(()=>{
    setDataHidden(tagBulk)
  },[tagBulk])

  useEffect(()=>{
    if(updateTag){
      setTagBulk(tagBulk)
      setUpdateTag(false)
    }
  },[updateTag])

  const columns = [
    {
      dataField: 'controldoc_id',
      text: 'ControldocId',
      headerStyle: {display : 'none'},
      style: {display : 'none'},
      formatter : function(value,row,index) {
        return (<Input key={_.uniqueId("controldoc_id_input_")} type="hidden" defaultValue={ value } readOnly/>);
      }
    }, {
      dataField: 'category',
      text: 'Categoria',
      formatter : function(value,row,index) {
        return(
          <Input
            key={_.uniqueId("category_input_")}
            type="select"
            id={`${row.row}-category`}
            placeholder="-- Seleccionar Trabajador --"
            className='form-control-border'
            onChange={ e => handleValues(e,index,row) }
            defaultValue={ value.split(" / ")[0] }
          >
            { drawValueCategorySelect() }
          </Input>
        )
      }
    }, {
      dataField: 'name',
      text: 'Nombre Etiqueta',
      formatter : function(value,row,index) {
        return (
          <Input
            key={_.uniqueId("name_input_")}
            type="text"
            id={`${row.row}-name`}
            onBlur={ e =>handleValues(e,index,row)}
            defaultValue={ value }
          />
        );
      }
    }, {
      dataField: "action",
      text: "Acciones",
      editable: false,
      headerStyle: { width: "150px" },
      formatter: (cellContent, row) => {
        return (
          <div className='d-flex justify-content-center'>
            <Button
              color="danger"
              outline
              className="border-0 mb-2"
              onClick={ (e) => handleDestroyTag(row.row) }
            >
              <i className="fas fa-trash-alt" />
            </Button>
            { iconNewTag(row)}
          </div>
        );
      }
    },
  ];

  const iconNewTag = (row) =>{
    if(row.controldoc_id == ""){
      return(
        <div className="p-1 fs-5 ml-2">
          <span id="tooltipTonNewHiring">
            <i className="fas fa-tag text-info"></i>
          </span>
          <UncontrolledTooltip key={  _.uniqueId(row.row) } placement="bottom" target="tooltipTonNewHiring">
            { I18n.t('bulk_load_tags.form.new_tag') }
          </UncontrolledTooltip>
        </div>
      )
    }
  }

  const drawTagBulk = () => {
    if( tagBulk?.length > 0 ){
      return(
        <div className="row">
          <h2 className="card-title mb-2">{ I18n.t('bulk_load_tags.form.records') } ({ tagBulk?.length }):</h2>
          <BootstrapTable
            key={ _.uniqueId("table_") }
            id="table-primary"
            keyField='row'
            data={ tagBulk }
            columns={ columns }
            wrapperClasses="table-responsive"
            headerClasses="bg-primary border border-primary rounded"
            pagination={ paginationFactory(options) }
            hover
            size="sm"
            noDataIndication="No existen registros"
          />
        </div>
      )
    }
  }

  const drawValueCategorySelect = () => {
    let blank = [ {id: '', name: '- Selecciona una categoria-'} ]
    return _.map(_.concat(blank, categorySelect), function(category){
      return (
        <option
          key={ _.uniqueId(`category_option_`) }
          value={ category.id }
          label={ category.name }
        >
          { category.name }
        </option>
      )
    })
  }

  const handleValidateTagId = (row) => {
    let addNewData = [];
    let filteredArray = [];

    _.each(tagBulkErrors, function(item){
      if(validateItem(item, row) && !isExistNameTag(item["name"]) ){
        addNewData.push(item);
        swal.fire({
          icon: 'success',
          title: 'Genial',
          text: 'Fila corregida'
        })
        return true;
      } else if(validateItem(item, row)){
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El nombre de la etiqueta ya se encuentra en uso',
        })
      } else if( item.row === row.row){
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Campos vacios verifique los datos',
        })
      }
      filteredArray.push(item)
    })
    setTagBulkErrors(filteredArray);
    if(addNewData.length > 0){
      let _tagBulk = [...tagBulk]
      _tagBulk = _.concat(addNewData,tagBulk)
      setTagBulk(_tagBulk);
    }
  }

  const handleDestroyTag = (row, direct = false) => {
    if(!direct){
      swal.fire({
        title: 'Eliminar fila',
        text: "Esta seguro de eliminar la fila",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'swal2-confirm btn btn-success ml-3',
          cancelButton: 'swal2-cancel btn btn-secondary'
        },
        buttonsStyling: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
        cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let _tagBulk = [...tagBulk]
          _.remove(_tagBulk, item => { return item.row == row })
          setTagBulk(_tagBulk);
          swal.fire(
            'Eliminado!',
            'La fila ha sido elimina correctamente',
            'success'
          )
        }
      })
    }else{
      let _tagBulk = [...tagBulk]
      _.remove(_tagBulk, item=>{return item.row == row})
      setTagBulk(_tagBulk);
    }
  };

  const setValueIfExistTag=(e, index, key, row )=>{
    let value = e.target.value;
    if( value == '' || isExistNameTag(value,row)){
      handleDestroyTag(tagBulk[index].row, true)
      setTagBulkErrors(_.concat([tagBulk[index]],tagBulkErrors))
    }
    tagBulk[index][key] = value;
  }

  const handleValues = ( e, index, row, error = false ) => {
    document.getElementById(e?.target?.id).value = e?.target?.value;
    let  key = e.target.id.split("-")[1];

    if( key == "name" && error ){
      tagBulkErrors[index][key] = e.target.value;
    }else if( key == "name" && !error ){
      setValueIfExistTag(e, index, key, row)
    }else if( key == "category" && !error ){
      tagBulk[index][key] = `${e.target.value} / ${e.target.selectedOptions[0].text}`;
    }else if( key == "category" && error ){
      tagBulkErrors[index][key] = `${e.target.value} / ${e.target.selectedOptions[0].text}`;
    }
    setUpdateTag(true)
  }

  const isExistNameTag= (value, row = false) =>{
    if(row){
      let fil = _.filter(dataHidden, item=>{ return item.row != row.row})
      return !!_.find(fil, {"name": value})
    }else{
      return !!_.find(dataHidden, {"name": value})
    }
  }

  const validateItem = ( item, row ) =>{
    return (
      item.row === row.row &&
      item.name != "" &&
      item.category != ""
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        <BulkLoadTagFileInput
          setTagBulk={ setTagBulk }
          setTagBulkErrors={ setTagBulkErrors }
          formName={ formName }
          setCategorySelect={ setCategorySelect }
        />
      </div>
      <div className="col-12">
        <BulkLoadTagListHidden
          formName={ formName}
          dataHidden={ dataHidden }
        />
      </div>
      <div className="col-12">
        <BulkLoadTagTableErrors
          iconNewTag={ iconNewTag }
          handleValues={ handleValues }
          handleValidateTagId={ handleValidateTagId }
          isExistNameTag={ isExistNameTag }
          tagBulk={ tagBulk }
          drawValueCategorySelect= { drawValueCategorySelect }
          setTagBulkErrors={ setTagBulkErrors }
          tagBulkErrors={ tagBulkErrors }
        />
      </div>
      <div className="col-12">
        { drawTagBulk() }
      </div>

    </div>
  )
}

export default BulkLoadTagForm;
