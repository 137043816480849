import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import swal from "sweetalert2";
import { Input, Label, FormText, List, UncontrolledAlert } from 'reactstrap';
import ListMessages from '../../../../helper/list_message';
import * as moment from 'moment';
import I18n from 'i18n-js';

const LaboralBulkLoadHiringFileInput = ({ setHiringBulk, setHiringBulkErrors , formName, setCompaniesSelect, setEmployeesSelect, headers, customFieldsHeaders}) => {
  const [messageException, setMessageException] = useState(false)
  const [headersFile, setHeadersFile] = useState([]);
  const [headersTranslate, setHeadersTranslate] = useState([]);


  const handleFile = (_target) => {
    let file = _target;
    let page = [];
    let pageErrors = [];
    let isXlsx = file.value.split("\\").pop().includes(".xlsx");

    if(file != "" && file.type =="file" && isXlsx){
      let reader = new FileReader();
      reader.readAsArrayBuffer(file.files[0]);
      reader.onloadend = (e) => {
        let newData = new Uint8Array(e.target.result);
        let workbook = XLSX.read(newData,{type: "array"});

        let firstSheet = workbook.SheetNames[0];
        let sheet = workbook.Sheets[firstSheet]['!ref'];
        let _range = XLSX.utils.decode_range(sheet);
        let excelInJSON = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], {range: _range, blankRows: true, defval: ''});
        // permite actulizar el valor de headersTranslate ya que si se encontraba dentro de la
        // condicion el componente no alcanzaba actualizar el estado

        let headersFile = _.keys(excelInJSON[0])
        // En caso de que las cabeceras del excel contengan espacios en blancos
        let headersFileFormat = _.map(headersFile, header=>{ return _.trim(header) })
        let is_eq = isEqHeaders(headersFileFormat);

        if(_.eq(headers.length, headersFileFormat.length) && is_eq){
          try{
            _.each(excelInJSON, function (row) {
              let item = {};
              let col = 0;
              let values_row = _.values(row);

              _.each(headers, function (value) {
                let customField = _.find(customFieldsHeaders, { code: value } )

                if(customField?.data_type == 'date' && _.isInteger(values_row[col])){
                  let _date = convertedDate(values_row[col]);
                  item[value] = _date;
                } else {
                  item[value] = values_row[col]
                }

                col++;
              })

              item.row = row.__rowNum__;
              item.uniqueId = _.uniqueId("id");
              item.employee_name = "";
              item.company_name = "";
              item.controldoc_id = item.controldoc_id.toString().trim();
              item.hiring_id = item.hiring_id.toString().trim();
              item.start_date = item.start_date != "" ? convertedDate(item.start_date) : "" ;
              item.end_date = item.end_date != "" ? convertedDate(item.end_date) : "";

              if (validateDates(item) && !isEmptyItem(item)) {
                page.push(item);
              } else if (!isEmptyItem(item)) {
                pageErrors.push(item);
              }
            });

            let _filled = _.map(page, (hiring) => {
              return [JSON.stringify(hiring), hiring]
            });
            let _pageArr = new Map(_filled); // Pares de clave y valor
            let _pageFilled = [..._pageArr.values()]; // Conversión a un array

            let dataNotRepeat =_.filter(_pageFilled,(valorActual, indiceAltual,arreglo)=>{
              return _.findIndex(arreglo, (valorDeArreglo) =>{
                let indice = _.findIndex(arreglo, valorDeArreglo => verify( valorDeArreglo, valorActual ))
                if(indice !== indiceAltual){
                  return valorDeArreglo;
                }
              })
            })

            setEmployeesSelect(getEmployees(_.concat(dataNotRepeat, pageErrors)));
            setCompaniesSelect(getCompanies(_.concat(dataNotRepeat, pageErrors)));
            setHiringBulkErrors(pageErrors);
            setHiringBulk(dataNotRepeat);
            setMessageException(false)
          }catch(error) {
            setMessageException(true)
          }
        }else{
          setHeadersFile(headersFile)
          setMessageException(true)
        }
      }
    }else{
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El documento debe ser formato excel',
      })
    }
  }

  const isEqHeaders = (headersFile) => {
    let defaultHeader = _.difference(headers, _.map(customFieldsHeaders, "code"))
    let _headers = _.map(defaultHeader, function(header){
      return I18n.t(`laboral.bulk.load.hirings.form.default_headers.${header}`)
    })

    let last = _.last(_headers)
    let _headersTranslate = _.concat(_.slice(_headers,0, (_headers.length -1)), _.map(customFieldsHeaders, "label"), last)
    setHeadersTranslate(_headersTranslate)

    return _.isEqual(headersFile, _headersTranslate)
  }

  // Se da el caso en que las fechas no podian ser procesadas debido al
  // formato tipo fecha proveniente del excel, por lo que el valor de dicha
  // fecha es convertida en un formato valido para el procesamiento de la lectura.
  const convertedDate = (date) => {
    let hours = Math.floor((date % 1) * 24);
    let minutes = Math.floor((((date % 1) * 24) - hours) * 60)
    return moment(new Date(Date.UTC(0, 0, date, hours-17, minutes))).format("YYYY-MM-DD");
  }

  const validateDates = (item)=>{
    return moment(item.start_date, 'YYYY-MM-DD',true).isValid() &&
      moment(item.end_date, 'YYYY-MM-DD',true).isValid() && item.start_date <= item.end_date ||
      moment(item.start_date, 'YYYY-MM-DD',true).isValid() &&
      item.end_date ===""
  }

  const isEmptyItem = (item)=>{
    return (item.controldoc_id == "" &&
      item.hiring_id == "" &&
      item.employee_id == "" &&
      item.company_id == ""
    )
  }

  const drawMessageException = () =>{
    if(messageException && !headersFile){
      return(
        <div className='col-12' >
          <UncontrolledAlert color = "danger">
            <Label> Error al leer excel </Label>
            <p>Verificar los datos en las siguientes columnas </p>
            <List className='danger' type = "unstyled">
              <li> Identificador de contratación:
                <ul>
                  <li>Dejar en blanco si es nuevo registro.</li>
                </ul>
              </li>
              <li> Trabajador:
                <ul>
                  <li>Utilizar el selector de trabajadores.</li>
                  <li>Verificar que la pestaña de trabajadores este llena.</li>
                </ul>
              </li>
              <li> Empresa:
                <ul>
                  <li>Utilizar el selector de empresas.</li>
                  <li>Verificar que la pestaña de empresas este llena.</li>
                </ul>
              </li>
              <li> Fecha Inicio:
                <ul>
                  <li>La celda debe ser formato FECHA.</li>
                </ul>
              </li>
              <li> Fecha Termino:
                <ul>
                  <li>La celda debe ser formato FECHA.</li>
                </ul>
              </li>
            </List>
          </UncontrolledAlert>
        </div>
      )
    }else if(messageException && !!headersFile){
      return(
        <div className='col-12 mt-3' >
           <ListMessages
            headers={headersTranslate}
            headersFile={headersFile}
          />
        </div>
      )
    }
  }

  const getEmployees = ( employees) =>{
    let arrayEmployees = [];
    _.forIn(employees, function(value, key){
      let id = value.employee_id.split("/")[0].trim()
      let employee = value.employee_id.split("/")[1].trim()
      arrayEmployees.push({"identifier":id, "name": employee})
    })
    return _.uniqBy(arrayEmployees,"identifier");
  }

  const getCompanies = ( companies) =>{
    let arrayCompanies = [];
    _.forIn(companies, function(value, key){
      let id = value.company_id.split("/")[0].trim()
      let company = value.company_id.split("/")[1].trim()
      arrayCompanies.push({"identifier":id, "name": company})
    })
    return  _.uniqBy(arrayCompanies,"identifier");
  }


  const verify =( valorDeArreglo, valorActual) =>{
    return JSON.stringify(valorDeArreglo.hiring_id) ===
      JSON.stringify(valorActual.hiring_id) &&
      JSON.stringify(valorDeArreglo.controldoc_id) ===
      JSON.stringify(valorActual.controldoc_id) &&
      JSON.stringify(valorDeArreglo.employee_id) ===
      JSON.stringify(valorActual.employee_id)
  }

  return(
    <div className="row">
      <div className="col-12 mb-4">
        <Label>Subir Archivo Excel</Label>

        <Input
          type="file"
          name={`${formName}[file]`}
          id="laboral_hiring_bulk_load_file"
          placeholder="Archivo de Excel"
          onChange={ (e) => handleFile(e.target) }
        />
        <FormText>
          { I18n.t('laboral.bulk.load.hirings.form.file_input.download_template') }
          <a
            href={ Routes.download_example_laboral_bulk_load_hirings_path()}
            target='_blank'
            className="ml-2"
          >{ I18n.t('actions.download') }</a>
        </FormText>
      </div>
      <div className='row'>
          { drawMessageException()}
      </div>
    </div>
  )
}
export default LaboralBulkLoadHiringFileInput;
