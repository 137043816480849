import React from "react";

import ControldocFormTemplateForm from "../../../controldoc_form/template/form";
import { create, update } from "./axios";

const CorporateControldocFormTemplateForm = props => {
  return(
    <div className="row">
      <div className="col-12">
        <ControldocFormTemplateForm
          template={ props?.template }
          currentUser={ props?.currentUser }
          formName="corporate_controldoc_form_template"
          imagePicker={ props?.imagePicker || {} }
          data={ props?.data || {} }
          actions={{
            createAction: create,
            updateAction: update,
            showPath: Routes.corporate_controldoc_form_template_path,
            indexPath: Routes.corporate_controldoc_form_templates_path()
          }}
        />
      </div>
    </div>
  )
}

export default CorporateControldocFormTemplateForm;
