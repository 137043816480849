import React, { useState } from 'react';
import I18n from 'i18n-js';
import * as XLSX from 'xlsx';

import {
  Label,
  Input,
  FormText,
  UncontrolledAlert,
  List
} from 'reactstrap';

const AbstractBulkLoadEntityInputFile = props => {
  const currentEntityType = props?.currentEntityType;
  const headers = props?.headers;
  const customFields = props?.customFields;

  const [hasSameHeaders, setHasSameHeaders] = useState(true);
  const [headersInFile, setHeadersInFile] = useState([]);

  const isXlsx = path => {
    return path?.split(".")?.pop() == "xlsx"
  }

  const excelToArrayObjects = (loadEndEvent) => {
    let data = new Uint8Array(loadEndEvent.target.result);
    let workbook = XLSX.read(data, { type: "array" });

    const firstSheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[firstSheetName]['!ref'];

    const decodeRange = XLSX.utils.decode_range(sheet);
    return XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheetName], { range: decodeRange, blankRows: true, defval: '' })
  }

  const handleFile = event => {
    let input = event.target
    let inputValue = input.value
    let file =  input.files[0]
    let page = [];

    if(isXlsx(inputValue)){
      let reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onloadend = e => {
        let tabulatedExcel = excelToArrayObjects(e)

        let _headersInFile = _.keys(tabulatedExcel[0])

        setHeadersInFile(_headersInFile)

        let sameHeaders = _.isEqual(translateHeaders(), _headersInFile)
        if(sameHeaders){
          props?.setFile(file)
          processData(tabulatedExcel)
        } else {
          setHasSameHeaders(false)
        }

      }
    } else {
      swal.fire({
        icon: I18n.t('abstract.bulk.load.entities.form.file_input.error.icon'),
        title: I18n.t('abstract.bulk.load.entities.form.file_input.error.title'),
        text: I18n.t('abstract.bulk.load.entities.form.file_input.error.text'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success ml-3 pl-4 pr-4',
        }
      })
    }
  }

  const processData = (data) => {
    let _entities = []


    _.each(data, function(row, index){
      let item = {}
      let values = _.values(row);
      let col = 0

      _.each(headers, function(header){
        let customField = _.find(customFields, { code: header });
        let relatedEntityTypes = currentEntityType.related_entity_types;
        if(!!_.find(relatedEntityTypes, { singular_name: header })){
          item[header] = _.values(row)[col].split(" / ")[0]
        } else {
          item[header] = String(_.values(row)[col]).trim();
        }

        col++;
      })

      item.row = row.__rowNum__;
      _entities = _.concat(_entities, item);
    });

    if(_.isFunction(props?.setEntities)){
      props?.setEntities(_entities)
    }
  }

  const drawDifferentHeadersErrors = () => {
    if(!hasSameHeaders){
      return(
        <div className="col-12">
          <UncontrolledAlert color="danger">
            <Label>{ I18n.t("errors.messages.error_excel") }</Label>

            <div className="row">
              <div className="col-12">
                <div dangerouslySetInnerHTML={{__html: I18n.t("errors.messages.error_columns_excel") }} />
              </div>
              <div className="col-md-6">
                <Label>Columnas permitidas</Label>

                <List className='danger' type='unstyled'>
                  { _.map(translateHeaders(), function(header){
                    return(
                      <li key={ _.uniqueId('permitted-columns') }>
                        { header }
                      </li>
                    )
                  })}
                </List>
              </div>
              <div className="col-md-6">
                <Label> Columnas en el excel</Label>

                <List className='danger' type = "unstyled">
                  {
                    _.map(headersInFile, function(header){
                      return(
                        <li key={ _.uniqueId("headers-file")}>{ header }</li>
                      )
                    })
                  }
                </List>
              </div>
            </div>
          </UncontrolledAlert>
        </div>
      )
    }
  }

  const translateHeaders = () => {
    let _headers = _.map(headers, function(header){
      // para que coincidan las cabeceras del archivo con los custom field permitidos ambos traducidos y asi
      // en la construccion de los entities contenga las llaves correspondientes
      let _customField = _.find(customFields, { code: header} )?.label || header
      return _.includes(I18n.t(`activerecord.attributes.abstract/entity.${_customField}`), 'missing') ? _customField : I18n.t(`activerecord.attributes.abstract/entity.${_customField}`)
    })

    return _headers
  }

  return(
    <div className="row">
      <div className="col-12">
        <Label>Subir Archivo</Label>

        <Input
          type='file'
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={ e => handleFile(e) }
        />

        <FormText>
          { I18n.t('abstract.bulk.load.entities.form.file_input.download_template', {entity: currentEntityType?.plural_name}) }
          <a
            href={ Routes.download_example_abstract_bulk_load_entities_path({ current_entity_type_id: currentEntityType.id } ) }
            target="_blank"
            className="ml-2"
          >
            { I18n.t("actions.download") }
          </a>
        </FormText>
      </div>

      { drawDifferentHeadersErrors() }
    </div>
  )
}

export default AbstractBulkLoadEntityInputFile;
