import React, {
  useEffect,
  useContext,
  useCallback
} from 'react';
import { Button, FormGroup, Label, Input } from "reactstrap";

import I18n from 'i18n-js';

import FieldError from '../helper/FieldError';

import {
  inputId,
  inputName,
} from '../helper/form';

import DocumentSignerConfigContext from '../document/context/signer_config_context';
import DocumentFormContext from '../document/context/document_form_context';

const SignerEmployeeAttributes = props => {

  const {
    signer, formName, callbackSetSigner, index
  } = props;

  const {
    resource
  } = useContext(DocumentSignerConfigContext);

  const {
    data,
    currentCustomer,
    _class,
    context,
    entities
  } = useContext(DocumentFormContext)

  useEffect(() => {
    // Si el contexto es laboral, buscará el entity seleccionado
    if(context == 'laboral'){
      let entity = _.find(entities, function(_entity){
        return String(_entity.id) == String(resource?.entity_id)
      });

      // se ejecuta cuando hace un dragg y no sea EmployeeDocument
      if(entity && !_class && (entity?.employee?.email != signer.email || entity?.employee?.name != signer.name)){
        let _signer = { ... signer };
        _signer.email = entity?.employee?.email || '';
        _signer.name = entity?.employee?.name || '';

        if(_.isFunction(callbackSetSigner)){
          callbackSetSigner(_signer, index);
        }
      }

      // se ejecuta cuando hace un dragg y es EmployeeDocument
      if(entity && !!_class && (entity?.email != signer.email || entity?.name != signer.name)){
        let _signer = { ... signer };

        _signer.email = entity?.email || '';
        _signer.name = entity?.name || '';

        if(_.isFunction(callbackSetSigner)){
          callbackSetSigner(_signer, index);
        }
      }
    }
  }, [resource?.entity_id])

  const handleSigner = (event, key) => {
    let value = event.target.value;
    let _signer = { ... signer };

    _signer[key] = value;

    if(_.isFunction(callbackSetSigner)){
      callbackSetSigner(_signer, index);
    }
  }

  const showFaoAttributes = () => {
    return currentCustomer?.enable_fao && resource.require_fao
  }

  const drawEmailInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.email || [] } >
            <Label
              for={ inputId(formName, 'email') }
            >
              { I18n.t("activerecord.attributes.signer.email") }
            </Label>

            <Input
              type='email'
              value={ signer?.email || "" }
              name={ inputName(formName, "email") }
              id={ inputId(formName, "email") }
              invalid={ _.has(signer?.errors, "email") }
              disabled={ true }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawIdentificationNumberInput = () => {
    if(showFaoAttributes()){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.identification_number || [] } >
              <Label
                for={ inputId(formName, 'identification_number') }
              >
                { I18n.t("activerecord.attributes.signer.identification_number") }
              </Label>

              <Input
                type='text'
                value={ signer?.identification_number || "" }
                onChange={ e => handleSigner(e, "identification_number") }
                name={ inputName(formName, "identification_number") }
                id={ inputId(formName, "identification_number") }
                invalid={ _.has(signer?.errors, "identification_number") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawNameInput = () => {
    let isDisabled = resource.isPersisted || !resource?.require_fao

    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.name || [] } >
            <Label
              for={ inputId(formName, 'name') }
            >
              { I18n.t("activerecord.attributes.signer.name") }
            </Label>

            <Input
              value={ signer?.name || "" }
              name={ inputName(formName, "name") }
              id={ inputId(formName, "name") }
              invalid={ _.has(signer?.errors, "name") }
              onChange={ e => handleSigner(e, "name") }
              disabled={ isDisabled }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawFirstSurnameInput = () => {
    if(showFaoAttributes()){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.first_surname || [] } >
              <Label
                for={ inputId(formName, 'first_surname') }
              >
                { I18n.t("activerecord.attributes.signer.first_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.first_surname || "" }
                onChange={ e => handleSigner(e, "first_surname") }
                name={ inputName(formName, "first_surname") }
                id={ inputId(formName, "first_surname") }
                invalid={ _.has(signer?.errors, "first_surname") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawLastSurnameInput = () => {
    if(showFaoAttributes()){
      return(
        <div className="col-md-4">
          <FormGroup>
            <FieldError errors={ signer?.errors?.last_surname || [] } >
              <Label
                for={ inputId(formName, 'last_surname') }
              >
                { I18n.t("activerecord.attributes.signer.last_surname") }
              </Label>

              <Input
                type='text'
                value={ signer?.last_surname || "" }
                onChange={ e => handleSigner(e, "last_surname") }
                name={ inputName(formName, "last_surname") }
                id={ inputId(formName, "last_surname") }
                invalid={ _.has(signer?.errors, "last_surname") }
                disabled={ resource.isPersisted }
              />
            </FieldError>
          </FormGroup>
        </div>
      )
    }
  }

  const drawRoleInput = () => {
    return(
      <div className="col-md-4">
        <FormGroup>
          <FieldError errors={ signer?.errors?.role || [] } >
            <Label
              for={ inputId(formName, 'role') }
            >
              { I18n.t("activerecord.attributes.signer.role") }
            </Label>

            <Input
              value={ signer?.role || "" }
              onChange={ e => handleSigner(e, "role") }
              name={ inputName(formName, "role") }
              id={ inputId(formName, "role") }
              invalid={ _.has(signer?.errors, "role") }
              disabled={ resource.isPersisted }
            />
          </FieldError>
        </FormGroup>
      </div>
    )
  }

  const drawBulkLabel = () => {
    if(context == 'laboral_bulk'){
      return(
        <p className="fs-5 text-info">
          <i className="fas fa-info mr-2"></i>
          El documento se enviará al correo electrónico asociado al trabajador.
        </p>
      )
    }
  }

  return(
    <div className="row">
      { drawBulkLabel() }
      { drawEmailInput() }
      { drawIdentificationNumberInput() }
      { drawNameInput() }
      { drawFirstSurnameInput() }
      { drawLastSurnameInput() }
      { drawRoleInput() }
    </div>
  )
}

export default SignerEmployeeAttributes;
