export const defaultTableHeaderStyle = {
  position: 'sticky',
  width: "250px",
  top: 0,
  color: '#fff',
  backgroundColor: '#5a3ee0'
}

export const defaultTableHeaderStyleWhite = {
  position: 'sticky',
  width: "250px",
  top: 0,
  backgroundColor: '#fff'
}
