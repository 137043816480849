import React, {
  useCallback,
  useMemo,
  useContext,
  useState
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import CreatableSelect from 'react-select/creatable';

import I18n from 'i18n-js';

import FieldError from "../../helper/FieldError";

import {
  inputId,
  inputName,
} from '../../helper/form';

import WorkflowDiagramContext from '../diagram_context';

const WorkflowStageFileItemAttributes = props => {

  const formName = _.uniqueId('stage_item_file');

  const [openModalAdvancedConfiguration, setOpenModalAdvancedConfiguration] = useState(false)

  const {
    fileItem,
    index,
    setCurrentNode,
    configuration: {
      stageForm,
      showGroupName
    },
    data: {
      groupNameOptions = []
    }
  } = props;

  const setFileItem = _fileItem => {
    setCurrentNode(prevState => {
      prevState.data[stageForm]['file_items'][index] = {
        ...prevState.data[stageForm]['file_items'][index],
        ..._fileItem
      }

      return { ... prevState }
    })
  }

  const onChangeFile = (event, key) => {
    // Si el campo se encuentra desabilitado no se debe poder modificar
    if(!fileItem.is_disabled){
      let value = event.target.value;
      const type = event.target.type;

      if(type == 'checkbox'){
        value = event.target.checked;
      }

      let fileChanges = {}

      fileChanges[key] = value

      setCurrentNode(prevState => {
        prevState.data[stageForm]['file_items'][index] = {
          ...prevState.data[stageForm]['file_items'][index],
          ...fileChanges
        }

        return { ... prevState }
      })
    }
  }

  // ---------- START NAME ----------
  const nameInput = () => {
    return(
      <td>
        <FormGroup>
          <FieldError errors={ fileItem?.errors?.name || [] }>
            <Input
              value={ fileItem?.name || '' }
              onChange={ e => onChangeFile(e, 'name') }
              invalid={ _.has(fileItem, 'errors.name') }
              disabled={ fileItem?.is_disabled || false }
            />
          </FieldError>
        </FormGroup>
      </td>
    )
  }
  // ---------- END NAME ----------

  // ---------- START IS REQUIRED ----------
  const isRequiredSwitchInput = () => {
    return(
      <td>
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                value={ '1' }
                onChange={ e => onChangeFile(e, 'is_required') }
                id={ inputId(formName, 'is_required') }
                name={ inputName(formName, 'is_required') }
                checked={ fileItem.is_required || false }
                disabled={ fileItem?.is_disabled || false }
              />
              <Label
                className='custom-control-label'
                htmlFor={ inputId(formName, 'is_required') }
              />
            </FieldError>
          </div>
        </FormGroup>
      </td>
    )
  }
  // ---------- END IS REQUIRED ----------

  // ---------- START GROUP NAME ----------
  const groupNameSelectOptions = () => {
    return _.map(groupNameOptions, option => {
      return {
        label: option,
        value: option
      }
    })
  }

  const groupNameSelectedOption = useMemo(() => {
    if(_.includes(groupNameOptions, fileItem.group_name)){
      return {
        label: fileItem.group_name,
        value: fileItem.group_name,
      }
    } else {
      return null
    }
  }, [fileItem.group_name])

  const onCreateOptionGroupName = value => {
    onChangeFile(
      { target: { value: value }},
      'group_name'
    )
  }

  const groupNameSelectInput = () => {
    if(showGroupName){
      return(
        <td className='text-center'>
          <FormGroup>
            <CreatableSelect
              isClearable
              options={ groupNameSelectOptions() }
              onChange={ e => onChangeFile({ target: { value: e.value}}, 'group_name') }
              onCreateOption={ onCreateOptionGroupName }
              value={ groupNameSelectedOption }
            />
          </FormGroup>
        </td>
      )
    }
  }
  // ---------- END GROUP NAME ----------

  // ---------- START ADVANCED CONFIGURATION  ----------
  const advancedConfigurationButton = () => {
    if(stageForm == 'output_stage_form'){
      return(
        <td>
          <Button
            outline
            color='primary'
            size='xs'
            onClick={ e => setOpenModalAdvancedConfiguration(true) }
          >
            <i className="fas fa-cog"></i>
          </Button>
        </td>
      )
    }
  }

  const toggleOpenModalAdvancedConfiguration = () => {
    setOpenModalAdvancedConfiguration(!openModalAdvancedConfiguration)
  }

  const advancedConfigurationModal = () => {
    return(
      <WorkflowStageFileItemModalAdvancedConfiguration
        openModalAdvancedConfiguration={ openModalAdvancedConfiguration }
        fileItem={ fileItem }
        configuration={
          { ... props?.configuration, formName: formName }
        }
        callbacks={{
          toggleOpenModalAdvancedConfiguration: toggleOpenModalAdvancedConfiguration,
          setFileItem: setFileItem
        }}
      />
    )
  }
  // ---------- END ADVANCED CONFIGURATION  ----------

  // ---------- START DELETE  ----------
  const deleteButton = () => {
    return(
      <td>
        <Button
          outline
          color='danger'
          size='xs'
          onClick={ e => onChangeFile({ target: { value: true } }, '_destroy') }
          disabled={ fileItem?.is_disabled || false }
        >
          <i className="fas fa-trash"></i>
        </Button>
      </td>

    )
  }
  // ---------- END DELETE  ----------

  return(
    <tr className={ `${ fileItem._destroy ? 'd-none' : '' } `} >
      { groupNameSelectInput() }
      { nameInput() }
      { isRequiredSwitchInput() }
      { advancedConfigurationButton() }
      { deleteButton() }
      { advancedConfigurationModal() }
    </tr>
  )
}

export default WorkflowStageFileItemAttributes;


const WorkflowStageFileItemModalAdvancedConfiguration = props => {
  const {
    openModalAdvancedConfiguration,
    callbacks: {
      toggleOpenModalAdvancedConfiguration: callbackToggleOpenModalAdvancedConfiguration,
      setFileItem: callbackSetFileItem
    },
    configuration: {
      formName,
      stageForm
    }
  } = props;

  const [fileItem, setFileItem] = useState({});

  const setDefaultFileItem = event => {
    setFileItem(props?.fileItem);
  }

  const onChangeFileItem = (event, key) => {
    const type = event.target.type;
    let value = event?.target?.value;

    if(type == 'file'){
      value = event?.target?.files[0]
    }

    setFileItem( prevState => {
      return { ... prevState, [key]: value}
    })
  }

  // ---------- START TEMPLATE FILE ----------

  const templateFileDownloadLink = () => {
    if(fileItem.template){
      const objectUrl = URL.createObjectURL(fileItem.template);
      return(
        <small>
          <a href={ objectUrl } download={ true }>
            Revisar archivo cargado
          </a>
        </small>
      )
    } else if(fileItem.blob_url){
      return(
        <small>
          <a href={ fileItem.blob_url } download={ true }>
            Revisar archivo cargado
          </a>
        </small>
      )
    }
  }


  const templateFileInput = () => {
    if(stageForm == 'output_stage_form'){
      return(
        <FieldError errors={ fileItem?.errors?.template || [] }>
          <FormGroup>
            <Label
              htmlFor={ inputId(formName, 'template') }
            >
              { I18n.t('activerecord.attributes.workflow/stage_file_item.template') }
            </Label>
            <Input
              type='file'
              onChange={ e => onChangeFileItem(e, 'template') }
              id={ inputId(formName, 'template') }
              name={ inputName(formName, 'template') }
            />
            { templateFileDownloadLink() }
          </FormGroup>
        </FieldError>
      )
    }
  }
  // ---------- END TEMPLATE FILE ----------

  // ---------- START HELPING TEXT ----------
  const helpingTextInput = () => {
    return(
      <FieldError errors={ fileItem?.errors?.helping_text || [] }>
        <FormGroup>
          <Label
            htmlFor={ inputId(formName, 'helping_text') }
          >
            { I18n.t('activerecord.attributes.workflow/stage_file_item.helping_text') }
          </Label>
          <Input
            type='textarea'
            onChange={ e => onChangeFileItem(e, 'helping_text') }
            id={ inputId(formName, 'helping_text') }
            name={ inputName(formName, 'helping_text') }
            value={ fileItem?.helping_text || '' }
          />
        </FormGroup>
      </FieldError>
    )
  }

  // ---------- END HELPING TEXT ----------

  const saveAdvancedConfiguration = () => {
    callbackToggleOpenModalAdvancedConfiguration()
    callbackSetFileItem(fileItem);
  }

  return(
    <Modal
      isOpen={ openModalAdvancedConfiguration }
      toggle={ callbackToggleOpenModalAdvancedConfiguration }
      unmountOnClose={ true }
      size={ 'lg' }
      onOpened={ event => setDefaultFileItem() }
    >
      <ModalHeader
        toggle={ callbackToggleOpenModalAdvancedConfiguration }
      >
        Configuración Avanzada
      </ModalHeader>
      <ModalBody>
        { helpingTextInput() }
        { templateFileInput() }
      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={ callbackToggleOpenModalAdvancedConfiguration }>
          { I18n.t('actions.close') }
        </Button>{' '}
        <Button color="success" onClick={ e => saveAdvancedConfiguration() }>
          { I18n.t('actions.save') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}
