import React, {
  useMemo,
  useContext
} from 'react'

import {
  Badge
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowCustomNode from './custom_node.jsx';
import WorkflowCustomNodeNoEditable from './custom_node_no_editable.jsx';

import WorkflowDiagramContext from '../../diagram_context';

const WorkflowShortlistNode = (props) => {

  const { data } = props;

  const {
    readOnly
  } = useContext(WorkflowDiagramContext);

  const CustomNodeComponent = readOnly ? WorkflowCustomNodeNoEditable : WorkflowCustomNode

  const badgeNodeType = useMemo(() => {
    return(
      <div className="row">
        <div className="col-12">
          <Badge color='success' tag='div'>
            { I18n.t('activerecord.attributes.workflow/stage.node_type.shortlist') }
          </Badge>
        </div>
      </div>
    )
  }, [])

  return (
    <CustomNodeComponent
      config={ { type: 'default' } }
      node={ props }
    >
      <div className={ `card h-100 shadow-sm rounded mb-0 workflow-node-card` }>
        <div className="card-body p-3 lh-sm">
          <p className="text-dark font-weight-bold d-block text-capitalize mb-1">
            { data?.label }
          </p>

          <p className="mb-1">
            { data?.description }
          </p>

          <div className="badge badge-primary">
            <i className="fas fa-id-card-alt mr-2"></i>
            { data?.group?.name || I18n.t('no_entry') }
          </div>

          { badgeNodeType }
        </div>
      </div>
    </CustomNodeComponent>
  );
}

export default WorkflowShortlistNode;
