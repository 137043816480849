import React, {
  useState,
  useCallback,
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowRequirementStageFormAttributes from '../requirement_stage_form/attributes'

const requirementStageShorlistInputFormData = (requirementStage, formName) => {
  let _formData = new FormData();

  _formData.append(`${ formName }[input_form_attributes][id]`, requirementStage?.input_form?.id || '');

  const inputFieldItems = requirementStage?.input_form?.field_items || [];

  const inputFieldItemsFormName = `${ formName }[input_form_attributes][field_items_attributes]`
  _.each(inputFieldItems, (fieldItem, index) => {

    _formData.append(`${ inputFieldItemsFormName }[${ index }][id]`, fieldItem?.id || '');
    _formData.append(`${ inputFieldItemsFormName }[${ index }][value]`, fieldItem?.value || '');

  })

  const inputFileItems = requirementStage?.input_form?.file_items || [];
  const inputFileItemsFormName = `${ formName }[input_form_attributes][file_items_attributes]`;

  _.each(inputFileItems, (fileItem, index) => {
    _formData.append(`${ inputFileItemsFormName }[${ index }][id]`, fileItem?.id || '');

    if(fileItem.file){
      _formData.append(`${ inputFileItemsFormName }[${ index }][file]`, fileItem?.file || '');
    }
  })

  return _formData;
}

const WorkflowRequirementStageShortlistInputForm = props => {

  const {
    formName = "workflow_requirement_stage",
    data,
    configuration: {
      defaultRequestParams
    },
    actions: {
      update: updateRequirementStage,
      showPath: showRequirementStagePath
    },
    // callbacks: {
    //   onChangeRequirementStage: callbackOnChangeRequirementStage
    // }
  } = props;

  const [requirementStage, setRequirementStage] = useState(props?.requirementStage || {})

  const onChangeRequirementStage = (event, key) => {
    let value = event.target.value;

    setRequirementStage(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirement_stages.form.confirm.title'),
      html: I18n.t('workflow.requirement_stages.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSubmit = () => {
    const formData = requirementStageShorlistInputFormData(requirementStage, formName);

    let requestParams = { ... defaultRequestParams, id: requirementStage.hashid }

    updateRequirementStage(requestParams, formData, response => {
      if(response.status == 200){
        requestParams = { ...requestParams, _options: true, format: '' }
        window.location = showRequirementStagePath(requestParams)
      } else {
        setRequirementStage(response.data)
      }
    })
  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementStageFormAttributes
          stageForm={ requirementStage.input_form }
          configuration={{ ... props.configuration, formName: `${ formName }[input_form_attributes]`, formType: 'input_form' } }
          callbacks={{
            onChangeRequirementStage: onChangeRequirementStage
          }}
        />

        <FormGroup className='text-end'>
          {/* <a
            href={ showRequirementStagePath({ ... defaultRequestParams, id: requirementStage.id }) }
            className="btn btn-default"
          >
            { I18n.t("actions.back") }
          </a> */}

          <Button
            color='success'
            className='ml-2'
            onClick={ e => onConfirmSubmit() }
          >
            { I18n.t('actions.save') }
          </Button>
        </FormGroup>
      </div>
    </div>
  )
}

export default WorkflowRequirementStageShortlistInputForm;
