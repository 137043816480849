import React from 'react';


export const onChangeDocumentHelper = (resource, key, value, documentTypes) => {
  let resourceChanges = {}

  if(key == 'requires_expire' && !value){
    resourceChanges['expires_at'] = null;
  }

  if(key == 'require_signers'){
    resourceChanges['keep_format'] = false;
  }

  if(key == 'require_fao' && value){
    resourceChanges['require_signers_order'] = true;
  }

  if (key == 'require_signers_order' && !value) {
    resourceChanges['require_fao'] = false;
  }

  if(key == 'require_signature_deadline' && !value){
    resourceChanges['signature_deadline_at'] = null;
  }

  if(
    (key == 'require_signers' && !value) ||
    (key == 'keep_format' && value)
  ){
    resourceChanges['require_signers_order'] = false;
    resourceChanges['require_fao'] = false;
    resourceChanges['require_signature_deadline'] = false;
    resourceChanges['signature_deadline_at'] = null;
    resourceChanges['generate_viewer_table'] = false;

    resourceChanges['signers'] = _.map(resource.signers, function(signer){
      signer._destroy = true;
      return signer
    })
  }

  if(key == 'document_type_id'){
    resourceChanges = { ... resourceChanges, ...onChangeDocumentTypeHelper(resource, value, documentTypes) }
  }

  return resourceChanges;
}

const onChangeDocumentTypeHelper = (resource, value, documentTypes) => {
  const selectedDocumentType = _.find(documentTypes, { id: parseInt(value)});

  let resourceChanges = {};

  resourceChanges['require_signers'] = selectedDocumentType?.require_signers || false;
  resourceChanges['require_signers_order'] = selectedDocumentType?.require_signers_order;
  resourceChanges['require_signature_deadline'] = false
  resourceChanges['signature_deadline_at'] = null;
  resourceChanges['generate_viewer_table'] = null;
  resourceChanges['require_fao'] = false;

  resourceChanges['signers'] = _.map(selectedDocumentType?.signers, function(signer){
    return {
      'id': '',
      'name': signer.name,
      'email': signer?.email, //Esto se dbee modificar despues segun el modulo
      'order': signer.order,
      'role': signer.role,
      'employee_sign': signer.employee_sign,
      'company_sign': signer.company_sign,
      'company_email': signer.company_email,
      'email_notification_sign': signer.email_notification_sign,
      'member_sign': signer.member_sign
    }
  })

  return resourceChanges;
}




export const defaultActionSignerOptions = (resource, key, value) => {

}

export const defaultActionDocumentType = (resource, value, data) => {
}
