import React, { useEffect, useState } from 'react'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap'
import I18n from 'i18n-js';

import ControldocFormTemplateMappingLinkResourceItem from './resource_item'
import ControldocFormTemplateMappingLinkResourceAttributeItem from './resource_attribute_item'


const ControldocFormTemplateMappingLinkItem = props => {
  const { formName, resources, abstractModule } = props;

  const [mappingLink, setMappingLink] = useState(props?.mappingLink || {});

  const handleResource = (value) => {
    let _mappingLink = { ... mappingLink };
    _mappingLink.resource = value;
    setMappingLink(_mappingLink)
  }

  const handleResourceAttribute = value => {
    let _mappingLink = { ... mappingLink };
    _mappingLink.resource_attribute = value;
    setMappingLink(_mappingLink);
  }


  return(
    <tr key={ `tr-mapping-link-${ mappingLink.id }`}>
      <td width='30%'>
        <Input
          name={`${ formName }[id]` }
          value={ mappingLink.id }
          type='hidden'
        />
        <Input
          name={`${ formName }[name_var]` }
          value={ mappingLink.name_var }
          readOnly
          type='text'
        />
      </td>

      <td>
        <ControldocFormTemplateMappingLinkResourceItem
          abstractModule={ abstractModule }
          resources={ resources }
          handleResource= { handleResource }
          mappingLink={ mappingLink }
          formName={ formName }
        />
      </td>
      <td>
        <ControldocFormTemplateMappingLinkResourceAttributeItem
          resources={ resources }
          handleResourceAttribute= { handleResourceAttribute }
          mappingLink={ mappingLink }
          formName={ formName }
        />
      </td>
    </tr>
  )
}


export default ControldocFormTemplateMappingLinkItem;
