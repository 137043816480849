(function(){
  const gridOptionsAvailable = ['list', 'grid'];

  window.toggleGridView = (event, grid) => {
    if(_.includes(gridOptionsAvailable, grid)){
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("grid_option", grid);
      window.location.search = searchParams.toString()
    }
  }
}())
