import axios from "axios";

export const update = (routeParams, formData, callback) => {
  const promise = axios({
    method: 'patch',
    url: Routes.public_crm_lead_path({ ...{ ...routeParams }, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
