import React, {
  useMemo,
  useCallback,
  useEffect
} from 'react';

import {
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import I18n from 'i18n-js';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../helper/form';

import WorkflowRequirementStageFormAttributes from '../requirement_stage_form/attributes'

const WorkflowRequirementStageAttributes = props => {

  const {
    requirementStage,
    actions,
    callbacks,
    callbacks: {
      onChangeRequirementStage: callbackOnChangeRequirementStage
    },
    configuration: {
      formName,
      defaultRequestParams,
    },
  } = props;

  const nextStages = useMemo(() => {
    return _.sortBy(requirementStage.next_stages, 'name')
  }, [requirementStage.next_stages])

  const returnStages = useMemo(() => {
    return _.sortBy(requirementStage.return_stages, 'name')
  }, [requirementStage.return_stages])

  const isInputStage = useMemo(() => {
    return requirementStage.node_type == 'input'
  }, [])

  const isDefaultStage = useMemo(() => {
    return requirementStage.node_type == 'default'
  }, [])

  const isOutputStage = useMemo(() => {
    return requirementStage.node_type == 'output'
  }, [])

  const isShortlistStage = useMemo(() => {
    return requirementStage.node_type == 'shortlist'
  }, [])

    // ---------- START AASM STATE ----------

  const toStageDefaultAuthorizedUsers = useMemo(() => {

    const stages = _.concat(nextStages, returnStages);

    const nextStage = _.find(stages, { id: requirementStage.to_stage_id })

    if(_.has(nextStage, 'group.users')){
      return nextStage.group.users
    } else {
      return []
    }
  }, [requirementStage.to_stage_id])

  useEffect(() => {
    const emails = _.map(toStageDefaultAuthorizedUsers, 'email');
    callbackOnChangeRequirementStage({ target: { value: emails }}, 'to_stage_authorized_emails')
  }, [requirementStage.to_stage_id])

  const aasmStateInputSelect = () => {
    if(!isInputStage){
      return(
        <FormGroup>
          { _.map(requirementStage.events.permitted, aasmState => {
            return(
              <div className="form-check" key={ `aasm_state_event_options_${ aasmState.event }` }>
                <Input
                  type='radio'
                  className='form-check-input'
                  name={ inputName(formName, 'aasm_state_event') }
                  id={ inputId(formName, `aasm_state_event_${ aasmState.event }`) }
                  value={ aasmState.event }
                  checked={ requirementStage.aasm_state_event == aasmState.event }
                  onChange={ e => callbackOnChangeRequirementStage(e, 'aasm_state_event') }
                />
                <Label
                  className='form-check-label'
                  htmlFor={ inputId(formName, `aasm_state_event_${ aasmState.event }`) }
                >
                  { aasmState.human_name }
                </Label>
              </div>
            )
          })}
        </FormGroup>
      )
    }
  }
  // ---------- END AASM STATE ----------
  // ----------------------------------------
  // ---------- START OBSERVATIONS ----------
  const observationsInput = () => {
    if(requirementStage.aasm_state_event == 'to_rejected' || requirementStage.aasm_state_event == 'to_returned'){
      return(
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ _.get(requirementStage, 'errors.observations') || [] }>
                <Label
                  className={ 'required' }
                  htmlFor={ inputId(formName, 'observations') }
                >
                  { I18n.t('activerecord.attributes.workflow/requirement_stage.observations') }
                </Label>
                <Input
                  type={ 'textarea' }
                  required={ true }
                  onChange={ e => callbackOnChangeRequirementStage(e, 'observations') }
                  value={ requirementStage.observations || '' }
                  id={ inputId(formName, 'observations') }
                  name={ inputName(formName, 'observations') }
                  invalid={ _.has(requirementStage, 'errors.observations') }
                />
              </FieldError>
            </FormGroup>
          </div>
        </div>
      )
    }
  }
  // ---------- END OBSERVATIONS ----------
  // ----------------------------------------
  // ---------- START TO STAGE ID ----------
  const nextStageOptions = useMemo(() => {
    if(requirementStage.aasm_state_event == 'to_proceeded'){
      return nextStages
    } else if(requirementStage.aasm_state_event == 'to_returned'){
      return returnStages
    }
  }, [requirementStage.aasm_state_event])

  const nextStagesSelectOptions = useMemo(() => {
    return _.map(nextStageOptions, stage => ({ value: stage.id, label: stage.name }))
  }, [requirementStage.aasm_state_event])

  const nextStageSelectedOption = useMemo(() => {
    return _.find(nextStagesSelectOptions, { id: requirementStage.to_stage_id })
  }, [requirementStage.to_stage_id])

  const nextStageSelectInput = () => {
    if(!isInputStage && (requirementStage.aasm_state_event == 'to_proceeded' || requirementStage.aasm_state_event == 'to_returned')){
      return(
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ _.get(requirementStage, 'errors.to_stage_id') || [] }>
                <Label
                  className={ 'required' }
                  htmlFor={ inputId(formName, 'to_stage_id') }
                >
                  { I18n.t(`activerecord.attributes.workflow/requirement_stage.to_stage_id.${ requirementStage.aasm_state_event }`) }
                </Label>

                <Select
                  id={ inputId(formName, 'to_stage_id') }
                  name={ inputName(formName, 'to_stage_id') }
                  options={ nextStagesSelectOptions }
                  value={ nextStageSelectedOption }
                  onChange={ e => callbackOnChangeRequirementStage({ target: { value: e?.value }}, 'to_stage_id') }
                />
              </FieldError>
            </FormGroup>
          </div>
        </div>
      )
    }
  }
  // ---------- END TO STAGE ID ----------

  // ---------- START TO STAGE AUTHORIZED EMAILS ----------



  const toStageAuthorizedEmailsSelectedOptions = useMemo(() => {
    const emails = requirementStage.to_stage_authorized_emails;

    return _.map(emails, email => {
      return { label: email, value: email }
    })
  }, [requirementStage.to_stage_authorized_emails])

  const toStageAuthorizedEmailsOptions = useMemo(() => {
    const defaultOptions =  toStageDefaultAuthorizedUsers;

    return _.map(defaultOptions, user => {
      return { label: user.email, value: user.email }
    })
  }, [requirementStage.to_stage_authorized_emails])

  const onChangeToStageAuthorizedEmails = (v) => {
    const values = _.map(v, 'value');

    callbackOnChangeRequirementStage({ target: { value: values }}, 'to_stage_authorized_emails')
  }

  const toStageAuthorizedEmailsSelectInput = () => {

    if(toStageDefaultAuthorizedUsers.length > 0){
      return(
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ _.get(requirementStage, 'errors.to_stage_authorized_emails') || [] }>
                <Label
                  className={ 'required' }
                  htmlFor={ inputId(formName, 'to_stage_authorized_emails') }
                >
                  { I18n.t('activerecord.attributes.workflow/requirement_stage.to_stage_authorized_emails') }
                </Label>

                <CreatableSelect
                  isMulti={ true }
                  id={ inputId(formName, 'to_stage_authorized_emails') }
                  name={ inputName(formName, 'to_stage_authorized_emails') }
                  options={ toStageAuthorizedEmailsOptions }
                  value={ toStageAuthorizedEmailsSelectedOptions }
                  onChange={ e => onChangeToStageAuthorizedEmails(e) }
                />
              </FieldError>
            </FormGroup>
          </div>
        </div>
      )
    }
  }

  // ---------- END TO STAGE AUTHORIZED EMAILS ----------
  const showWorkflowRequirementStageFormAttributesComponent = useMemo(() => {
    return _.includes(['to_proceeded', 'to_completed'], requirementStage.aasm_state_event) || requirementStage.aasm_state_event == null
  }, [requirementStage.aasm_state_event])

  const workflowRequirementStageFormAttributesComponent = () => {
    if(showWorkflowRequirementStageFormAttributesComponent){
      return(
        <WorkflowRequirementStageFormAttributes
          requirementStage={ requirementStage }
          actions={ actions }
          stageForm={ requirementStage.output_form }
          configuration={{ ... props.configuration, formName: `${ formName }[output_form_attributes]`, formType: 'output_form', defaultRequestParams: defaultRequestParams }}
          callbacks={ callbacks }
        />
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        {/*{ aasmStateInputSelect() }*/}
        { nextStageSelectInput() }
        { toStageAuthorizedEmailsSelectInput() }
        { workflowRequirementStageFormAttributesComponent() }
        { observationsInput() }
      </div>
    </div>
  )
}

export default WorkflowRequirementStageAttributes;
