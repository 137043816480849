import axios from 'axios';

export const create = (requestParams, formData, callback) => {
  let promise = axios({
    method: 'post',
    url: Routes.abstract_document_types_path({ ... {... requestParams }, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const update = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.abstract_document_type_path({ ... { ... requestParams }, format: "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
