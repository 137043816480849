import React, { useState } from "react";

import _ from "lodash";
import AbstractEntityRelatedEntityInput from "./related_entity_input";

const AbstractEntityConfigList = props => {

  const entityConfigs = _.orderBy(props?.entityConfigs, 'abstract_entity_type.order', 'asc');


  const formName = `${ props?.configuration?.formName }[entity_configs_attributes]`

  const { currentEntity } = props;

  const handleEntityConfigs = (entityConfig, indexConfig) => {
    let _entityConfigs = [ ...entityConfigs ];
    _entityConfigs[indexConfig] = entityConfig;

    if(_.isFunction(props?.callbackSetEntityConfigs)){
      props?.callbackSetEntityConfigs(_entityConfigs);
    }
  }

  const drawAbstractEntityTypeSelect = () => {
    return _.map(entityConfigs, function(entityConfig, index){
      return(
        <AbstractEntityRelatedEntityInput
          key={ `abstract-entity-related-entity-${ entityConfig.abstract_entity_type_id }` }
          currentEntity={ currentEntity }
          entityConfig={ entityConfig }
          configuration={{
            formName: `${ formName }[${ index }]`
          }}
          callbackHandleEntityConfigs={ handleEntityConfigs }
          indexConfig={ index }
        />
      )
    })
  }
  return(
    <>
      { drawAbstractEntityTypeSelect() }
    </>
  )
}

export default AbstractEntityConfigList;
