import swal from "sweetalert2";
import Rails from "@rails/ujs";

Rails.confirm = function (message, element) {
  const title = element.getAttribute("data-title")
  const size = element.getAttribute('data-size') || "";
  const type = element.getAttribute('data-type');

  const _swalSize = swalSize(size);

  let sweetBody =  {
    html: message,
    title: title,
  };

  if(_swalSize){
    sweetBody.width = _swalSize;
  }
  if(type){
    swalAlertInputConfirm(element, sweetBody)
  } else {
    swalAlert(element, sweetBody)
  }

};

const swalSize = function(size){
  switch( size ){
    case "xl":
      return "90%"
    case "lg":
      return "75%"
    case "md":
      return "50%"
  }
}

const swalAlertInputConfirm = function(element, options){
  const code = (Math.random() + 1).toString(36).substring(7);

  // No se porque no toma este código de las traducciones
  const _confirm_code = `<p>Para confirmar la acción ingresa el siguiente código</p>
        <div class='border border-primary p-3 mt-3 font-weight-bold'>${code}</div>`

  options['html'] += _confirm_code
  options['input'] = 'text'
  options['inputValidator'] = value => {
    if(value != code){
      return 'Código invalido'
    }
  }

  swalWithBootstrap
    .fire(options)
    .then((result) => {
      if (result.value == code) {
        element.removeAttribute("data-confirm");
        element.click();
      }
    });
}

const swalAlert = function(element, options){
  swalWithBootstrap
    .fire(options)
    .then((result) => {
      if (result.value) {
        element.removeAttribute("data-confirm");
        element.click();
      }
    });
}
