import React, { useMemo, useCallback } from "react";
import { FormGroup, FormFeedback, Input, Label, Button} from "reactstrap";
import I18n from 'i18n-js';

import Select from 'react-select';

const LaboralBulkMyFilterFieldForm = (props) => {
  const {
    index,
    bootstrapTableRef,
    currentFilters,
    filter,
    isOptionDisabled,
    setFilters,
    onRemoveField
  } = props;

  const bootstrapTableColumns = bootstrapTableRef?.current?.table?.props?.columns

  const getColumnFilterOptions = useCallback((key) => {
    let column = _.find(bootstrapTableColumns || {}, { dataField: key } )

    return column?.filter?.props?.options || []
  }, [currentFilters])

  const columnFilterValueOptions = getColumnFilterOptions(filter?.name)

  const columnNameFilterSelectOptions = useMemo(()=>{
    return _.map(bootstrapTableColumns, column => { return { value: column?.dataField, label: column?.text } })
  }, [])

  const valueSelectOptions = useMemo(() => {
    return _.map(columnFilterValueOptions || [], option => ({ value: String(option.value), label: option.label }))
  }, [columnFilterValueOptions])

  const valueSelectedOption = useMemo(() => {
    let selected = _.find(valueSelectOptions, { value: filter.filterVal })

    return selected ? selected : null
  }, [filter])

  const nameSelectedOption = useMemo(() => {
    let selected = _.find(columnNameFilterSelectOptions, { value: filter?.name })

    return selected ? selected : null
  }, [filter])

  const onChangeField = (event, key) => {
    let value = event.target.value;

    let fieldChanges = {
      "comparator": "LIKE",
      "filterType": "TEXT"
    }

    if(columnFilterValueOptions?.length > 0){
      fieldChanges["comparator"] = "="
      fieldChanges["filterType"] = "SELECT"
    }

    fieldChanges[key] = value

    setFilters(prevState => {
      let _filter = prevState[index]
      prevState[index] = {..._filter, ...fieldChanges}

      // En caso de que cambie el nombre del campo y no queden almacenados
      // los valores anteriories
      if(key == 'name' && !_.isEmpty(_filter.name)){
        currentFilters[_filter.name] = {}
      }

      if(prevState[index]?.name){
        currentFilters[prevState[index]?.name] = fieldChanges
      }

      bootstrapTableRef.current.handleRemoteFilterChange(currentFilters)
      return [ ... prevState ]
    })
  }

  const onChangeNameInputSelect = option => {
    onChangeField(
      { target: { value: option?.value }},
      'name'
    )
  }

  const removeFieldButton = () => {
    if(filter?.name){
      return (
        <Button
          className="mt-1"
          color="danger"
          size="sm"
          onClick={ e => onRemoveField(index) }
        >
          <i className="fas fa-trash"></i>
        </Button>
      )
    }
  }

  const nameSelectInput = () => {
    return(
      <FormGroup>
        <Select
          isClearable
          options={ columnNameFilterSelectOptions }
          value={ nameSelectedOption }
          isOptionDisabled={ option => isOptionDisabled(option) }
          onChange={ (option)=>{ onChangeNameInputSelect(option) } }
          placeholder="Seleccione Campo"
        />
      </FormGroup>
    )
  }

  const valueInput = () => {
    if(columnFilterValueOptions?.length > 0){
      return(
        <FormGroup>
          <Select
            isClearable={ true }
            value={ valueSelectedOption }
            options={ valueSelectOptions }
            onChange={ option => onChangeField({ target: { value: option?.value }}, 'filterVal') }
            placeholder="Seleccione Valor"
          />
        </FormGroup>
      )

    }else{
      return(
        <FormGroup>
          <Input
            type="text"
            defaultValue={ filter?.filterVal }
            onChange={ e => onChangeField(e, 'filterVal') }
            placeholder="Ingrese Valor"
          />
        </FormGroup>
      )
    }
  }

  return (
    <tr>
      <td className='text-center'>
        { nameSelectInput() }
      </td>
      <td className='text-center'>
        { valueInput() }
      </td>
      <td className='text-center'>
        { removeFieldButton() }
      </td>
    </tr>
  )
}

export default LaboralBulkMyFilterFieldForm;