import { MarkerType } from 'reactflow';

const defaultEdgeOptions = {
  animated: true,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: '#ddd',
  },
  markerStart: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: '#ddd',
    orient: 'auto-start-reverse'
  },
  style: {
    strokeWidth: 2,
    stroke: '#ddd',
  },
}

const defaultNodeOptions = {
  type: 'default',
  style: {
    width: 200,
    height: 100
  }
}

export { defaultEdgeOptions, defaultNodeOptions }
