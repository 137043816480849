import React, { useCallback, MouseEvent, useMemo } from "react";
import {
  ReactFlowProvider,
} from "reactflow";

import "reactflow/dist/style.css";

import WorkflowBasicFlow from './basic_flow';

const WorkflowProvider = props => {

  return (
    <div className="vh-100">
      <ReactFlowProvider>
        <WorkflowBasicFlow />
      </ReactFlowProvider>
    </div>
  );
}

export default WorkflowProvider;
