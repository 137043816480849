import React, {
  useMemo,
  useCallback,
  useEffect
} from 'react';

import I18n from "i18n-js";

import {
  Button
} from 'reactstrap';

import BulkRequestDocumentConfigForm from './form';

const BulkRequestDocumentConfigList = React.memo((props) => {
  const {
    bulkRequest,
    setBulkRequest,
    data,
    configuration: { context, formName }
  } = props;

  useEffect(() => {
    if(bulkRequest.configs.length == 0){
      onAddConfig()
    }
  }, [])

  const onAddConfig = () => {
    let configAttributes = {
      document_type_id: '',
      uploaded_by: 'upload_file_user',
      file: '',
      template_id: '',
      require_signers: false,
      require_fao: false,
      require_signers_order: false,
      autocompleted_by_entity: false,
      label: '',
      _destroy: false
    }

    setBulkRequest(prevState => {
      return {
        ... prevState,
        configs: [ ... prevState.configs, configAttributes]
      }
    })
  }

  const onChangeConfig = useCallback((configAttributes, index) => {
    setBulkRequest(prevState => {
      let configs = [ ... prevState.configs ]
      configs[index] = { ... configs[index], ...configAttributes }
      return { ... prevState, configs: configs }
    })
  }, [])

  // ---------- START CONFIG FORM ----------

  const configForms = () => {
    return _.map(bulkRequest.configs, (config, index) => {
      return (
        <BulkRequestDocumentConfigForm
          key={ `bulkRequestDocumentConfigForm${ index }` }
          bulkRequestConfig={ config }
          callbackOnChangeConfig={ onChangeConfig }
          index={ index }
          data={ data }
          configuration={ props?.configuration }
          formName={ `${ formName }[configs_attributes][${index}]`}
        />
      )
    })
  }

  // ---------- END CONFIG FORM ----------

  return(
    <div className="row">
      <div className="col-12">
        <div className="accordion" id="accordionDocs">
          { configForms() }
        </div>
        <div className="text-end">
          <Button
            outline
            color='success'
            size='sm'
            onClick={ e => onAddConfig() }
          >
            <i className="fas fa-file-medical mr-1"></i>
            { I18n.t('laboral.bulk.request.hiring_documents.form.add_config') }
          </Button>
        </div>
      </div>
    </div>
  )
})

export default BulkRequestDocumentConfigList;
