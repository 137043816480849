import React, {
  useMemo,
  useContext,
  useCallback
} from 'react';

import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
  MarkerType
} from 'reactflow';

import WorkflowDiagramContext from '../../diagram_context';

const WorkflowCustomEdgeNoEditable = ({ edge, config }) => {

  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    markerStart,
    style={}
  } = edge;

  const { type } = config;

  const isDirectionalEdge = useMemo(() => {
    return type == 'directional';
  }, [edge])

  const isBidirectionalEdge = useMemo(() => {
    return type == 'bidirectional';
  }, [edge])

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={ edgePath }
        markerEnd={ markerEnd }
        markerStart={ isBidirectionalEdge ? markerStart : '' }
        style={ style }

      />
      <EdgeLabelRenderer></EdgeLabelRenderer>
    </>
  );
}

export default React.memo(WorkflowCustomEdgeNoEditable);
