import React, {
  useContext,
  useMemo
} from 'react';

import {
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import CrmTutorialTourContext from '../tour/context/tour_context';

const PublicTutorialTourCapsuleList = props => {

  const {
    tour: {
      tour_capsules: tourCapsules
    },
    currentTourCapsule,
    callbacks
  } = props;

  return(
    <div className="row">
      <div className="col-12">
        <Nav pills className="flex-column signature_history_nav nav-justified">
          { _.map(tourCapsules, tourCapsule => {
            return(
              <PublicTutorialTourCapsuleListItem
                key={ `PublicTutorialTourCapsuleListItem${ tourCapsule.id }` }
                tourCapsule={ tourCapsule }
                currentTourCapsule={ currentTourCapsule }
                callbacks={ callbacks }
              />
            )
          })}
        </Nav>
      </div>
    </div>
  )
}

export default PublicTutorialTourCapsuleList;


const PublicTutorialTourCapsuleListItem = props => {
  const {
    tourCapsule,
    currentTourCapsule,
    callbacks: {
      setCurrentTourCapsule: callbackSetCurrentTourCapsule
    }
  } = props;

  const viewedStateIcon = useMemo(() => {
    let iconClass = 'fas fa-minus-circle'

    switch (tourCapsule.viewed_state) {
      case 'incomplete':
        iconClass = 'fas fa-clock';
        break;
      case 'completed':
        iconClass = 'fas fa-check-circle'
        break;
    }

    return <i className={ iconClass }></i>
  }, [tourCapsule])

  const viewedStateClass = useMemo(() => {
    let iconClass = 'bg-light'

    switch (tourCapsule.viewed_state) {
      case 'completed':
        iconClass = 'bg-success'
        break;
    }

    return iconClass;
  }, [tourCapsule])

  return(
    <NavItem>
      <NavLink
        active={ currentTourCapsule.id == tourCapsule.id }
        className="signature_history_nav_item"
        onClick={ event => callbackSetCurrentTourCapsule(tourCapsule) }
        style={{ cursor: "pointer "}}
      >
        <div className="d-flex align-items-center justify-content-start">
          <div className={`icon-container shadow-sm ${ viewedStateClass }`}>
            { viewedStateIcon }
          </div>
          <small className="lh-sm ml-3 text-left">
            { tourCapsule.capsule.name }
          </small>
        </div>
      </NavLink>
    </NavItem>
  )
}
