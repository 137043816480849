import React from "react";
import {
  Input
} from 'reactstrap';

const LaboralBulkLoadHiringListHidden = ({ formName, dataHidden, customFieldsHeaders }) => {

  const drawnHiringHindden=()=>{
    if(dataHidden?.length > 0){
      return(
        <div>
          { _.map( dataHidden, function(hiring){
            return(
              <div key={ _.uniqueId("hiring_hidden") }>
                <Input
                  key={_.uniqueId("controldoc_id_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][controldoc_id]` }
                  defaultValue={ hiring.controldoc_id }
                />
                <Input
                  key={_.uniqueId("hiring_id_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][hiring_identifier]` }
                  defaultValue={ hiring.hiring_id }
                />
                <Input
                  key={_.uniqueId("employee_id_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][employee_identifier]` }
                  defaultValue={ hiring.employee_id.split(" / ")[0] }
                />
                <Input
                  key={_.uniqueId("employee_name_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][employee_name]` }
                  defaultValue={ hiring.employee_id.split(" / ")[1] }
                />
                <Input
                  key={_.uniqueId("company_id_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][company_identifier]`}
                  defaultValue={ hiring.company_id.split(" / ")[0] }
                />
                <Input
                  key={_.uniqueId("company_name_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][company_name]`}
                  defaultValue={ hiring.company_id.split(" / ")[1] }
                />
                <Input
                  key={_.uniqueId("start_date_hidden")}
                  type="hidden"
                  name={`${formName}[items_attributes][][start_date]` }
                  defaultValue={ hiring.start_date }
                />
                <Input
                  key={_.uniqueId("end_date_hidden")}
                  type="hidden"
                  name={ `${formName}[items_attributes][][end_date]` }
                  defaultValue={ hiring.end_date }
                />
                { drawCustomFields(hiring) }
              </div>
            )
          })}
        </div>
      )
    }
  }

  const drawCustomFields = (item) =>{
    return _.map(customFieldsHeaders, function(field){
      return(
        <Input
          key={ _.uniqueId(`${item.field}_hidden`) }
          type="hidden"
          name={ `${formName}[items_attributes][][custom_fields][${field}]` }
          defaultValue={ item[field] }
        />
      )
    })
  }

  return(
    <div className="row">
      { drawnHiringHindden() }
    </div>
  )
}
export default LaboralBulkLoadHiringListHidden;
