import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import { Input, Button, FormText, Label, List, UncontrolledAlert } from 'reactstrap';
import ListMessages from '../../../../helper/list_message';
import swal from "sweetalert2";
import I18n from 'i18n-js';

const LaboralBulkLoadEmployeeFileInput = ({ handleValues, formName, headers, customFieldsHeaders }) => {
  const [messageException, setMessageException] = useState(false);
  const [headersFile, setHeadersFile] = useState([]);
  const [headersTranslate, setHeadersTranslate] = useState([]);

  const convertedDate = (date) => {
    let hours = Math.floor((date % 1) * 24);
    let minutes = Math.floor((((date % 1) * 24) - hours) * 60)
    return moment(new Date(Date.UTC(0, 0, date, hours-17, minutes))).format("DD/MM/YYYY");
  }

  const handleFile = (_target) => {
    let file = _target;
    let page = [];
    let isXlsx = file?.value?.split("\\")?.pop()?.includes(".xlsx");
    if(file != "" && file.type =="file" && isXlsx){
      let reader = new FileReader();
      reader.readAsArrayBuffer(file.files[0]);
      reader.onloadend = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data,{type: "array"});

        const firstSheet = workbook.SheetNames[0];
        const sheet = workbook.Sheets[firstSheet]['!ref']
        const _range = XLSX.utils.decode_range(sheet);

        const excelInJSON = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], {range: _range, blankRows: true, defval: ''});
        // permite actulizar el valor de headersTranslate ya que si se encontraba dentro de la
        // condicion el componente no alcanzaba actualizar el estado
        let _is_eq = isEqHeaders(_.keys(excelInJSON[0]));

        if(_.eq(headers.length, _.keys(excelInJSON[0]).length) && _is_eq ){
          _.each(excelInJSON, function(row) {
              let item = {};
              let col = 0;
              let values_row = _.values(row);

            _.each(headers, function(value){

              let customField = _.find(customFieldsHeaders, { code: value } )

              if(customField?.data_type == 'date' && _.isInteger(values_row[col])){
                let _date = convertedDate(values_row[col]);
                item[value] = _date;
              } else {
                item[value] = String(values_row[col]).trim()
              }

              col++;
            })
            item.row = row.__rowNum__;
            item.is_deleted = false;
            item.state = 'created';

            page.push(item);
          });

          let _filled = _.map(page, function(employee) {
            return [JSON.stringify(employee), employee];
          });

          let _pageArr = new Map(_filled);

          let _pageFilled = [..._pageArr.values()];
          handleValues(_pageFilled, "create-option");
        }else{
          setHeadersFile(_.keys(excelInJSON[0]))
          setMessageException(true)
        }
      }
    } else {
      swal.fire({
        icon: I18n.t('laboral.bulk.load.employees.form.file_input.error.icon'),
        title: I18n.t('laboral.bulk.load.employees.form.file_input.error.title'),
        text: I18n.t('laboral.bulk.load.employees.form.file_input.error.text'),
      })
    }
  }

  const drawMessageException = () =>{
    if(messageException && !!headersFile){
      return(
        <div className='col-12 mt-3' >
          <ListMessages
            headers={headersTranslate}
            headersFile={headersFile}
          />
        </div>
      )
    }
  }

  const isEqHeaders = (headersFile) => {
    let defaultHeader = _.difference(headers, _.map(customFieldsHeaders, "code"))
    let _headers = _.map(defaultHeader, function(header){
      return I18n.t(`laboral.bulk.load.employees.form.default_headers.${header}`)
    })

    let last = _.last(_headers)
    let _headersTranslate = _.concat(_.slice(_headers,0, (_headers.length -1)), _.map(customFieldsHeaders, "label"), last)

    setHeadersTranslate(_headersTranslate)

    return _.isEqual(headersFile, _headersTranslate)
  }

  return(
    <div className="row">
      <div className="col-12 mb-4">
        <Label>Subir Archivo Excel</Label>

        <Input
          type='file'
          name={ `${formName}[file]` }
          onChange={ (e) => handleFile(e.target) }
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />

        <FormText>
          { I18n.t('laboral.bulk.load.employees.form.file_input.download_template') }
          <a
            href={Routes.download_example_laboral_bulk_load_employees_path() }
            target='_blank'
            className="ml-2"
          >{ I18n.t('actions.download') }</a>
        </FormText>

        <div className='row'>
          { drawMessageException()}
        </div>
      </div>
    </div>
  )
}
export default LaboralBulkLoadEmployeeFileInput;
