import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext
} from "react";

import { Button, FormGroup, Label, Input } from "reactstrap";
import I18n from 'i18n-js';
// Form
import { useDrag, useDrop } from "react-dnd";

import {
  inputId,
  inputName,
  drawTooltipHelp,
  tooltipInitializaer
} from '../helper/form';

const CustomFieldOrderForm = React.memo((props) => {
  let customField = useMemo(() => { return props.customField}, [props.customField]);

  const { index, formName } = props;

  // Start Drag Custom Field
  const draggableRef = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: "custom-field-card",
    hover(item, monitor) {
      if (!draggableRef.current || item.index === index) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      const hoverBoundingRect = draggableRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if(_.isFunction(props?.handleMoveCustomField)){
        item.index = hoverIndex;
        props?.handleMoveCustomField(dragIndex, hoverIndex);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "custom-field-card",
    item: {
      id: customField.order,
      index: index,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(draggableRef));

  // End Drag Custom Field

  // Start Draw
  const drawOrder = () => {
    return(
      <div className="text-end">
        <div className="text-muted">
          { I18n.t("activerecord.attributes.custom_field.order") }
          &nbsp;
          { index + 1 }
        </div>
      </div>
    )
  }

  const containerClassDndContent = () => {
    let klasses = []

    klasses.push('dnd-content')
    if(isDragging){ klasses.push('is-dragging') }
    if(isOver){ klasses.push('is-over') }

    return _.join(klasses, ' ');
  }

  const getResourceType = (customField) => {
    const defaultResourceType = props?.currentEntityType?.singular_name;

    const resourceTypeMap = {
      'Laboral::Employee': 'Trabajador',
      'Laboral::Hiring': 'Contratación'
    };

    return resourceTypeMap[customField.resource_type] || defaultResourceType;
  }

  return(
    <div
      className={ `card mb-3 clip custom-field-card ${ containerClassDndContent() }`}
      ref={ draggableRef }
    >
      <div className="card-body">
        <div className="row d-flex align-items-center">
          <div className="col-md-8">
            <div className="row">
              <Input
                type="hidden"
                id={ inputId(formName, 'id') }
                name={ inputName(formName, 'id') }
                value={ customField?.id || '' }
              />

              <Input
                type="hidden"
                id={ inputId(formName, 'order') }
                name={ inputName(formName, 'order') }
                value={ customField?.order || 0 }
              />
            </div>
            <h2 className="h6 text-dark font-weight-bold d-block mb-2">
              { customField.label }
            </h2>
            <ul className="list-unstyled list-inline text-muted mb-2 mb-sm-0 small">
              <li className="list-inline-item mr-3">
                { `${I18n.t("activerecord.attributes.custom_field.resource_type")}: ${getResourceType(customField)}` }
              </li>
              <li className="list-inline-item mr-3">
                { `${I18n.t("activerecord.attributes.custom_field.code")}: ${customField.code}` }
              </li>
              <li className="list-inline-item mr-3">
                { `${I18n.t("activerecord.attributes.custom_field.data_type")}: ${I18n.t(`custom_fields.data_types.${customField.data_type}`)}` }
              </li>
              <li className="list-inline-item mr-3">
                { `${I18n.t("activerecord.attributes.custom_field.created_at")}: ${moment(customField.created_at).format('DD-MM-YYYY')}` }
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            { drawOrder() }
          </div>
        </div>
      </div>
    </div>
  )
});

export default CustomFieldOrderForm;
