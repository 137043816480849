import React from 'react';
import {
  UncontrolledTooltip
} from 'reactstrap';

export const tooltipText = text => {
  const uniqueId = _.uniqueId('tooltip_');
  return(
    <>
      <button
        type="button"
        className="btn btn-link p-0 ml-2"
        id={ uniqueId }
      >
        <i className="fas fa-info-circle text-info fa-lg"></i>
      </button>
      <UncontrolledTooltip target={ uniqueId }>
        { text }
      </UncontrolledTooltip>
    </>
  )
}
