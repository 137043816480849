import React, {
  useCallback,
  useContext,
  useMemo
} from 'react'

import {
  Handle,
  Position,
  NodeResizer,
  NodeToolbar,
  useReactFlow,
  getConnectedEdges
} from 'reactflow';

import I18n from 'i18n-js';

import WorkflowDiagramContext from '../../diagram_context';

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const WorkflowCustomNodeNoEditable = ({children, config, node }) => {
  const { id, data, isConnectable, selected } = node;
  const { type } = config;

  const {
    showPointer
  } = useContext(WorkflowDiagramContext);

  const pointerClass = showPointer ? 'show-pointer' : 'none-pointer'

   const drawHandleSourceOption = () => {
    if(type == 'input' || type == 'default'){
      return(
        <Handle
          type="source"
          position={ Position.Right }
          className='bg-primary'
          style={{ ...DEFAULT_HANDLE_STYLE }}
          isConnectable={ false }
        />
      )
    }
  }

  const drawHandleTargetOption = () => {
    if(type == 'default' || type == 'output'){
      return(
        <Handle
          type="target"
          position={ Position.Left }
          className='bg-primary'
          style={{ ...DEFAULT_HANDLE_STYLE }}
          isConnectable={ false }
        />
      )
    }
  }

  return (
    <div className={ `${ data?.isCurrent ? 'current-node' : '' } ${ pointerClass }` }>
      { children }
      { drawHandleSourceOption() }
      { drawHandleTargetOption() }
    </div>
  );
}

export default WorkflowCustomNodeNoEditable;

