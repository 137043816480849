import React, { useMemo, useContext } from 'react';

import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowDiagramContext from '../diagram_context';

const WorkflowToolbar = (props) => {

  const {
    callbacks: {
      onNodeAdd: callbackOnNodeAdd,
    }
  } = useContext(WorkflowDiagramContext);

  const drawDropdownActiosButton = useMemo(() => {
    return(
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          color='success'
          outline
         >
           Agregar etapa
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={ e => callbackOnNodeAdd(e, 'default') }
          >
            { I18n.t('activerecord.attributes.workflow/stage.node_type.default') }
          </DropdownItem>
          {/*<DropdownItem
            onClick={ e => callbackOnNodeAdd(e, 'shortlist') }
          >
            { I18n.t('activerecord.attributes.workflow/stage.node_type.shortlist') }
          </DropdownItem>*/}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }, [])

  return(
    <div className="row" style={{ position: 'absolute', zIndex: 5 }}>
      <div className="col-12 m-2">
        { drawDropdownActiosButton }
      </div>
    </div>
  )
}

export default WorkflowToolbar;
