import I18n from 'i18n-js';

const HTML_INPUT_TYPES = [
  // "button",
  // "checkbox",
  "color",
  "date",
  "datetime",
  "email",
  // "file",
  // "hidden",
  "image",
  "month",
  "number",
  "password",
  // "radio",
  "range",
  // "reset",
  // "search",
  // "submit",
  // "tel",
  "text",
  "time",
  "url",
  "week",
]

export const KARIN_LAW_DEFAULT_GROUP_NAME = [
  I18n.t('karin_law.workflow.requirements.form.victim.title'),
  I18n.t('karin_law.workflow.requirements.form.representative.title'),
  I18n.t('karin_law.workflow.requirements.form.perpetrator.title'),
]

export const ECONOMIC_CRIME_DEFAULT_GROUP_NAME = [
  I18n.t('economic_crime.workflow.requirements.form.informer.title')
]

// ---------- START FIELD ITEM ----------
export const fieldItemIsRequired = fieldItem => {
  return fieldItem.is_required || false
}

export const fieldItemHtmlInputType = fieldItem => {
  if(_.includes(HTML_INPUT_TYPES, fieldItem.data_type)){
    return fieldItem.data_type;
  } else {
    return 'text'
  }
}
// ---------- END FIELD ITEM ----------

// ---------- START FILE ITEM ----------
export const fileItemIsRequired = fileItem => {
  return fileItem.is_required || false;
}
// ---------- END FILE ITEM ----------
