import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BulkRequestZipDocumentTable from "../../../../../bulk/request/zip/document/table";
import BootstrapTableSortHeader from "../../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../../helper/bootstrap-table/filter/filter_header";
import { defaultTableHeaderStyleWhite } from "../../../../../helper/bootstrap-table/helper";

import { index as indexLaboralHiringDocument, getByIds as getLaboralHiringDocumentByIds } from "../../../../../laboral/hiring_document/axios";
import { create } from "../../../../../laboral/bulk/request/zip/document/axios";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

import { limitCharacters } from "../../../../../helper/form"

const LaboralBulkRequestZipDocumentForm = props => {
  const { companies, documentTypes, aasmStates, securityLayers } = props?.filters;
  const { currentCustomer } = props;
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);
  const hiringStateOptions = [ {value: 'active', label: I18n.t('laboral.hirings.index.tabs.active') }, { value: 'expired', label: I18n.t('laboral.hirings.index.tabs.expired')}]

  // Ref
  const bootstrapTable = useRef(null);

  const getEmployeeSecurityLayerColumn = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `entity.employee.security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        headerStyle: defaultTableHeaderStyleWhite,
        filterByFilterrific: `by_employee_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const columns = [
    {
      dataField: "entity.company.company_config.name",
      text: I18n.t("activerecord.attributes.laboral/hiring.company"),
      filterByFilterrific: "by_company",
      sortByFilterrific: "company_name_",
      myFilter: 'company.name',
      filter: selectFilter({
        placeholder: `Seleccione una ${I18n.t(
          "activerecord.attributes.laboral/hiring.company"
        )}`,
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name,
        })),
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.employee.identifier",
      text: I18n.t("activerecord.attributes.laboral/employee.identifier"),
      filterByFilterrific: "search_by_employee_identifier",
      sortByFilterrific: "employee_identifier_",
      myFilter: 'employee.identifier',
      filter: textFilter({
        placeholder: `Ingrese el identificador del ${I18n.t(
          "activerecord.attributes.laboral/hiring.employee"
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.employee.employee_config.email",
      text: I18n.t("activerecord.attributes.laboral/hiring.employee_email"),
      filterByFilterrific: "search_by_employee_email",
      sortByFilterrific: "employee_email_",
      myFilter: 'employee.email',
      filter: textFilter({
        placeholder: `Ingrese el correo electrónico del ${I18n.t(
          "activerecord.attributes.laboral/hiring.employee"
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.employee.employee_config.name",
      text: I18n.t("activerecord.attributes.laboral/hiring.employee"),
      filterByFilterrific: "search_by_employee_name",
      sortByFilterrific: "employee_name_",
      myFilter: 'employee.name',
      filter: textFilter({
        placeholder: `Ingrese el nombre del ${I18n.t(
          "activerecord.attributes.laboral/hiring.employee"
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.identifier",
      text: I18n.t("activerecord.attributes.laboral/hiring.identifier"),
      filterByFilterrific: "search_by_hiring_identifier",
      sortByFilterrific: "hiring_identifier_",
      myFilter: 'hiring.identifier',
      filter: textFilter({
        placeholder: `Ingrese el idenficador del ${I18n.t(
          "activerecord.attributes.laboral/hiring"
        )}`,
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.active_to_s",
      text: `Estado de Contratación`,
      filterByFilterrific: "by_hiring_state",
      sortByFilterrific: "",
      myFilter: 'hiring.active_to_s',
      filter: selectFilter({
        placeholder: `Seleccione un estado de contratación`,
        options: hiringStateOptions,
      }),
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.start_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`,
      filterByFilterrific: 'search_by_hiring_start_date',
      filter: textFilter({
        placeholder: `Ingrese la ${I18n.t(
          "activerecord.attributes.laboral/hiring.start_date"
        )}`,
      }),
      sortByFilterrific: "start_date_",
      myFilter: 'hiring.start_date_to_s',
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.end_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`,
      filterByFilterrific: 'search_by_hiring_end_date',
      filter: textFilter({
        placeholder: `Ingrese la ${I18n.t(
          "activerecord.attributes.laboral/hiring.end_date"
        )}`,
      }),
      sortByFilterrific: "",
      myFilter: 'hiring.end_date_to_s',
      headerStyle: defaultTableHeaderStyleWhite,
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "label",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.label"),
      filterByFilterrific: "search_by_label",
      sortByFilterrific: "label_",
      myFilter: 'document.label',
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      filter: textFilter({}),
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.document_type"),
      filterByFilterrific: "by_document_type",
      sortByFilterrific: "document_type_name_",
      myFilter: 'document_type.name',
      filter: selectFilter({
        options: _.map(documentTypes, document_type => ({
          value: document_type.id,
          label: document_type.name,
        })),
      }),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("undefined"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.created_at"),
      filterByFilterrific: 'search_by_created_at',
      filter: textFilter({
        placeholder: `Ingrese la ${I18n.t(
          "activerecord.attributes.laboral/hiring_document.created_at"
        )}`,
      }),
      sortByFilterrific: "created_at_to_s_",
      myFilter: 'document.created_at_to_s',
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "aasm_state",
      text: I18n.t("activerecord.attributes.laboral/hiring_document.aasm_state.one"),
      filterByFilterrific: "by_aasm_state",
      sortByFilterrific: "aasm_state_",
      myFilter: 'document.aasm_state',
      filter: selectFilter({
        placeholder: `Seleccione un estado`,
        options: aasmStates,
      }),
      formatter: (value, row, index) => I18n.t(`activerecord.attributes.laboral/hiring_document.aasm_state.${ value }`),
      sort: true,
      headerStyle: defaultTableHeaderStyleWhite,
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      )
    }
  ];

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `hiring.custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: defaultTableHeaderStyleWhite,
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const columnsHiringDocuments = _.concat(
    getEmployeeSecurityLayerColumn(),
    columns,
    getCustomFields(props?.customFields)
  );

  const tableConfirmDocument = (documents, drawDeleteSelectedDocument) => {
    return (
      <div className="d-flex">
        <table className="table mt-2 table-responsive-md">
          <thead>
            <tr>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.company")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.employee")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring_document.document_type")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.start_date")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.end_date")}
              </th>
              <th>
                {I18n.t("activerecord.attributes.laboral/hiring.delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(documents, function (document, index) {
              return (
                <tr key={`tr-list-documents-${document.id}`}>
                  <td>{document.entity.company.company_config.name}</td>
                  <td>{document.entity.employee.employee_config.name}</td>
                  <td>{document.document_type.name}</td>
                  <td>{document.entity.start_date_to_s}</td>
                  <td>{document.entity.end_date_to_s || "Indefinido"}</td>
                  <td>{drawDeleteSelectedDocument(document)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <BulkRequestZipDocumentTable
        showBulkMyFilter={ true }
        bulkRequest={bulkRequest}
        columns={columnsHiringDocuments}
        _ref={bootstrapTable}
        tableConfirmDocument={tableConfirmDocument}
        configuration={{
          context: "laboral",
          defaultRequestParams: {},
        }}
        actions={{
          indexDocument: indexLaboralHiringDocument,
          createService: create,
          getDocumentByIds: getLaboralHiringDocumentByIds
        }}
        buttonActions={{
          show: Routes.laboral_bulk_request_zip_document_path,
          back: Routes.laboral_bulk_request_zip_documents_path(),
        }}
      />
    </div>
  );
};

export default LaboralBulkRequestZipDocumentForm;
