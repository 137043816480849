import { createContext } from 'react'

const DocumentFormContext = createContext({
  currentUser: {},
  currentCustomer: {},
  currentEntityType: {},
  context: "",
  entities: []
});

export default DocumentFormContext;
