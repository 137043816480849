import React from 'react';

import {

} from 'reactstrap';

import WorkflowStageItemFieldList from '../stage_field_item/list';
import WorkflowStageItemFileList from '../stage_file_item/list';

const WorkflowInputStageFormAttributes = props => {

  const {
    currentNode,
    setCurrentNode,
    inputStageForm: {
      field_items: fieldItems,
      file_items: fileItems
    }
  } = props;

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowStageItemFieldList
          fieldItems={ fieldItems }
          currentNode={ currentNode }
          setCurrentNode={ setCurrentNode }
          configuration={{
            stageForm: 'input_stage_form'
          }}
          data={{}}
        />

        <WorkflowStageItemFileList
          fileItems={ fileItems }
          currentNode={ currentNode }
          setCurrentNode={ setCurrentNode }
          configuration={{
            stageForm: 'input_stage_form'
          }}
          data={{}}
        />
      </div>
    </div>
  )
}

export default WorkflowInputStageFormAttributes;
