import React, {
  useState
} from 'react';

import {
  Button,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import I18n from 'i18n-js';

import FieldError from '../../../helper/FieldError';

import EntityFolderCheckListRequirementAttributes from '../../../entity_folder/check_list_requirement/attributes'

import {
  create
} from './axios';

const AbstractEntityFolderCheckListRequirementForm = props => {
  const {
    data,
    currentEntityType,
    configuration: {
      entity: entityConfiguration
    },
    formName = 'entity_folder_check_list_requirement'
  } = props;

  const [requirement, setRequirement] = useState(props?.requirement || {})

  const defaultRequestParams = { current_entity_type_id: currentEntityType?.hashid }

  const onChangeRequirement = (_requirement) => {
    setRequirement(_requirement);
  }

  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('entity_folder.check_list_requirements.form.confirm.title'),
      html: I18n.t('entity_folder.check_list_requirements.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit()
      }
    })
  }

  const onSubmit = () => {
    const formData = getFormData();

    create(defaultRequestParams, formData, response => {
      if(response.status == 201){
        window.location = Routes.abstract_entity_path({ ... defaultRequestParams, id: response.data.folder.entity.hashid, _options: true });
      } else {
        setRequirement(response.data);
      }
    })
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, requirement.id || '');
    formData.append(`${ formName }[document_type_id]`, requirement.document_type_id || '');
    formData.append(`${ formName }[name]`, requirement.name || '');
    formData.append(`${ formName }[folder_id]`, requirement.folder_id || '');
    formData.append(`${ formName }[parent_requirement_id]`, requirement.parent_requirement_id || '');

    formData.append(`${ formName }[require_deadline_upload]`, requirement.require_deadline_upload || false);
    formData.append(`${ formName }[deadline_upload_notification_at]`, requirement.deadline_upload_notification_at || '');

    formData.append(`${ formName }[deadline_upload_at]`, requirement.deadline_upload_at || '');

    formData.append(`${ formName }[is_recurrent]`, requirement.is_recurrent || false);
    formData.append(`${ formName }[recurrence_period]`, requirement.recurrence_period || '');
    formData.append(`${ formName }[recurrence_start_at]`, requirement.recurrence_start_at || '');
    formData.append(`${ formName }[recurrence_end_at]`, requirement.recurrence_end_at || '');

    formData.append(`${ formName }[recurrence_deadline_upload_days]`, requirement.recurrence_deadline_upload_days || '');
    formData.append(`${ formName }[allow_clone]`, requirement.allow_clone || false);
    formData.append(`${ formName }[_destroy]`, requirement._destroy || false);

    return formData;
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-material">
          <div className="card-body">
            <EntityFolderCheckListRequirementAttributes
              requirement={ requirement }
              index={ 0 }
              configuration={{
                formName: formName,
                entity: {
                  label: currentEntityType.singular_name
                },
                destroy: {
                  hideButton: true
                }
              }}
              data={ data }
              callbacks={{
                callbackOnChangeRequirement: onChangeRequirement
              }}
            />
          </div>
        </div>

        <div className="text-end">
          <a
            href={ Routes.abstract_entity_path({ ... defaultRequestParams, id: requirement.folder.entity.hashid, _options: true }) }
            className='btn btn-default'
          >
            { I18n.t('actions.back') }
          </a>

          <Button
            color='success'
            className='ml-2'
            onClick={ event => onConfirmSubmit() }
          >
            { I18n.t('actions.save') }
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AbstractEntityFolderCheckListRequirementForm;
