import React, {
  useState
} from 'react'

import EntityFolderCheckListSharedAttributes from '../../../entity_folder/check_list_shared/attributes';

import {
  create as createShared,
  update as updateShared,
} from './axios'

const AbstractEntityFolderCheckListSharedForm = props => {

  const {
    currentCustomer,
    currentEntityType,
    sharedObject,
    data
  } = props;

  const defaultRequestParams = { current_entity_type_id: currentEntityType.hashid }

  return(
    <div className="row">
      <div className="col-12">
        <EntityFolderCheckListSharedAttributes
          sharedObject={ sharedObject }
          data={ data }
          configuration={{
            entity: {
              label: currentEntityType?.singular_name,
            },
            formName: 'entity_folder_check_list_shared',
            defaultRequestParams: defaultRequestParams
          }}
          actions={{
            showEntityPath: Routes.abstract_entity_path,
            createShared: createShared,
            updateShared: updateShared
          }}
        />
      </div>
    </div>
  )
}

export default AbstractEntityFolderCheckListSharedForm;
