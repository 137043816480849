import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BootstrapTableCustomFilter from "../../../../helper/bootstrap-table/table_custom_filter";

import { FormGroup, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import { pageListRenderer, options as optionPagination } from "../../../../helper/pagination";
import _ from "lodash";
import { handleTableChangeHelper } from "../../../../helper/bootstrap-table/filter/helper";
import useFilterrific from "../../../../hooks/useFilterrific";

import useFilterRequestParams from "../../../../hooks/useFilterRequestParams";

const BulkRequestZipDocumentTable = props => {
  const formName = props?.formName || "bulk_request_zip_document";

  const {
    columns,
    tableConfirmDocument,
    configuration: {
      context,
      defaultRequestParams
    },
    actions: {
      indexDocument,
      createService,
      getDocumentByIds
    },
    buttonActions
  } = props;

  const [documents, setDocuments] =  useState(props?.documents || []);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [ bulkRequest, setBulkRequest ] =  useState(props?.bulkRequest);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  // Pagination
  const [page, setPage] =  useState(1)
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] =  useState(documents.length || 10)
  const [filterrific, setFilterrific] = useFilterrific({})
  const [perPage, setPerPage] = useState(10);

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const [hide, setHide] = useState(false);

  const toggleHide = () => {
    setHide(!hide)
  }

  useEffect(() => {
    getDocuments()
  }, [requestFilterParams])

  useEffect(() => {
    if (hide === true){
      getSelectedDocuments()
    }
  }, [hide])

  const getDocuments = () => {
    let _requestFilterParams = {...requestFilterParams, ...defaultRequestParams}

    indexDocument(_requestFilterParams, response => {
      setDocuments(response.data.documents);
      setTotalSize(response.data.total);
    })
  }

  const getSelectedDocuments = () => {
    let ids = _.map(bulkRequest.items, "document_id")
    let formData = new FormData();

    _.each(ids, function (id, _index) {
      formData.append(
        `ids[]`, id || ""
      );
    });

    getDocumentByIds(defaultRequestParams, formData, response => {
      if (response?.status == 200) {
        setSelectedDocuments(response?.data?.documents)
      }
    })
  }


  const drawErrorMessage = () => {
    if (bulkRequest.items.length == 0){
      setHide(false)
      swalWithBootstrap.fire({
        icon: "error",
        title: "Oops!",
        showCloseButton: false,
        showCancelButton: false,
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Aceptar",
        text: "Debes seleccionar al menos un documento para realizar la descarga"
      })
    }
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t("bulk.request.zip.documents.form.confirm.title"),
      html: I18n.t("bulk.request.zip.documents.form.confirm.message")
    })
    .then(result => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  const drawTextSubmitButton = () => {
    if (disabledSubmitButton) {
      return "Guardando información ...";
    } else {
      return I18n.t("actions.save");
    }
  };

  const drawSubmitButton = () => {
    return (
      <Button
        color="success"
        className="ml-2"
        onClick={ handleConfirmSubmit }
        disabled={ disabledSubmitButton || selectedDocuments.length == 0}
      >
        { drawTextSubmitButton() }
      </Button>
    );
  };

  const drawNextPageButton = () => {
    return (
      <Button
        color="success"
        className="ml-2"
        onClick={ () => {toggleHide(), drawErrorMessage()} }
      >
        Siguiente
      </Button>
    );
  }

  const drawReturnPageButton = () => {
    return (
      <Button
        color="success"
        className="btn btn-default"
        onClick={ () => toggleHide() }
      >
        Volver al listado de documentos
      </Button>
    );
  }

  const handleSubmit = () => {
    let formData = getFormData();
    setDisabledSubmitButton(true);

    createService(defaultRequestParams, formData, response => {
      if (response.status == 201) {
        window.location = buttonActions.show({ ...defaultRequestParams, id: response.data.hashid, _options: true });
      } else if (response.status == 422) {
        setBulkRequest(response.data);
      }
    });
  };

  const getFormData = () => {
    let _formData = new FormData();
    _.each(bulkRequest.items, function (item, _index) {
      _formData.append(`${formName}[items_attributes][${_index}][document_id]`, item.document_id || "");
    });

    return _formData;
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    selected: _.map(bulkRequest?.items || [], item => item.document_id),
    onSelect: (row, isSelect) => {
      let _event = { target: { value: row.id, checked: isSelect } };
      handleBulkRequestData(_event, "documents");
    },
    onSelectAll: (isSelect, rows, e) => {
      handleSelectAll(isSelect, rows);
    },
  }

  const handleBulkRequestData = (e, key) => {
    let value = e?.target?.value;
    let _bulkRequest = { ...bulkRequest };

    if (key == "documents" && e.target.checked) {
      _bulkRequest.items.push({ document_id: value});
    } else if (key == "documents" && !e.target.checked) {
      _.remove(_bulkRequest.items, item => String(item.document_id) == String(value));
      _.remove(selectedDocuments, d => String(d.id) == String(value));
    }

    setBulkRequest(_bulkRequest);
  };

  const handleSelectAll = (isSelected, documents) => {
    let _bulkRequest = { ...bulkRequest };
    if (isSelected) {
      _.each(documents, h => _bulkRequest.items.push({ document_id: h.id } ));
    } else {
      let ids = _.map(documents, h => String(h.id));
      _.remove(_bulkRequest.items, function (item, index) {
        return _.includes(ids, String(item.document_id));
      });
    }

    setBulkRequest(_bulkRequest);
  };

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: props?._ref
    })
  }

  const drawDeleteSelectedDocument = document => {
    let _event = { target: { value: document.id } }
    return (
      <Button
        color="danger"
        outline
        className="border-0"
        onClick={e => handleBulkRequestData(_event, "documents")}
      >
        <i className="fas fa-trash-alt"/>
      </Button>
    )
  }

  const drawTableConfirmDocument = () => {
    if (selectedDocuments.length > 0) {
      return tableConfirmDocument(selectedDocuments, drawDeleteSelectedDocument)
    } else {
      return (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger text-center">Debes seleccionar al menos un documento</div>
          </div>
        </div>
      )
    }
  }

  const drawTabConfirm = () => {
    return(
      <div className="row">
        <div className="col-12" style={{ display: hide ? "block" :"none" }}>
          <Label className="d-flex justify-content-center h5 my-4">{`Documentos seleccionados: ${ selectedDocuments.length }`}</Label>
          { drawTableConfirmDocument() }
          <FormGroup className="text-end mt-5">
            <div>
              { drawReturnPageButton() }
              { drawSubmitButton() }
            </div>
          </FormGroup>
        </div>
      </div>
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="row" style={{ display: hide ? "none" :"block" }}>
          <div className="col-12">
            <Label className="d-flex justify-content-center h5 my-4">Lista de documentos</Label>
              <BootstrapTableCustomFilter
                showBulkMyFilter={ props?.showBulkMyFilter }
                remote={ true }
                fetchParamsConfig={{
                  entityKey: 'documents',
                  indexEntities: indexDocument,
                  requestParams: {...requestFilterParams, ...defaultRequestParams}
                }}
                keyField="id"
                selectRow={ selectRow }
                data={ documents }
                filter={ filterFactory() }
                columns={ columns }
                pagination={paginationFactory(optionPagination({
                  totalSize: totalSize,
                  sizePerPage: requestFilterParams?.per_page,
                })) }
                classes="table-responsive height-600"
                onTableChange={ handleTableChange }
                _ref={ props?._ref }
              />
          </div>
          <FormGroup className="text-end mt-5">
            <div>
              <a
                href={ buttonActions.back }
                className="btn btn-default"
              >
                { I18n.t("actions.back") }
              </a>
              { drawNextPageButton() }
            </div>
          </FormGroup>
        </div>
        {drawTabConfirm()}
      </div>
    </div>
  )
}


export default BulkRequestZipDocumentTable;
