import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useRef
} from "react";

import {
  useReactFlow
} from 'reactflow';

import {
  Label,
  Input,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';

import I18n from 'i18n-js';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'

import FieldError from "../../helper/FieldError";

import {
  validateEmail,
  inputId,
  inputName
} from '../../helper/form'

import WorkflowDiagramContext from '../diagram_context';

import WorkflowOutputStageFormAttributes from '../output_stage_form/attributes'
import WorkflowInputStageFormAttributes from '../input_stage_form/attributes'

const WorkFlowNodeForm = props => {
  const [currentNode, setCurrentNode] = useState(props?.currentNode || {})

  const [groups, setGroups] = useState([]);

  const { setNodes, getNodes, getNode } = useReactFlow();

  const {
    callbacks: {
      onNodeUpdate,
      unSelectNodeOnEdit,
      isNodeInput,
      isNodeOutput,
      isNodeDefault,
      isNodeShortlist
    },
    configuration: {
      stage: {
        defaultRequestParams: stageDefaultRequestParams,
        formName: stageFormName
      },
      group: {
        defaultRequestParams: groupDefaultRequestParams
      },
      context
    },
    routes: {
      requirementPublicPath
    },
    refs: { modalNodeFormRef },
    helpers: { getStageFormData },
    data: {
      shortlist: {
        projects: shortlistProjects
      }
    },
    services: {
      stage: {
        updateService: updateStageService
      },
      group: {
        indexService: indexGroupService
      }
    }
  } = useContext(WorkflowDiagramContext);


  const onChangeCurrentNode = ( event, key ) => {
    let value = event?.target?.value;
    let _currentNode = { ... currentNode }

    if(event.target.type == 'radio' && key == 'data.is_public'){
      value = event.target.value == 'true' ? true : false;
    }

    if(event.target.type == 'checkbox'){
      value = event.target.checked;
    }

    if(value == true && key == "data.is_public"){
      // clear group values
      _currentNode.data.group = {}
    }

    if(key == 'data.shortlist_project'){
      const shortlist = _.find(shortlistProjects, shortlist =>  { return String(shortlist.id) == String(value) } )
      value = _.pick(shortlist, ['id', 'title', 'url'])
    }

    _.set(_currentNode, key, value)

    setCurrentNode(_currentNode);
  }

  const onSubmitStage = () => {
    let _currentNode = { ... currentNode };

    _currentNode.data.group = selectedGroup;

    const requestParams = { ... stageDefaultRequestParams, ... { id: currentNode?.data?.id }}

    const formData = getStageFormData(_currentNode);

    updateStageService(requestParams, formData, response => {
      if(response.status == 200){
        setNodes((nds) => {
          return _.map(nds, nd => {
            if(nd.id == currentNode.id){
              nd = response.data.config_react_flow
            }
            return nd
          })
        });

        unSelectNodeOnEdit()

        swalToastBootstrap.fire({
          icon: 'success',
          title: I18n.t('workflow.stages.update.successfully')
        });
      } else {
        setCurrentNode(response.data.config_react_flow)
        swalToastBootstrap.fire({
          icon: 'error',
          title: I18n.t('errors.unprocessable_entity')
        });
      }
    })
  }
  // ----------------------------------------
  // ---------- START NAME ----------
  const nameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ currentNode?.data?.errors?.name || [] }>
          <Label>
            { I18n.t('activerecord.attributes.workflow/stage.name') }
          </Label>

          <Input
            onChange={ e => onChangeCurrentNode(e, 'data.label') }
            value={ currentNode?.data?.label }
            invalid={ _.has(currentNode, 'data.errors.name') }
            autoFocus
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END NAME ----------
  // ----------------------------------------
  // ---------- START DESCRIPTION ----------
  const descriptionInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ currentNode?.data?.errors?.description || [] }>
          <Label>
            { I18n.t('activerecord.attributes.workflow/stage.description') }
          </Label>
          <Input
            type='textarea'
            value={ currentNode?.data?.description }
            onChange={ e => onChangeCurrentNode(e, 'data.description') }
            invalid={ _.has(currentNode, 'data.errors.description') }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END DESCRIPTION ----------
  // ----------------------------------------
  // ---------- START DAYS DURATION ----------
  const daysDurationInput = () => {
    if(!isNodeInput(currentNode)){
      return(
        <FormGroup>
          <FieldError errors={ currentNode?.data?.errors?.days_duration || [] }>
            <Label>
              { I18n.t('activerecord.attributes.workflow/stage.days_duration') }
            </Label>
            <Input
              type='number'
              value={ currentNode?.data?.days_duration }
              onChange={ e => onChangeCurrentNode(e, 'data.days_duration') }
              invalid={ _.has(currentNode, 'data.errors.days_duration') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- END DAYS DURATION ----------
  // ----------------------------------------
  // ---------- START RESPONSIBLE ----------
  const responsibleContainerLabel = useMemo(() => {
    if(isNodeInput(currentNode)){
      return '¿Quién puede iniciar el proceso?'
    } else {
      return I18n.t('activerecord.models.workflow/group.one')
    }
  }, [currentNode])

  const responsibleContainerBody = () => {
    if(isNodeInput(currentNode)){
      return(
        <div className="row">
          <div className="col-12">
            { isPublicRadioInput() }
            { requirementPublicLink() }
            { groupContainer() }
          </div>
        </div>
      )
    } else {
      return(
        <div className="row">
          <div className="col-12">
            { groupContainer() }
          </div>
        </div>
      )
    }
  }

  const responsibleContainer = () => {
    return(
      <div className="card card-material bg-real-light border-0 mt-4">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
            <div className="font-weight-bold">
              <i className="fas fa-id-card-alt mr-2"></i>
              { responsibleContainerLabel }
            </div>
          </div>
        </div>

        <div className="card-body">
          { responsibleContainerBody() }
        </div>
      </div>
    )
  }
  // ---------- END RESPONSIBLE
  // ----------------------------------------
  // ---------- START IS PUBLIC ----------
  const isPublicRadioInput = () => {
    if(isNodeInput(currentNode)){
      return(
        <FormGroup>
          <div className="custom-control custom-radio custom-control-inline">
            <Input
              className="custom-control-input"
              type="radio"
              value={ true }
              id={ inputId(stageFormName, 'is_public_true') }
              invalid={ _.has(currentNode.errors, 'is_public') }
              onChange={ e => onChangeCurrentNode(e, 'data.is_public') }
              checked={ currentNode?.data?.is_public }
            />
            <Label
              className="custom-control-label"
              for={ inputId(stageFormName, 'is_public_true') }
            >
              { I18n.t('activerecord.attributes.workflow/stage.is_public.true') }
            </Label>
          </div>

          <div className="custom-control custom-radio custom-control-inline">
            <Input
              className="custom-control-input"
              type="radio"
              value={ false }
              id={ inputId(stageFormName, 'is_public_false') }
              invalid={ _.has(currentNode.errors, 'is_public') }
              onChange={ e => onChangeCurrentNode(e, 'data.is_public') }
              checked={ !currentNode?.data?.is_public }
            />
            <Label
              className="custom-control-label"
              for={ inputId(stageFormName, 'is_public_false') }
            >
              { I18n.t('activerecord.attributes.workflow/stage.is_public.false') }
            </Label>
          </div>
        </FormGroup>
      )
    }
  }

  const requirementPublicLink = () => {
    if(currentNode?.data?.is_public){

      const origin = window.location.origin;

      return(
        <a href={ `${ origin }${ requirementPublicPath }`} className="fw-bold hover-icon text-info fw-bold" target="_blank">
          { `${ origin }${ requirementPublicPath }`}
          <i className="fas fa-external-link-alt ms-2 text-info fa-md fw-bold"></i>
        </a>
      )
    }
  }

  // ---------- END IS PUBLIC ----------
  // ----------------------------------------
  // ---------- START GROUP ----------
  const selectedGroup = useMemo(() => {
    return _.find(groups, g => g.id == currentNode?.data?.group?.id)
  }, [groups, currentNode?.data?.group?.id])

  const groupContainer = () => {
    if(!currentNode?.data?.is_public){
      return(
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              { groupNameInputSelect() }
            </div>
            { groupUserInputSelect() }
          </div>
        </div>
      )
    }
  }

  const groupNameInputSelect = () => {
    if(!currentNode?.data?.is_public){
      return (
        <FormGroup>
          <Label>
            { I18n.t('activerecord.attributes.workflow/group.name') }
          </Label>
          <CreatableSelect
            isClearable
            options={ groupSelectOptions }
            onChange={ onChangeGroup }
            value={ groupSelectedOption }
          />
        </FormGroup>
      )
    }
  }

  const fetchGroups = useCallback(() => {
    let groupRequestParams = groupDefaultRequestParams;
    groupRequestParams['filterrific'] = { 'show_all': true }

    indexGroupService(groupRequestParams, response => {
      if(response.status == 200 ){
        setGroups(
          _.sortBy(response.data.groups, 'name')
        );
      } else {
        console.error('Problema al obtener groups')
      }
    })
  }, [])

  const groupSelectOptions = useMemo(() => {
    return _.map(groups, g => ({ label: g.name, value: g.id }))
  }, [groups])

  const groupSelectedOption = useMemo(() => {
    return _.find(groupSelectOptions, { value: currentNode?.data?.group?.id } )
  }, [currentNode?.data?.group?.id, groups])

  const onChangeGroup = useCallback((group) => {
    const groupAttributes = {
      id: group?.value,
      name: group?.label,
      isNew: group?.__isNew__,
      group_users: []
    }

    let _groups = [... groups ]

    if(groupAttributes.isNew){
      _groups.push(groupAttributes);
      setGroups(_groups);
    }

    const value = { id: group.id }
    value['id'] = group.value;

    onChangeCurrentNode({ target: { value: value } }, 'data.group');
  }, [currentNode])
  // ---------- END GROUP ----------
  // ----------------------------------------
  // ---------- START GROUPUSER ----------
  const onChangeGroupUser = useCallback((values, event) => {
    let selectAction = event?.action;

    let _groups = [ ... groups ];

    const groupIndex = _.findIndex(_groups, {id: currentNode?.data?.group.id } );

    if(selectAction == 'select-option' && validateEmail(event?.option?.label)){
      _groups[groupIndex].group_users.push(
        {
          id: _.uniqueId('group_user_'),
          email: event.option.label,
          isNew: true
        }
      );
    }

    if(selectAction == 'create-option' && validateEmail(event?.option?.label)){
      _groups[groupIndex].group_users.push(
        {
          id: event.option.value,
          email: event.option.label,
          isNew: event.option.__isNew__
        }
      );
    }

    if(selectAction == 'remove-value'){
      _.each(_groups[groupIndex].group_users, guser => {
        if(guser.id == event.removedValue?.value){
          guser['_destroy'] = true
        }
      })
    }

    setGroups(_groups);
  }, [groups])

  const groupUserInputSelect = () => {
    if(!currentNode?.data?.is_public && currentNode?.data?.group?.id){
      return(
        <FormGroup>
          <FieldError errors={ currentNode?.data?.errors['group.group_users'] || [] }>
            <Label>
              { I18n.t('activerecord.attributes.workflow/group.users') }
            </Label>

            <CreatableSelect
              isClearable
              value={ groupUserSelectedOption }
              isMulti
              options={ groupUserSelectOptions }
              onChange={ onChangeGroupUser }
              invalid={ _.has(currentNode, 'data.errors.group.group_users') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const groupUserSelectOptions = useMemo(() => {
    const groupUsers = _.filter(_.flatMap(groups, 'group_users'), guser => !guser._destroy)

    const uniqueGroupUsers = _.uniqBy(groupUsers, 'id')

    let options = _.map(uniqueGroupUsers, guser => {
      return { value: guser.id, label: guser.email }
    });

    return _.sortBy(options, 'label');

  }, [groups, currentNode?.data?.groups?.group_users])

  const groupUserSelectedOption = useMemo(() => {
    try {
      const groupUsers = _.filter(selectedGroup.group_users, guser => !guser._destroy)

      return _.map(groupUsers, guser => {
        return { value: guser.id, label: guser.email }
      })

    } catch(e){
      return []
    }
  }, [groups, currentNode?.data?.group])
  // ---------- END GROUPUSER ----------
  // ----------------------------------------
  // ---------- START INPUT STAGE FORM ----------
  const showInputStageForm = useMemo(() => {
    return isNodeDefault(currentNode) || isNodeOutput(currentNode) || isNodeShortlist(currentNode)
  }, [currentNode])

  const inputStageFormTab = () => {
    if(showInputStageForm){
      return(
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="input-stage-form-tab"
            data-bs-toggle="tab"
            data-bs-target="#input-stage-form"
            type="button"
            role="tab"
            aria-controls="input-stage-form"
            aria-selected="true"
          >
            { I18n.t('activerecord.models.workflow/input_stage_form.other') }
          </button>
        </li>
      )
    }
  }

  const inputStageFormContent = () => {
    if(showInputStageForm){
      return(
         <div
          className="tab-pane fade show active pt-3"
          id="input-stage-form"
          role="tabpanel"
          aria-labelledby="input-stage-form-tab"
        >
          <WorkflowInputStageFormAttributes
            inputStageForm={ currentNode?.data?.input_stage_form }
            currentNode={ currentNode }
            setCurrentNode={ setCurrentNode }
          />
        </div>
      )
    }
  }
  // ---------- END INPUT STAGE FORM ----------
  // ----------------------------------------
  // ---------- START OUTPUT STAGE FORM ----------
  const showOutputStageForm = useMemo(() => {
    return isNodeDefault(currentNode) || isNodeInput(currentNode) || isNodeOutput(currentNode) || isNodeShortlist(currentNode)
  }, [currentNode])

  const outputStageFormTab = () => {
    if(showOutputStageForm){
      return(
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="output-stage-form-tab"
            data-bs-toggle="tab"
            data-bs-target="#output-stage-form"
            type="button"
            role="tab"
            aria-controls="output-stage-form"
            aria-selected="false"
          >
            { I18n.t('activerecord.models.workflow/output_stage_form.other') }
          </button>
        </li>
      )
    }
  }

  const outputStageFormContent = () => {
    if(showOutputStageForm){
      return(
         <div
          className="tab-pane fade pt-3"
          id="output-stage-form"
          role="tabpanel"
          aria-labelledby="output-stage-form-tab"
        >
          <WorkflowOutputStageFormAttributes
            outputStageForm={ currentNode?.data?.output_stage_form }
            currentNode={ currentNode }
            setCurrentNode={ setCurrentNode }
          />
        </div>
      )
    }
  }
  // ---------- END OUTPUT STAGE FORM ----------
  // ----------------------------------------
  // ---------- START STAGE FORM ----------
  const stageForm = () => {
    return(
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills nav-justified" id="stageFormTab" role="tablist">
            { inputStageFormTab() }
            { outputStageFormTab() }
          </ul>
          <div className="tab-content" id="stageFormTabContent">
           { inputStageFormContent() }
           { outputStageFormContent() }
          </div>
        </div>
      </div>
    )
  }

  const onClickFirstTabStageForm = () => {
    const tabElement = document.querySelector('#stageFormTab > li.nav-item:first-child button')

    if(tabElement){
      tabElement.click()
    }
  }
  // ---------- END STAGE FORM ----------
  // ----------------------------------------
  // ---------- START SHORTLIST PROJECT SELECT ----------
  const shortlistContainer = () => {
    if(isNodeShortlist(currentNode)){
      return(
        <div className="card card-material bg-real-light border-0 mt-4">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                <i className="fas fa-comment mr-2"></i>
                Configuración Shortlist
              </div>
            </div>
          </div>

          <div className="card-body">
            { shortlistProjectInputSelect() }
          </div>
        </div>
      )
    }
  }


  const shortlistProjectInputSelect = () => {
    if(isNodeShortlist(currentNode)){
      return(
        <FormGroup>
          <FieldError errors={ [] }>
            <Label for={ inputId(stageFormName, 'shortlist_project') }>
              { I18n.t('activerecord.attributes.workflow/stage.shortlist_project') }
            </Label>
            <Select
              id={ inputId(stageFormName, 'shortlist_project') }
              name={ inputName(stageFormName, 'shortlist_project') }
              invalid={ _.has(currentNode, 'data.errors.shortlist_project') }
              isSearchable={ true }
              value={ shortlistProjectSelectedOption }
              onChange={ e => onChangeCurrentNode({ target: { value: e.value } }, 'data.shortlist_project') }
              options={ shortlistProjectSelectOptions }
              placeholder={ `-- Seleccionar Projecto de Shortlist --` }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const shortlistProjectSelectOptions = useMemo(() => {
    const projects = _.sortBy(shortlistProjects, 'title');

    return _.map(projects, project => {
      return { label: project.title, value: project.id }
    })
  }, [])

  const shortlistProjectSelectedOption = useMemo(() => {
    const selected = _.find(shortlistProjects, project => {
      return String(project.id) == String(currentNode?.data?.shortlist_project?.id)
    })

    if(selected){
      return { label: selected.title, value: selected.id }
    } else {
      return null;
    }
  }, [currentNode?.data?.shortlist_project])
  // ---------- END SHORTLIST PROJECT SELECT ----------
  // ----------------------------------------

  // const modalOnKeyDown = event => {
  //   if(event.key == 'Enter'){
  //     onSubmitStage()
  //   }
  // }

  const onOpenedModal = (event) => {
    onClickFirstTabStageForm();
  }

  const createEntityOnApproval = () => {
    if(false){
    // if(isNodeInput(currentNode)){
      return(
        <WorkflowStageFormEntitySettings
          currentNode={ currentNode }
          configuration={{ formName: stageFormName }}
          callbacks={{
            onChangeCurrentNode: onChangeCurrentNode
          }}
        />
      )
    }
  }

  const karinLawInfo = useMemo(() => {
    if(context == "karin_law" && isNodeInput(currentNode)){
      return(
        <div className="alert alert-info my-4 fw-bold">
          <div className="">
            El formulario de denuncia ya tiene creado por defecto los siguientes campos:
            <ul className="mb-0">
              <li>Datos del denunciante afectado/representante</li>
              <li>Datos del denunciado</li>
            </ul>
          </div>
        </div>
      )
    }
  }, [currentNode])

  return (
    <Modal
      isOpen={ modalNodeFormRef.current }
      toggle={ unSelectNodeOnEdit }
      onEnter={ fetchGroups }
      size='xl'
      // onKeyDown={ event => modalOnKeyDown(event) }
      unmountOnClose={ true }
      onOpened={ event => onOpenedModal(event) }
    >
      <ModalHeader
        toggle={ unSelectNodeOnEdit }
      >
        <i className="fas fa-project-diagram mr-2"></i>
        { I18n.t('workflow.stages.form.configuration') }
      </ModalHeader>
      <ModalBody>
        { nameInput() }
        { descriptionInput() }
        { daysDurationInput() }

        { responsibleContainer() }

        { shortlistContainer() }

        { karinLawInfo }

        { stageForm() }

        { createEntityOnApproval() }

      </ModalBody>
      <ModalFooter>
        <Button color="default" onClick={ unSelectNodeOnEdit }>
          { I18n.t('actions.close') }
        </Button>
        <Button
          color="success" onClick={ onSubmitStage }>
          Guardar Etapa
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default WorkFlowNodeForm;


const WorkflowStageFormEntitySettings = props => {

  const {
    currentNode,
    configuration: {
      formName
    },
    callbacks: {
      onChangeCurrentNode: callbackOnChangeCurrentNode
    }
  } = props;

  const {
    configuration: {
      entity: entityConfiguration,
    },
    data: {
      customFields
    }
  } = useContext(WorkflowDiagramContext);

  const createEntityOnApprovalSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'create_entity_on_approval') }
              id={ inputId(formName, 'create_entity_on_approval') }
              invalid={ _.has(currentNode?.errors, 'create_entity_on_approval') }
              onChange={ e => callbackOnChangeCurrentNode(e, 'data.create_entity_on_approval') }
              checked={ currentNode?.data?.create_entity_on_approval || false }
            />
            <Label
              className='custom-control-label'
              for={ inputId(formName, 'create_entity_on_approval') }
            >
              { I18n.t('activerecord.attributes.workflow/stage.create_entity_on_approval', { label: entityConfiguration?.label }) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }

  const customFieldsInputs = () => {
    if(currentNode?.data?.create_entity_on_approval){
      return(
        <div className="row">
          <div className="col-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Campo personlizado</th>
                  <th>Campo personlizado 2</th>
                </tr>
              </thead>
              <tbody>
                { _.map(customFields, customField => {
                  return(
                    <tr key={ `trCustomField-${ customField.id }` } >
                      <td>{ customField.label }</td>
                      <td>
                        <Select
                          id={ inputId(`${ formName }[entity_mapping_field]`, customField.code) }
                          name={ inputName(`${ formName }[entity_mapping_field]`, customField.code) }
                          value={ entityMappingOutputFieldItemSelectedOption(customField.code) }
                          onChange={ e => onChangeEntityMappingField({ target: { value: e.value }}, customField.code) }
                          options={ entityMappingOutputFieldItemsOptions() }
                          isClearable={ true }
                          placeholder={ `-- Selecciona un ${ I18n.t('activerecord.models.workflow/stage_field_item.one') } --` }
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  const entityMappingOutputFieldItemsOptions = () => {
    return _.map(currentNode.data.output_stage_form.field_items, item => {
      return { label: item.name, value: item.name }
    })
  }

  const entityMappingOutputFieldItemSelectedOption = (customFieldCode) => {
    const selectedValue = currentNode.data.entity_mapping_field[customFieldCode];

    const selectedOption = _.find(currentNode.data.output_stage_form.field_items, item => {
      return item.name == selectedValue
    })

    if(selectedOption){
      return { label: selectedOption.name, value: selectedOption.name }
    } else {
      return null;
    }
  }

  const onChangeEntityMappingField = (event, key) => {
    const value = event.target.value;

    const entityMappingField = currentNode?.data?.entity_mapping_field || {};

    entityMappingField[`${ key }`] = value;

    callbackOnChangeCurrentNode({ target: { value: entityMappingField}}, 'data.entity_mapping_field');
  }

  return(
    <div className="row">
      <div className="col-12">
        { createEntityOnApprovalSwitchInput() }
        { customFieldsInputs() }
      </div>
    </div>
  )
}

