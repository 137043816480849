import React, { useState, useEffect } from 'react';

import OtpInput from 'react-otp-input';

import {
  inputId,
  inputName,
} from '../helper/form';

const OtpInputForm = props => {

  const [pinCode, setPinCode] = useState(props?.pinCode || '')

  useEffect(() => {
    if(pinCode != props?.pinCode){
      setPinCode(props?.pinCode)
    }
  }, [props?.pinCode])

  const {
    configuration: {
      formName = '',
      attributeName = 'otp_code_token'
    } = {},
    callbacks: {
      onChangeOtpToken: callbackOnChangeOtpToken
    } = {}
  } = props

  const onChangePinCode = value => {
    setPinCode(value);

    if(_.isFunction(callbackOnChangeOtpToken)){
      callbackOnChangeOtpToken(value, attributeName);
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <OtpInput
          value={ pinCode }
          onChange={ onChangePinCode }
          numInputs={ 6 }
          renderSeparator={ <span></span> }
          inputType={ 'password' }
          inputStyle={ 'form-control ml-2 text-center' }
          skipDefaultStyles={ true }
          renderInput={ (props) => <input {...props} /> }
        />

        <input
          type="hidden"
          name={ inputName(formName, attributeName) }
          id={ inputId(formName, attributeName) }
          value={ pinCode }
        />
      </div>
    </div>
  )
}

export default OtpInputForm;
