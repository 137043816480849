import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { index as indexEntities } from './axios';
import _ from 'lodash';
import { inputId, inputName } from '../../helper/form';
import FieldError from '../../helper/FieldError';
import I18n from 'i18n-js';

const AbstractEntityRelatedEntityInput = props => {
  const {
    entityConfig,
    configuration,
    callbackHandleEntityConfigs,
    indexConfig,
    currentEntity
  } = props;

  const abstractEntityType = entityConfig?.abstract_entity_type;
  const abstractEntityId = entityConfig?.entity_id;

  const nestedEntityType = _.find(abstractEntityType.entity_type_configs, { nested_entity_type: true})

  const nestedEntitySelectedId = _.find(
      currentEntity.entity_configs, {
        abstract_entity_type_id: nestedEntityType?.entity_type_id
      }
    )?.entity_id || null

  const { formName } = configuration;
  const [entities, setEntities] = useState(null);

  const selectRef = useRef(null);

  useEffect(() => {
    if(!nestedEntityType){
      getEntities({})
    }
  }, [])

  useEffect(() => {
    if(nestedEntityType && nestedEntitySelectedId){
      getEntities({ [nestedEntityType.module_name]: nestedEntitySelectedId })
    } else if(nestedEntityType) {
      setEntities([])
    }
  }, [nestedEntitySelectedId])

  useEffect(() => {
    if(entities != null && selectRef.current?.getValue()?.length == 0){
      handleRelatedEntity('', 'entity_id')
    }
  }, [entities])

  const getEntities = (filterrificParams) => {
    let requestParams = {
      current_entity_type_id: abstractEntityType.id,
      filterrific: {
        show_all: true,
        by_related_entity_types: {}
      }
    }

    requestParams.filterrific.by_related_entity_types = _.merge(
      requestParams.filterrific.by_related_entity_types,
      filterrificParams
    );

    indexEntities(requestParams, response => {
      setEntities(response.data.entities);
    })
  }


  //Start Handler
  const handleRelatedEntity = (value, key) => {
    let _entityConfig = { ...entityConfig };
    _entityConfig[key] = value;

    if(_.isFunction(callbackHandleEntityConfigs)){
      callbackHandleEntityConfigs(_entityConfig, indexConfig);
    }
  }
  //End Handler

  //Start Draw
  const drawEntitiesSelectOptions = () => {
    return _.map(entities, function(entity){
      return { label: entity.name, value: entity.id }
    })
  }
  //End Draw

  //Start Getter
  const getEntitySelectedOption = () => {
    let selected = _.find(entities, { id: abstractEntityId });

    if(selected){
      return { label: selected.name, value: selected.id }
    } else {
      return null
    }
  }
  //End Getter

  return(
    <div className='row'>
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ entityConfig?.errors?.entity_id || [] }>
            <Label for={ inputId(formName, 'entity_id') }>
              { abstractEntityType.module_name }
            </Label>
            <Select
              id={ inputId(formName, 'entity_id') }
              name={ inputName(formName, 'entity_id') }
              invalid={ _.has(entityConfig?.errors, 'entity_id') }
              isSearchable={ true }
              value={ getEntitySelectedOption() }
              onChange={ e => handleRelatedEntity(e.value, 'entity_id') }
              options={ drawEntitiesSelectOptions() }
              placeholder={ `-- Seleccionar ${ abstractEntityType.singular_name } --` }
              ref={ selectRef }
            />
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

export default AbstractEntityRelatedEntityInput;
