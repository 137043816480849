import React, { useEffect, useState, useRef } from "react";

import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";

import I18n from 'i18n-js';

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter";
import BootstrapTableFilterHeader from "../../../helper/bootstrap-table/filter/filter_header";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pageListRenderer, options as optionPagination, } from "../../../helper/pagination";

import useFilterrific from "../../../hooks/useFilterrific";
import useFilterRequestParams from "../../../hooks/useFilterRequestParams";

import { index as indexAbstractEntity } from "../../entity/axios";

import { limitCharacters } from "../../../helper/form"

const AbstractCheckListProcessTable = props => {

  const currentEntityType = props?.currentEntityType

  const customFields = props?.customFields

  const [entities, setEntities] = useState(props?.entities || []);

  const [entityIds, setEntityIds] = useState(props?.entityIds || [])

  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(entities.length || 10);

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});


  const bootstrapTable = useRef(null);

  let [matchColumnsWithFilterrific, setMatchColumnsWithFilterrific] = useState({})

  useEffect(() => {
    let _matchColumnsWithFilterrific = {
      filters: {
        "identifier": "search_query"
      }
    }

    _.each(customFields, function(customField){
      let key = {};
      key[`custom_fields.${ customField.code }`] = "search_by_key_custom_fields"
      _.merge(_matchColumnsWithFilterrific.filters, key)
    })

    setMatchColumnsWithFilterrific(_matchColumnsWithFilterrific)
  }, [])

  const columns = [
    {
      dataField: "identifier",
      text: I18n.t("activerecord.attributes.abstract/entity.identifier"),
      headerStyle: {
        width: "200px",
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      filter: textFilter({
        placeholder: `Ingrese ${ I18n.t("activerecord.attributes.abstract/entity.identifier") }`
      }),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    }
  ]

  const columnCustomFields = () => {
    return _.map(customFields, function(customField){
      return {
        dataField: `custom_fields.${ customField.code }`,
        text: _.capitalize(customField.label),
        filter: textFilter({
          placeholder: `Ingrese ${_.lowerCase(customField.label) }`
        }),
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
        formatter:(value, row, index) => { return limitCharacters(value) } ,
      }
    })
  }

  const columnsTable = _.concat(columns, columnCustomFields())

  const handleSelectAll = (isSelected, entities) => {
    let _entityIds = [ ... entityIds ];

    if(isSelected){
      _.each(entities, h => _entityIds.push(h.id))
    } else {
      let ids = _.map(entities, h => String(h.id));

      _.remove(_entityIds, function(entityId, index){
        return _.includes(ids, String(entityId))
      })
    }

    if(_.isFunction(props?.callbackHandleProcessData)){
      let _event = { target: { value: _entityIds } }

      props.callbackHandleProcessData(_event, 'entity_ids')
    }
  }

  const handleOnSelect = ( isSelected, row) => {
    let _entityIds = [ ... entityIds ]

    if(isSelected){
      _entityIds.push(row.id)
    } else {
      _.remove(_entityIds, id => String(id) == String(row.id) )
    }

    if(_.isFunction(props?.callbackHandleProcessData)){
      let _event = { target: { value: _entityIds } }

      props.callbackHandleProcessData(_event, 'entity_ids')
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: entityIds,
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnSelect(isSelect, row)
    },
    onSelectAll: (isSelect, rows, e) => {
      handleSelectAll(isSelect, rows);
    },
  };

  useEffect(() => {
    setEntityIds(props?.entityIds || [])
  }, [props])

  useEffect(() => {
    getEntities()
  }, []);

  useEffect(() => {
    getEntities();
  }, [requestFilterParams]);

  const getEntities = () => {

    let _requestParams = { ... requestFilterParams };

    _requestParams = { ...{ ... _requestParams }, current_entity_type_id: currentEntityType.hashid }

    indexAbstractEntity(_requestParams, response => {
      setEntities(response.data.entities);
      setTotalSize(response.data.total);
    });
  };

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  }

  const drawEntityErrors = () => {
    if(props?.errors){
      return(
        <div className="alert alert-danger text-center">
          { props.errors }
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-body">
            { drawEntityErrors() }
            <BootstrapTableCustomFilter
              showBulkMyFilter={ false }
              remote={ true }
              keyField="id"
              selectRow={ selectRow }
              data={ entities }
              filter={ filterFactory() }
              columns={ columnsTable }
              pagination={ paginationFactory(optionPagination({ totalSize: totalSize, page: page } ) )}
              classes="table-responsive height-600"
              onTableChange={ handleTableChange }
              _ref={ bootstrapTable }
            />
          </div>
        </div>
      </div>
    </div>
  )

}

export default AbstractCheckListProcessTable
