import { createContext } from 'react'

const DocumentSignerConfigContext = createContext({
  resource: {
    require_signers: false,
    require_signers_order: false,
    require_fao: false,
    entity_id: false,
    isPersisted: false
  }
});

export default DocumentSignerConfigContext;
