import React from 'react';

import WorkflowRequirementCommentBox from '../../../../workflow/requirement/comment/box'

const EconomicCrimeWorkflowRequirementCommentBox = props => {
  const {
    requirement
  } = props;

  const commentDefaultRequestParams = { resource_id: requirement.hashid }
  const requirementDefaultRequestParams = {  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementCommentBox
          requirement={ requirement }
          configuration={{
            commentDefaultRequestParams: commentDefaultRequestParams,
            requirementDefaultRequestParams: requirementDefaultRequestParams,
            showInfoStage: false
          }}
          routes={{
            downloadAttachCommentPath: Routes.download_attach_comment_workflow_requirement_path,
            downloadOutputFilePath: Routes.download_output_file_item_economic_crime_workflow_requirement_stage_path,
            indexCommentPath: Routes.comment_workflow_requirements_path,
            createCommentPath: Routes.comment_workflow_requirements_path,
          }}
        />
      </div>
    </div>
  )
}

export default EconomicCrimeWorkflowRequirementCommentBox;
