import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button
} from 'reactstrap'
import Select from 'react-select'

import FieldError from "../../../helper/FieldError";
import CheckListProcessForm from '../../../check_list/process/form';

import { inputId, inputName } from '../../../helper/form';

import I18n from 'i18n-js';

import { create, update } from './axios';

const CorporateCheckListAgreementProcessForm = props => {
  return(
    <div className="row">
      <div className="col-12">
        <CheckListProcessForm
          context="corporate"
          checkListProcess={ props?.checkListProcess }
          documentTypes={ props?.documentTypes }
          customFields={ props?.customFields }
          dataFilters={ props?.dataFilters }
          actions={{
            createAction: create,
            updateAction: update,
            showPath: Routes.corporate_check_list_agreement_process_path,
            indexPath: Routes.corporate_check_list_agreement_processes_path()
          }}
        />
      </div>
    </div>
  )
}

export default CorporateCheckListAgreementProcessForm;
