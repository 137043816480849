import axios from "axios";

export const create = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.laboral_employee_documents_path({'format': 'json'}),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

export const update = (id, formData, callback) => {
  var promise = axios({
    method: 'PATCH',
    url: Routes.laboral_employee_document_path(id, { format: "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const index = (filterRequestParams , callback) => {
  var promise = axios({
    method: 'get',
    url: Routes.laboral_employee_documents_path({ ...{...filterRequestParams}, format: "json", _options: true}),
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};