import React from 'react';
import {
  Input,
  FormFeedback,
  UncontrolledTooltip
} from 'reactstrap';
import I18n from 'i18n-js';
import _ from 'lodash';

export const inputId = (formName, inputName) => {
  let _formName = formName.replace(/[\[\]']+/g, '_');
  let _inputName = inputName.replace(/[\[\]']+/g, '_');

  if(_formName[_formName.length - 1] != '_'){
    _formName = _formName + '_';
  }

  return `${_formName}${_inputName}`
}

export const inputName = (formName, inputName) => {
  return `${formName}[${inputName}]`
}

export const drawErrors = (errors, key) => {
  if(_.has(errors, key)){
    return _.map(errors[key], function(_error, _index){
      return(
        <FormFeedback key={ `error-${ key }-${ _index }`}>
          { _error }
        </FormFeedback>
      )
    })
  }
}

export const drawAlertError = (errors) => {
  if(!_.isEmpty(errors)){
    return(
      <div className="row">
        <div className="col-12">
          <div
            className="alert alert-danger alert-dismissible fade show mb-3 text-center"
            role='alert'
          > { I18n.t('errors.unprocessable_entity') }
            <button
              className="btn-close"
              aria-label="Close"
              data-bs-dismiss="alert"
              type="button"
            ></button>
          </div>
        </div>
      </div>
    )
  }
}

export const scrollToTop = () => {
  window.scrollTo(0,0);
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const validateName = (name) => {
  var re = /([^\s])/;
  return re.test(name);
}

export const drawTooltipDocumentFile = () =>{
  return(
    <button
      type="button"
      className="btn btn-link p-0 ml-2"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      title={ I18n.t('laboral.hiring_documents.new.tooltip_doc')}
    >
      <i className="fas fa-info-circle text-info fa-lg"></i>
    </button>
  )
}

export const drawTooltipHelp = (title) =>{
  return(
    <button
      type="button"
      className="btn btn-link p-0 ml-2"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      title={title}
    >
      <i className="fas fa-info-circle text-info fa-lg"></i>
    </button>
  )
}

export const drawRoute = (_module, _controller, _id = '', _format = '') => {
  let route='';
  if(_id){
    route = `/${_module}/${_controller}/${_id}${_format}`;
  }else{
    route = `/${_module}/${_controller}${_format}`;
  }
  return route;
}

export const drawSpanAlertConfig = (row) => {
  return(
    <div className="d-inline-block">
      <span id="tooltipTonAlert">
        <i className="fas fa-exclamation-circle text-danger"/>
      </span>
      <UncontrolledTooltip key={  _.uniqueId(row.id) } placement="bottom" target="tooltipTonAlert">
        { I18n.t('activerecord.errors.models.corporate/company.attributes.config') }
      </UncontrolledTooltip>
    </div>
  )
}

export const convertedDate = (date) => {
  let hours = Math.floor((date % 1) * 24);
  let minutes = Math.floor((((date % 1) * 24) - hours) * 60)
  return moment(new Date(Date.UTC(0, 0, date, hours-17, minutes))).format("DD/MM/YYYY");
}

export const handleConfirmSubmit = (e, id_form, setDisabledSubmitButton, label) => {
  e.preventDefault();
  swal.fire({
    title: `Realizar carga masiva de ${label}`,
    html: '¿Estás seguro de realizar la carga masiva?',
    cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
    confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
    reverseButtons: true,
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-success ml-3',
      cancelButton: 'btn btn-secondary'
    },
    buttonsStyling: false,
  }).then( result => {
    if(result.isConfirmed){
      let _form = document.getElementById(id_form)
      setDisabledSubmitButton(true)
      _form.submit()
    }
  })
}

export const drawTextSubmitButton = (disabledSubmitButton) => {
  if(disabledSubmitButton){
    return I18n.t('actions.saving')
  } else {
    return I18n.t('actions.save')
  }
}

export const drawBtnSubmit = (records, id_form, disabledSubmitButton, setDisabledSubmitButton, label) => {
  if( records?.length > 0 ){
    return(
      <button
        type='submit'
        className='btn btn-success ml-2'
        onClick={ e => handleConfirmSubmit(e, id_form, setDisabledSubmitButton, label) }
        disabled={ disabledSubmitButton }
      >
        { drawTextSubmitButton(disabledSubmitButton) }
      </button>
    )
  }
}

export const limitCharacters = (value) => {
  return _.truncate(value, {
    'length': 200
  }) || I18n.t("no_entry")
}

//Esta funcion fue creada para inicializar correctamente todos los tooltips que no se muestran correctamente
export const tooltipInitializaer = () => {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

export const clearInputFiles = () => {
  _.map(document.querySelectorAll('input[type="file"]'), function (_input) {
    _input.value = "";
  });
}

export const selectStyle = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};